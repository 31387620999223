import { Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppPageRoutes } from 'src/services/api/models';
import { TParentBikeModel } from 'src/stores/types/ParentBike';
import Link from '../Link';
import ParentBike from '../ParentBike';
import SearchSparePartForm from './SearchSparePartForm';

interface SearchSparePartsProps {
    clearResults: () => void;
    setSearchedTermText: (text: string) => void;
    isReturnFlow?: boolean;
    parentBike?: TParentBikeModel;
}

const SearchSpareParts = ({
    isReturnFlow = false,
    clearResults,
    setSearchedTermText,
    parentBike,
}: SearchSparePartsProps) => {
    const intl = useIntl();

    return (
        <Stack data-testid="search-spare-parts" direction="row" width="100%" sx={{ flexWrap: 'wrap' }}>
            <SearchSparePartForm
                isReturnFlow={isReturnFlow}
                clearResults={clearResults}
                setSearchedTermText={setSearchedTermText}
            />
            <Stack
                sx={{
                    width: {
                        xs: '100%',
                        lg: '50%',
                    },
                }}
                direction="row"
                justifyContent="center"
                padding="1rem"
            >
                {parentBike ? (
                    <ParentBike showWarranty={false} bike={parentBike} />
                ) : (
                    <Stack>
                        {!isReturnFlow && (
                            <Typography marginBottom="1rem">
                                <FormattedMessage id="search.spareParts.description.title" />
                            </Typography>
                        )}
                        <Typography>
                            {isReturnFlow ? (
                                <FormattedMessage
                                    id="search.spareParts.description.postscriptum_return"
                                    values={{
                                        processInfo: (
                                            <Link href={AppPageRoutes.RETURNS_PROCESS_INFORMATION}>
                                                <FormattedMessage id="returns_process_info_label" />
                                            </Link>
                                        ),
                                        defectBattery: (
                                            <Link
                                                href={intl.formatMessage({
                                                    id: 'returns.form_label_confirm_fees_link_href',
                                                })}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <FormattedMessage id="returns.defect_battery_guide_label" />
                                            </Link>
                                        ),
                                    }}
                                />
                            ) : (
                                <FormattedMessage id="search.spareParts.description.postscriptum" />
                            )}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default SearchSpareParts;
