import { Pagination, Theme } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import ListViewToggle from 'src/components/ListViewToggle';
import Loading from 'src/components/loading/Loading';
import TextFilter from 'src/components/TextFilter';
import Store from 'src/stores/RootStore';
import { DEFAULT_ITEMS_PER_PAGE } from 'src/utils/constants';
import { getNextLimitAndOffset } from 'src/utils/getNextLimitAndOffset';
import MarketingMaterialsGrid from './MarketingMaterialsGrid';
import MarketingMaterialsTable from './MarketingMaterialsTable';

interface MarketingMaterialsPageProps extends WithStyles<typeof styles> {
    store?: typeof Store.Type;
}

const MarketingMaterialsPage = inject('store')(
    observer(({ classes, store }: MarketingMaterialsPageProps) => {
        const [listView, setListView] = useState(false);
        const [textFilter, setTextFilter] = useState('');
        const [currentPage, setCurrentPage] = useState(1);

        useEffect(() => {
            const { offset: newOffset, limit } = getNextLimitAndOffset(
                store?.marketingMaterials.list.size,
                currentPage,
            );
            store?.marketingMaterials.loadMarketingMaterials(limit, newOffset);
        }, [store, currentPage]);

        // @TODO: fix this with new api, which will support searching
        const handleChangeSearch = (value: string) => {
            setTextFilter(value);
            // setCurrentPage(1);
        };

        const itemsList = store!.marketingMaterials.filteredList(textFilter, DEFAULT_ITEMS_PER_PAGE, currentPage);

        if (store!.marketingMaterials.isLoading) {
            return <Loading small />;
        }

        return (
            <>
                {store!.marketingMaterials.isLoaded ? (
                    <>
                        <div className={classes.container}>
                            <div className={classes.filters}>
                                <TextFilter
                                    labelTextId="marketingMaterials.search.label"
                                    text={textFilter}
                                    onChange={handleChangeSearch}
                                />

                                <ListViewToggle listView={listView} setListView={() => setListView(!listView)} />
                            </div>
                            {listView ? (
                                <MarketingMaterialsTable items={itemsList} />
                            ) : (
                                <MarketingMaterialsGrid items={itemsList} textFilter={textFilter} />
                            )}
                        </div>
                        <div className={classes.paginationContainer}>
                            <Pagination
                                className={classes.pagination}
                                size="small"
                                shape="rounded"
                                color="secondary"
                                count={Math.ceil(store!.marketingMaterials.totalItems / DEFAULT_ITEMS_PER_PAGE)}
                                onChange={(event, page) => {
                                    setCurrentPage(page);
                                }}
                                page={currentPage}
                            />
                        </div>
                    </>
                ) : (
                    <FormattedMessage id="general.error.somethingWentWrong" />
                )}
            </>
        );
    }),
);

function styles({ palette }: Theme) {
    return createStyles({
        container: {
            flex: 1,
            position: 'relative',
        },
        filters: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        paginationContainer: {
            textAlign: 'center',
            marginTop: '1em',
            position: 'absolute',
            bottom: 0,
            left: 240,
            right: 0,
        },
        pagination: {
            background: palette.background.default,
            padding: '0.5em 1em',
            display: 'inline-block',
            boxShadow: '0 0 15px rgba(0,0,0,.15)',
        },
    });
}

const pageWithTrackWindowScroll = trackWindowScroll(MarketingMaterialsPage);

export default withStyles(styles)(pageWithTrackWindowScroll);
