import { types } from 'mobx-state-tree';
import { ViewModeEnum } from 'src/utils/constants';

const ViewModeModel = types.union(types.literal(ViewModeEnum.DEALER), types.literal(ViewModeEnum.CONSUMER));

const Store = types
    .model('ConfigStore', {
        viewMode: types.optional(ViewModeModel, ViewModeEnum.DEALER),
    })
    .volatile(self => ({
        cart: false,
        cartReturns: false,
    }))
    .views(self => ({
        get isConsumer() {
            return self.viewMode === ViewModeEnum.CONSUMER;
        },
        get isDealer() {
            return self.viewMode === ViewModeEnum.DEALER;
        },
    }))
    .actions(self => ({
        toggleCart: () => {
            self.cart = !self.cart;
        },
        toggleCartReturns: () => {
            self.cartReturns = !self.cartReturns;
        },
        setViewMode: (mode: ViewModeEnum) => {
            if (ViewModeModel.is(mode)) {
                self.viewMode = mode;
            }
        },
        selectedViewMode: (mode: ViewModeEnum): boolean => {
            return ViewModeModel.is(mode) ? self.viewMode === mode : false;
        },
    }));

export default Store;
