import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import React, { useState, useMemo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { isAfter, isBefore, add as addDate, isWithinInterval } from 'date-fns';
import Button from 'src/components/button/Button';
import { ButtonCustomVariant } from 'src/components/button/type';
import Chip from 'src/components/Chip';
import Link from 'src/components/Link';
import { AppPageRoutes } from 'src/services/api/models';
import QueryParams from 'src/services/QueryParamsService';
import PersistentStore from 'src/stores/PersistentStore';
import Store from 'src/stores/RootStore';
import { TReturnRequest } from 'src/stores/types/ReturnRequestModel';
import {
    createTableValues,
    CustomPaginationFooter,
    defaultSort,
    TableCell,
    TableColumnTypes,
} from 'src/utils/tableColumnUtils';
import { defaultTheme } from '../withRoot';
import { CreditingStatusMap, ShippingStatusMap } from './helper';
import ReturnFilters from './ReturnFilters';
import UserStore from 'src/stores/UserStore';

interface ReturnsListProps {
    store?: typeof Store.Type;
    persistentStore?: typeof PersistentStore.Type;
    user?: typeof UserStore.Type;
}

const ReturnsList = inject(
    'store',
    'persistentStore',
    'user',
)(
    observer(({ store, persistentStore, user }: ReturnsListProps) => {
        const navigate = useNavigate();
        const intl = useIntl();
        const { returnListValues } = store?.returns;
        const lang = user?.preferredLang;

        const [warrantyFilter, setWarrantyFilter] = useState<string[]>([]);
        const [shippingFilter, setShippingFilter] = useState<string[]>([]);
        const [dateFrom, setDateFrom] = useState<string | null>(null);
        const [dateTo, setDateTo] = useState<string | null>(null);
        const [searchText, setSearchText] = useState('');

        const handleChangeSearch = (text: string | null) => {
            setSearchText(text ?? '');
        };

        // Reset query filters on unmount
        useEffect(
            () => () => {
                QueryParams.resetQueryParams();
            },
            [],
        );

        const dateFilters = useMemo(() => {
            const result: string[] = [];
            result.push(dateFrom ?? '');
            result.push(dateTo ?? '');

            return result;
        }, [dateFrom, dateTo]);

        const buildReturnsList = () => {
            const returnItemsData = returnListValues.map((returnedItem: TReturnRequest, index: number): TableCell[] => [
                {
                    id: 'itemNumber', // 0 index
                    value: returnedItem.productCode,
                    display: 'false',
                },
                { id: 'id', value: returnedItem.id, display: 'false' }, // 1 index
                {
                    id: 'createdDate', // 2 index
                    value: returnedItem.createdDate,
                    type: TableColumnTypes.date,
                    filterOptions: {
                        names: [],
                        logic(cell, filterValue) {
                            // filter Date from
                            if (filterValue[0] && !filterValue[1]) {
                                // add -1 day to include the selected date
                                return isBefore(new Date(cell), addDate(new Date(filterValue[0]), { days: -1 }));
                            }
                            // filter Date to
                            if (filterValue[1] && !filterValue[0]) {
                                // add 1 day to include the selected date
                                return isAfter(new Date(cell), addDate(new Date(filterValue[1]), { days: 1 }));
                            }
                            // filter Date between from and to
                            if (filterValue[0] && filterValue[1]) {
                                return !isWithinInterval(new Date(cell), {
                                    // add -1 day to include the selected date
                                    start: addDate(new Date(filterValue[0]), { days: -1 }),
                                    // add 1 day to include the selected date
                                    end: addDate(new Date(filterValue[1]), { days: 1 }),
                                });
                            }
                            return false;
                        },
                    },
                    filterList: dateFilters,
                },
                { id: 'caseNumber', value: returnedItem.name, sort: false }, // 3 index (should be unique per row)
                { id: 'frameNumber', value: returnedItem.frameNumber, sort: false }, // 4 index
                { id: 'product', value: returnedItem.product, sort: false }, // 5 index
                { id: 'comments', value: returnedItem.reference, sort: false }, // 6 index
                {
                    id: 'warranty', // 7 index
                    value: returnedItem.creditingStatus,
                    sort: false,
                    filterList: warrantyFilter,
                    filterOptions: {
                        logic(cell, filterValue) {
                            return !filterValue.includes(String(cell));
                        },
                    },
                    type: TableColumnTypes.custom,
                    customRender: (status?: number) =>
                        CreditingStatusMap.has(status) ? (
                            <Chip
                                label={<FormattedMessage id={`creditingStatus_${status}`} />}
                                color={CreditingStatusMap.get(status).color}
                            />
                        ) : (
                            <></>
                        ),
                },
                {
                    id: 'shipping', // 8 index
                    value: returnedItem.pickUpStatus,
                    sort: false,
                    filterList: shippingFilter,
                    filterOptions: {
                        logic(cell, filterValue) {
                            return !filterValue.includes(String(cell));
                        },
                    },
                    type: TableColumnTypes.custom,
                    customRender: (status?: number) =>
                        ShippingStatusMap.has(status) ? (
                            <Chip
                                label={<FormattedMessage id={`shippingStatus_${status}`} />}
                                color={ShippingStatusMap.get(status).color}
                            />
                        ) : (
                            <></>
                        ),
                },
                {
                    id: 'creditNote', // 9 index
                    value: returnedItem.creditNote,
                    sort: false,
                    type: TableColumnTypes.custom,
                    customRender: (creditNote?: string) =>
                        creditNote ? (
                            <Button
                                customVariant={ButtonCustomVariant.ICON_BUTTON}
                                tooltipTitle={intl.formatMessage({ id: 'download.pdf' })}
                                onClick={e => {
                                    e.stopPropagation();
                                    store?.invoices.downloadPDFbyID(creditNote, lang);
                                }}
                            >
                                <FontAwesomeIcon icon={faFileInvoiceDollar} size="sm" />
                            </Button>
                        ) : (
                            <></>
                        ),
                },
                {
                    id: 'replacementOrder', // 10 index
                    value: returnedItem.orderNumberNAV,
                    sort: false,
                    type: TableColumnTypes.custom,
                    customRender: (orderNumber?: string) => (
                        <Link
                            target="_blank"
                            rel="noreferrer"
                            href={`${AppPageRoutes.ORDERS}/${orderNumber}`}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            {orderNumber}
                        </Link>
                    ),
                },
            ]);
            return createTableValues(returnItemsData);
        };

        const renderSparePartTable = (title: string) => {
            const table = buildReturnsList();
            const data = table.data;
            const columns = table.columns;

            const options: MUIDataTableOptions = {
                filterType: 'multiselect',
                selectableRows: 'none',
                responsive: 'vertical',
                pagination: true,
                filter: false,
                search: false,
                searchText,
                onSearchChange: handleChangeSearch,
                download: false,
                print: false,
                viewColumns: false,
                elevation: 0,
                sort: true,
                page: persistentStore!.returnsTable.tablePage,
                onChangePage: persistentStore!.returnsTable.setTablePage,
                rowsPerPage: persistentStore!.returnsTable.tableRowsPerPage,
                onChangeRowsPerPage: persistentStore!.returnsTable.setTableRowsPerPage,
                onCellClick: (colData, cellMeta) => {
                    columns.forEach((column, index) => {
                        if (column.name === 'id') {
                            navigate(
                                AppPageRoutes.RETURN_REQUESTS_ITEM_DETAILS.replace(
                                    ':itemId',
                                    data[cellMeta.dataIndex][index],
                                ),
                            );
                        }
                    });
                },
                customSort: defaultSort,
                customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
                    return (
                        <CustomPaginationFooter
                            rowCount={count}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            changeRowsPerPage={changeRowsPerPage}
                            changePage={changePage}
                        />
                    );
                },
                textLabels: {
                    body: { noMatch: intl.formatMessage({ id: 'table.textLabels.noMatch' }) },
                },
            };
            return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
        };

        return (
            <>
                <ReturnFilters
                    warrantyFilter={warrantyFilter}
                    setWarrantyFilter={setWarrantyFilter}
                    shippingFilter={shippingFilter}
                    setShippingFilter={setShippingFilter}
                    dateFrom={dateFrom}
                    setDateFrom={setDateFrom}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                    searchText={searchText}
                    setSearchText={handleChangeSearch}
                />
                <FormattedMessage id="return_request.title">
                    {title => {
                        const titleString = typeof title === 'string' ? title : '';
                        return (
                            <ThemeProvider theme={customTableTheme}>
                                <div style={{ width: '100%' }}>{renderSparePartTable(titleString)}</div>
                            </ThemeProvider>
                        );
                    }}
                </FormattedMessage>
            </>
        );
    }),
);

let tableTheme: ThemeOptions = defaultTheme;
if (!tableTheme.components) {
    tableTheme.components = {};
}
tableTheme.components.MuiTableCell = { styleOverrides: { root: { padding: '8px' } } };
tableTheme.components.MUIDataTableFilterList = { styleOverrides: { chip: { display: 'none' } } };
const customTableTheme = createTheme(tableTheme);

export default ReturnsList;
