import { OrderNotificationStatusCode, OrderNotificationStatusCodeKeys } from 'src/types/commons/notifications';

export const getNotificationStatusCodeTranslationId = (statusCode: string): string => {
    const keyIndex = (Object.values(OrderNotificationStatusCode) as string[]).indexOf(statusCode);
    const keyAsString = Object.keys(OrderNotificationStatusCode)[keyIndex];
    const statusCodeAsEnum = OrderNotificationStatusCode[keyAsString as OrderNotificationStatusCodeKeys];

    switch (statusCodeAsEnum) {
    case OrderNotificationStatusCode.DRAFT:
        return 'notifications.statuses.draft';

    case OrderNotificationStatusCode.ACTIVATED:
        return 'notifications.statuses.activated';

    case OrderNotificationStatusCode.PROCESSED:
        return 'notifications.statuses.processed';

    case OrderNotificationStatusCode.BACK_ORDER:
        return 'notifications.statuses.back_order';

    case OrderNotificationStatusCode.PRE_ORDER:
        return 'notifications.statuses.pre_order';

    case OrderNotificationStatusCode.CREDIT_CHECK:
        return 'notifications.statuses.credit_check';

    case OrderNotificationStatusCode.PROFORMA_PAYMENT:
        return 'notifications.statuses.proforma_payment';

    case OrderNotificationStatusCode.READY_FOR_SHIPMENT:
        return 'notifications.statuses.ready_for_shipment';

    case OrderNotificationStatusCode.WAITING_FOR_CUSTOMER:
        return 'notifications.statuses.waiting_for_customer';

    case OrderNotificationStatusCode.OFFICE_DELIVERY:
        return 'notifications.statuses.office_delivery';

    case OrderNotificationStatusCode.SHIPPED:
        return 'notifications.statuses.shipped';

    case OrderNotificationStatusCode.ON_DEMAND:
        return 'notifications.statuses.on_demand';

    case OrderNotificationStatusCode.RECEIVED_AT_EXPERIENCE_CENTER:
        return 'notifications.statuses.received_at_experience_center';

    case OrderNotificationStatusCode.HANDED_OVER_TO_SERVICE_PARTNER:
        return 'notifications.statuses.handed_over_to_partner';

    case OrderNotificationStatusCode.READY_FOR_PICKUP:
        return 'notifications.statuses.ready_to_pickup';

    case OrderNotificationStatusCode.HANDED_OVER_TO_CUSTOMER:
        return 'notifications.statuses.handed_over_to_customer';

    case OrderNotificationStatusCode.CANCELLED:
        return 'notifications.statuses.cancelled';

    default:
        return '';
    }
};
