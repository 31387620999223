import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
    chip: {
        lineHeight: 'inherit',
        whiteSpace: 'pre-wrap',

        '& span': {
            whiteSpace: 'pre-wrap',
            padding: 0,
        },
    },
});

interface ProductAvailabilityLabelProps {
    inStock: boolean | null;
    availableFrom: string | null;
}

const ProductAvailabilityLabel = ({ inStock, availableFrom }: ProductAvailabilityLabelProps) => {
    const classes = useStyles();

    return (
        <FormattedMessage id={inStock ? 'status.inStock' : !availableFrom ? 'status.Unavailable' : 'status.outOfStock'}>
            {text => (
                <Chip
                    className={classes.chip}
                    data-testid="product-availability-badge"
                    label={!inStock ? `${text} ${dayjs(availableFrom || '').format('DD/MM/YYYY')}` : `${text}`}
                />
            )}
        </FormattedMessage>
    );
};

export default ProductAvailabilityLabel;
