import { Grid } from '@mui/material';
import localStorage from 'localstorage-ttl';
import uniq from 'lodash/uniq';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedDate } from 'react-intl';
import { CardListItem } from 'src/components/CardListItem';
import Store from 'src/stores/RootStore';
import { StorageNameSpace } from 'src/utils/storageNameSpace';

export enum ENewsLocation {
    DASHBOARD = 'DASHBOARD',
    NEWSPAGE = 'NEWSPAGE',
}

interface NewsProps {
    store?: typeof Store.Type;
    location: ENewsLocation;
}

const NewsWidget = inject('store')(
    observer(({ store, location }: NewsProps) => {
        const [readItemIds, setReadItemIds] = useState<number[]>([]);

        const newsList = store?.news.items || [];

        // Getting read news ids from local storage
        useEffect(() => {
            const readedItemIds = localStorage.get(StorageNameSpace.NEWS);
            if (!!readedItemIds) {
                setReadItemIds(JSON.parse(readedItemIds));
            }
        }, []);

        const decodeHtmlEntity = (str: string) => {
            return str.replace(/&#(\d+);/g, (match: any, dec: any) => {
                return String.fromCharCode(dec);
            });
        };

        const handleMouseEnter = (index: number) => {
            store!.news.setActiveNews(index);
        };

        const handleClick = (index: number) => {
            const newReadItemIds = uniq([...readItemIds, newsList[index]?.id]);
            setReadItemIds(newReadItemIds);
            // Saving changes in the read news ids list into local storage
            localStorage.set(StorageNameSpace.NEWS, JSON.stringify(newReadItemIds));
        };

        return (
            <div data-testid="news-list">
                <Grid container spacing={2} alignItems="stretch">
                    {newsList.map((newsItem: any, index: number) => (
                        <CardListItem
                            key={newsItem.id}
                            fullWidth={location === ENewsLocation.DASHBOARD}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onTouchEndCapture={() => handleMouseEnter(index)}
                            isActive={index === store!.news.active}
                            itemLink={newsItem.link}
                            onClickItem={() => handleClick(index)}
                            itemImage={newsItem.image}
                            unread={readItemIds.indexOf(newsItem.id) < 0}
                            itemTitle={decodeHtmlEntity(newsItem.title)}
                            itemSubTitle={
                                <FormattedDate value={newsItem.date} year="numeric" month="long" day="2-digit" />
                            }
                        />
                    ))}
                </Grid>
            </div>
        );
    }),
);

export default NewsWidget;
