import React, { PropsWithChildren } from 'react';

interface ElseProps {}

const Else = ({ children }: PropsWithChildren<ElseProps>) => (
    <>
        {children}
    </>
);

export default Else;
