import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Button, CircularProgress, FormControl, InputAdornment, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { Field, Form, Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { TextField } from 'formik-mui';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import IfNot from 'src/components/conditional/IfNot';
import LightTooltip from 'src/components/LightTooltip';
import Service from 'src/services/api/Api';
import Toast from 'src/services/Toasts';
import { GPSActivationStatus, GPSActivationTranslationKeys } from 'src/utils/constants';
import * as yup from 'yup';

interface GPSProps extends WithStyles<typeof styles> {}

interface GPSFormFields {
    frameNumber: string;
    IMEI: string;
}

const initialValues: GPSFormFields = { frameNumber: '', IMEI: '' };

const GPSPage = inject(
    'store',
    'persistentStore',
)(
    observer(({ classes }: GPSProps) => {
        const intl = useIntl();

        const handleSubmit = async (
            { frameNumber, IMEI }: GPSFormFields,
            { setSubmitting, setStatus, resetForm }: FormikHelpers<GPSFormFields>,
        ) => {
            try {
                const { status } = await Service.activateGPS(frameNumber, IMEI);

                if (status === 200) {
                    // The operation has finished successfully, displaying a toast and resetting the form
                    Toast.gpsFormSuccess(GPSActivationTranslationKeys.OK);
                    resetForm({ values: { frameNumber: '', IMEI: '' } });
                } else {
                    Toast.GeneralError();
                }
            } catch (error: any) {
                const errorStatus = error?.response?.data?.data?.status as string;

                if (errorStatus) {
                    switch (errorStatus) {
                    // The operation has an error, displaying correct error message according to the status
                    case GPSActivationStatus.ERROR_NOT_EXIST_FR:
                    case GPSActivationStatus.ERROR_NOT_ATLAS_BIKE:
                    case GPSActivationStatus.ERROR_MISSING_FN_OR_IMEI:
                    case GPSActivationStatus.ERROR_REPEATED_IMEI:
                    case GPSActivationStatus.ERROR_MESSAGE_NO_VALUE:
                    case GPSActivationStatus.ERROR_MESSAGE_NO_GENERAL_SETTINGS: {
                        Toast.gpsFormError(GPSActivationTranslationKeys[errorStatus]);
                        setStatus(intl.formatMessage({ id: GPSActivationTranslationKeys[errorStatus] }));
                        break;
                    }

                    // The operation has an unknown error, displaying a general purpose error message here
                    default:
                        Toast.GeneralError();
                        break;
                    }
                    return;
                }

                console.error(error);
                // An unexpected error has happened, displaying a general purpose error message here
                Toast.GeneralError();
            }

            setSubmitting(false);
        };

        const validationSchema = yup.object().shape({
            frameNumber: yup
                .string()
                .required(intl.formatMessage({ id: 'gps.form.frameNumber.required' }))
                .matches(/^[0-9a-zA-Z]+$/, intl.formatMessage({ id: 'gps.form.frameNumber.onlyCharacters' }))
                .min(10, intl.formatMessage({ id: 'gps.form.frameNumber.minLength' })),

            IMEI: yup
                .string()
                .required(intl.formatMessage({ id: 'gps.form.imei.required' }))
                .matches(/^[0-9]+$/, intl.formatMessage({ id: 'gps.form.imei.onlyDigits' }))
                .min(15, intl.formatMessage({ id: 'gps.form.imei.length' }))
                .max(17, intl.formatMessage({ id: 'gps.form.imei.length' })),
        });

        return (
            <div className={classes.root}>
                <Typography variant="h5" gutterBottom>
                    <FormattedMessage id="gps.pageTitle" />
                </Typography>

                <Typography variant="body1" gutterBottom>
                    <FormattedMessage id="gps.description" />
                </Typography>

                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ values, isSubmitting, status, setStatus }) => (
                        <Form className={classes.form} data-testid="gps-form">
                            {status && (
                                <div className={classes.error} data-testid="gps-form-errors">
                                    <Typography variant="body2">{status}</Typography>
                                </div>
                            )}

                            <FormControl margin="dense">
                                <Field
                                    data-testid="gps-form-frame-number-field"
                                    label={intl.formatMessage({ id: 'gps.form.frameNumber.title' })}
                                    name="frameNumber"
                                    variant="outlined"
                                    component={TextField}
                                    inputProps={{
                                        maxLength: 50,
                                        onChange: () => setStatus(null),
                                    }}
                                    value={values.frameNumber}
                                />
                            </FormControl>

                            <FormControl margin="dense">
                                <Field
                                    data-testid="gps-form-imei-field"
                                    label={intl.formatMessage({ id: 'gps.form.imei.title' })}
                                    name="IMEI"
                                    variant="outlined"
                                    component={TextField}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LightTooltip
                                                    className={classes.infoIcon}
                                                    title={intl.formatMessage({ id: 'gps.form.imei.explanation' })}
                                                >
                                                    <InfoIcon fontSize="small" />
                                                </LightTooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        maxLength: 17,
                                        onChange: () => setStatus(null),
                                    }}
                                    value={values.IMEI}
                                />
                            </FormControl>

                            <Button
                                data-testid="gps-form-submit-button"
                                className={classes.submit}
                                disabled={isSubmitting}
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                <IfNot
                                    condition={isSubmitting}
                                    otherwise={<CircularProgress size={20} color="inherit" />}
                                >
                                    <FormattedMessage id="gps.form.submit.title" />
                                </IfNot>
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }),
);

function styles({ spacing, palette }: Theme) {
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
        },
        infoIcon: {
            verticalAlign: 'middle',
            cursor: 'pointer',
        },
        error: {
            display: 'flex',
            flexDirection: 'column',
            padding: spacing(2),
            marginTop: spacing(1),
            marginBottom: spacing(1),
            color: palette.error.contrastText,
            backgroundColor: palette.error.dark,
            borderRadius: spacing(1),
        },
        submit: {
            borderRadius: spacing(0.5),
            height: 40,
            marginTop: spacing(1),
        },
    });
}

export default withStyles(styles)(GPSPage);
