import { createStyles, TextField, Theme } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface InputReferenceProps extends WithStyles<typeof styles> {
    reference: string;
    updateReference: (val: string) => void;
    unfocus?: () => void;
    testIdItem?: string;
}

const InputReference = ({ classes, reference, testIdItem, unfocus, updateReference }: InputReferenceProps) => {
    return (
        <TextField
            data-testid={`reference-input${testIdItem ? `-${testIdItem}` : ''}`}
            value={reference}
            inputProps={{
                maxLength: 20,
            }}
            onChange={input => updateReference(input.target.value)}
            label={<FormattedMessage id="cart.reference" />}
            helperText={<FormattedMessage id="labels.maxChars" />}
            fullWidth
            className={classes.textField}
            onBlur={unfocus && unfocus}
            variant="standard"
        />
    );
};

function styles({ spacing }: Theme) {
    return createStyles({
        textField: {
            marginTop: spacing(1),
        },
    });
}

export default withStyles(styles)(InputReference);
