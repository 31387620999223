import { Box, CardMedia, Theme } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React, { useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import batteryPlaceholder from 'src/assets/battery-back.svg';
import partPlaceholder from 'src/assets/part.svg';
import Image from 'src/components/Image';
import RootStore from 'src/stores/RootStore';
import { ProductCategoryTypes } from 'src/utils/constants';
import { SwiperOptions } from 'swiper';
import { register } from 'swiper/element/bundle';

// register Swiper custom elements
register();

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'swiper-container': React.DetailedHTMLProps<SwiperOptions & React.HTMLAttributes<HTMLElement>, HTMLElement>;
            'swiper-slide': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

interface ProductImageProps extends WithStyles<typeof styles> {
    store?: typeof RootStore.Type;
    type?: ProductCategoryTypes;
    id: string;
    showThumbnail?: boolean;
}

const ProductImage = inject('store')(
    observer(({ store, id, type, classes, showThumbnail = true }: ProductImageProps) => {
        store!.mediaAssets.loadImage(id, type);
        const thumbnail = store!.mediaAssets.thumbnail(id);
        const productHasGallery = store!.mediaAssets.isGallery(id);
        const productImage = store!.mediaAssets.image(id);
        const productImageGallery = store!.mediaAssets.gallery(id);

        const swiperElRef = useRef(null);

        if (!!thumbnail && showThumbnail) {
            return <LazyLoadImage className={classes.imagesResponsiveThumbnail} src={thumbnail} />;
        }

        if (productHasGallery && productImage !== '' && !showThumbnail) {
            return (
                <swiper-container ref={swiperElRef} navigation={true}>
                    {productHasGallery &&
                        productImageGallery.map((image: string) => {
                            return (
                                <swiper-slide key={image}>
                                    <Box sx={{ paddingTop: '60%', position: 'relative' }}>
                                        <Image
                                            src={image}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                position: 'absolute',
                                                top: '0',
                                                left: '0',
                                            }}
                                            loading="lazy"
                                        />
                                    </Box>
                                </swiper-slide>
                            );
                        })}
                </swiper-container>
            );
        }

        if (productImageGallery.length === 1 && productImage !== '' && !showThumbnail) {
            return <LazyLoadImage className={classes.imagesResponsive} src={productImage} />;
        }

        return (
            <CardMedia
                component={'img'}
                className={showThumbnail ? classes.mediaThumbnail : classes.media}
                image={type && type === ProductCategoryTypes.BATTERY ? batteryPlaceholder : partPlaceholder}
            />
        );
    }),
);

function styles({ palette }: Theme) {
    return createStyles({
        mediaThumbnail: {
            // ⚠️ object-fit is not supported by IE11.
            width: '100%',
            maxWidth: '200px',
            padding: '1em',
            margin: '0 auto',
        },
        imagesResponsiveThumbnail: {
            border: `1px solid ${palette.primary.light}`,
            width: '100%',
            maxWidth: '500px',
            padding: '0',
            margin: '0 auto 0.3em',
        },
        media: {
            // ⚠️ object-fit is not supported by IE11.
            width: '60%',
            maxWidth: '300px',
            padding: '1em',
            margin: '0 auto',
        },
        imagesResponsive: {
            border: `1px solid ${palette.primary.light}`,
            width: '100%',
            maxWidth: '700px',
            margin: '0 auto 1em',
        },
    });
}

export default withStyles(styles)(ProductImage);
