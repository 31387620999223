export interface OrderProduct {
    ProductCode: string;
    Quantity: number;
    Reference: string;
    FrameNumber?: string;
}

export interface CreateOrder {
    RequestedDeliveryDate?: string;
    OrderType: 'Bike' | 'Part';
    Products: Array<OrderProduct>;
    DeliveryFee?: number;
}

export enum AppPageRoutes {
    ROOT = '/',
    UNAUTHORIZED = '/unauthorized',
    DASHBOARD = '/dashboard',
    NEWS = '/news',
    ORDER_NOTIFICATIONS = '/notifications',
    BIKE_DASHBOARD = '/bike-dashboard',
    BIKE_DETAIL = '/bike-dashboard/:SFId',
    GPS = '/gps',
    PRODUCTS = '/products',
    THANK_YOU = '/thank-you',
    MARKETING_MATERIALS = '/marketing-materials',
    SPARE_PARTS = '/spare-parts',
    ORDERS = '/orders',
    ORDERS_WITH_ID_PARAM = '/orders/:orderId',
    INVOICES = '/invoices',
    INVOICE_DETAILS = '/invoices/:invoiceId',
    PAYMENT_PROCESSING = '/payment-processing',
    NEW_RETURN_REQUEST = '/new-return-request',
    RETURNS_PROCESS_INFORMATION = '/returns-process-information',
    THANK_YOU_RETURNS = '/thank-you-returns',
    RETURN_REQUESTS_SELECT_ITEM = '/return-request/:caseNumber',
    RETURN_REQUESTS = '/return-requests',
    RETURN_REQUESTS_ITEM_DETAILS = '/return-request/:itemId/details',
    SHIP_RETURN_ITEMS = '/ship-return-items',
    KNOWLEDGE_INFO = '/knowledge-info',
    CONTACT = '/contact',
}

export enum AppModules {
    REQUEST_RETURN_ITEMS = 'request-return-items',
    ORDER_PRODUCTS = 'order-products',
    ORDER_BIKES = 'order-bikes',
    DASHBOARD_RECENT_ORDERS = 'dashboard-recent-orders',
    VIEW_MODE_TOGGLE = 'view-mode-toggle',
    UNIT_PRICE_IN_ORDER_DETAIL_POPUP = 'unit-price-in-order-detail-popup',
}
