import {
    faAddressCard,
    faBicycle,
    faDolly,
    faExchange,
    faFileInvoiceDollar,
    faGraduationCap,
    faReceipt,
    faThLarge,
    faTshirt,
    faPlusLarge,
    faWrench,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { AppPageRoutes } from 'src/services/api/models';
import QueryParams from 'src/services/QueryParamsService';
import RoleBaseAccess from 'src/services/RoleBaseAccess';
import Store from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import { QLogo } from './Logo';

interface GroupRoutes {
    name: string;
    routes: ListRoutes[];
}

interface ListRoutes {
    icon: any;
    text: string;
    route: AppPageRoutes | string;
    target?: string;
    testId: string;
}
interface MenuListProps {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
}

const MenuListItems = inject(
    'store',
    'user',
)(
    observer(({ store, user }: MenuListProps) => {
        const navigate = useNavigate();

        const groupedListOfRoutes: GroupRoutes[] = [
            {
                name: 'dashboard',
                routes: [
                    {
                        icon: <QLogo width={24} style={{ display: 'flex' }} />,
                        text: 'menu.dashboard',
                        route: AppPageRoutes.DASHBOARD,
                        testId: 'menu-dashboard',
                    },
                    {
                        icon: faThLarge,
                        text: 'menu.bikeDashboard',
                        route: AppPageRoutes.BIKE_DASHBOARD,
                        testId: 'menu-bikeDashboard',
                    },
                ],
            },
            {
                name: 'menu.group.orders',
                routes: [
                    {
                        icon: faBicycle,
                        text: 'menu.products',
                        route: AppPageRoutes.PRODUCTS,
                        testId: 'menu-products',
                    },
                    {
                        icon: faWrench,
                        text: 'menu.spare_parts',
                        route: AppPageRoutes.SPARE_PARTS,
                        testId: 'menu-spare-parts',
                    },
                    {
                        icon: faTshirt,
                        text: 'menu.marketingMaterials',
                        route: AppPageRoutes.MARKETING_MATERIALS,
                        testId: 'menu-marketing-materials',
                    },
                    {
                        icon: faReceipt,
                        text: 'menu.orders',
                        route: AppPageRoutes.ORDERS,
                        testId: 'menu-orders',
                    },
                    {
                        icon: faFileInvoiceDollar,
                        text: 'menu.invoices',
                        route: AppPageRoutes.INVOICES,
                        testId: 'menu-invoices',
                    },
                ],
            },
            {
                name: 'menu.group.returns',
                routes: [
                    ...(user?.useReturnRequest
                        ? [
                              {
                                  icon: faPlusLarge,
                                  text: 'menu.newReturn',
                                  route: AppPageRoutes.NEW_RETURN_REQUEST,
                                  testId: 'menu-new-return-request',
                              },
                          ]
                        : []),
                    {
                        icon: faExchange,
                        text: 'menu.viewReturns',
                        route: AppPageRoutes.RETURN_REQUESTS,
                        testId: 'menu-return-requests',
                    },
                    ...(user?.useNewReturnRequest
                        ? [
                              {
                                  icon: faDolly,
                                  text: 'menu.shipReturns',
                                  route: AppPageRoutes.SHIP_RETURN_ITEMS,
                                  testId: 'menu-ship-return-items',
                              },
                          ]
                        : []),
                ],
            },
            {
                name: 'menu.group.information',
                routes: [
                    {
                        icon: faGraduationCap,
                        text: 'menu.knowledgeInfo',
                        route: AppPageRoutes.KNOWLEDGE_INFO,
                        testId: 'menu-knowledge-info',
                    },
                    {
                        icon: faAddressCard,
                        text: 'menu.contact',
                        route: AppPageRoutes.CONTACT,
                        testId: 'menu-contact',
                    },
                ],
            },
        ];

        // @TODO: add type to path and children
        function Match({ path, children }: { path: any; children: any }) {
            let match = useMatch(path);
            let location = useLocation();
            return children({ match, location, navigate });
        }

        return (
            <ThemeProvider theme={theme}>
                {groupedListOfRoutes.map((group: GroupRoutes) => (
                    <React.Fragment key={group.name}>
                        {group.name !== 'dashboard' && (
                            <Typography sx={{ opacity: 0.5, paddingLeft: '12px' }}>
                                <FormattedMessage id={group.name} />
                            </Typography>
                        )}
                        {group.routes.map((item: ListRoutes) => {
                            return RoleBaseAccess.isRouteAllowed(item.route as AppPageRoutes) ? (
                                <Match key={item.route} path={`${item.route}/*`}>
                                    {({ match }: { match: any }) => (
                                        <ListItem
                                            onClick={() => {
                                                if (item.route === AppPageRoutes.SPARE_PARTS) {
                                                    store!.spareParts.searchResult.clearResult();
                                                    QueryParams.resetQueryParams();
                                                }
                                                if (item.route === AppPageRoutes.NEW_RETURN_REQUEST) {
                                                    store!.spareParts.searchResult.clearResult();
                                                    QueryParams.resetQueryParams();
                                                }
                                                if (item.hasOwnProperty('target')) {
                                                    window.open(item.route, item.target);
                                                } else {
                                                    navigate(item.route);
                                                }
                                            }}
                                            key={item.text}
                                            selected={!!match}
                                            data-testid={item.testId}
                                            button
                                        >
                                            <ListItemIcon>
                                                {item.text === 'menu.dashboard' ? (
                                                    item.icon
                                                ) : (
                                                    <FontAwesomeIcon icon={item.icon} size="xl" />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText>
                                                <FormattedMessage id={item.text} />
                                            </ListItemText>
                                        </ListItem>
                                    )}
                                </Match>
                            ) : null;
                        })}
                    </React.Fragment>
                ))}
            </ThemeProvider>
        );
    }),
);

const theme = createTheme({
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#EE7728 !important',
                        color: '#FFF !important',
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: '#EE7728 !important',
                        color: '#FFF !important',
                    },
                    '&.Mui-selected:focus': {
                        backgroundColor: '#EE7728 !important',
                        color: '#FFF !important',
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Apercu !important',
                    color: 'inherit !important',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    width: '.4em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '.7em',
                    marginRight: '30px',
                    minWidth: '0px',
                    color: 'inherit',
                },
            },
        },
    },
    palette: {
        action: {
            selected: '#FFF !important',
        },
    },
});

export default MenuListItems;
