import { DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef, useState } from 'react';

const useStyles = makeStyles({
    dialogContent: {
        outline: 'none',
    },
});

const DialogContentWithRef = ({ children }: { children: React.ReactNode }) => {
    const classes = useStyles();

    const [isFocused, setIsFocused] = useState(false);

    // Ref for Dialog Content
    const refDialogContent: React.MutableRefObject<HTMLElement | undefined> = useRef();

    return (
        <DialogContent
            ref={(node: HTMLDivElement) => {
                // Write node to ref and focus it to make scroll by keyboard
                // Using together with tabIndex={0}
                refDialogContent.current = node;
                if (node && !isFocused) {
                    node.focus();
                    setIsFocused(true);
                }
            }}
            tabIndex={0}
            className={classes.dialogContent}
        >
            <>{children}</>
        </DialogContent>
    );
};

export default DialogContentWithRef;
