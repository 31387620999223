import { types } from 'mobx-state-tree';

export enum ModalType {
    ERROR = 'ERROR',
    SUCCESS = 'INFO',
    WARNING = 'WARNING',
    CONFIRMATION = 'CONFIRMATION',
    UNAUTHORIZED = 'UNAUTHORIZED',
}

interface ModalInfo {
    title: string;
    message: string;
    dismissable?: boolean;
    type: ModalType;
    buttonText: string;
    onConfirm?: () => void;
}

export const ModalModel = types
    .model('Toast', {
        title: types.string,
        message: types.string,
        dismissable: types.optional(types.boolean, true),
        type: types.union(
            types.literal(ModalType.ERROR),
            types.literal(ModalType.SUCCESS),
            types.literal(ModalType.WARNING),
            types.literal(ModalType.UNAUTHORIZED),
            types.literal(ModalType.CONFIRMATION),
        ),
        isOpen: types.optional(types.boolean, false),
        buttonText: types.string,
    })
    .volatile(self => ({
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onConfirm: () => {},
    }))
    .actions(self => ({
        set(modalInfo: ModalInfo) {
            self.title = modalInfo.title;
            self.message = modalInfo.message;
            self.buttonText = modalInfo.buttonText;
            self.type = modalInfo.type;
            self.isOpen = true;
            self.dismissable = modalInfo.dismissable ? modalInfo.dismissable : false;
            if (modalInfo.onConfirm) {
                self.onConfirm = modalInfo.onConfirm;
            }
        },
        close() {
            self.isOpen = false;
        },
    }));

const ModalStore = ModalModel.create({
    title: '',
    message: '',
    type: ModalType.ERROR,
    buttonText: '',
});

export default ModalStore;
