import { flow, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';
import { UserPreferences } from 'src/types/commons/users';
import getLanguage from 'src/utils/getBrowserLanguage';
import Security from '../services/Security';
import { UserRoles } from '../utils/constants';

export interface UserModel {
    userId: string;
    name: string;
    accountId: string;
    accountNumber: string;
    role: UserRoles | null;
    paymentsEnabled: boolean;
    showConsumerPrices?: boolean;
    preferredLang: string;
    useReturnRequest?: boolean;
    useNewReturnRequest?: boolean;
}

const UserStore = types
    .model('UserStore', {
        token: types.optional(types.string, ''),
    })
    .volatile(
        (): UserModel => ({
            userId: '',
            name: '',
            accountId: '',
            accountNumber: '',
            role: null,
            paymentsEnabled: false,
            showConsumerPrices: false,
            // Default language is taken from browser
            preferredLang: getLanguage(),

            // Feature flag for ANY return request flow
            useReturnRequest: false,

            // Feature flag for new return request flow
            useNewReturnRequest: false,
        }),
    )
    .volatile(() => ({
        loading: false,
        // State for user mode switch
        showConsumerPricesEnabled: false,
    }))
    .views(self => ({
        get isAuthenticated() {
            return !!(Security.validUserCookie() && self.token);
        },
    }))
    .actions(self => ({
        setToken(token: string) {
            if (token) {
                self.token = token;
            } else {
                throw Error('No auth provided');
            }
        },
        setUser(user: Partial<UserModel>) {
            if (UserStore.is(user)) {
                self.userId = user.userId;
                self.name = user.name;
                self.accountId = user.accountId;
                self.accountNumber = user.accountNumber;
                self.role = user.role;
                self.paymentsEnabled = user.paymentsEnabled;
            } else {
                throw new Error('invalid user details');
            }
        },
        cleanLogin(done?: () => void) {
            try {
                self.userId = '';
                self.name = '';
                self.accountNumber = '';
                self.accountId = '';
                self.role = null;
                self.paymentsEnabled = false;
                self.token = '';
                Security.clean();
                if (done) {
                    done();
                }
            } catch {
                console.warn('something wrong');
            }
        },

        isLangSelected: (lang: string) => {
            return self.preferredLang === lang;
        },

        // For changing language before login
        setLangPreferenceWithoutRequest: (lang: string) => {
            self.preferredLang = lang;
        },

        getUserPreferences: flow(function* () {
            self.loading = true;

            try {
                const response: any = yield Service.getUserPreferences();

                if (response?.data) {
                    self.showConsumerPrices = !!response.data?.defaultToConsumerMode;
                    self.showConsumerPricesEnabled = !!response.data?.defaultToConsumerMode;

                    // Set preferredLang, if it was setted before and it is different from the browser language
                    if (response.data.preferredLang && response.data.preferredLang !== self.preferredLang) {
                        self.preferredLang = response.data.preferredLang;
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                self.loading = false;
            }
        }),

        changeUserPreferences: flow(function* (data: UserPreferences) {
            self.loading = true;

            if (data.defaultToConsumerMode !== undefined) {
                self.showConsumerPricesEnabled = data.defaultToConsumerMode || false;
            }
            if (data.preferredLang !== undefined) {
                self.preferredLang = data.preferredLang;
            }

            try {
                yield Service.changeUserPreferences(data);
            } catch (error) {
                console.error(error);
            } finally {
                self.loading = false;
            }
        }),

        changeUseReturnRequest: (state: boolean) => {
            self.useReturnRequest = state;
        },

        changeUseNewReturnRequest: (state: boolean) => {
            self.useNewReturnRequest = state;
        },
    }));

export default UserStore;
