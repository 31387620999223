import { Chip } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

interface TagProps extends WithStyles<typeof styles> {
    translationId: string;
}

const Tag = ({ classes, translationId }: TagProps) => {
    return (
        <FormattedMessage id={translationId}>
            {text => <Chip className={classes.tag} label={`${text}`} />}
        </FormattedMessage>
    );
};

function styles() {
    return createStyles({
        tag: {
            marginLeft: '10px',
            color: 'secondary',
        },
    });
}

export default withStyles(styles)(Tag);
