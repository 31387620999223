import React, { ImgHTMLAttributes, SyntheticEvent, useRef } from 'react';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    className?: string;
    fallbackSrc?: string;
}

const Image = ({ className, fallbackSrc = '', alt = '', onError, src, style, children, ...otherProps }: ImageProps) => {
    const imageElement = useRef<HTMLImageElement>(null);

    const handleOnError = (event: SyntheticEvent<HTMLImageElement>) => {
        event.preventDefault();

        imageElement.current!.src = fallbackSrc;

        if (onError) {
            onError(event);
        }
    };

    return (
        <img
            ref={imageElement}
            className={className}
            alt={alt}
            onError={handleOnError}
            src={src || fallbackSrc}
            // @ts-ignore
            style={style}
            {...otherProps}
        />
    );
};

export default Image;
