import { MenuItem, Select } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { values } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ConsumerBatteryPriceFormat from 'src/components/ConsumerBatteryPrice';
import CurrencyFormat from 'src/components/currency/Currency';
import ProductAvailabilityLabel from 'src/components/productAvailabilityLabel';
import RootStore from 'src/stores/RootStore';
import { TProductModel, TBatteryModel } from 'src/stores/types/ProductTypes';
interface SelectBatteryProps extends WithStyles<typeof styles> {
    product: TProductModel;
    store?: typeof RootStore.Type;
    selectedBatteryCode: string;
    setSelectedBattery: (selected: string) => void;
}

const SelectBattery = inject('store')(
    observer(({ classes, product, store, selectedBatteryCode, setSelectedBattery }: SelectBatteryProps) => {
        const [isOpen, setOpen] = useState(false);

        const hideBatteryPriceDifference: boolean = store!.config.isConsumer;

        const getOption = (value: any) => {
            const foundOption = values(product.batteryOptions).find(battery => battery.id === value);
            return foundOption ? (
                foundOption.productInformation.size
            ) : (
                <span>
                    <FormattedMessage id="product.accu.empty" />
                </span>
            );
        };

        // Use the cheapest battery price for free upgrade baterry promotion
        let cheapestBattery: TBatteryModel | null;
        if (product.isBatteryUpgradePromotion) {
            cheapestBattery = product.cheapestBattery ?? null;
        }

        return (
            <Select
                data-testid="batterySelector"
                open={isOpen}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={selectedBatteryCode}
                name="selectedAccuCode"
                className={classes.select}
                displayEmpty={false}
                renderValue={(value: any) => getOption(value)}
                onChange={e => setSelectedBattery(e.target.value as string)}
            >
                <MenuItem key="empty" value="empty" data-testid="no-battery">
                    <FormattedMessage id="product.accu.empty">{text => <em>{`${text}`}</em>}</FormattedMessage>
                </MenuItem>

                {product
                    .batteryOptions!.slice()
                    .sort()
                    .map(({ productInformation, id, calculatedPrice }, index) => (
                        <MenuItem
                            key={id}
                            value={id}
                            data-testid="battery"
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                            // Disable select cheapest battery for the fre upgrade baterry bikes
                            disabled={cheapestBattery?.id === id}
                        >
                            <span>
                                <span>{productInformation.size}</span>
                                {isOpen && !hideBatteryPriceDifference ? (
                                    <span>
                                        <span className={classes.addedPrice}>{'(+'}</span>

                                        {(cheapestBattery?.calculatedPrice ?? calculatedPrice) !== null &&
                                            (store!.config.isConsumer ? (
                                                <ConsumerBatteryPriceFormat
                                                    batteryList={product.batteryOptions!.toJSON()}
                                                    currentIndex={index}
                                                    value={cheapestBattery?.calculatedPrice! ?? calculatedPrice}
                                                />
                                            ) : (
                                                <span>
                                                    <CurrencyFormat
                                                        value={cheapestBattery?.calculatedPrice! ?? calculatedPrice}
                                                    />
                                                </span>
                                            ))}

                                        <span>{')'}</span>
                                    </span>
                                ) : null}
                            </span>
                            <ProductAvailabilityLabel
                                inStock={productInformation.inStock}
                                availableFrom={productInformation.availableFrom}
                            />
                        </MenuItem>
                    ))}
            </Select>
        );
    }),
);

function styles() {
    return createStyles({
        select: {
            overflow: 'hidden',
        },
        addedPrice: {
            marginLeft: '5px',
        },
    });
}

export default withStyles(styles)(SelectBattery);
