import { Box, Typography, useTheme } from '@mui/material';
import { AxiosError } from 'axios';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'src/components/button/Button';
import { FileUpload } from 'src/components/FileUpload';
import Service from 'src/services/api/Api';
import Toast from 'src/services/Toasts';
import { CustomRequriedField, CustomSelectField } from './CustomFields';
import { DoneForm } from './DoneForm';
import { initialValues, validation, parseData } from './formHelpers';
import {
    IValues,
    SubjectOptions,
    ContactCaseSubject,
    ContactCaseReasonL3Options,
    ContactCaseReasonL4Options,
    ContactCaseReasonL4,
} from './types';

export const ContactForm = () => {
    const intl = useIntl();
    const { spacing } = useTheme();
    const gap = { break: <span /> };

    const [files, setFiles] = useState<File[]>([]);
    const [isSent, setIsSent] = useState(false);

    const handleRemoveFile = (setFieldValue: any) => (fileName: string) => {
        const newAttachemtns = files.filter(att => att.name !== fileName);
        setFiles(newAttachemtns);
        setFieldValue('attachments', newAttachemtns);
    };

    const sendCase = (values: IValues, { resetForm, setSubmitting }: any) => {
        setSubmitting(true);

        const data = parseData(values);

        Service.createCaseContact(data)
            .then(() => {
                Toast.contactCaseSent();
                setSubmitting(false);
                resetForm();
                setFiles([]);
                setIsSent(true);
            })
            .catch((error: AxiosError<any>) => {
                if (error.response?.data?.detail) {
                    Toast.FreeTextError(error.response.data.detail);
                } else {
                    Toast.GeneralError();
                }
                setSubmitting(false);
            });
    };

    const handleValidate = validation(intl.formatMessage);

    return (
        <Box width="45%" minWidth="400px">
            <Typography variant="h6" sx={{ marginBottom: spacing(2) }}>
                <FormattedMessage id="contact.form" />
            </Typography>

            {isSent ? (
                <DoneForm handleClick={() => setIsSent(false)} />
            ) : (
                <>
                    <Typography sx={{ ['& > span']: { display: 'block', marginTop: '1rem' }, marginBottom: '1rem' }}>
                        <FormattedMessage id="contact.form_description" values={gap} />
                    </Typography>

                    <Formik initialValues={initialValues} validate={handleValidate} onSubmit={sendCase}>
                        {({ values, errors, isSubmitting, setFieldValue }) => {
                            return (
                                <Form
                                    data-testid="contact-form"
                                    style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                        <CustomSelectField
                                            id="subject"
                                            label={intl.formatMessage({ id: 'contact.subjectLabel' })}
                                            options={SubjectOptions}
                                        />

                                        {values.subject === ContactCaseSubject.ORDER && (
                                            <CustomRequriedField targetName={values.subject} />
                                        )}

                                        {values.subject === ContactCaseSubject.TECHNICAL && (
                                            <CustomRequriedField targetName={values.subject} />
                                        )}

                                        {values.subject === ContactCaseSubject.RETURN && (
                                            <CustomRequriedField targetName={values.subject} />
                                        )}

                                        {values.subject === ContactCaseSubject.WORKSTATUS_ORDER && (
                                            <CustomRequriedField targetName={values.subject} />
                                        )}

                                        {values.subject === ContactCaseSubject.PRODUCT_INFO && (
                                            <>
                                                <CustomSelectField
                                                    id="reasonL3"
                                                    label={intl.formatMessage({ id: 'contact.caseReasonL3.label' })}
                                                    options={ContactCaseReasonL3Options}
                                                />
                                                <CustomSelectField
                                                    id="reasonL4"
                                                    label={intl.formatMessage({ id: 'contact.caseReasonL4.label' })}
                                                    options={ContactCaseReasonL4Options}
                                                />

                                                {values.reasonL4 === ContactCaseReasonL4.BATTERY && (
                                                    <CustomRequriedField targetName={values.reasonL4} />
                                                )}
                                            </>
                                        )}

                                        <Field
                                            data-testid="contact-form-description-field"
                                            label={intl.formatMessage({ id: 'contact.descriptionLabel' })}
                                            multiline
                                            name="description"
                                            id="description"
                                            rows="5"
                                            margin="normal"
                                            variant="outlined"
                                            component={TextField}
                                        />

                                        <FileUpload
                                            label={<FormattedMessage id="contact.select.attachments" />}
                                            handleInputChange={(e: any) => {
                                                const currentFile = e.currentTarget.files[0];
                                                if (currentFile) {
                                                    setFiles([...files, currentFile]);
                                                    setFieldValue('attachments', [...files, currentFile]);
                                                }
                                            }}
                                            value={''}
                                            isError={!!errors.attachments}
                                            errorText={!!errors.attachments ? String(errors.attachments) : ''}
                                            uploadedFiles={files}
                                            handleRemoveFile={handleRemoveFile(setFieldValue)}
                                            multiple
                                        />
                                    </div>

                                    <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'flex-end' }}>
                                        <Button
                                            data-testid="contact-form-submit-button"
                                            disabled={isSubmitting}
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            isLoading={isSubmitting}
                                        >
                                            <span>
                                                <FormattedMessage id="contact.send" />
                                            </span>
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </Box>
    );
};
