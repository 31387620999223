import { faBell, faExchange, faShoppingCart, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Badge, Button, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AppModules, AppPageRoutes } from 'src/services/api/models';
import RoleBaseAccess from 'src/services/RoleBaseAccess';
import Store from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import CONFIG from 'src/utils/config';
import useWindowSize from 'src/utils/useWindowSize';
import { Logo } from './Logo';
import ViewModeSwitcher from './ViewModeSwitcher';

const drawerWidth = 240;
interface IconWithBadgeProps {
    icon: any;
    badgeContent: ReactNode;
    color: 'secondary' | 'primary';
}

const IconWithBadge = ({ icon, badgeContent, color }: IconWithBadgeProps) => {
    return (
        <Badge badgeContent={badgeContent} color={color}>
            <FontAwesomeIcon style={{ marginRight: '5px' }} icon={icon} size="lg" />
        </Badge>
    );
};

interface AppBarCustomProps {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
    openProfileDrawer: () => void;
    openNotificationsDrawer: () => void;
    isMenuDrawerOpen: boolean;
}

const AppBarCustom = inject(
    'user',
    'store',
)(
    observer(({ store, user, openProfileDrawer, openNotificationsDrawer, isMenuDrawerOpen }: AppBarCustomProps) => {
        const [useNarrowButtons, setUseNarrowButtons] = useState(false);
        const { width: windowWidth } = useWindowSize();

        const { breakpoints, zIndex, transitions, spacing } = useTheme();
        const onlyXS = useMediaQuery(breakpoints.only('xs'));

        useEffect(() => {
            if (windowWidth) {
                setUseNarrowButtons(windowWidth < CONFIG.WINDOW_WIDTH_THRESHOLD);
            }
        }, [windowWidth]);

        const logoProps = store!.config.isConsumer
            ? {
                  logoColor: { fill: '#FFF' },
                  logoQtail: { fill: '#FFF' },
              }
            : {};

        const appBarColor = store!.config.isConsumer ? 'secondary' : 'inherit';
        const appTextColor = store!.config.isConsumer ? 'primary' : 'secondary';

        const isAppBarShift = isMenuDrawerOpen && store;
        const appBarWidth = isAppBarShift ? `calc(100% - ${drawerWidth}px)` : `calc(100% - ${spacing(7)})`;

        return (
            <AppBar
                elevation={0}
                position="absolute"
                color={appBarColor}
                sx={{
                    zIndex: zIndex.drawer,
                    width: useNarrowButtons ? '100%' : appBarWidth,
                    overflow: 'auto',
                    borderBottomColor: store?.config.isConsumer ? 'orange' : '#EEE',
                    transition: transitions.create(['width', 'margin'], {
                        easing: transitions.easing.sharp,
                        duration: isAppBarShift
                            ? transitions.duration.enteringScreen
                            : transitions.duration.leavingScreen,
                    }),

                    marginLeft: isAppBarShift ? drawerWidth : '',
                }}
            >
                <Toolbar
                    sx={{
                        '& button': {
                            minWidth: useNarrowButtons ? 'inherit' : '',
                        },
                    }}
                >
                    <Typography component="h1" variant="h4" color="inherit" noWrap flexGrow={1}>
                        <Link to={AppPageRoutes.DASHBOARD}>
                            <Logo {...logoProps} />
                        </Link>
                    </Typography>

                    {RoleBaseAccess.isModuleAllowed(AppModules.VIEW_MODE_TOGGLE) && <ViewModeSwitcher />}

                    {RoleBaseAccess.isModuleAllowed(AppModules.ORDER_PRODUCTS) && store!.cart.isLoaded && (
                        <Button
                            data-testid="menu-cart"
                            onClick={() => {
                                store!.config.toggleCart();
                                store!.cart.toggleBundleWarning();
                            }}
                            color="inherit"
                        >
                            {store!.cart.totalItemsQuantity > 0 ? (
                                <IconWithBadge
                                    badgeContent={
                                        !RoleBaseAccess.isModuleAllowed(AppModules.ORDER_BIKES) &&
                                        !store?.spareParts.loading &&
                                        store!.cart.totalSparePartsQuantity
                                            ? store!.cart.totalSparePartsQuantity
                                            : store!.cart.totalItemsQuantity
                                    }
                                    color={appTextColor}
                                    icon={faShoppingCart}
                                />
                            ) : (
                                <IconWithBadge badgeContent={0} color="secondary" icon={faShoppingCart} />
                            )}
                        </Button>
                    )}

                    {RoleBaseAccess.isModuleAllowed(AppModules.REQUEST_RETURN_ITEMS) &&
                        store!.cartReturns.totalItemsQuantity > 0 && (
                            <Button
                                data-testid="menu-returns-cart"
                                onClick={() => {
                                    store!.config.toggleCartReturns();
                                    store!.cartReturns.showBundleWarning();
                                    store!.cartReturns.showBundleWarningBatteries();
                                    store!.cartReturns.showNonReturnablePartsWarning();
                                }}
                                color="inherit"
                            >
                                <IconWithBadge
                                    badgeContent={store!.cartReturns.totalItemsQuantity}
                                    color={appTextColor}
                                    icon={faExchange}
                                />

                                {!onlyXS && <FormattedMessage id="menu.returnsCart" />}
                            </Button>
                        )}

                    <Button data-testid="menu-notifications" onClick={openNotificationsDrawer} color="inherit">
                        <IconWithBadge
                            badgeContent={store!.orderNotifications.unreadNotificationsCount}
                            color="secondary"
                            icon={faBell}
                        />
                    </Button>

                    <Button data-testid="menu-profile" onClick={openProfileDrawer} color="inherit">
                        <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faUser} size="lg" />
                        {!onlyXS && user!.name}
                    </Button>
                </Toolbar>
            </AppBar>
        );
    }),
);

export default AppBarCustom;
