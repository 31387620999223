import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Fuse from 'fuse.js';
import deburr from 'lodash/deburr';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import { applySnapshot, flow, getParent, types } from 'mobx-state-tree';
import ReactGA from 'react-ga';
import { GACategories } from 'src/services/Analytics';
import Service from 'src/services/api/Api';
import QueryParams from 'src/services/QueryParamsService';
import { AvailabilityOptions } from 'src/utils/constants';
import { logFaultyProperty, logRejectedModel } from 'src/utils/logger';
import Store from './RootStore';
import { BatteryModel, ProductModel, TBatteryModel, TProductModel } from './types/ProductTypes';

dayjs.extend(isSameOrBefore);

const ProductStore: any = types
    .model('productStore', {
        bikes: types.optional(types.map(ProductModel), {}),
        bikeSizes: types.map(types.string),
    })
    .volatile<{
        loading: boolean;
        selectedBikeHpCode: string;
        selectedBikeBatteryCode: string;
        isProductDetailDialogOpen: boolean;
        isProductDetailDialogFromCart: boolean;

        // products filters
        seriesFilter: string;
        textFilter: string;
        selectedSizesFilter?: string[];
        selectedAvailabilityFilter: string;
        hideDeprecatedFilter: boolean;
        itemsPerPage: number;
    }>(() => ({
        loading: false,
        selectedBikeHpCode: '',
        selectedBikeBatteryCode: '',
        isProductDetailDialogOpen: false,
        isProductDetailDialogFromCart: false,

        seriesFilter: '',
        textFilter: '',
        selectedSizesFilter: [],
        selectedAvailabilityFilter: '',
        hideDeprecatedFilter: false,
        itemsPerPage: 64,
    }))
    .views(self => ({
        get sortedBikes() {
            // priority of certain models; every model not in list has 0 priority
            const priorityBikes: Record<string, number> = { HP15035: 10 };

            return self.bikes
                ? Array.from(self.bikes.values())
                      .filter(bike => bike.type === 'bike')
                      .sort((p1: TProductModel, p2: TProductModel) => {
                          // first order by model's priority; descending
                          return (
                              (priorityBikes[p2.id] ?? 0) - (priorityBikes[p1.id] ?? 0) ||
                              // then by serie; ascending
                              (p1.serie && p2.serie && p1.serie.localeCompare(p2.serie)) ||
                              // then by model; ascending
                              (p1.model && p2.model && p1.model.localeCompare(p2.model)) ||
                              0
                          );
                      })
                : [];
        },
        get sizes() {
            return self.bikeSizes.size > 0 ? Array.from(self.bikeSizes.values()) : [];
        },
    }))
    .views(self => ({
        get filteredBikeList() {
            const parent = getParent<typeof Store.Type>(self, 1);
            let filtered;

            // filter out by fixed criteria
            if (self.seriesFilter === 'Favorites') {
                filtered = self.sortedBikes.filter(bike => parent.favorites.isFavorite(bike));
            } else if (self.seriesFilter === 'Other') {
                filtered = self.sortedBikes.filter(bike => bike.serie === 'Trend' || bike.serie === 'Compact');
            } else {
                filtered =
                    self.seriesFilter === ''
                        ? self.sortedBikes
                        : self.sortedBikes.filter(bike => bike.serie === self.seriesFilter);
            }

            if (self.selectedSizesFilter && self.selectedSizesFilter.length > 0) {
                filtered = findBySizes(filtered, self.selectedSizesFilter);
            }

            if (self.selectedAvailabilityFilter && self.selectedAvailabilityFilter.length > 0) {
                filtered = findByAvailability(filtered, self.selectedAvailabilityFilter);
            }

            // Filtering out deprecated and out of stock bikes
            if (self.hideDeprecatedFilter) {
                filtered = filtered.filter(
                    bike => bike.productInformation.deprecated === false && bike.productInformation.inStock === true,
                );
            }

            // filter by fuzzy searching
            if (self.textFilter && self.textFilter !== '' && self.textFilter.length > 2) {
                let fuse;
                if (self.textFilter.match(/^(HP)?[0-9]+/i)) {
                    fuse = new Fuse(filtered, {
                        threshold: 0.1,
                        distance: 990,
                        minMatchCharLength: 3,
                        keys: ['id'],
                    });
                } else {
                    fuse = new Fuse(filtered, {
                        threshold: 0.2,
                        location: 0,
                        distance: 50,
                        minMatchCharLength: 3,
                        keys: [
                            'composedName',
                            'model',
                            'serie',
                            'productInformation.size',
                            'productInformation.color',
                            'productInformation.version',
                        ],
                    });
                }

                const inputValue = deburr(self.textFilter.trim()).toLowerCase();

                ReactGA.event({
                    category: GACategories.Products,
                    action: 'User searched for a term',
                    label: inputValue,
                });

                filtered = fuse.search(inputValue).map(suggestion => suggestion.item);
            }

            return filtered;
        },

        getBatteryData(product: TProductModel, batteryCode: string) {
            if (product && ProductModel.is(product)) {
                const batteryOption = product.batteryOptions!.find(
                    (battery: TBatteryModel) => battery.id === batteryCode,
                );
                if (batteryOption && batteryOption.id && batteryOption.productInformation.size) {
                    return {
                        code: batteryOption.id,
                        description: `${batteryOption.productInformation.name}`,
                        descriptionDE: `${batteryOption.productInformation.descriptionDE}`,
                        price: Number(batteryOption.productInformation.unitPrice),
                        priceNextYear: Number(batteryOption.productInformation.unitPriceNextYear),
                    };
                }
            }
            return { code: 'N/A', price: 0 };
        },
    }))
    .actions(self => ({
        loadProducts: flow(function* (serie: string) {
            const rootStore = getParent<typeof Store.Type>(self, 1);
            self.loading = true;
            try {
                const country: string = rootStore.billingCountry;
                rootStore.batteries.loadBatteries({ country });
                const response: any = yield Service.getBikes({ serie, country });
                if (self.bikeSizes.size === 0) {
                    const sizes: any = yield Service.getBikeSizes();
                    if (sizes && sizes.data) {
                        let sizeMap: Record<string, any> = {};
                        sizes.data.forEach((size: string) => {
                            sizeMap[size] = size;
                        });
                        applySnapshot(self.bikeSizes, sizeMap);
                    }
                }

                if (response && response.status === 200 && response.data && response.data.length > 0) {
                    response.data.forEach((bike: any) => {
                        if (!self.bikes.has(bike.id)) {
                            const onlyValidBatteries = (battery: TBatteryModel) => BatteryModel.is(battery);
                            const getBatteryFromStore = (batteryId: string) =>
                                rootStore.batteries.batteries.get(batteryId);

                            bike.batteryOptions = bike.batteryOptions
                                .map(getBatteryFromStore)
                                .filter(onlyValidBatteries);
                            bike.composedName = '';

                            if (ProductModel.is(bike)) {
                                bike.composedName = `${bike.serie} ${bike.model}`;
                                self.bikes.set(bike.id, bike);
                            } else {
                                logRejectedModel(ProductModel.name, bike);
                            }
                        }
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                self.loading = false;
            }
        }),

        toggleProductDetailDialog: (isProductDetailDialogFromCart: boolean = false) => {
            self.isProductDetailDialogOpen = !self.isProductDetailDialogOpen;
            // if dialog is open
            if (self.isProductDetailDialogOpen) {
                self.isProductDetailDialogFromCart = isProductDetailDialogFromCart;
                if (!self.isProductDetailDialogFromCart) {
                    self.isProductDetailDialogOpen = true;
                }
            } else {
                // if dialog is closed
                if (!self.isProductDetailDialogFromCart) {
                    self.isProductDetailDialogOpen = false;
                }
            }
        },

        setSelectedBikeHpCode: (bikeHpCode: string) => {
            if (self.bikes.has(bikeHpCode)) {
                self.selectedBikeHpCode = bikeHpCode;
            } else {
                self.selectedBikeHpCode = '';
            }
        },

        setSelectedBikeBatteryCode: (batteryCode: string) => {
            self.selectedBikeBatteryCode = batteryCode;
        },

        setTextFilter: (text: string) => {
            self.textFilter = text;
            QueryParams.setQueryParams({ search: text });
        },

        setSerieFilter: (serie: string) => {
            self.seriesFilter = serie;
            QueryParams.setQueryParams({ serie: serie });
        },

        setSelectedSizesFilter: (sizes: string[]) => {
            self.selectedSizesFilter = sizes;
            QueryParams.setQueryParams({ sizes: sizes });
        },

        setSelectedAvailablityFilter: (availability: string) => {
            self.selectedAvailabilityFilter = availability;
            QueryParams.setQueryParams({ availability: availability });
        },

        setHideDeprecatedFilter: (value: boolean) => {
            self.hideDeprecatedFilter = value;
            QueryParams.setQueryParams({ inStockOnly: value ? 'true' : '' });
        },

        setItemsPerPage: (value: number) => {
            self.itemsPerPage = value;
            QueryParams.setQueryParams({ itemsPerPage: value.toString() });
        },

        setInitProductFilters: (newFiltersValue: Record<string, any>) => {
            for (const key in newFiltersValue) {
                // @ts-ignore
                if (!self[key]?.length && (newFiltersValue[key]?.length || newFiltersValue[key] === true)) {
                    // @ts-ignore
                    self[key] = newFiltersValue[key];
                }
            }
        },

        resetFilterValues: () => {
            self.seriesFilter = '';
            self.textFilter = '';
            self.selectedSizesFilter = [];
            self.selectedAvailabilityFilter = '';
            self.hideDeprecatedFilter = false;
            self.itemsPerPage = 64;
        },
    }));

export default ProductStore;

const findBySizes = (collection: TProductModel[], sizes: string[]) => {
    return filter(collection, ({ productInformation }: any) => {
        return reduce(
            sizes,
            (found: boolean, size: string) => {
                if (!productInformation || !productInformation.size) {
                    // TODO productInformation should be an object
                    // TODO size should never be null
                    logFaultyProperty(productInformation);
                }
                if (productInformation?.size?.includes(size)) {
                    return found || true;
                } else {
                    return found;
                }
            },
            false,
        );
    });
};
const findByAvailability = (collection: TProductModel[], availabilityOption: string) => {
    return collection.filter(({ productInformation }: any) => {
        if (productInformation.availableFrom === null) {
            return false;
        }
        let dateToCompare: dayjs.Dayjs;
        switch (availabilityOption) {
            case AvailabilityOptions.TWO_WEEKS:
                dateToCompare = dayjs().add(2, 'week');
                break;
            case AvailabilityOptions.THREE_WEEKS:
                dateToCompare = dayjs().add(3, 'week');
                break;
            case AvailabilityOptions.ONE_MONTH:
                dateToCompare = dayjs().add(1, 'month');
                break;
            case AvailabilityOptions.NOW:
                dateToCompare = dayjs().add(1, 'week');
                break;
            default:
                dateToCompare = dayjs().add(1, 'month');
                return dayjs(productInformation.availableFrom).isAfter(dateToCompare);
        }
        return dayjs(productInformation.availableFrom).isSameOrBefore(dateToCompare);
    });
};
