import { Tooltip, TooltipProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const LightTooltip = (props: TooltipProps) => {
    const theme = useTheme();
    return (
        <Tooltip
            slotProps={{
                tooltip: {
                    style: {
                        backgroundColor: theme.palette.common.white,
                        color: 'rgba(0, 0, 0, 0.87)',
                        boxShadow: theme.shadows[1],
                        fontSize: '.8em',
                    },
                },
            }}
            {...props}
        />
    );
};

export default LightTooltip;
