import { Box, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const ContactLine = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.spacing(2),
    '& > p': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 0,
    },
    '& > span': {
        content: '""',
    },
    '& *': {
        color: 'inherit',
    },
}));

interface ContactInformationProps {
    lang?: string;
}

export const ContactInformation = ({ lang }: ContactInformationProps) => {
    const { spacing } = useTheme();
    const intl = useIntl();
    const gap = { break: <span /> };

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '45%', minWidth: '400px' }}
        >
            <Typography variant="h6" sx={{ marginBottom: spacing(2) }}>
                <FormattedMessage id="contact.information" />
            </Typography>

            <ContactLine>
                <Typography>
                    <FormattedMessage id="contact.phone" values={gap} />
                </Typography>
                {lang === 'nl-NL' && (
                    <Typography>
                        <FormattedMessage id="contact.phoneBE" values={gap} />
                    </Typography>
                )}
            </ContactLine>
            <ContactLine>
                <p>
                    <Typography component="span">
                        <FormattedMessage id="contact.email_insideSales_label" />
                    </Typography>
                    <a href={`mailto: ${intl.formatMessage({ id: 'contact.email_insideSales_email' })}`}>
                        <Typography component="span">
                            <FormattedMessage id="contact.email_insideSales_email" />
                        </Typography>
                    </a>
                </p>
                <p>
                    <Typography component="span">
                        <FormattedMessage id="contact.email_technicalService_label" />
                    </Typography>
                    <a href={`mailto: ${intl.formatMessage({ id: 'contact.email_technicalService_email' })}`}>
                        <Typography component="span">
                            <FormattedMessage id="contact.email_technicalService_email" />
                        </Typography>
                    </a>
                </p>
            </ContactLine>
            <ContactLine>
                <Typography>
                    <FormattedMessage id="contact.bank_account" values={gap} />
                </Typography>
                <Typography>
                    <FormattedMessage id="contact.bank_bic" values={gap} />
                </Typography>
            </ContactLine>
            <ContactLine>
                <Typography>
                    <FormattedMessage id="contact.kvk" values={gap} />
                </Typography>
                <Typography>
                    <FormattedMessage id="contact.btw" values={gap} />
                </Typography>
            </ContactLine>
            <ContactLine>
                <Typography variant="h6" sx={{ marginBottom: spacing(2) }}>
                    <FormattedMessage id="contact.qwic_office" />
                </Typography>
                <Typography>
                    <FormattedMessage id="contact.qwic_officeBuilding" />
                </Typography>
                <Typography>
                    <FormattedMessage id="contact.qwic_officeStreet" />
                </Typography>
                <Typography>
                    <FormattedMessage id="contact.qwic_officeAddress" />
                </Typography>
            </ContactLine>
        </Box>
    );
};
