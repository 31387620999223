import { TUserInfo, WindowUserGuidingEmbedded } from './types';

export const appendHeadScript = (scriptText: string, scriptId: string): boolean => {
    try {
        const existentScript = document.getElementById(scriptId) as HTMLScriptElement;
        const script = existentScript || document.createElement('script');
        script.id = scriptId;
        script.innerText = scriptText;
        script.crossOrigin = 'anonymous';

        document.head.appendChild(script);

        return true;
    } catch {
        return false;
    }
};

export function userGuidingInitScript(): boolean {
    const hasWindow = typeof window !== 'undefined';

    if (!hasWindow) {
        throw Error('UserGuiding depends on window. Window is undefined.');
    }

    // eslint-disable-next-line max-len
    const userGuidingScriptCode = '(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src=\'https://static.userguiding.com/media/user-guiding-\'+s+\'-embedded.js\';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=[\'previewGuide\',\'finishPreview\',\'track\',\'identify\',\'triggerNps\',\'hideChecklist\',\'launchChecklist\'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,\'script\',\'userGuiding\',\'userGuidingLayer\',\'582783147ID\');';
    const isAppended = appendHeadScript(userGuidingScriptCode, 'user-guiding-init-script');

    if (isAppended && hasWindow && (window as unknown as WindowUserGuidingEmbedded).userGuiding) {
        return true;
    }

    throw Error('User guiding initialization failed!');
}

export function userGuidingIdentifyScript(userId: string | null, userInfo: TUserInfo): void {
    const hasWindow = typeof window !== 'undefined';
    if (
        hasWindow &&
        (window as unknown as WindowUserGuidingEmbedded).userGuiding &&
        (window as unknown as WindowUserGuidingEmbedded).userGuiding.identify
    ) {
        return (window as unknown as WindowUserGuidingEmbedded).userGuiding.identify(userId, userInfo);
    }

    throw Error('User guiding is not available! Is Hotjar initialized?');
}

export function checkReadyState(): boolean {
    const hasWindow = typeof window !== 'undefined';
    return !!(hasWindow && (window as unknown as WindowUserGuidingEmbedded).userGuiding);
}
