import { useTheme } from '@mui/material';
import React from 'react';

const Link = ({
    children,
    ...props
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => {
    const { palette } = useTheme();
    return (
        <a style={{ color: palette.secondary.main }} {...props}>
            {children}
        </a>
    );
};

export default Link;
