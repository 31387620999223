import { CircularProgress, DialogActions, DialogTitle, Divider, useTheme } from '@mui/material';
import { Form, Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import React, { useCallback } from 'react';
import ReactGA from 'react-ga';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import RootStore from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import { CartReturnItemModel, TCartReturnItemModel } from 'src/stores/types/ReturnCartItemModel';
import { TReturnReason, TSparePartToReturn } from 'src/stores/types/ReturnRequestModel';
import { TInvoice, TInvoiceLine } from 'src/stores/types/Invoice';
import config from 'src/utils/config';
import { validateFramenumber } from 'src/utils/validateFrameNumber';
import { logBigFileUpload } from 'src/utils/logger';

import { GACategories } from 'src/services/Analytics';
import Toast from 'src/services/Toasts';

import DialogContentWithRef from 'src/components/DialogContentWithRef';
import { ReturnInfoBox } from 'src/components/ReturnInfoBox';
import { WarningBoxLight } from 'src/components/WarningBoxLight';
import { SparePartCategory } from 'src/utils/constants';

import { BooleanRadioButton } from './components/BooleanRadioButton';
import { BrokenPartFeilds } from './components/BrokenPartFields';
import { ReturnDialogActions } from './components/ReturnDialogActions';
import { ReturnDialogTitle } from './components/ReturnDialogTitle';
import { SparePartFields } from './components/SparePartFields';

const OUT_OF_WARRANTY_DATE = '2022-02-28';
export const OUT_OF_WARRANTY = 'out_of_warranty';

export interface ReturnItemFormValues {
    isBroken?: string;
    isDamaged?: string;
    frameNumber?: string;
    noFrameNumber?: boolean;
    reasonNoFramenumber?: string;
    reason?: string;
    reasonDescription?: string;
    consumerPurchaseDate?: Date;
    serialNumber?: string;
    reorder?: string;
    reference?: string;
    acceptBatteryBack?: boolean;
    confirmFees?: boolean;
    attachment?: File;
    invoiceNumber?: string;
}

interface ReturnRequestFormProps {
    store?: typeof RootStore.Type;
    user?: typeof UserStore.Type;
    partToReturn: TSparePartToReturn;
    invoice?: TInvoice;
    invoiceLine?: TInvoiceLine;
}

const ReturnRequestFormV2 = inject(
    'user',
    'store',
)(
    observer(({ store, user, partToReturn, invoice, invoiceLine }: ReturnRequestFormProps) => {
        const intl = useIntl();
        const theme = useTheme();

        const editing = store!.cartReturns.editing;
        const itemToEdit = store!.cartReturns.getItemToEdit();
        const invoicesBySparePart = store!.invoices.listBySparePart.get(partToReturn.id);
        const fromInvoice = !!invoice && !!invoiceLine;

        // early return a spinner if there is no partToReturn
        if (!partToReturn) {
            return <CircularProgress size={20} color="secondary" />;
        }

        const isBattery = partToReturn.category === SparePartCategory.BATTERIES;

        const handleOnSuccess = useCallback((message: string) => {
            store!.returns.toggleReturnFormDialog();
            Toast.addItem(message, store!.config.toggleCartReturns);
        }, []);

        const updateReturnItem = (item: TCartReturnItemModel) => {
            store!.cartReturns.updateCartItem(item);
            handleOnSuccess('returns.itemUpdatedMessage');
        };

        const addReturnItem = (item: TCartReturnItemModel) => {
            const succsessMessage = 'returns.itemAddedMessage';
            if (item.attachment) {
                store!.cartReturns.addCartItemWithUpload(item, () => handleOnSuccess(succsessMessage));
            } else {
                store!.cartReturns.addCartItem(item);
                handleOnSuccess(succsessMessage);
            }
        };

        const onSubmit = (values: ReturnItemFormValues) => {
            const isUnusedSparePart = !isBattery && values.isBroken === 'no';

            const item = CartReturnItemModel.create({
                id: editing ? itemToEdit!.id : `RRI-${store!.cartReturns.items.size}-${partToReturn!.id}`,
                description: values.reasonDescription,
                frameNumber: partToReturn?.parentBike?.frameNumber
                    ? partToReturn.parentBike.frameNumber
                    : values.frameNumber,
                reorder: partToReturn?.deprecated ? false : values.reorder === 'yes',
                parentProductCode: partToReturn?.parentBike?.productCode,
                productCode: partToReturn!.id,
                productType: partToReturn!.type,
                reason: values.reason,
                reference: values.reference,
                serialNumber: values.serialNumber || '',
                productName: partToReturn!.description,
                category: isUnusedSparePart ? SparePartCategory.UNUSED : partToReturn!.category,
                physicalDamageBattery: values.isDamaged === 'yes',
                reasonNoFramenumber: values.reasonNoFramenumber,
                consumerPurchaseDate: values.consumerPurchaseDate?.toISOString(),
                attachment: values.attachment,
                invoiceNumber: values.invoiceNumber,
                ...(fromInvoice && {
                    invoiceId: invoice?.invoiceId,
                    invoiceLineId: invoiceLine.id,
                }),
            });

            ReactGA.event({
                category: GACategories.ReturnRequests,
                action: `User ${editing ? 'edited' : 'added'} a spare part within warranty`,
            });

            if (editing) {
                updateReturnItem(item);
            } else {
                addReturnItem(item);
            }
        };

        const getValidationSchemaBattery = () => {
            return Yup.object().shape({
                isBroken: Yup.string().required(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' })),
                isDamaged: Yup.string().required(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' })),
                frameNumber: Yup.string()
                    .when('noFrameNumber', {
                        is: false,
                        then: Yup.string().required(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' })),
                    })
                    .test(
                        'frameNumber',
                        intl.formatMessage({ id: 'return_request.invalidFrameNumber' }),
                        function (value: any) {
                            return !value || (value && validateFramenumber(value));
                        },
                    ),
                noFrameNumber: Yup.boolean(),
                reasonNoFramenumber: Yup.string().when('noFrameNumber', {
                    is: true,
                    then: Yup.string().required(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' })),
                }),
                reason: Yup.string().required(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' })),
                reasonDescription: Yup.mixed().test(
                    'reasonDescription',
                    intl.formatMessage({ id: 'return_request.fieldRequiredMessage' }),
                    function (value?: string) {
                        const returnReason: TReturnReason = store!.returns.getReturnReasonById(this.parent.reason);
                        const needDescription: boolean = returnReason ? returnReason.needDescription : false;
                        return !needDescription || !!(needDescription && value);
                    },
                ),
                consumerPurchaseDate: Yup.date()
                    .required(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' }))
                    .min(new Date(OUT_OF_WARRANTY_DATE), OUT_OF_WARRANTY),
                serialNumber: Yup.string()
                    .required(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' }))
                    .min(14, intl.formatMessage({ id: 'return_request.serialNumber_min_char' })),
                reorder: Yup.string().required(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' })),
                reference: Yup.string().test(
                    'reference',
                    intl.formatMessage({ id: 'return_request.commentsTooLongMessage' }),
                    (value?: string) => {
                        return value ? value.length <= 1000 : true;
                    },
                ),
                acceptBatteryBack: Yup.boolean().isTrue(
                    intl.formatMessage({ id: 'return_request.fieldRequiredMessage' }),
                ),
                confirmFees: Yup.boolean().isTrue(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' })),
                attachment: Yup.mixed().test(
                    'upload',
                    intl.formatMessage(
                        { id: 'return_request.fileTooLarge' },
                        { size: config.CONTACT_MAX_FILE_SIZE_MB },
                    ),
                    (value?: File) => {
                        if (editing) return true;
                        // log big file upload to Sentry
                        if (!!value && value.size > config.RETURN_REQUEST_MAX_INVOICE_FILE_SIZE) {
                            logBigFileUpload(value.size);
                        }
                        return !!value ? value.size < config.RETURN_REQUEST_MAX_INVOICE_FILE_SIZE : false;
                    },
                ),
            });
        };

        const getValidationSchemaSparePart = () => {
            return Yup.object().shape({
                isBroken: Yup.string().required(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' })),
                invoiceNumber: Yup.string().when('isBroken', {
                    is: 'no',
                    then: Yup.string().required(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' })),
                }),
                consumerPurchaseDate: Yup.date().when('isBroken', {
                    is: 'yes',
                    then: Yup.date()
                        .required(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' }))
                        .min(new Date(OUT_OF_WARRANTY_DATE), OUT_OF_WARRANTY),
                }),
                attachment: Yup.mixed().when('isBroken', {
                    is: 'yes',
                    then: Yup.mixed().test(
                        'upload',
                        intl.formatMessage(
                            { id: 'return_request.fileTooLarge' },
                            { size: config.CONTACT_MAX_FILE_SIZE_MB },
                        ),
                        (value?: File) => {
                            if (editing) return true;
                            // log big file upload to Sentry
                            if (!!value && value.size > config.RETURN_REQUEST_MAX_INVOICE_FILE_SIZE) {
                                logBigFileUpload(value.size);
                            }
                            return !!value ? value.size < config.RETURN_REQUEST_MAX_INVOICE_FILE_SIZE : false;
                        },
                    ),
                }),
                reason: Yup.string().when('isBroken', {
                    is: 'yes',
                    then: Yup.string().required(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' })),
                }),
                reference: Yup.string().test(
                    'reference',
                    intl.formatMessage({ id: 'return_request.commentsTooLongMessage' }),
                    (value?: string) => {
                        return value ? value.length <= 1000 : true;
                    },
                ),
                confirmFees: Yup.boolean().isTrue(intl.formatMessage({ id: 'return_request.fieldRequiredMessage' })),
            });
        };

        const getInitialValues = () => {
            if (fromInvoice) {
                return {
                    isBroken: 'no',
                    invoiceNumber: itemToEdit ? itemToEdit.invoiceNumber : invoice?.name,
                    confirmFees: itemToEdit ? true : false,
                };
            }

            const isUnusedPart = itemToEdit && itemToEdit.category === SparePartCategory.UNUSED;

            if (isUnusedPart) {
                return {
                    isBroken: itemToEdit ? 'no' : '',
                    invoiceNumber: itemToEdit ? itemToEdit.invoiceNumber : '',
                    confirmFees: itemToEdit ? true : false,
                };
            }

            const itemToEditReturnReason = itemToEdit && itemToEdit.reason ? itemToEdit.reason : null;
            const editedSerialNumber = itemToEdit && itemToEdit.serialNumber ? itemToEdit.serialNumber : '';

            return {
                isBroken: itemToEdit ? 'yes' : '',
                isDamaged: itemToEdit ? 'no' : '',
                frameNumber: itemToEdit ? itemToEdit.frameNumber : partToReturn?.parentBike?.frameNumber,
                noFrameNumber: itemToEdit && itemToEdit.reasonNoFramenumber ? true : false,
                reasonNoFramenumber: itemToEdit ? itemToEdit.reasonNoFramenumber : '',
                reason: itemToEditReturnReason ? itemToEditReturnReason : '',
                reasonDescription: itemToEdit && itemToEdit.description ? itemToEdit.description : '',
                consumerPurchaseDate: itemToEdit ? new Date(itemToEdit.consumerPurchaseDate) : undefined,
                serialNumber: itemToEdit ? editedSerialNumber : '',
                reorder: itemToEdit ? (itemToEdit.reorder ? 'yes' : 'no') : partToReturn?.deprecated ? 'no' : 'yes',
                reference: itemToEdit && itemToEdit.reference ? itemToEdit.reference : '',
                acceptBatteryBack: itemToEdit ? true : false,
                confirmFees: itemToEdit ? true : false,
                attachment: itemToEdit ? itemToEdit.attachment : undefined,
                invoiceNumber: '',
            };
        };

        let bikeFrameSuggestions = store!.spareParts.bikesAutocomleteList;

        return (
            <Formik
                initialValues={getInitialValues()}
                validationSchema={isBattery ? getValidationSchemaBattery : getValidationSchemaSparePart}
                onSubmit={onSubmit}
            >
                {formikProps => {
                    const { values, errors, isSubmitting, submitCount } = formikProps;
                    const selectedReturnReason = store!.returns.getReturnReasonById(values.reason);
                    const needDescription: boolean = selectedReturnReason
                        ? selectedReturnReason.needDescription
                        : false;

                    return (
                        <Form style={{ minWidth: '600px' }}>
                            <DialogTitle id="new-return-request-dialog-title" sx={{ fontWeight: 'bold' }}>
                                <ReturnDialogTitle partToReturn={partToReturn} />
                            </DialogTitle>

                            <Divider light />

                            <ReturnInfoBox productCode={partToReturn.id} />

                            <DialogContentWithRef>
                                {isBattery ? (
                                    <div>
                                        <div style={{ paddingBottom: '1rem' }}>
                                            <BooleanRadioButton
                                                name="isBroken"
                                                labelId="returns.form_label_isBroken"
                                                yesLabelId="returns.form_label_isBroken_yes"
                                                isError={!!errors.isBroken && submitCount > 0}
                                                error={errors.isBroken}
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {values.isBroken === 'yes' && (
                                            <BrokenPartFeilds
                                                partToReturn={partToReturn}
                                                bikeFrameSuggestions={bikeFrameSuggestions}
                                                getOptionsAutocomplete={store?.spareParts.getBikesAutocomplete}
                                                preferredLang={user!.preferredLang}
                                                needDescription={needDescription}
                                                hideUpload={!!itemToEdit?.attachment}
                                                {...formikProps}
                                            />
                                        )}

                                        {values.isBroken === 'no' && (
                                            <WarningBoxLight
                                                titleKey="returns.form.warning.no.unused.battery"
                                                messageKey="returns.form.warning.no.unused.battery.message"
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <SparePartFields
                                        invoicesBySparePart={invoicesBySparePart}
                                        partToReturn={partToReturn}
                                        preferredLang={user!.preferredLang}
                                        needDescription={needDescription}
                                        hideUpload={!!itemToEdit?.attachment}
                                        fromInvoice={fromInvoice}
                                        {...formikProps}
                                    />
                                )}
                            </DialogContentWithRef>

                            <DialogActions sx={{ padding: theme.spacing(3), paddingTop: 0 }}>
                                <ReturnDialogActions
                                    disabled={isSubmitting}
                                    isSubmitting={isSubmitting}
                                    isEditing={editing}
                                    onCancelClick={store!.returns.toggleReturnFormDialog}
                                />
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        );
    }),
);

export default ReturnRequestFormV2;
