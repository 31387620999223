import { faAsterisk, faBicycle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme } from '@mui/material/styles';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import React from 'react';

export interface LoadingProps extends WithStyles<typeof styles> {
    small?: boolean;
    error?: boolean;
}

const Loading = ({ classes, error, small }: LoadingProps) => {
    return (
        <div
            className={`
                    ${classes.loadingContainer}
                    ${error ? classes.loadingContainerError : ''}
                    ${small ? classes.small : ''}
                `}
        >
            <FontAwesomeIcon
                data-testid="back-wheel"
                icon={faAsterisk}
                size="5x"
                className={`${classes.backWheel} ${classes.wheel} ${error ? classes.backWheelError : ''}`}
            />
            <FontAwesomeIcon
                data-testid="front-wheel"
                icon={faAsterisk}
                size="5x"
                className={`${classes.frontWheel} ${classes.wheel} ${error ? classes.frontWheelError : ''}`}
            />
            <FontAwesomeIcon icon={faBicycle} size="10x" className={classes.bike} />
            <div className={classes.battery} />
        </div>
    );
};

function styles(theme: Theme) {
    return createStyles({
        '@keyframes spin': {
            to: {
                transform: 'rotate(1turn)',
            },
        },
        '@keyframes fadeIn': {
            from: {
                opacity: 0,
            },
        },
        small: {
            transform: 'scale(.5)',
        },
        loadingContainer: {
            textAlign: 'center',
            position: 'relative',
            width: '220px',
            height: '160px',
            margin: '0 auto',
            alignSelf: 'center',
            animation: '$fadeIn .4s',
        },
        loadingContainerError: {
            transform: 'rotate(200deg)',
        },
        wheel: {
            animation: '$spin 1s linear infinite',
            stroke: 'white',
            strokeWidth: '15px',
            position: 'absolute',
            bottom: '20px',
        },
        backWheel: {
            left: '18px',
            top: '71px',
        },
        frontWheel: {
            right: '18px',
            top: '71px',
        },
        backWheelError: {
            animation: '$spin 6s ease-in-out alternate infinite',
        },
        frontWheelError: {
            animation: '$spin 6s -1s ease-in-out alternate infinite',
        },
        bike: {
            stroke: 'white',
            strokeWidth: '15px',
            position: 'relative',
            zIndex: 1,
        },
        battery: {
            top: '55px',
            left: '95px',
            width: '45px',
            position: 'absolute',
            transform: 'rotate(-55deg)',
            border: 'solid 10px transparent',
            borderBottomColor: theme.palette.secondary.main,
        },
    });
}

export default withStyles(styles)(Loading);
