import { createStyles, withStyles, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import QRCode from 'qrcode.react';
import React from 'react';
import { AppPageRoutes } from 'src/services/api/models';
import QRIcon from '../../../../assets/qr-icon.svg';

interface FileUploadCodeProps extends WithStyles<typeof styles> {
    id: string;
    type: 'item' | 'case';
    variant?: 'default' | 'compact';
    className?: string;
    foregroundColor?: string;
    backgroundColor?: string;
    withLink?: boolean;
}

const FileUploadCodeComponent = observer(
    ({
        classes,
        className,
        id,
        type,
        variant = 'default',
        foregroundColor = '#000',
        backgroundColor = '#FFF',
        withLink = false,
    }: FileUploadCodeProps) => {
        let linkUrl: string = '';

        if (type === 'item') {
            linkUrl = AppPageRoutes.RETURN_REQUESTS_ITEM_DETAILS.replace(':itemId', id);
        }

        if (type === 'case') {
            linkUrl = AppPageRoutes.RETURN_REQUESTS_SELECT_ITEM.replace(':caseNumber', id);
        }

        const imageSettingsDefault = { src: QRIcon, width: 40, height: 40, excavate: true };

        const renderDefaultVariant = (link: string) => (
            <QRCode
                value={window.location.origin + link}
                level="H"
                size={140}
                imageSettings={imageSettingsDefault}
                color={foregroundColor}
                bgColor={backgroundColor}
            />
        );

        const renderCompactVariant = (link: string) => (
            <QRCode
                value={window.location.origin + link}
                level="H"
                size={100}
                color={foregroundColor}
                bgColor={backgroundColor}
            />
        );

        const calculatedClassNames = classNames(classes.root, className);

        let renderedCode = variant === 'compact' ? renderCompactVariant(linkUrl) : renderDefaultVariant(linkUrl);

        if (withLink) {
            renderedCode = (
                <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                    {renderedCode}
                </a>
            );
        }

        return <div className={calculatedClassNames}>{renderedCode}</div>;
    },
);

function styles() {
    return createStyles({
        root: {
            display: 'inline-flex',
            flexDirection: 'column',
        },
    });
}

const FileUploadCode = withStyles(styles)(FileUploadCodeComponent);

export default FileUploadCode;
