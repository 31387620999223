import { Grid } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Store from 'src/stores/RootStore';
import { TCartReturnItemModel } from 'src/stores/types/ReturnCartItemModel';
import { CartActions } from './components/CartActions';
import { CartItem } from './components/CartItem';
import { CartHeader } from './components/CartHeader';
import { RemoveItemDialog } from './components/RemoveItemDialog';

interface CartProps {
    // path: string;
    store?: typeof Store.Type;
}

const CartReturn = inject('store')(
    observer(({ store }: CartProps) => {
        const navigate = useNavigate();
        const cartItems = store!.cartReturns.getItems;

        const [openWarning, setOpenWarning] = useState(false);
        const [itemToRemove, setItemToRemove] = useState<TCartReturnItemModel>();

        if (!store!.returns.returnReasons.size) {
            store!.returns.getReturnReasons();
        }

        const handleOnRemoveItem = (item: TCartReturnItemModel) => {
            setItemToRemove(item);
            setOpenWarning(true);
        };

        const handleRemoveItem = (item?: TCartReturnItemModel) => {
            store!.cartReturns.removeCartItem(item);
            setOpenWarning(false);
        };

        return (
            <>
                <CartHeader onClickClose={store!.config.toggleCartReturns} />

                <Grid
                    container
                    spacing={3}
                    style={{
                        padding: '10px 20px',
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'nowrap',
                        height: 'calc(100vh - 50px)',
                    }}
                >
                    <Grid item sm={12} style={{ overflowY: 'auto' }}>
                        {!!cartItems.length &&
                            cartItems.map((item: TCartReturnItemModel, index: number) => (
                                <CartItem
                                    key={`cart-return-${item.productCode}-${index}`}
                                    item={item}
                                    onRemove={() => handleOnRemoveItem(item)}
                                    onEdit={() => store!.cartReturns.editCartItem(item)}
                                />
                            ))}
                    </Grid>

                    <CartActions
                        isSending={store!.cartReturns.sending}
                        onClickSend={() => {
                            store!.cartReturns.sendReturnItemsV2(navigate);
                        }}
                    />
                </Grid>

                <RemoveItemDialog
                    open={openWarning}
                    item={itemToRemove}
                    onCancel={() => setOpenWarning(false)}
                    onConfirm={() => handleRemoveItem(itemToRemove)}
                />
            </>
        );
    }),
);

export default CartReturn;
