import { faBox, faHashtag, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardActions, Fab, Grid, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import CurrencyFormat from 'src/components/currency/Currency';
import ProductImage from 'src/components/ProductImage';
import ProductStockIndicator from 'src/components/ProductStockIndicator';
import Tag from 'src/components/Tag';
import { AppModules } from 'src/services/api/models';
import RoleBaseAccess from 'src/services/RoleBaseAccess';
import { ProductCategoryTypes } from 'src/utils/constants';

export interface ProductCardProps extends WithStyles<typeof styles> {
    onCardClick: () => void;
    productId: string;
    type?: ProductCategoryTypes;
    name: string;
    price: string | number | null;
    deprecated?: boolean;
    warrantyExpiryDate?: string | null;
    showWarrantyExpirydate?: boolean;
    isInStock: boolean;
    stockQuantity?: number | null;
    availableFrom?: string | null;
}

const ProductCard = ({
    classes,
    onCardClick,
    productId,
    type,
    name,
    price,
    deprecated,
    warrantyExpiryDate,
    showWarrantyExpirydate,
    isInStock,
    stockQuantity,
    availableFrom,
}: ProductCardProps) => {
    return (
        <Card className={classes.card} elevation={0} data-testid="product-card" onClick={onCardClick}>
            <ProductImage id={productId} type={type} />
            <CardActions>
                <Grid data-testid={`product-card-${productId}`} container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom={!warrantyExpiryDate}>
                            {name}
                        </Typography>
                    </Grid>

                    {showWarrantyExpirydate && (
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                <FormattedMessage id="returns.warrantyUntil" />
                            </Typography>
                            <Typography variant="body2" color="secondary" gutterBottom>
                                {warrantyExpiryDate}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            <span className={classes.detailIcon}>
                                <FontAwesomeIcon icon={faBox} size="1x" className={classes.detailIcon} />
                            </span>

                            <ProductStockIndicator
                                isInStock={isInStock}
                                availableFrom={availableFrom}
                                stockQuantity={stockQuantity}
                            />
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            <span className={classes.detailIcon}>
                                <FontAwesomeIcon icon={faHashtag} size="lg" />
                            </span>
                            {productId}
                        </Typography>
                    </Grid>

                    {price !== null && (
                        <Grid item xs={8}>
                            <Typography variant="h4" data-testid={`product-${productId}-price`}>
                                <CurrencyFormat value={price} />
                            </Typography>
                        </Grid>
                    )}

                    {RoleBaseAccess.isModuleAllowed(AppModules.ORDER_PRODUCTS) && price !== null && (
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                            <Fab className={classes.addButton} data-testid={`add-product-${productId}`} color="primary">
                                <FontAwesomeIcon icon={faPlus} size="lg" />
                            </Fab>
                        </Grid>
                    )}
                    {deprecated && (
                        <Grid data-testid="deprecated" item xs={8}>
                            <Tag translationId="deprecated" />
                        </Grid>
                    )}
                </Grid>
            </CardActions>
        </Card>
    );
};

function styles({ breakpoints }: Theme) {
    return createStyles({
        '@keyframes fadeIn': {
            from: {
                opacity: 0,
            },
            to: {
                opacity: 1,
            },
        },
        addButton: {
            flex: 1,
        },
        card: {
            flexGrow: 1,
            maxWidth: 'calc(100% * (1/4) - 20px - 20px)',
            minWidth: 'calc(100% * (1/4) - 20px - 20px)',
            padding: '10px',
            margin: '20px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflow: 'visible',
            [breakpoints.down('xl')]: {
                minWidth: 'calc(100% * (1/4) - 20px - 20px)',
            },
            [breakpoints.down('lg')]: {
                maxWidth: 'calc(100% * (1/3) - 20px - 20px)',
            },
            [breakpoints.down('md')]: {
                maxWidth: '100%',
                margin: '10px',
            },
            animationDuration: '1s',
            animationFillMode: 'both',
            animationName: '$fadeIn',
            transition: 'background-color .3s ease-out',
            cursor: 'pointer',
            '&:hover': {
                background: '#eee',
            },
        },
        detailIcon: {
            display: 'inline-block',
            width: '2rem',
        },
    });
}

export default withStyles(styles)(ProductCard);
