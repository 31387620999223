import InfoIcon from '@mui/icons-material/InfoOutlined';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumn } from 'mui-datatables';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import LightTooltip from 'src/components/LightTooltip';
import PageLoader from 'src/components/PageLoader';
import Tag from 'src/components/Tag';
import { AppPageRoutes } from 'src/services/api/models';
import QueryParams from 'src/services/QueryParamsService';
import PersistentStore from 'src/stores/PersistentStore';
import Store from 'src/stores/RootStore';
import { TOrderLine } from 'src/stores/types/OrdersTypes';
import UserStore from 'src/stores/UserStore';
import { getFormattedDeliveryDate } from 'src/utils/getFormattedDeliveryDate';
import { createTableValues, CustomPaginationFooter, TableCell, TableColumnTypes } from 'src/utils/tableColumnUtils';

interface OrdersProps extends WithStyles<typeof styles> {
    path?: string;
    user?: typeof UserStore.Type;
    store?: typeof Store.Type;
    persistentStore?: typeof PersistentStore.Type;
}

const Orders = inject(
    'user',
    'store',
    'persistentStore',
)(
    observer(({ classes, persistentStore, store, user }: OrdersProps) => {
        const navigate = useNavigate();
        const intl = useIntl();
        const location = useLocation();

        const pageTitle = intl.formatMessage({ id: 'orders.title' });

        const isOrdersLoading = store!.orders.isLoading;

        // Current filters and searchOrder values from url
        const queries = QueryParams.getQueryParams(['productType', 'status', 'searchOrder'], location.search);
        const typeFilter = queries?.productType
            ? Array.isArray(queries.productType)
                ? queries.productType
                : [queries.productType]
            : undefined;

        const statusFilter = queries?.status
            ? Array.isArray(queries.status)
                ? queries.status
                : [queries.status]
            : undefined;
        const searchText = (queries?.searchOrder && String(queries.searchOrder)) || '';

        useEffect(() => {
            store!.orders.getOrders();
            return function () {
                store!.orders.setSelected('');
                QueryParams.resetQueryParams();
            };
        }, [store, user]);

        // Set filters to url when it triggered
        const handleChangeFilter = (changedColumn: string | MUIDataTableColumn | null, filterList: string[][]) => {
            // filterList index is the index of columns
            if (changedColumn === 'productType') {
                QueryParams.setQueryParams({ [changedColumn]: filterList[4] });
            } else if (changedColumn === 'status') {
                QueryParams.setQueryParams({ [changedColumn]: filterList[8] });
            }
        };

        // Set search string to url when searching by text
        const handleSearchChange = (text: string | null) => {
            if (text) {
                QueryParams.setQueryParams({ searchOrder: text });
            }
        };

        const buildTable = () => {
            const rows: TableCell[][] = [];

            store!.orders.all.forEach((order: any) => {
                order.lines!.forEach((orderLine: TOrderLine) => {
                    // Hiding verzendkosten lines here
                    if (orderLine.productType === '') {
                        return;
                    }

                    rows.push([
                        { id: 'orderNumber', value: order.name },
                        { id: 'productCode', value: orderLine.code },
                        {
                            id: 'description',
                            value: orderLine.description,
                            type: TableColumnTypes.custom,
                            customRender: (value: any) => {
                                if (value && value.text) {
                                    return (
                                        <span>
                                            <p>{value.text.substring(0, 30) + '...'}</p>
                                            {value.showDeprecated && <Tag translationId="deprecated" />}
                                        </span>
                                    );
                                } else {
                                    return value ? value.substring(0, 30) + '...' : value;
                                }
                            },
                        },
                        { id: 'reference', value: orderLine.reference },
                        { id: 'productType', value: orderLine.productType, filter: true, filterList: typeFilter },
                        { id: 'quantity', value: orderLine.quantity },
                        {
                            id: 'plannedDeliveryDate',
                            value: store!.orders.list.get(order.name)!.isEditable
                                ? order.plannedDeliveryDate
                                : orderLine.plannedDeliveryDate,
                            type: TableColumnTypes.custom,
                            customRender: (value: string) => {
                                if (!value) {
                                    return <FormattedMessage id="labels.toConfirm" />;
                                }

                                const formattedValues = getFormattedDeliveryDate(value);

                                return (
                                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                        <LightTooltip title={formattedValues.tooltip} style={{ marginRight: 4 }}>
                                            <InfoIcon fontSize="small" />
                                        </LightTooltip>

                                        {formattedValues.text}
                                    </div>
                                );
                            },
                        },
                        {
                            id: 'requestedDeliveryDate',
                            value: orderLine.requestedDeliveryDate,
                            type: TableColumnTypes.date,
                        },
                        { id: 'status', value: order.status, filter: true, filterList: statusFilter },
                        {
                            id: 'onStock',
                            value: order.onStock,
                            type: TableColumnTypes.custom,
                            customRender: (value: boolean) => {
                                return value ? (
                                    <FormattedMessage id="labels.yes" />
                                ) : (
                                    <FormattedMessage id="labels.no" />
                                );
                            },
                        },
                    ]);
                });
            });

            return createTableValues(rows);
        };

        const renderTable = (title: string) => {
            const table = buildTable();
            const data = table.data;
            const columns = table.columns;

            const options: MUIDataTableOptions = {
                filterType: 'multiselect',
                selectableRows: 'none',
                searchText,
                onSearchChange: handleSearchChange,
                responsive: 'vertical',
                pagination: true,
                filter: true,
                onFilterChange: handleChangeFilter,
                download: false,
                print: false,
                viewColumns: false,
                elevation: 0,
                sort: true,
                page: persistentStore!.ordersTable.tablePage,
                onChangePage: persistentStore!.ordersTable.setTablePage,
                rowsPerPage: persistentStore!.ordersTable.tableRowsPerPage,
                onChangeRowsPerPage: persistentStore!.ordersTable.setTableRowsPerPage,
                onRowClick: (rowData: any) => {
                    let orderNumber: string = '';
                    let status: string = '';
                    columns.forEach((column, index) => {
                        if (column.name === 'orderNumber') {
                            orderNumber = rowData[index].props.children;
                        }
                        if (column.name === 'status') {
                            status = rowData[index].props.children;
                        }
                    });

                    if (status !== 'pending') {
                        store!.orders.setSelected(orderNumber);
                        navigate(AppPageRoutes.ORDERS + '/' + orderNumber);
                    }
                },
                setRowProps: (rowData: any) => {
                    let status: string = '';

                    columns.forEach((column, index) => {
                        if (column.name === 'status') {
                            status = rowData[index].props.children;
                        }
                    });

                    if (status === 'pending') {
                        return {
                            className: classes.disabled,
                        };
                    }

                    return {
                        className: classes.withPointer,
                    };
                },
                customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
                    return (
                        <CustomPaginationFooter
                            rowCount={count}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            changeRowsPerPage={changeRowsPerPage}
                            changePage={changePage}
                        />
                    );
                },
            };

            return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
        };

        return isOrdersLoading ? <PageLoader /> : renderTable(pageTitle);
    }),
);

function styles() {
    return createStyles({
        disabled: {
            opacity: 0.4,
        },
        withPointer: {
            cursor: 'pointer',
        },
    });
}

export default withStyles(styles)(Orders);
