import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
    profileSection: {
        marginBottom: '10px',
        flex: 1,
    },
    listLabel: {
        display: 'inline-block',
        width: '120px',
        opacity: 0.5,
    },
});

interface ItemContent {
    id: string;
    value: string;
}

interface ProfileSectionProps {
    titleId: string;
    content: ItemContent[];
}

export const ProfileSection: FC<ProfileSectionProps> = ({ titleId, content }) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.profileSection}>
            <Typography gutterBottom variant="h6">
                <FormattedMessage id={titleId} />
            </Typography>

            {content.map(item => (
                <Typography variant="body1" key={item.id}>
                    <span className={classes.listLabel}>
                        <FormattedMessage id={item.id} />
                    </span>
                    {item.value}
                </Typography>
            ))}
        </Grid>
    );
};
