import { CloudUpload, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, TextField as MuiTextField, Typography, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import Button from 'src/components/button/Button';
import { ButtonCustomVariant } from 'src/components/button/type';
import config from 'src/utils/config';
import formatFileSize from 'src/utils/formatFileSize';
import { truncateLongFileName } from 'src/utils/stringUtils';

interface FileUploadProps {
    label?: ReactNode;
    handleInputChange?: React.ChangeEventHandler<HTMLInputElement>;
    handleClickLabel?: React.MouseEventHandler<HTMLLabelElement>;
    acceptFileFormats?: string;
    value: string;
    isError?: boolean;
    errorText?: ReactNode;
    uploadedFiles?: File[];
    handleRemoveFile?: (fileName: string) => void;
    multiple?: boolean;
}

export const FileUpload = ({
    label,
    handleInputChange,
    handleClickLabel,
    acceptFileFormats = config.FILE_ALLOWED_FORMATS,
    value,
    isError,
    errorText,
    uploadedFiles,
    handleRemoveFile,
    multiple = false,
}: FileUploadProps) => {
    const { spacing } = useTheme();

    return (
        <>
            {label && (
                <Typography data-testid="upload-file-label" color="primary" variant="body1">
                    {label}
                </Typography>
            )}

            {uploadedFiles &&
                handleRemoveFile &&
                uploadedFiles.map(file => (
                    <Box key={file.name} sx={{ marginTop: spacing(1), display: 'flex', alignItems: 'center' }}>
                        <MuiTextField
                            sx={{ width: '100%' }}
                            value={`${truncateLongFileName(file.name, 30)} (${formatFileSize(file.size, true)})`}
                            disabled
                            name={`file-${file.name}`}
                            InputProps={{
                                readOnly: true,
                                sx: { '&::before': { borderBottomStyle: 'solid !important' } },
                            }}
                            variant="standard"
                            helperText={isError && errorText}
                            error={isError}
                        />
                        <Button
                            customVariant={ButtonCustomVariant.ICON_BUTTON}
                            sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
                            color="primary"
                            onClick={() => handleRemoveFile(file.name)}
                        >
                            <DeleteIcon sx={{ marginLeft: spacing(1) }} />
                        </Button>
                    </Box>
                ))}

            {(multiple || !uploadedFiles) && (
                <Box sx={{ marginTop: spacing(1), display: 'flex', alignItems: 'flex-end' }}>
                    <label
                        htmlFor="inputFile"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            width: '100%',
                        }}
                        onClick={handleClickLabel}
                    >
                        <CloudUpload sx={{ marginRight: spacing(1) }} />

                        <input
                            onChange={handleInputChange}
                            accept={acceptFileFormats}
                            type="file"
                            id="inputFile"
                            style={{ display: 'none' }}
                        />

                        <MuiTextField
                            sx={{ width: '100%' }}
                            value={value}
                            disabled
                            name="file"
                            helperText={isError && errorText}
                            error={isError}
                            InputProps={{
                                readOnly: true,
                                sx: { '&::before': { borderBottomStyle: 'solid !important' } },
                            }}
                            variant="standard"
                        />
                    </label>
                </Box>
            )}
        </>
    );
};
