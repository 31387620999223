import { CardMedia, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import bikeNotFoundImage from 'src/assets/bike-notfound.png';
import { TParentBikeModel } from 'src/stores/types/ParentBike';
import { convertImageToThumbnail } from 'src/utils/convertImageSize';
import { fadeInDownKeyFrames } from './styles/keyframes';
import WarrantyDetails from './WarrantyDetails';

const classes = {
    bikeImage: 'bikeImage',
    media: 'media',
};

const BikeImage = styled('div')(() => ({
    [`&.${classes.bikeImage}`]: {
        transition: 'all .3s ease',
        height: '100px',
    },
    [`& .${classes.media}`]: {
        // ⚠️ object-fit is not supported by IE11.
        objectFit: 'cover',
        height: '100px',
        display: 'block',
        width: 'auto',
        animation: `${fadeInDownKeyFrames} 0.5s ease-out`,
        transition: 'all .3s ease',
    },
}));

const TitleContainer = styled('div')(() => ({
    margin: '1rem',
    '& p': {
        transition: 'all .3s ease',
    },
}));

interface ParentBikeProps {
    showWarranty: boolean;
    bike: TParentBikeModel;
}

const ParentBike = ({ showWarranty, bike }: ParentBikeProps) => {
    const { palette } = useTheme();
    return (
        <>
            <BikeImage className={classes.bikeImage}>
                <CardMedia
                    data-testid="bike-card"
                    component="img"
                    className={classes.media}
                    src={bike.imageUrl ? convertImageToThumbnail(bike.imageUrl) : bikeNotFoundImage}
                    title={bike.name}
                />
            </BikeImage>
            <TitleContainer>
                {bike.frameNumber && (
                    <Typography data-testid="edit-search">
                        <span>{bike.frameNumber}</span>
                    </Typography>
                )}
                <Typography data-testid="bike-name">{bike.name}</Typography>
                {bike.productCode && (
                    <Typography
                        data-testid="bike-id"
                        color={palette.secondary.light}
                    >{`#${bike.productCode}`}</Typography>
                )}
                {showWarranty && <WarrantyDetails product={bike} shortText />}
            </TitleContainer>
        </>
    );
};

export default ParentBike;
