import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Grid, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppModules } from 'src/services/api/models';
import RoleBaseAccess from 'src/services/RoleBaseAccess';
import Store from 'src/stores/RootStore';
import { TCartItem } from 'src/stores/types/CartItemModel';
import { ProductCategoryTypes } from 'src/utils/constants';
import CartDetails from './CartDetails';
import CartItem from './CartItem';
interface CartProps extends WithStyles<typeof styles> {
    path: string;
    store?: typeof Store.Type;
    activeCartType: ProductCategoryTypes.BIKE | ProductCategoryTypes.PART;
    setActiveCartType: (type: ProductCategoryTypes.BIKE | ProductCategoryTypes.PART) => void;
}

const Cart = inject('store')(
    observer(({ classes, store, activeCartType, setActiveCartType }: CartProps) => {
        useEffect(() => {
            const bikes = store!.cart.filterItems([ProductCategoryTypes.BIKE]);
            const parts = store!.cart.filterItems([
                ProductCategoryTypes.PART,
                ProductCategoryTypes.BATTERY,
                ProductCategoryTypes.MARKETING,
            ]);

            if (bikes.length && !parts.length && RoleBaseAccess.isModuleAllowed(AppModules.ORDER_BIKES)) {
                setActiveCartType(ProductCategoryTypes.BIKE);
            } else if ((!bikes.length && parts.length) || !RoleBaseAccess.isModuleAllowed(AppModules.ORDER_BIKES)) {
                setActiveCartType(ProductCategoryTypes.PART);
            }
        }, [store, setActiveCartType]);

        const displayItem = (cartItem: TCartItem) => {
            if (cartItem.product!.type === ProductCategoryTypes.BIKE) {
                store!.products.setSelectedBikeHpCode(cartItem.id);
                if (cartItem && cartItem.battery && cartItem.battery.code) {
                    store!.products.setSelectedBikeBatteryCode(cartItem.battery.code);
                }
                store!.products.toggleProductDetailDialog(true);
            } else if (cartItem.product!.type === ProductCategoryTypes.MARKETING) {
                store!.marketingMaterials.setSelectedItemId(cartItem.id);
                store!.marketingMaterials.toggleMarketingMaterialDetailsDialog(true);
            } else {
                store!.spareParts.setSelectedSparePartId(cartItem.id);
                store!.spareParts.toggleSparePartDetailDialog(true);
            }
        };

        const renderCartBy = (types: ProductCategoryTypes[]) => {
            const filteredItems = store!.cart.filterItems(types);
            if (!filteredItems.length) {
                return [];
            }

            return filteredItems.map((item: TCartItem, index: number) => {
                return (
                    <CartItem
                        key={`${item.id}${
                            item.product.type === ProductCategoryTypes.BIKE && item.battery?.code
                                ? '-' + item.battery.code
                                : ''
                        }-${index}`}
                        item={item}
                        removeCartItem={() => store!.cart.removeCartItem(item)}
                        viewItem={(viewItem: TCartItem) => displayItem(viewItem)}
                    />
                );
            });
        };

        const renderCartTabsContent = () => {
            let bikesCart = renderCartBy([ProductCategoryTypes.BIKE]);
            let sparePartsCart = renderCartBy([
                ProductCategoryTypes.PART,
                ProductCategoryTypes.BATTERY,
                ProductCategoryTypes.MARKETING,
            ]);
            const emptyCartMessage = (
                <Card className={classes.card} data-testid="empty-cart">
                    <Typography>
                        <FormattedMessage id="cart.empty_cart" />
                    </Typography>
                </Card>
            );
            return (
                <Grid container spacing={2} className={classes.cartHolder}>
                    <Grid item sm={12} className={classes.cartItems}>
                        {activeCartType === ProductCategoryTypes.BIKE &&
                        RoleBaseAccess.isModuleAllowed(AppModules.ORDER_BIKES) ? (
                                bikesCart.length ? (
                                    <>
                                        <Typography>
                                            <FormattedMessage id="cart.selectingNote" />
                                        </Typography>
                                        {bikesCart}
                                    </>
                                ) : (
                                    emptyCartMessage
                                )
                            ) : sparePartsCart.length ? (
                                sparePartsCart
                            ) : (
                                emptyCartMessage
                            )}
                    </Grid>

                    <Grid item sm={12} className={classes.cartDetails}>
                        <CartDetails activeCartType={activeCartType} totalPrice={store!.cart.totalPrice(activeCartType)} />
                    </Grid>
                </Grid>
            );
        };

        return (
            <>
                <Grid container spacing={3} className={classes.cart}>
                    <Grid item sm={10} xs={9}>
                        <Typography variant="h5" className={classes.cartTitle}>
                            <FormattedMessage id="cart.title" />
                        </Typography>
                        <Typography component={'div'} className={classes.tabs} variant="body1">
                            {RoleBaseAccess.isModuleAllowed(AppModules.ORDER_BIKES) && (
                                <div
                                    data-testid="tab-bike"
                                    className={`
                                    ${classes.tab}
                                    ${activeCartType === ProductCategoryTypes.BIKE ? classes.activeTab : ''}
                                `}
                                    onClick={() => setActiveCartType(ProductCategoryTypes.BIKE)}
                                >
                                    <FormattedMessage id="cart.bikes" />
                                    <span
                                        className={`
                                        ${classes.badge}
                                        ${activeCartType === ProductCategoryTypes.BIKE ? classes.activeBadge : ''}
                                    `}
                                    >
                                        {store!.cart.totalBikesQuantity}
                                    </span>
                                </div>
                            )}
                            <div
                                data-testid="tab-parts"
                                className={`
                                    ${classes.tab}
                                    ${activeCartType === ProductCategoryTypes.PART ? classes.activeTab : ''}
                                `}
                                onClick={() => setActiveCartType(ProductCategoryTypes.PART)}
                            >
                                <FormattedMessage id="cart.accessories" />
                                <span
                                    className={`
                                        ${classes.badge}
                                        ${activeCartType === ProductCategoryTypes.PART ? classes.activeBadge : ''}
                                    `}
                                >
                                    {store!.cart.totalSparePartsQuantity}
                                </span>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item sm={2} xs={3}>
                        {store!.config ? (
                            <Button onClick={store!.config.toggleCart} data-testid="cart-close">
                                <FontAwesomeIcon icon={faTimes} size="lg" />
                            </Button>
                        ) : null}
                    </Grid>
                </Grid>
                {renderCartTabsContent()}
            </>
        );
    }),
);

function styles({ breakpoints, spacing, palette }: Theme) {
    return createStyles({
        cart: {
            boxShadow: 'none',
            padding: '20px 20px 10px 20px',
        },
        cartTitle: {
            marginBottom: spacing(2),
        },
        cartHolder: {
            padding: '10px 20px',
            alignItems: 'stretch',
            alignContent: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            height: 'calc(100vh - 95px)',
        },
        cartItems: {
            overflowY: 'scroll',
        },
        cartDetails: {
            flex: 'none',
        },
        card: {
            flexGrow: 1,
            maxWidth: '100%',
            margin: '0 0 1.5%',
            boxShadow: 'none',
            justifyContent: 'space-between',
            [breakpoints.down('sm')]: {
                maxWidth: '100%',
                margin: '0 0 10%',
            },
        },
        tabs: {
            display: 'flex',
        },
        tab: {
            paddingRight: spacing(4),
            flex: 'none',
            textTransform: 'uppercase',
            color: palette.text.primary,
            cursor: 'pointer',
        },
        activeTab: {
            color: palette.secondary.main,
        },
        badge: {
            backgroundColor: '#eee',
            color: palette.text.primary,
            borderRadius: '50%',
            fontSize: '0.8rem',
            width: '1.5rem',
            height: '1.5rem',
            display: 'inline-block',
            position: 'relative',
            left: '3px',
            top: '-8px',
            textAlign: 'center',
            lineHeight: '1.5rem',
        },
        activeBadge: {
            background: palette.secondary.main,
            color: 'white',
        },
    });
}

export default withStyles(styles)(Cart);
