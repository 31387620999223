import { CardMedia, Typography, styled } from '@mui/material';
import React from 'react';
import bikeNotFoundImage from 'src/assets/bike-notfound.png';
import { TParentBikeModel } from 'src/stores/types/ParentBike';
import { convertImageToThumbnail } from 'src/utils/convertImageSize';
import { fadeInDownKeyFrames } from './styles';

const classes = {
    root: 'root',
    details: 'details',
    media: 'media',
};

const Container = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    [`& .${classes.details}`]: {
        display: 'flex',
        width: '100%',
        opacity: 0.8,
    },
    [`& .${classes.media}`]: {
        // ⚠️ object-fit is not supported by IE11.
        objectFit: 'cover',
        height: '90px',
        margin: '.5rem 0',
        display: 'block',
        width: 'auto',
        animation: `${fadeInDownKeyFrames} 0.5s ease-out`,
        transition: 'all .3s ease',
    },
}));

interface ParentBikeInModalProps {
    bike: TParentBikeModel;
}

const ParentBikeInModal = ({ bike }: ParentBikeInModalProps) => {
    return (
        <Container className={classes.root}>
            <div className={classes.details}>
                <div style={{ margin: '1rem 0', flex: 1 }}>
                    {bike.frameNumber && (
                        <Typography data-testid="product-detail-dialog-bike-frame" fontWeight={700}>
                            <span>{bike.frameNumber}</span>
                        </Typography>
                    )}
                    <Typography data-testid="product-detail-dialog-bike-name" fontSize="1rem" variant="h6">
                        {bike.name}
                    </Typography>
                    {bike.productCode && <Typography sx={{ opacity: 0.5 }}>{`#${bike.productCode}`}</Typography>}
                </div>
                <div style={{ transition: 'all .3s ease' }}>
                    <CardMedia
                        component="img"
                        data-testid="product-detail-dialog-bike-img"
                        className={classes.media}
                        src={bike.imageUrl ? convertImageToThumbnail(bike.imageUrl) : bikeNotFoundImage}
                        title={bike.name}
                    />
                </div>
            </div>
        </Container>
    );
};

export default ParentBikeInModal;
