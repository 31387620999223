import dayjs from 'dayjs';
import { types } from 'mobx-state-tree';

export type TParentBikeModel = typeof ParentBikeModel.Type;

export const ParentBikeModel = types
    .model('ParentBikeModel', {
        id: types.identifier,
        name: types.string,
        imageUrl: types.maybeNull(types.string),
        productCode: types.string,
        frameNumber: types.string,
        shipmentDate: types.maybeNull(types.string),

        /**
         * @deprecated Bike warranty expiry date is deprecated by SE-2298, will be removed from the model soon
         */
        warrantyExpiryDate: types.maybeNull(types.string),
    })
    .views(self => ({
        get dateSold() {
            return self.shipmentDate ? dayjs(self.shipmentDate).format('DD MMM YYYY') : null;
        },
        get warrantyLimit() {
            return self.warrantyExpiryDate ? dayjs(self.warrantyExpiryDate).format('DD MMM YYYY') : null;
        },
        get isWarrantyExpired() {
            const today = dayjs();
            return self.warrantyExpiryDate ? dayjs(self.warrantyExpiryDate).isBefore(today) : false;
        },
    }));
