import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Modal, Slide, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalModel, ModalType } from 'src/stores/ModalStore';
import { Chains } from '../Chains';

interface ToastModalProps extends WithStyles<typeof styles> {
    modal?: typeof ModalModel.Type;
}

const modalWidth = 600;

const WarningModal = inject('modal')(
    observer(({ classes, modal }: ToastModalProps) => {
        return (
            <Modal
                open={modal!.isOpen}
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="down" in={modal!.isOpen} mountOnEnter unmountOnExit>
                    <div style={getModalStyle()} className={classes.paper}>
                        <div className={classes.padding}>
                            <Grid container spacing={2}>
                                <Grid item xs={9} md={10} lg={11}>
                                    <Typography variant="h5" gutterBottom>
                                        <FormattedMessage id={modal!.title} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} md={2} lg={1} style={{ textAlign: 'right' }}>
                                    {modal!.dismissable && (
                                        <Button
                                            style={{ minWidth: 30 }}
                                            size="small"
                                            variant="text"
                                            disableRipple
                                            onClick={modal!.close}
                                        >
                                            <FontAwesomeIcon icon={faTimes} size={'lg'} />
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                            <Typography variant="subtitle1" gutterBottom>
                                <FormattedMessage id={modal!.message} />
                            </Typography>
                        </div>
                        {modal!.type !== ModalType.CONFIRMATION && <Chains />}
                        <div className={classes.actions}>
                            {modal!.type === ModalType.CONFIRMATION ? (
                                <>
                                    <Button
                                        data-testid="dismiss-warning"
                                        variant="contained"
                                        onClick={modal!.close}
                                        color="primary"
                                    >
                                        <FormattedMessage id={'returns.noButton'} />
                                    </Button>
                                    <Button
                                        data-testid="confirm-warning"
                                        variant="contained"
                                        onClick={() => {
                                            modal!.close();
                                            modal!.onConfirm();
                                        }}
                                        color="secondary"
                                    >
                                        <FormattedMessage id={modal!.buttonText} />
                                    </Button>
                                </>
                            ) : (
                                <Button variant="contained" onClick={modal!.close} color="primary">
                                    <FormattedMessage id={modal!.buttonText} />
                                </Button>
                            )}
                        </div>
                    </div>
                </Slide>
            </Modal>
        );
    }),
);

function getModalStyle() {
    const top = 25;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        marginLeft: `-${modalWidth / 2}px`,
    };
}

function styles({ palette, spacing, shadows }: Theme) {
    return createStyles({
        padding: {
            padding: spacing(2),
        },
        actions: {
            padding: spacing(2),
            textAlign: 'right',
            '& button': {
                marginLeft: '.5rem',
            },
        },
        paper: {
            position: 'absolute',
            width: modalWidth,
            backgroundColor: palette.background.paper,
            boxShadow: shadows[5],
        },
    });
}

export default withStyles(styles)(WarningModal);
