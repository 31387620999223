import Dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import UserStore from 'src/stores/UserStore';
import CONFIG from 'src/utils/config';
import { StageEnviroments } from 'src/utils/constants';
import { StorageNameSpace } from 'src/utils/storageNameSpace';

const { DOMAIN } = CONFIG;

class UserService {
    public user: StorageNameSpace.USER;

    constructor() {
        this.user = StorageNameSpace.USER;
    }

    private static expires(hours: number = 1) {
        return Dayjs().add(hours, 'hour').toDate();
    }

    saveUser(user: SnapshotIn<typeof UserStore>): void {
        Cookies.set(this.user, JSON.stringify(user), {
            domain: DOMAIN,
            expires: UserService.expires(12),
            secure: DOMAIN !== StageEnviroments.LOCAL,
            sameSite: 'strict',
        });
    }

    validUserCookie() {
        return !!Cookies.get(this.user);
    }

    getUserCookie() {
        return Cookies.getJSON(this.user);
    }

    getUser(): SnapshotOut<typeof UserStore> | null {
        const user = this.getUserCookie();
        return user ? user : null;
    }

    clean() {
        Cookies.set(this.user, '', {
            domain: DOMAIN,
            expires: -1, // equal to SF session timeout
            secure: DOMAIN !== StageEnviroments.LOCAL,
        });
    }
}

export default new UserService();
