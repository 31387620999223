import {
    faFilter as faFilterSolid,
    faMagnifyingGlass as faMagnifyingGlassSolid,
} from '@fortawesome/free-solid-svg-icons';
import { faFilter, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import { Box, FormGroup, FormControlLabel, Stack, Input, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import IOSSwitch from 'src/components/IOSSwitch';

interface TableFiltersBarProps {
    switcherValue?: boolean;
    onChangeSwitcher?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    switcherLabel: React.ReactNode;
    openFilters: boolean;
    onClickFilters: () => void;
    openSearch: boolean;
    onClickSearchIcon: () => void;
    inputSearchRef: React.RefObject<HTMLInputElement>;
    searchValue: string;
    onSearchChange: (text: string) => void;
}

const TableFiltersBar = ({
    switcherValue,
    onChangeSwitcher,
    switcherLabel,
    openFilters,
    onClickFilters,
    openSearch,
    onClickSearchIcon,
    inputSearchRef,
    searchValue,
    onSearchChange,
}: TableFiltersBarProps) => {
    const { palette } = useTheme();
    return (
        <Stack direction="row" alignItems="center" flexWrap="wrap">
            <FormGroup>
                <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} checked={switcherValue} onChange={onChangeSwitcher} />}
                    label={switcherLabel}
                />
            </FormGroup>

            <FontAwesomeIcon
                icon={openFilters ? faFilterSolid : faFilter}
                size="lg"
                color={palette.secondary.light}
                cursor="pointer"
                onClick={onClickFilters}
                style={{ paddingRight: '1rem' }}
            />

            <FontAwesomeIcon
                icon={openSearch ? faMagnifyingGlassSolid : faMagnifyingGlass}
                size="lg"
                color={palette.secondary.light}
                cursor="pointer"
                onClick={onClickSearchIcon}
                style={{ paddingRight: '1.5rem' }}
            />

            <Box
                sx={{
                    opacity: openSearch ? 1 : 0,
                    transition: `opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
                }}
            >
                <Input
                    sx={{ minWidth: '300px' }}
                    inputRef={inputSearchRef}
                    value={searchValue}
                    onChange={e => onSearchChange(e.target.value)}
                />
                <IconButton onClick={() => onSearchChange('')}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Stack>
    );
};

export default TableFiltersBar;
