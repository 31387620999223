import React, { PropsWithChildren } from 'react';
import Else from './Else';
import When from './When';

interface ChooseProps {}

const Choose = ({ children }: PropsWithChildren<ChooseProps>) => {
    let isAnyConditionValid = false;

    let modifiedChildren = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            const childType = (child as React.ReactElement).type;

            if (childType !== When && childType !== Else) {
                throw new Error('You can only use \'When\' and \'Else\' components as children');
            }

            const { condition } = child.props;
            const isConditionValid = (typeof condition === 'function') ? condition() : condition;

            if (isConditionValid) {
                isAnyConditionValid = true;
            }
        }

        return child;
    });

    modifiedChildren = isAnyConditionValid
        ? modifiedChildren?.filter(child => (child as React.ReactElement).type === When)
        : modifiedChildren?.filter(child => (child as React.ReactElement).type === Else);

    return (
        <>
            {modifiedChildren}
        </>
    );
};

export default Choose;
