import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface WarningBoxLightProps {
    titleKey: string;
    messageKey?: string;
    messageKeyValues?: Record<string, React.ReactNode>;
}

export const WarningBoxLight = ({ titleKey, messageKey, messageKeyValues }: WarningBoxLightProps) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.grey[200],
                padding: '1rem',
            }}
        >
            <Stack direction="row" alignItems="center" paddingBottom="1rem">
                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" color={theme.palette.secondary.main} />
                <Typography variant="subtitle1" fontWeight={600} paddingLeft="1rem">
                    <FormattedMessage id={titleKey} />
                </Typography>
            </Stack>
            {!!messageKey && (
                <Typography>
                    <FormattedMessage id={messageKey} values={messageKeyValues} />
                </Typography>
            )}
        </Box>
    );
};
