import { Grid } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React, { PropsWithChildren } from 'react';
import MarketingImage from 'src/components/MarketingImage';
import Store from 'src/stores/RootStore';
import { TMarketingMaterial } from 'src/stores/types/MarketingMaterialTypes';

interface DashboardSlideShowProps extends PropsWithChildren<WithStyles<typeof styles>> {
    store?: typeof Store.Type;
}

const DashboardSlideShow = inject('store')(
    observer(({ classes, store }: DashboardSlideShowProps) => {
        return (
            <>
                {store!.marketing.items.map((tool: TMarketingMaterial, index: number) =>
                    tool === store!.marketing.items[0] ? (
                        <Grid
                            container
                            className={classes.dashboardPromoWrapper}
                            key={index}
                            data-testid="marketing-promotion-wrapper"
                            item
                            xs={12}
                            sm={3}
                            md={5}
                            lg={6}
                        >
                            <MarketingImage tool={tool} activeTool={store!.marketing.active} />
                        </Grid>
                    ) : null,
                )}
            </>
        );
    }),
);

function styles() {
    return createStyles({
        '@keyframes fadeInDown': {
            from: {
                opacity: 0,
                transform: 'translate(0, -30px)',
            },
            to: {
                opacity: 1,
                transform: 'translate(0, 0)',
            },
        },
        dashboardPromoWrapper: {
            position: 'absolute',
            paddingTop: '1rem',
            maxWidth: '100%',
            maxHeight: '100%',
            animation: '$fadeInDown 1s ease-out',
        },
    });
}

export default withStyles(styles)(DashboardSlideShow);
