import { types } from 'mobx-state-tree';

export type TBikeInServiceModel = typeof BikeInServiceModel.Type;

export const BikeInServiceModel = types.model('BikeInServiceModel', {
    id: types.string,
    color: types.string,
    imageUrl: types.maybeNull(types.string),
    size: types.string,
    version: types.string,
    model: types.string,
    serie: types.string,
});
