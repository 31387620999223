import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Theme,
} from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Store from 'src/stores/RootStore';
import { AvailabilityOptions, AvailabilityOptionsKeys } from 'src/utils/constants';
import ProductSearch from './ProductSearch';

interface ProductFiltersProps extends WithStyles<typeof styles> {
    store?: typeof Store.Type;
    resetPagination: () => void;
    initSearchFilterValue?: string | string[];
}

const itemsPerPageOptions = [64, 96, 128, 160];

const ProductFilters = inject('store')(
    observer(({ classes, resetPagination, store, initSearchFilterValue }: ProductFiltersProps) => {
        return (
            <div className={classes.filterContainer}>
                <div className={classes.filterField}>
                    <ProductSearch
                        resetPagination={resetPagination}
                        setTextFilter={store?.products.setTextFilter}
                        initSearchFilterValue={initSearchFilterValue}
                    />
                </div>

                <div className={classes.filterField}>
                    <FormControl className={classes!.formControl}>
                        <InputLabel htmlFor="size-label-placeholder">
                            <FormattedMessage id="filters.select_size" />
                        </InputLabel>
                        <Select
                            data-testid="search-product-size"
                            multiple
                            value={store?.products.selectedSizesFilter}
                            onChange={event => {
                                store?.products.setSelectedSizesFilter(event.target.value as string[]);
                                resetPagination();
                            }}
                            input={<Input className={classes.filterInput} id="size-label-placeholder" />}
                            renderValue={selected => (
                                <>
                                    {Array.isArray(selected) ? (
                                        <div className={classes.chips}>
                                            {selected &&
                                                selected.map(value => (
                                                    <Chip key={value} label={value} className={classes.chip} />
                                                ))}
                                        </div>
                                    ) : (
                                        <div className={classes!.chips}>
                                            <Chip label={selected as string} className={classes!.chip} />
                                        </div>
                                    )}
                                </>
                            )}
                        >
                            <MenuItem disabled selected key="n/a" value="">
                                <FormattedMessage id="filters.no_size_selected" />
                            </MenuItem>
                            {store!.products.sizes.map((size: string) => (
                                <MenuItem key={size} value={size}>
                                    <Checkbox
                                        checked={store?.products.selectedSizesFilter.indexOf(size) > -1}
                                        color="secondary"
                                    />
                                    <ListItemText primary={size} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {store?.products.selectedSizesFilter.length > 0 && (
                        <Button
                            size="small"
                            className={classes.filterReset}
                            onClick={() => {
                                store?.products.setSelectedSizesFilter([]);
                                resetPagination();
                            }}
                        >
                            <CloseIcon color="secondary" />
                        </Button>
                    )}
                </div>

                <div className={classes.filterField}>
                    <FormControl className={classes!.formControl}>
                        <InputLabel htmlFor="availability-label-placeholder">
                            <FormattedMessage id="filters.select_availability" />
                        </InputLabel>
                        <Select
                            data-testid="search-product-availability"
                            value={store?.products.selectedAvailabilityFilter}
                            onChange={event => {
                                store?.products.setSelectedAvailablityFilter(event.target.value as string);
                                resetPagination();
                            }}
                            input={<Input className={classes.filterInput} id="availability-label-placeholder" />}
                        >
                            <MenuItem disabled selected key="no_availability_selected" value="">
                                <FormattedMessage id="filters.no_availability_selected" />
                            </MenuItem>
                            {Object.keys(AvailabilityOptions).map((option, index) => (
                                <MenuItem key={index} value={AvailabilityOptions[option as AvailabilityOptionsKeys]}>
                                    <FormattedMessage
                                        id={`filters.availability.${
                                            AvailabilityOptions[option as AvailabilityOptionsKeys]
                                        }`}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {store?.products.selectedAvailabilityFilter.length > 0 && (
                        <Button
                            size="small"
                            className={classes.filterReset}
                            onClick={() => {
                                store?.products.setSelectedAvailablityFilter('');
                                resetPagination();
                            }}
                        >
                            <CloseIcon color="secondary" />
                        </Button>
                    )}
                </div>

                <div className={classes.filterField}>
                    <FormControl className={classes!.formControl}>
                        <InputLabel htmlFor="itemsPerPage-label-placeholder">
                            <FormattedMessage id="filters.itemsPerPage" />
                        </InputLabel>
                        <Select
                            style={{ marginTop: '13px' }}
                            value={store?.products.itemsPerPage}
                            onChange={event => {
                                store?.products.setItemsPerPage(parseInt(event.target.value as string, 10));
                                resetPagination();
                            }}
                            input={<Input className={classes.filterInput} id="itemsPerPage-label-placeholder" />}
                        >
                            {itemsPerPageOptions.map((option, index) => (
                                <MenuItem key={index + 1} value={option}>
                                    <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div className={classes.filterField}>
                    <FormControlLabel
                        className={classes!.formControl}
                        label={<FormattedMessage id="filters.only_in_stock" />}
                        control={
                            <Checkbox
                                data-testid="hide-deprecated"
                                checked={store?.products.hideDeprecatedFilter}
                                onChange={event => {
                                    store?.products.setHideDeprecatedFilter(event.target.checked);
                                }}
                                color="secondary"
                            />
                        }
                    />
                </div>
            </div>
        );
    }),
);

function styles({ spacing }: Theme) {
    return createStyles({
        holder: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        formControl: {
            margin: spacing(1),
            minWidth: 190,
            flex: 1,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: '2px',
            padding: 0,
        },
        filterContainer: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        filterField: {
            display: 'flex',
        },
        filterReset: {
            flex: 1,
            minWidth: '20px',
            padding: 0,
        },
        filterInput: {
            minHeight: '42px',
        },
    });
}

export default withStyles(styles)(ProductFilters);
