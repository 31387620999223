import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import React from 'react';

const borderColor = '#e9e9e9';

export let defaultTheme: ThemeOptions = {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    overflow: 'hidden',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h5: {
                    fontWeight: 800,
                },
                h6: {
                    fontWeight: 600,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: 25,
                    lineHeight: '25px',
                    borderRadius: 0,
                    padding: '.5rem',
                    boxSizing: 'content-box',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderBottom: `1px solid ${borderColor}`,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
    },
    typography: {
        fontFamily: ['Apercu'].join(','),
    },
    palette: {
        primary: {
            main: '#1c1c1c',
            light: '#eee',
        },
        secondary: {
            main: '#EE7728',
            contrastText: '#fff',
            light: '#707070',
        },
        background: {
            default: '#FFF',
        },
    },
};

const theme = createTheme(defaultTheme);

function withRoot<P>(Component: React.ComponentType<P>) {
    function WithRoot(props?: P) {
        return (
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                {/* @ts-ignore */}
                <Component {...props} />
            </ThemeProvider>
        );
    }

    return WithRoot;
}

export default withRoot;
