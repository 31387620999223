import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const CartHeader = ({ onClickClose }: { onClickClose: () => void }) => {
    return (
        <Grid container spacing={3} style={{ flex: 'none ', boxShadow: 'none', padding: '20px 20px 10px 20px' }}>
            <Grid item sm={10} xs={9}>
                <Typography component="h1" variant="h6">
                    <FormattedMessage id="cartReturns.title" />
                </Typography>
            </Grid>
            <Grid item sm={2} xs={3}>
                <Button onClick={onClickClose} data-testid="cart-returns-close">
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                </Button>
            </Grid>
        </Grid>
    );
};
