import { getParent, Instance, types } from 'mobx-state-tree';
import { CartReturnItemParenType, ProductCategoryTypes, SearchType, SparePartCategory } from '../../utils/constants';
import RootStore from '../RootStore';

export interface TCartReturnItemModel extends Instance<typeof CartReturnItemModel> {}

export const CartReturnItemModel = types
    .model('CartReturnItemModel', {
        id: types.identifier,
        comments: types.optional(types.string, ''),
        attachment: types.maybeNull(types.frozen()),
        attachmentType: types.optional(types.string, ''),
        description: types.optional(types.string, ''),
        frameNumber: types.optional(types.string, ''),
        invoiceLineId: types.optional(types.string, ''),
        invoiceNumber: types.optional(types.string, ''),
        invoiceId: types.optional(types.string, ''),
        reorder: types.optional(types.boolean, false),
        parentProductCode: types.optional(types.string, ''),
        productCode: types.string,
        productType: types.enumeration(Object.values(ProductCategoryTypes)),
        category: types.optional(types.string, SparePartCategory.GENERAL),
        reason: types.optional(types.string, ''),
        reference: types.optional(types.string, ''),
        serialNumber: types.optional(types.string, ''),
        productName: types.string,
        receipt: types.optional(types.boolean, false),
        parentType: types.maybe(types.enumeration(Object.values(CartReturnItemParenType))),
        warranty: types.maybe(types.boolean),
        physicalDamageBattery: types.maybe(types.boolean),
        reasonNoFramenumber: types.maybe(types.string),
        consumerPurchaseDate: types.maybe(types.string),
    })
    .volatile(self => ({
        fileError: false,
        fileErrorMessage: '',
        isSelected: false,
        attachmentBase64: '',
    }))
    .actions(self => ({
        setAttachmentBase64(value: string) {
            self.attachmentBase64 = value;
        },

        setAttachmentType(type: string) {
            self.attachmentType = type;
        },

        async setAttachment(fileData: File) {
            self.attachment = fileData;

            // Use FileReader to convert file to base64 string
            const fileToBase64 = async (file: File) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsBinaryString(file);
                    reader.onload = () => resolve(btoa(reader.result as string));
                    reader.onerror = e => reject(e);
                });

            const imageStr = await fileToBase64(fileData);
            this.setAttachmentBase64(imageStr as string);

            const { name: fileName } = fileData;
            this.setAttachmentType(fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileData.type);
        },

        setFileError(status: boolean, message: string) {
            self.fileError = status;
            self.fileErrorMessage = message;
        },

        async loadSparePartInModel() {
            const rootStore: typeof RootStore.Type = getParent(self, 3);
            if (self.frameNumber) {
                await rootStore.spareParts.getSparePartsBy(self.frameNumber, SearchType.FRAME_NUMBER, false);
            } else if (self.parentProductCode) {
                await rootStore.spareParts.getSparePartsBy(self.parentProductCode, SearchType.PRODUCT_CODE, false);
            } else {
                await rootStore.spareParts.getSparePartsByPartId(self.productCode);
            }
        },

        async getInvoiceObject() {
            if (self.invoiceId) {
                const rootStore: typeof RootStore.Type = getParent(self, 3);
                await rootStore.invoices.getInvoiceByInvoiceId(self.invoiceId);
                const invoice = rootStore.invoices.list.get(self.invoiceId);
                if (invoice) {
                    await invoice.getInvoiceLines();
                }
            }
        },

        toggleSelect() {
            self.isSelected = !self.isSelected;
        },
    }));
