import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, LinearProgress, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface CartActionsProps {
    isSending: boolean;
    onClickSend: () => void;
}

export const CartActions = ({ isSending, onClickSend }: CartActionsProps) => {
    return (
        <Grid item sm={12} style={{ flex: 'none', width: '100%' }}>
            {isSending ? (
                <>
                    <Typography align="right">
                        <FormattedMessage id="cart.placing_order" />
                    </Typography>
                    <LinearProgress />
                </>
            ) : (
                <Button
                    data-testid="submit-return-request-items"
                    variant="contained"
                    color="primary"
                    onClick={onClickSend}
                    fullWidth
                >
                    <FormattedMessage id="cartReturns.sendReturnItems" />
                    <FontAwesomeIcon icon={faPaperPlane} style={{ paddingLeft: '1rem' }} />
                </Button>
            )}
        </Grid>
    );
};
