import { DEFAULT_ITEMS_PER_PAGE } from 'src/utils/constants';

export const getNextLimitAndOffset = (currentTotalItems = 0, currentPage = 1, itemPerPage = DEFAULT_ITEMS_PER_PAGE) => {
    if (currentPage * itemPerPage > currentTotalItems) {
        return {
            offset: currentTotalItems,
            limit: currentPage * itemPerPage - currentTotalItems,
        };
    }

    return {
        offset: currentPage * itemPerPage,
        limit: itemPerPage,
    };
};
