import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-calendar/dist/Calendar.css';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import NotificationsListItem from 'src/components/NotificationsListItem';
import PageLoader from 'src/components/PageLoader';
import { AppPageRoutes } from 'src/services/api/models';
import Store from 'src/stores/RootStore';
import { TOrderNotification } from 'src/stores/types/NotificationsTypes';
import UserStore from 'src/stores/UserStore';

interface OrdersNotificationsProps extends WithStyles<typeof styles> {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
}

const OrdersNotifications = inject(
    'store',
    'user',
)(
    observer(({ classes, store, user }: OrdersNotificationsProps) => {
        const navigate = useNavigate();
        const [dateRange, setDateRange] = useState<Date[]>([dayjs().subtract(14, 'days').toDate(), dayjs().toDate()]);

        // Getting notifications using the date range coming from the UI
        useEffect(() => {
            store!.orderNotifications.requestNotificationsFromDateRange(dateRange[0], dateRange[1]);
        }, [store, dateRange]);

        // @TODO: this function is actually not called anywhere?
        const handleNotificationClick = (notification: TOrderNotification) => {
            navigate(AppPageRoutes.ORDERS_WITH_ID_PARAM.replace(':orderId', notification.erpOrderId || ''));
        };

        const language = user?.preferredLang;

        if (store!.orderNotifications.isLoading) {
            return <PageLoader />;
        }

        return (
            <div className={classes.root}>
                <div className={classes.notificationsHeader}>
                    <Typography variant="h5">
                        <FormattedMessage id="notifications.pageTitle" />
                    </Typography>

                    <DateRangePicker
                        className={classes.datePicker}
                        required={true}
                        locale={language}
                        format="dd/MM/y"
                        calendarIcon={<FontAwesomeIcon icon={faCalendarAlt} size="2x" />}
                        clearIcon={null}
                        maxDate={dayjs().startOf('day').toDate()}
                        value={dateRange}
                        onChange={(newDateRange: Date[]) => setDateRange(newDateRange)}
                    />
                </div>

                {store!.orderNotifications.itemsFromDateRange.length <= 0 && (
                    <Typography variant="body2">
                        <FormattedMessage id="notifications.notFound" />
                    </Typography>
                )}

                <Grid item className={classes.notificationsList} data-testid="notifications-list">
                    {store!.orderNotifications.itemsFromDateRange.map(notification => (
                        <NotificationsListItem
                            key={notification.notificationId}
                            notification={notification}
                            isUnread={store!.orderNotifications.isNotificationUnread(notification)}
                            onClick={handleNotificationClick}
                        />
                    ))}
                </Grid>
            </div>
        );
    }),
);

function styles({ spacing, typography, palette }: Theme) {
    return createStyles({
        root: {},

        notificationsHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: spacing(1),
        },

        datePicker: {
            ...typography.body1,
            zIndex: 999,
            marginLeft: spacing(2),

            '@global': {
                '.react-daterange-picker__wrapper': {
                    alignItems: 'center',
                    border: 'none',
                    fontSize: '1rem',
                },
                '.react-daterange-picker__inputGroup': {
                    margin: '0 .5rem',
                },
                '.react-date-picker__inputGroup__divider': {
                    margin: '0 2px',
                },
                '.react-daterange-picker__inputGroup__day:not(.react-daterange-picker__inputGroup__input--hasLeadingZero)': {
                    minWidth: '20px',
                },
                '.react-daterange-picker__inputGroup__year': {
                    minWidth: '35px',
                },
                '.react-calendar__navigation__arrow, .react-calendar__navigation__label': {
                    fontFamily: typography.h6.fontFamily,
                    fontSize: '1rem',
                },
                '.react-calendar__tile--active': {
                    backgroundColor: palette.secondary.main,
                },
                '.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus': {
                    backgroundColor: palette.secondary.main,
                },
            },
        },

        notificationsList: {
            display: 'flex',
            flexDirection: 'column',
        },
    });
}

export default withStyles(styles)(OrdersNotifications);
