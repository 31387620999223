import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { Grid, Stack, Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { ButtonCustomVariant } from 'src/components/button/type';
import Chip from 'src/components/Chip';
import DefinitionsBox from 'src/components/DefinitionsBox';
import Link from 'src/components/Link';
import Loading from 'src/components/loading/Loading';
import { AppPageRoutes } from 'src/services/api/models';
import Store from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import { TReturnRequest } from 'src/stores/types/ReturnRequestModel';
import { CreditingStatusMap, ShippingStatusMap } from './helper';

interface ReturnRequestDetailsProps {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
}

const ReturnRequestDetails = inject(
    'store',
    'user',
)(
    observer(({ store, user }: ReturnRequestDetailsProps) => {
        const navigate = useNavigate();
        const location = useLocation();
        const { itemId } = useParams();

        const lang = user?.preferredLang;
        const isLoading: boolean = store?.returns.loading;

        useEffect(() => {
            if (itemId) {
                store!.returns.getReturnRequestById(itemId);
            }
        }, [store, itemId]);

        const selectedRequest = useMemo(() => {
            if (itemId && !isLoading) {
                return store?.returns.getReturnById(itemId);
            }

            return undefined;
        }, [itemId, isLoading, store]);

        const renderReturnRequest = (request: TReturnRequest) => {
            const itemData: Record<string, any> = {
                product: request.product,
                productCode: request.productCode,
            };
            if (request.serialNumber) itemData.serialNumber = request.serialNumber;
            if (request.unitPrice) itemData.value = `€ ${request.unitPrice}`;

            const bikeData: Record<string, any> = {};
            if (request.frameNumber) bikeData.frameNumber = request.frameNumber;
            if (request.bikeModel || request.bikeSerie) {
                bikeData.bikeModel = request.bikeModel || '' + request.bikeSerie || '';
            }
            if (request.bikeProductCode) bikeData.modelNumber = request.bikeProductCode;

            const statusData: Record<string, any> = {
                warranty: CreditingStatusMap.has(request.creditingStatus) ? (
                    <Chip
                        label={<FormattedMessage id={`creditingStatus_${request.creditingStatus}`} />}
                        color={CreditingStatusMap.get(request.creditingStatus).color}
                    />
                ) : (
                    '-'
                ),
                shipping: ShippingStatusMap.has(request.pickUpStatus) ? (
                    <Chip
                        label={<FormattedMessage id={`shippingStatus_${request.pickUpStatus}`} />}
                        color={ShippingStatusMap.get(request.pickUpStatus).color}
                    />
                ) : (
                    '-'
                ),
            };
            if (request.orderNumberNAV) {
                statusData.replacementOrder = (
                    <Link
                        target="_blank"
                        rel="noreferrer"
                        href={`${AppPageRoutes.ORDERS}/${request.orderNumberNAV}`}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                    >
                        {request.orderNumberNAV}
                    </Link>
                );
            }
            if (request.creditNote) {
                statusData.creditNote = (
                    <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        size="xl"
                        cursor="pointer"
                        onClick={e => {
                            e.stopPropagation();
                            store?.invoices.downloadPDFbyID(request.creditNote!, lang);
                        }}
                    />
                );
            }

            const requestData: Record<string, any> = {
                createdDate: dayjs(request.createdDate).format('DD/MM/YYYY'),
                caseNumber: request.caseNumber,
                ...(request.invoiceNumber && { invoiceNumber: request.invoiceNumber }),
                ...(request.invoiceUploaded !== undefined && { invoiceUploaded: request.invoiceUploaded }),
                reason: request.reason,
                description: request.description,
                purchaseDate: request.purchaseDate ? dayjs(request.purchaseDate).format('DD/MM/YYYY') : '-',
                consumerPurchaseDate: request.consumerPurchaseDate
                    ? dayjs(request.consumerPurchaseDate).format('DD/MM/YYYY')
                    : '-',
                comments: request.reference,
            };

            return (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <DefinitionsBox translationId="returnRequest.details.item" data={itemData} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DefinitionsBox translationId="returnRequest.details.bike" data={bikeData} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DefinitionsBox translationId="returnRequest.details.status" data={statusData} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DefinitionsBox translationId="returnRequest.details.request" data={requestData} />
                        </Grid>
                    </Grid>
                </>
            );
        };

        return (
            <Stack>
                <Grid
                    container
                    spacing={3}
                    marginBottom="1rem"
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <Grid item xs={12} sm={6}>
                        <Button
                            customVariant={ButtonCustomVariant.BACK_BUTTON}
                            onClick={() => navigate(-1)}
                            hideButton={location.key === 'default'}
                        />
                    </Grid>
                    {selectedRequest?.name && (
                        <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end">
                            <Button
                                onClick={() => store!.returns.downloadPDF(selectedRequest.name, lang)}
                                color="primary"
                                variant="contained"
                                labelTextId="download.pdf"
                                endIcon={<FontAwesomeIcon icon={faFilePdf} size="lg" />}
                            />
                        </Grid>
                    )}
                </Grid>

                <Typography variant="h5" gutterBottom>
                    <FormattedMessage id="returnRequest.details.title" />
                    {` ${selectedRequest?.name ?? ''}`}
                </Typography>

                {isLoading && (
                    <Stack>
                        <Typography variant="body2" align="center">
                            <FormattedMessage id="loading.pleasewait" />
                        </Typography>

                        <Box sx={{ transform: 'scale(.5)', marginTop: '-40px' }}>
                            <Loading />
                        </Box>
                    </Stack>
                )}

                {!isLoading &&
                    (selectedRequest ? (
                        renderReturnRequest(selectedRequest)
                    ) : (
                        <Stack marginTop="1rem">
                            <Typography variant="body2" align="center">
                                <FormattedMessage id="returnRequest.details.notFound" />
                            </Typography>
                        </Stack>
                    ))}
            </Stack>
        );
    }),
);

export default ReturnRequestDetails;
