import React from 'react';
import CONFIG from 'src/utils/config';
import { StageEnviroments } from 'src/utils/constants';
import { logUserGuidingError } from 'src/utils/logger';
import { checkReadyState, userGuidingIdentifyScript, userGuidingInitScript } from './dependencies';
import { TUserInfo, UseUserGuiding } from './types';

export default function useUserGuiding(): UseUserGuiding {
    const isReadyState = checkReadyState();
    const [readyState, setReadyState] = React.useState(React.useMemo(() => isReadyState, [isReadyState]));

    const initUserGuiding = React.useCallback((logCallback?: (...data: unknown[]) => void): boolean => {
        try {
            userGuidingInitScript();

            setReadyState(true);

            if (logCallback && typeof logCallback === 'function') {
                logCallback('User guiding ready: true');
            }

            return true;
        } catch (error) {
            if (error instanceof Error) {
                logUserGuidingError(`User guiding error: ${error.message}`);
            }

            return false;
        }
    }, []);

    const identifyUserGuiding = React.useCallback(
        (userId: string | null, userInfo: TUserInfo, logCallback?: (...data: unknown[]) => void): boolean => {
            if (CONFIG.APP_ENVIRONMENT === StageEnviroments.PRODUCTION) {
                try {
                    userGuidingIdentifyScript(userId, userInfo);

                    if (logCallback && typeof logCallback === 'function') {
                        logCallback('User guiding identified');
                    }

                    return true;
                } catch (error) {
                    if (error instanceof Error) {
                        logUserGuidingError(`User guiding error: ${error.message}`);
                    }

                    return false;
                }
            } else {
                return false;
            }
        },
        [],
    );

    return React.useMemo(
        () => ({
            readyState,
            initUserGuiding,
            identifyUserGuiding,
        }),
        [readyState, initUserGuiding, identifyUserGuiding],
    );
}
