import { TextField } from '@mui/material';
import { Field, FormikProps } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import { ReactNode, useEffect, useState } from 'react';
import { BikeAutocompleteListItem, BikesSuggestion } from 'src/components/SearchSpareParts';
import { useDebounce } from 'src/utils/useDebounce';
import { ReturnItemFormValues } from '../ReturnRequestFormv2';

interface AutocompleteFieldProps {
    label: ReactNode;
    name: string;
    list: BikesSuggestion[];
    getOptionsAutocomplete: (value: string) => void;
    placeholder?: string;
    disabled?: boolean;
    error?: string;
    defaultValue?: string;
}

export const AutocompleteField = ({
    label,
    name,
    list,
    getOptionsAutocomplete,
    placeholder,
    disabled = false,
    error,
    defaultValue,
    ...formikProps
}: AutocompleteFieldProps & FormikProps<ReturnItemFormValues>) => {
    const { setFieldValue } = formikProps;

    const [inputValue, setInputValue] = useState(defaultValue);
    const [value, setValue] = useState<BikesSuggestion | undefined>();

    // Use debounce to throttle requests on input change
    const debouncedSearchInput = useDebounce(inputValue, 1000);

    const handleGetOptionsAutocomplete = (value: string) => {
        if (value?.length > 2) {
            getOptionsAutocomplete(value);
        }
    };

    // Auto request options for autocomplete
    useEffect(() => {
        handleGetOptionsAutocomplete(debouncedSearchInput);
        setFieldValue(name, debouncedSearchInput);
    }, [debouncedSearchInput]);

    return (
        <Field
            name={name}
            component={Autocomplete}
            disabled={disabled}
            freeSolo
            options={list}
            getOptionLabel={(option: BikesSuggestion) => option.id ?? ''}
            renderOption={(props: any, option: BikesSuggestion) => {
                return (
                    <BikeAutocompleteListItem
                        {...props}
                        key={option.id}
                        bikeLabel={option.label}
                        bikeDescription={option.description}
                    />
                );
            }}
            fullWidth
            filterOptions={(x: any) => x}
            value={value}
            onChange={(event: any, newValue?: BikesSuggestion) => {
                setValue(newValue);
            }}
            onInputChange={(event: any, newInputValue: string) => {
                setInputValue(newInputValue);
            }}
            inputValue={inputValue}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                    {...params}
                    name={name}
                    error={!!error}
                    helperText={error}
                    label={label}
                    variant="outlined"
                    placeholder={placeholder}
                />
            )}
        />
    );
};
