import { types } from 'mobx-state-tree';
import { OrderNotificationChangedField } from 'src/utils/constants';

export type TOrderNotification = typeof OrderNotification.Type;

export const OrderNotification = types.model('OrderNotification', {
    notificationId: types.string,
    erpOrderId: types.maybeNull(types.string), // Navision ID
    crmOrderId: types.maybeNull(types.string), // Salesforce ID
    crmAccountNumber: types.maybeNull(types.string), // Account number for dealer (max 40 chars)
    crmAccountId: types.maybeNull(types.string), // Account id for dealer (min 15 chars, max 18)
    crmOrderUpdatedAt: types.maybeNull(types.string), // Order Updated date; Format ISO 8601: yyyy-mm-ddTHH:mm:ssZ
    createdAt: types.string, // Notification created date; Format ISO 8601: yyyy-mm-ddTHH:mm:ssZ
    changedField: types.enumeration([...Object.values(OrderNotificationChangedField)]), // Changed field name
    previousValue: types.string,
    currentValue: types.string,
});
