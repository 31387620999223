import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faBatteryBolt, faBox, faExpandAlt, faHashtag, faStar, faTag } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import { Fab, Grid } from '@mui/material';
import { Theme } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ColorDot from 'src/components/ColorDot';
import CurrencyFormat from 'src/components/currency/Currency';
import Image from 'src/components/Image';
import ProductStockIndicator from 'src/components/ProductStockIndicator';
import { AppModules } from 'src/services/api/models';
import RoleBaseAccess from 'src/services/RoleBaseAccess';
import Store from 'src/stores/RootStore';
import { TProductModel } from 'src/stores/types/ProductTypes';
import { DefaultValues } from 'src/utils/constants';
import { convertImageToThumbnail } from 'src/utils/convertImageSize';

interface ProductCardProps extends WithStyles<typeof styles> {
    product: TProductModel;
    store?: typeof Store.Type;
    selectProduct: (itemCode: string) => void;
}

const ProductCard = inject('store')(
    observer(({ classes, product, selectProduct, store }: ProductCardProps) => {
        const isFavorite = store!.favorites.isFavorite(product);
        const isDeprecated = product.productInformation.deprecated;
        const price = product.getPrice();

        const toggleFav = (e: React.MouseEvent) => {
            store!.favorites.toggleProduct(product);
            e.stopPropagation();
        };

        const calculatedClassNames = classNames(classes.card, {
            [classes.cardDeprecated]: isDeprecated,
        });

        const calculatedAddButtonClassNames = classNames(classes.addButton, {
            [classes.addButtonDisabled]: !product.productInformation.stockQuantity,
        });

        return (
            <Card
                className={calculatedClassNames}
                elevation={0}
                data-testid="product"
                onClick={() => selectProduct(product.id)}
            >
                {isDeprecated && (
                    <div className={classes.soldOut}>
                        <Typography variant="subtitle1" className={classes.soldOutText}>
                            <FormattedMessage id="status.soldOut" />
                        </Typography>
                    </div>
                )}

                <div style={{ opacity: isDeprecated ? 0.3 : 1 }}>
                    <CardMedia
                        component={(props: any) => (
                            <div style={{ position: 'relative', padding: '1rem' }}>
                                <Image
                                    {...props}
                                    src={
                                        product.productInformation?.imageUrl &&
                                        convertImageToThumbnail(product.productInformation.imageUrl)
                                    }
                                    fallbackSrc={DefaultValues.ImageNotFound}
                                />
                                {product.isBatteryUpgradePromotion && (
                                    <Image src={DefaultValues.BatteryUpgradeLogo} className={classes.promotionLogo} />
                                )}
                            </div>
                        )}
                        className={classes.media}
                        title={product.productInformation.name}
                    />

                    <CardActions>
                        <span
                            data-testid={`${isFavorite ? 'remove-from-' : 'add-to-'}favs`}
                            onClick={toggleFav}
                            className={classes.fav}
                        >
                            {isFavorite ? (
                                <FontAwesomeIcon
                                    className={classes.favIcon}
                                    icon={faStarSolid}
                                    size="lg"
                                    style={{ fontSize: '1.5em' }}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faStar} size="lg" style={{ fontSize: '1.5em' }} />
                            )}
                        </span>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    data-testid="product-serie-model"
                                    variant="subtitle1"
                                    className={classes.detailTitle}
                                    title={product.displayName}
                                >
                                    {product.displayName}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className={classes.detailRow}>
                                <Typography variant="subtitle1" className={classes.detailText}>
                                    <span className={classes.detailIcon}>
                                        <FontAwesomeIcon icon={faBox} size="1x" className={classes.detailIcon} />
                                    </span>

                                    <ProductStockIndicator
                                        isInStock={product.productInformation!.inStock === true}
                                        availableFrom={product.productInformation!.availableFrom}
                                        stockQuantity={product.productInformation!.stockQuantity}
                                    />
                                </Typography>
                            </Grid>

                            {/* TODO Color should never be null */}
                            {product.productInformation.color && (
                                <Grid
                                    data-testid={`product-color-${product.productInformation.color}`}
                                    item
                                    xs={12}
                                    className={classes.detailRow}
                                >
                                    <ColorDot color={product.productInformation.color} label={true} />
                                </Grid>
                            )}

                            <Grid item xs={6} className={classes.detailRow}>
                                <Typography variant="subtitle1" className={classes.detailText} title={product.id}>
                                    <span className={classes.detailIcon}>
                                        <FontAwesomeIcon icon={faHashtag} size="1x" />
                                    </span>
                                    {product.id}
                                </Typography>
                            </Grid>

                            <Grid item xs={6} className={classes.detailRow}>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.detailText}
                                    title={product.productInformation.size || ''}
                                >
                                    <span className={classes.detailIcon}>
                                        <FontAwesomeIcon icon={faExpandAlt} size="1x" />
                                    </span>
                                    {product.productInformation.size}
                                </Typography>
                            </Grid>

                            <Grid item xs={6} className={classes.detailRow}>
                                <Typography variant="subtitle1" className={classes.detailText}>
                                    <span className={classes.detailIcon}>
                                        <FontAwesomeIcon
                                            icon={faBatteryBolt}
                                            size="1x"
                                            className={classes.detailIcon}
                                        />
                                    </span>
                                    <FormattedMessage id="product.accu.empty" />
                                </Typography>
                            </Grid>

                            <Grid item xs={6} className={classes.detailRow}>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.detailText}
                                    title={product.productInformation.version || ''}
                                >
                                    <span className={classes.detailIcon}>
                                        <FontAwesomeIcon icon={faTag} size="1x" />
                                    </span>
                                    {product.productInformation.version}{' '}
                                </Typography>
                            </Grid>

                            {price !== null && (
                                <Grid item xs={8} className={classes.priceRow}>
                                    <Typography variant="h4" data-testid="product-price" className={classes.price}>
                                        <CurrencyFormat value={price} />
                                    </Typography>
                                </Grid>
                            )}

                            {RoleBaseAccess.isModuleAllowed(AppModules.ORDER_PRODUCTS) && price !== null && (
                                <Grid item xs={4} style={{ textAlign: 'right' }}>
                                    <Fab
                                        className={calculatedAddButtonClassNames}
                                        data-testid={`add-product-${product.id}`}
                                        color="primary"
                                        size="medium"
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Grid>
                            )}
                        </Grid>
                    </CardActions>
                </div>
            </Card>
        );
    }),
);

function styles({ breakpoints, palette, spacing }: Theme) {
    return createStyles({
        '@keyframes fadeIn': {
            from: {
                opacity: 0,
            },
            to: {
                opacity: 1,
            },
        },
        fav: {
            position: 'absolute',
            top: spacing(0.5),
            left: spacing(0.5),
            color: palette.secondary.main,
            cursor: 'pointer',
            padding: '1rem',
        },
        favIcon: {
            transition: 'all .3s ease',
        },
        card: {
            flexGrow: 1,
            position: 'relative',
            maxWidth: 'calc(100% * (1/4) - 20px - 20px)',
            minWidth: 'calc(100% * (1/4) - 20px - 20px)',
            margin: '20px',
            borderRadius: '5px',
            display: 'inline-block',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            overflow: 'visible',
            [breakpoints.down('xl')]: {
                minWidth: 'calc(100% * (1/4) - 20px - 20px)',
            },
            [breakpoints.down('lg')]: {
                maxWidth: 'calc(100% * (1/3) - 20px - 20px)',
            },
            [breakpoints.down('md')]: {
                maxWidth: '100%',
                margin: '10px',
            },
            animationDuration: '1s',
            animationFillMode: 'both',
            animationName: '$fadeIn',
            transition: 'background-color .3s ease-out',

            '&:hover': {
                background: palette.primary.light,
                cursor: 'pointer',
            },
        },
        cardDeprecated: {
            'pointer-events': 'none',
        },
        soldOut: {
            zIndex: 1,
            position: 'absolute',
            textAlign: 'center',
            top: '85px',
            left: '50%',
            transform: 'translate(-50%, 0)',
            padding: '8px 16px',
            borderRadius: '6px',
            backgroundColor: 'white',
            boxShadow: '1px 1px 5px -3px black',
        },
        soldOutText: {
            fontSize: '1.6rem',
            lineHeight: '1.6rem',
            whiteSpace: 'nowrap',
            color: 'black',
        },
        price: {
            fontSize: '1.6rem',
            lineHeight: '1.6rem',
        },
        media: {
            // ⚠️ object-fit is not supported by IE11.
            objectFit: 'cover',
            width: '100%',
        },
        promotionLogo: {
            position: 'absolute',
            width: '15%',
            top: '2rem',
            right: '2rem',
        },
        addButton: {
            flex: 1,
        },
        addButtonDisabled: {
            'pointer-events': 'none',
            visibility: 'hidden',
        },
        priceRow: {
            display: 'flex',
            alignItems: 'center',
        },
        detailRow: {
            marginTop: '-.8rem',
        },
        detailTitle: {
            fontSize: '1.6rem',
            lineHeight: '2.1rem',
            marginBottom: '1.2rem',
        },
        detailIcon: {
            display: 'inline-block',
            width: '2rem',
        },
        detailText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    });
}

const ProductCardWithStyles = withStyles(styles)(ProductCard);

export default ProductCardWithStyles;
