import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { values } from 'mobx';
import { flow, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';
import { logRejectedModel } from 'src/utils/logger';
import { Order, TOrder } from './types/OrdersTypes';

const Orders = types
    .model('OrdersStore', {
        list: types.optional(types.map(Order), {}),
        selected: types.maybe(types.reference(Order)),
    })
    .volatile(() => ({
        isLoading: false,
        isLoaded: false,
        orderNumber: '',
    }))
    .views(self => ({
        get all() {
            return values(self.list);
        },
        getOrderByID: (id: string) => {
            return self.list.get(id);
        },
        get recentOrders() {
            return orderBy(values(self.list), ['plannedDeliveryDate', 'orderDate'], ['desc', 'desc']).slice(0, 5);
        },
    }))
    .actions(self => ({
        getOrders: flow(function* (limit: number = 10000, offset: number = 0) {
            self.isLoading = true;
            try {
                const response: any = yield Service.getAllOrders(limit, offset);

                self.list.clear();

                if (response.data) {
                    response.data.forEach((order: TOrder) => {
                        if (Order.is(order) && order.name) {
                            self.list.set(order.name, order);
                        } else {
                            logRejectedModel(Order.name, order);
                        }
                    });
                    self.isLoaded = true;
                }
            } catch (error) {
                console.error(error);
            } finally {
                self.isLoading = false;
            }
        }),
        getOneOrderById: flow(function* (orderId: string) {
            self.isLoading = true;
            try {
                const response: any = yield Service.getOneOrderByOrderId(orderId);
                const order: TOrder = response.data;
                if (response && response.status === 200 && !isEmpty(order)) {
                    if (Order.is(order)) {
                        self.list.set(order.name, order);
                        self.selected = self.list.get(order.name);
                    } else {
                        logRejectedModel(Order.name, order);
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                self.isLoading = false;
            }
        }),
        setSelected: (orderId: string) => {
            if (orderId === '' || self.list.has(orderId)) {
                self.selected = self.list.get(orderId);
            }
        },
        setOrderNumber: (erpId: string) => {
            self.orderNumber = erpId;
        },
    }));

export default Orders;
