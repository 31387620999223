import dayjs from 'dayjs';
import { Instance, types } from 'mobx-state-tree';
import { ProductCategoryTypes, SparePartCategory } from 'src/utils/constants';
import { ParentBikeModel } from './ParentBike';

export type TReturnReason = typeof ReturnReason.Type;

export const ReturnReason = types
    .model('ReturnReason', {
        id: types.identifier,
        category: types.optional(types.string, SparePartCategory.GENERAL),
        needDescription: types.optional(types.boolean, false),
        reasonDE: types.optional(types.string, ''),
        reasonEN: types.optional(types.string, ''),
        reasonNL: types.optional(types.string, ''),
    })
    .views(self => ({
        translated(lang: string) {
            if (lang === 'de') {
                return self.reasonDE;
            } else if (lang === 'nl-NL') {
                return self.reasonNL;
            }
            return self.reasonEN;
        },
    }));

export type TReturnRequest = typeof ReturnRequest.Type;

export const ReturnRequest = types.model('ReturnRequest', {
    bikeModel: types.maybeNull(types.string),
    bikeProductCode: types.maybeNull(types.string),
    bikeSerie: types.maybeNull(types.string),
    caseId: types.string,
    caseNumber: types.string,
    code: types.identifier,
    name: types.string,
    id: types.string,
    invoiceNumber: types.maybeNull(types.string),
    invoiceUploaded: types.maybe(types.boolean),
    createdDate: types.string,
    description: types.maybeNull(types.string),
    reason: types.maybeNull(types.string),
    comments: types.maybeNull(types.string),
    consumerPrice: types.optional(types.number, 0),
    product: types.maybeNull(types.string),
    reference: types.maybeNull(types.string),
    frameNumber: types.maybeNull(types.string),
    pickUpStatus: types.maybeNull(types.number),
    productCode: types.maybeNull(types.string),
    serialNumber: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    creditNote: types.maybeNull(types.string),
    orderNumberNAV: types.maybeNull(types.string),
    orderReplacement: types.maybeNull(types.boolean),
    creditingStatus: types.maybeNull(types.number),
    refund: types.maybeNull(types.string),
    purchaseDate: types.maybeNull(types.string),
    consumerPurchaseDate: types.maybeNull(types.string),
    unitPrice: types.optional(types.number, 0),
});

export interface TSparePartToReturn extends Instance<typeof SparePartToReturn> {}

export const SparePartToReturn = types
    .model('SparePartToReturn', {
        id: types.string,
        type: types.enumeration(Object.values(ProductCategoryTypes)),
        imageUrl: types.maybeNull(types.string),
        description: types.string,
        serialNumber: types.optional(types.string, ''),
        serialNumberRequiredForReturn: types.boolean,
        parentBike: types.maybeNull(ParentBikeModel),
        returnReasons: types.optional(types.array(ReturnReason), []),
        category: types.optional(types.string, SparePartCategory.GENERAL),
        deprecated: types.boolean,
        warrantyExpiryDate: types.maybeNull(types.string),
    })
    .views(self => ({
        get isWarrantyExpired(): boolean {
            const today = dayjs();
            return self.warrantyExpiryDate ? dayjs(self.warrantyExpiryDate).isBefore(today) : false;
        },
    }));
