import * as React from 'react';

interface IconEnterProps {
    color?: string;
    position?: 'left' | 'right';
}

const IconEnter = ({ color, position }: IconEnterProps) => {
    const style = { stroke: color ? color : 'black', fill: 'none' };
    const marginLeft = { marginLeft: '10px' };
    const marginRight = { marginRight: '10px' };
    const margin = position === 'right' ? marginRight : marginLeft;
    return (
        <div style={{ display: 'flex', ...margin }}>
            <svg width="26" height="18" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <circle cx="16" cy="9" r="8" style={style} />
                    <svg y="1">
                        <path style={style} d="M19 8H0M19 8l-3.5-3.5M19.353 7.647L15.5 11.5" />
                    </svg>
                </g>
            </svg>
        </div>
    );
};

export default IconEnter;
