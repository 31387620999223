import BikeIcon from '@mui/icons-material/DirectionsBike';
import LabelIcon from '@mui/icons-material/Label';
import { Card, Grid, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { ButtonCustomVariant } from 'src/components/button/type';
import ColorDot from 'src/components/ColorDot';
import DefinitionBox from 'src/components/DefinitionsBox';
import Image from 'src/components/Image';
import Loading from 'src/components/loading/Loading';

import RootStore from 'src/stores/RootStore';

import { DefaultValues } from 'src/utils/constants';
import { convertImageToLarge } from 'src/utils/convertImageSize';

interface BikeDetaiProps extends WithStyles<typeof styles> {
    store?: typeof RootStore.Type;
}

const BikeDetail = inject('store')(
    observer(({ classes, store }: BikeDetaiProps) => {
        const navigate = useNavigate();
        const location = useLocation();
        const { SFId } = useParams();
        const selectedBike = store!.bikes.getBikeById(SFId || '');
        const loading = store!.bikes.isLoading;

        useEffect(() => {
            if (!loading && !selectedBike && SFId) {
                const fetchBike = async () => {
                    await store!.bikes.getOneBikeById(SFId);
                };

                fetchBike();
            }
        }, []);

        if (!selectedBike && loading) {
            return <Loading small />;
        }

        if (!selectedBike) {
            return (
                <Typography variant="body1" gutterBottom style={{ marginLeft: '20px' }}>
                    <FormattedMessage id="bikesDashboard.details.notFound" />
                </Typography>
            );
        }

        const bikeInfoData = {
            productCode: selectedBike.itemNumber,
            frameNumber: selectedBike.frameNumber || 'Unknow',
        };

        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={3}>
                        <Button
                            customVariant={ButtonCustomVariant.BACK_BUTTON}
                            onClick={() => navigate(-1)}
                            hideButton={location.key === 'default'}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item sm={12} md={6}>
                        <Card elevation={0} className={classes.card}>
                            <Image
                                src={selectedBike.displayUrl ? convertImageToLarge(selectedBike.displayUrl) : undefined}
                                fallbackSrc={
                                    selectedBike.displayUrl ? selectedBike.displayUrl : DefaultValues.ImageNotFound
                                }
                                style={{ maxWidth: '400px' }}
                                loading="lazy"
                            />
                        </Card>

                        <Typography gutterBottom variant="h6">
                            {selectedBike.name}
                        </Typography>

                        <div className={classes.infoSpecs}>
                            {selectedBike.color && <ColorDot color={selectedBike.color} label />}
                            {selectedBike.version && (
                                <div className={classes.specItem}>
                                    <LabelIcon />
                                    <Typography variant="body1">{selectedBike.version}</Typography>
                                </div>
                            )}
                            {selectedBike.size && (
                                <div className={classes.specItem}>
                                    <BikeIcon />
                                    <Typography variant="body1">{selectedBike.size}</Typography>
                                </div>
                            )}
                        </div>

                        <Typography gutterBottom variant="h6">
                            <FormattedMessage id="bikesDashboard.bikeInformation" />
                        </Typography>
                        <DefinitionBox data={bikeInfoData} />
                    </Grid>

                    {/* This Grid is a container for Register parts (Right side of the screen) */}
                    <Grid item sm={12} md={6} />
                </Grid>
            </>
        );
    }),
);

function styles({ spacing }: Theme) {
    return createStyles({
        card: {
            flexGrow: 1,
            maxWidth: '100%',
            padding: spacing(3),
        },
        infoSpecs: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginBottom: '0.35em',
        },
        specItem: {
            display: 'flex',
            alignItems: 'center',

            '& > svg': {
                marginRight: '1rem',
            },
        },
    });
}

export default withStyles(styles)(BikeDetail);
