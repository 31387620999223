import Fuse from 'fuse.js';
import deburr from 'lodash/deburr';
import sortBy from 'lodash/sortBy';
import { values } from 'mobx';
import { flow, getParent, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';
import { logRejectedModel } from 'src/utils/logger';
import RootStore from './RootStore';
import { ProductModel, TProductModel } from './types/ProductTypes';

const MarketingMaterialsModel = types
    .model('MarketingMaterialsModel', {
        list: types.optional(types.map(ProductModel), {}),
        totalItems: types.optional(types.number, 0),
    })
    .volatile(() => ({
        isLoading: false,
        isLoaded: false,
        selectedItemId: '',
        isMarketingMaterialDetailDialogOpen: false,
        isMarketingMaterialDetailDialogFromCart: false,
    }))
    .views(self => ({
        filteredList: (searchWord: string, itemsPerPage: number, currentPage: number): TProductModel[] => {
            let items = values(self.list);

            const paginate = (array: any, pageSize: number, pageNumber: number) => {
                return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
            };

            if (!items.length) {
                return [];
            }

            if (searchWord !== '' && searchWord.length > 2) {
                let fuse;
                if (searchWord.match(/^(BA)?[0-9]+/i)) {
                    fuse = new Fuse(items, {
                        threshold: 0.1,
                        distance: 990,
                        minMatchCharLength: 3,
                        keys: ['id'],
                    });
                } else {
                    fuse = new Fuse(items, {
                        shouldSort: true,
                        threshold: 0.6,
                        location: 0,
                        distance: 100,
                        minMatchCharLength: 1,
                        keys: ['name', 'size', 'version'],
                    });
                }

                const inputValue = deburr(searchWord.trim()).toLowerCase();
                const results = fuse.search(inputValue).map(suggestion => suggestion.item);
                return sortBy(paginate(results, itemsPerPage, currentPage), ['name']);
            }

            return sortBy(paginate(items, itemsPerPage, currentPage), ['name']);
        },

        get selectedItem() {
            return self.list.get(self.selectedItemId);
        },
    }))
    .actions(self => ({
        loadMarketingMaterials: flow(function* (limit: number = 1000, offset: number = 0) {
            self.isLoading = true;

            const rootStore = getParent<typeof RootStore.Type>(self, 1);

            try {
                const response: any = yield Service.getMarketingMaterials(limit, offset);
                const { data: materials, meta } = response;

                materials?.forEach((material: any) => {
                    if (ProductModel.is(material)) {
                        if (!self.list.has(material.id)) {
                            // load image via cdn
                            rootStore.mediaAssets.loadImage(material.id, material.type);
                            material.productInformation.imageUrl = rootStore.mediaAssets.thumbnail(material.id);

                            self.list.set(material.id, material);
                        }
                    } else {
                        logRejectedModel(ProductModel.name, material);
                    }
                });

                // save total items to store
                if (meta?.totalRecords) {
                    self.totalItems = meta.totalRecords;
                }

                self.isLoaded = true;
            } catch (error) {
                console.error(error);
            } finally {
                self.isLoading = false;
            }
        }),

        loadMarketingMaterialsById: flow(function* (id: string) {
            if (self.list.get(id)) {
                return;
            }

            self.isLoading = true;
            const rootStore = getParent<typeof RootStore.Type>(self, 1);

            try {
                const response: any = yield Service.getMarketingMaterialById(id);
                const { data: material } = response;

                if (ProductModel.is(material)) {
                    // load image via cdn
                    rootStore.mediaAssets.loadImage(material.id, material.type);
                    material.productInformation.imageUrl = rootStore.mediaAssets.thumbnail(material.id);

                    self.list.set(material.id, material);
                } else {
                    logRejectedModel(ProductModel.name, material);
                }

                self.isLoaded = true;
            } catch (error) {
                console.error(error);
            } finally {
                self.isLoading = false;
            }
        }),

        toggleMarketingMaterialDetailsDialog: (isFromCart: boolean = false) => {
            self.isMarketingMaterialDetailDialogOpen = !self.isMarketingMaterialDetailDialogOpen;

            // if dialog is open
            if (self.isMarketingMaterialDetailDialogOpen) {
                self.isMarketingMaterialDetailDialogFromCart = isFromCart;
                if (!self.isMarketingMaterialDetailDialogFromCart) {
                    self.isMarketingMaterialDetailDialogOpen = true;
                }
            } else {
                // if dialog is closed
                if (!self.isMarketingMaterialDetailDialogFromCart) {
                    self.isMarketingMaterialDetailDialogOpen = false;
                }
            }
        },

        setSelectedItemId(id: string) {
            self.selectedItemId = id;
        },
    }));

export default MarketingMaterialsModel;
