import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export const MinDeliveryDate = () => {
    const [minDate, setMinDay] = useState(0);
    const currentDate = dayjs().toDate();
    const currentDay = currentDate.getDay();

    // Call getMinDay once on component build
    useEffect(() => {
        GetMinDay(currentDay, setMinDay);
    }, [currentDay]);

    return minDate;
};

// TODO: check if this is still correct, or replace it with getMinDeliveryDate
// 'getMinDay' calculates the first available delivery date
export const GetMinDay = (crtDay: number, setMinDay: any) => {
    switch (crtDay) {
    case 0:
        setMinDay(-5);
        break;
    case 1:
        setMinDay(-4);
        break;
    case 2:
        setMinDay(-3);
        break;
    case 3:
        setMinDay(-6);
        break;
    case 4:
    case 5:
    case 6:
        setMinDay(-6);
        break;
    default:
        setMinDay(0);
        break;
    }
};

// calculates the first available delivery date
export const getMinDeliveryDate = (pickedDate: Date = dayjs().toDate()) => {
    let bufferDays = 0;

    // If it is today, we need to count 2 buffer days
    if (dayjs(pickedDate).isSame(dayjs())) {
        bufferDays = 2;
    }

    const currentDay = pickedDate.getDay();

    // earliest today + buffer of 2 days
    switch (currentDay) {
    case 0: // sunday
        return -2 - bufferDays;
    case 1: // monday
    case 2: // tuesday
    case 3: // wednesday
    case 4: // thursday
        return -1 - bufferDays;
    case 5: // friday
        return -4 - bufferDays;
    case 6: // saturday
        return -3 - bufferDays;
    default:
        return 0;
    }
};

// 'isWeekday' filters Saturday, Sunday and Monday from the weekarray for further processing
export const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 1 && day !== 6;
};
