import { faSatelliteDish } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Image from 'src/components/Image';
import PageLoader from 'src/components/PageLoader';
import Button from 'src/components/button/Button';
import { AppPageRoutes } from 'src/services/api/models';
import PersistentStore from 'src/stores/PersistentStore';
import RootStore from 'src/stores/RootStore';
import { TBikeAsset } from 'src/stores/types/BikesTypes';
import { DefaultValues } from 'src/utils/constants';
import { convertImageToThumbnail } from 'src/utils/convertImageSize';
import { createTableValues, CustomPaginationFooter, TableCell, TableColumnTypes } from 'src/utils/tableColumnUtils';

interface BikesProps {
    store?: typeof RootStore.Type;
    persistentStore?: typeof PersistentStore.Type;
}

const BikeDashboardPage = inject(
    'store',
    'persistentStore',
)(
    observer(({ persistentStore, store }: BikesProps) => {
        const navigate = useNavigate();

        const { tablePage, setTablePage, tableRowsPerPage, setTableRowsPerPage } = persistentStore!.bikeDashboardTable;
        const bikesLoading = store!.bikes.isLoading;

        useEffect(() => {
            const loadData = async () => {
                store!.bikes.getBikes(tableRowsPerPage * (tablePage + 1)).then(() => {
                    store!.bikes.setColumnValue('');
                });
            };
            loadData();
        }, [tablePage, tableRowsPerPage, store]);

        const buildTable = () => {
            const rows: TableCell[][] = [];

            store!.bikes.all.forEach((bike: TBikeAsset) => {
                rows.push([
                    { id: 'SFId', value: bike.id, display: 'false' },
                    {
                        id: 'image',
                        value: bike.displayUrl,
                        type: TableColumnTypes.custom,
                        customRender: (_value: string) => {
                            return (
                                <Image
                                    data-testid="bike-image-in-bikes-dashboard"
                                    height={'85px'}
                                    src={_value ? convertImageToThumbnail(_value) : undefined}
                                    fallbackSrc={DefaultValues.ImageNotFound}
                                    loading="lazy"
                                />
                            );
                        },
                    },
                    { id: 'frameNumber', value: bike.frameNumber, filter: true },
                    { id: 'name', value: bike.name },
                    {
                        id: 'description',
                        value: bike.description,
                    },
                    { id: 'itemNumber', value: bike.itemNumber },
                    {
                        id: 'createdDate',
                        value: bike.createdDate,
                        display: 'false',
                        type: TableColumnTypes.date,
                    },
                ]);
            });
            return createTableValues(rows);
        };

        const renderTable = () => {
            const table = buildTable();
            const data = table.data;
            const columns = table.columns;

            const options: MUIDataTableOptions = {
                selectableRows: 'none',
                selectableRowsHeader: false,
                responsive: 'vertical',
                pagination: true,
                filter: false,
                download: false,
                print: false,
                viewColumns: false,
                elevation: 0,
                sort: true,
                sortOrder: {
                    name: 'createdDate',
                    direction: 'desc',
                },
                count: store?.bikes.totalItems,
                page: tablePage,
                onChangePage: setTablePage,
                rowsPerPage: tableRowsPerPage,
                onChangeRowsPerPage: setTableRowsPerPage,
                onRowClick: (rowData: any) => {
                    const SFId = rowData[0].props.children;
                    navigate(AppPageRoutes.BIKE_DETAIL.replace(':SFId', SFId));
                },
                customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
                    return (
                        <CustomPaginationFooter
                            rowCount={count}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            changeRowsPerPage={changeRowsPerPage}
                            changePage={changePage}
                        />
                    );
                },
            };

            return (
                <>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5">
                            <FormattedMessage id="menu.bikeDashboard" />
                        </Typography>
                        <Button
                            data-testid="register-gps"
                            onClick={() => navigate(AppPageRoutes.GPS)}
                            color="primary"
                            variant="contained"
                            labelTextId="menu.gps"
                            endIcon={<FontAwesomeIcon icon={faSatelliteDish} size="lg" color="white" />}
                        />
                    </Stack>
                    <MUIDataTable
                        data-test-id="bike-assets-table"
                        data={data}
                        columns={columns}
                        options={options}
                        title={''}
                    />
                </>
            );
        };

        return bikesLoading ? <PageLoader /> : renderTable();
    }),
);

export default BikeDashboardPage;
