import { InfoRounded } from '@mui/icons-material';
import { FormControl, IconButton, ListItem, ListItemText, Paper, TextField, Typography, useTheme } from '@mui/material';
import { ListItemProps } from '@mui/material/ListItem';
import Downshift, { GetInputPropsOptions, GetItemPropsOptions } from 'downshift';
import { Field } from 'formik';
import Fuse from 'fuse.js';
import deburr from 'lodash/deburr';
import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { AppPageRoutes } from 'src/services/api/models';
import { InvoiceBySparePart } from 'src/stores/InvoicesStore';

type Invoice = typeof InvoiceBySparePart.Type;

interface InvoiceNumberListPickerProps {
    items?: Invoice[];
    value: string | undefined;
    onChange(value: string): void;
    error?: boolean;
    helperText?: string;
}

const getSuggestions = (suggestions: Invoice[], value: string): Invoice[] => {
    const inputValue = value && deburr(value.trim()).toLowerCase();

    if (!inputValue.length) {
        return suggestions;
    }

    const fuse = new Fuse(suggestions, {
        shouldSort: true,
        threshold: 0.2,
        location: 0,
        distance: 50,
        minMatchCharLength: 3,
        keys: ['invoiceId', 'name', 'documentDate', 'externalDocument'],
    });

    return fuse.search(inputValue).map(suggestion => suggestion.item);
};

const renderSuggestion = ({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
}: {
    suggestion: Invoice;
    index: number;
    itemProps: GetItemPropsOptions<string> & ListItemProps;
    highlightedIndex: number | null;
}) => {
    const isHighlighted = highlightedIndex === index;

    const handleClickInvoice = (e: React.MouseEvent<HTMLButtonElement>) => {
        window.open(AppPageRoutes.INVOICE_DETAILS.replace(':invoiceId', suggestion.invoiceId));
        e.stopPropagation();
    };

    return (
        <ListItem
            button={true as any}
            key={`${suggestion.name}-${index}`}
            selected={isHighlighted}
            data-testid={`returns_invoiceNumberOption${index}`}
            secondaryAction={
                <IconButton onClick={handleClickInvoice}>
                    <InfoRounded color="primary" fontSize="small" />
                </IconButton>
            }
            {...itemProps}
        >
            <ListItemText
                primary={suggestion.name}
                secondary={
                    <>
                        <Typography variant="caption">
                            <FormattedDate value={suggestion.documentDate} year="numeric" month="long" day="2-digit" />
                        </Typography>

                        <Typography variant="caption">
                            {suggestion.externalDocument ? ` - ${suggestion.externalDocument}` : ''}
                        </Typography>
                    </>
                }
            />
        </ListItem>
    );
};

const renderInput = (props: GetInputPropsOptions) => (
    <Field
        label={<FormattedMessage id="returns.form_label_selectInvoiceNumber" />}
        component={TextField}
        margin="normal"
        variant="outlined"
        data-testid="returns_invoiceNumber"
        {...props}
    />
);

const InvoiceNumberListPicker = ({ items, onChange, value, error, helperText }: InvoiceNumberListPickerProps) => {
    const intl = useIntl();
    const theme = useTheme();
    let selectedItem: Invoice | null = null;

    if (value && items) {
        const item = items.find((invoice: Invoice) => invoice.name === value);
        selectedItem = item as Invoice;
    }

    return (
        <FormControl sx={{ flex: 1, width: '100%', paddingBottom: !items ? '1rem' : 0 }}>
            <Downshift
                selectedItem={selectedItem}
                onChange={item => onChange(item!.name)}
                itemToString={item => (item ? item.name : '')}
            >
                {({ getInputProps, getItemProps, getMenuProps, isOpen, openMenu, inputValue, highlightedIndex }) => {
                    const inputProps = getInputProps({
                        name: 'invoiceNumber',
                        fullWidth: true,
                        onFocus: openMenu,
                        disabled: !items,
                        value: !items ? intl.formatMessage({ id: 'returns.form_no_invoice_found' }) : inputValue,
                        error,
                        helperText,
                    });
                    const menuProps = getMenuProps({
                        style: {
                            position: 'absolute',
                            zIndex: 2,
                            width: '100%',
                        },
                    });

                    return (
                        <div>
                            {renderInput(inputProps)}
                            <div {...menuProps}>
                                {isOpen && items && (
                                    <Paper
                                        sx={{
                                            minHeight: theme.spacing(2),
                                            maxHeight: `calc(100% - ${theme.spacing(2)})`,
                                            overflow: 'scroll',
                                        }}
                                        square
                                    >
                                        {getSuggestions(items, inputValue as string).map((suggestion, index) =>
                                            renderSuggestion({
                                                suggestion,
                                                index,
                                                itemProps: getItemProps({
                                                    item: suggestion,
                                                }),
                                                highlightedIndex,
                                            }),
                                        )}
                                    </Paper>
                                )}
                            </div>
                        </div>
                    );
                }}
            </Downshift>
        </FormControl>
    );
};

export default InvoiceNumberListPicker;
