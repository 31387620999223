import { flow, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';
import { logRejectedModel } from 'src/utils/logger';
import { MarketingMaterial, TMarketingMaterial } from './types/MarketingMaterialTypes';

const Promotions = types
    .model('MarketingStore', {
        list: types.optional(types.array(MarketingMaterial), []),
        status: types.optional(
            types.enumeration('MarketingMaterialState', ['IDLE', 'LOADING', 'ERROR', 'SUCCESS']),
            'IDLE',
        ),
        active: types.optional(types.string, ''),
    })
    .views(self => ({
        get items() {
            return self.list.length > 0 ? self.list.toJSON() : [];
        },
    }))
    .actions(self => {
        const setActiveMarketingTool = (tool: string) => {
            self.active = tool;
        };
        const getMarketingBanners = flow(function* (lang: string) {
            self.status = 'LOADING';
            try {
                const response: any = yield Service.getMarketingBanners(lang, self.active);
                self.list.clear();
                if (response.data && response.data.length > 0) {
                    response.data.forEach((marketingmaterial: TMarketingMaterial) => {
                        if (MarketingMaterial.is(marketingmaterial)) {
                            self.list.push(marketingmaterial);
                        } else {
                            logRejectedModel(MarketingMaterial.name, marketingmaterial);
                        }
                    });
                }
                self.status = 'SUCCESS';
            } catch (error) {
                console.error(error);
                self.status = 'ERROR';
            }
        });
        return {
            getMarketingBanners,
            setActiveMarketingTool,
        };
    });

export default Promotions;
