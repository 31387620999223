import { TranslationCell, TranslationItem, Translations, langColumnIdMap } from 'src/types/commons/translation';

// Converting Gridly response to translation object
export const convertToTranslations = (data: TranslationItem[]): Translations => {
    const translations: Translations = {};

    data.forEach((item: TranslationItem) => {
        item.cells.forEach((cell: TranslationCell) => {
            const columnName = langColumnIdMap[cell.columnId];
            if (!translations[columnName]) {
                translations[columnName] = {};
            }
            translations[columnName][item.id] = cell.value;
        });
    });
    return translations;
};
