import { types } from 'mobx-state-tree';
import OrderNotificationsStore from 'src/stores/OrderNotificationsStore';
import BatteriesStore from './BatteriesStore';
import BikesStore from './BikesStore';
import CartReturnStore from './CartReturnsStore';
import CartStore from './CartStore';
import ConfigStore from './ConfigStore';
import FavoritesStore from './FavoritesStore';
import InvoicesStore from './InvoicesStore';
import MarketingMaterialsStore from './MarketingMaterialsStore';
import MarketingStore from './MarketingStore';
import MediaAssetsStore from './MediaAssetsStore';
import NewsStore from './NewsStore';
import OrdersStore from './OrdersStore';
import ProductStore from './ProductStore';
import ReturnStore from './ReturnStore';
import SparePartsStore from './SparePartsStore';

const RootStore = types
    .model('RootStore', {
        config: types.optional(ConfigStore, {}),
        cart: types.optional(CartStore, {}),
        cartReturns: types.optional(CartReturnStore, {}),
        products: types.optional(ProductStore, {}),
        orders: types.optional(OrdersStore, {}),
        bikes: types.optional(BikesStore, {}),
        spareParts: types.optional(SparePartsStore, {}),
        batteries: types.optional(BatteriesStore, {}),
        marketingMaterials: types.optional(MarketingMaterialsStore, {}),
        marketing: types.optional(MarketingStore, {}),
        news: types.optional(NewsStore, {}),
        returns: types.optional(ReturnStore, {}),
        invoices: types.optional(InvoicesStore, {}),
        favorites: types.optional(FavoritesStore, {}),
        mediaAssets: types.optional(MediaAssetsStore, {}),
        billingCountry: types.optional(types.string, ''),
        orderNotifications: types.optional(OrderNotificationsStore, {}),
    })
    .actions(self => ({
        setBillingCountry(billingCountry: string) {
            if (billingCountry) {
                self.billingCountry = billingCountry;
            }
        },
    }));

export default RootStore;
