import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { useIntl } from 'react-intl';
import LightTooltip from 'src/components/LightTooltip';
import { TOrderNotification } from 'src/stores/types/NotificationsTypes';
import { OrderNotificationChangedField } from 'src/utils/constants';
import { DeliveryDate, getFormattedDeliveryDate } from 'src/utils/getFormattedDeliveryDate';
import { getNotificationStatusCodeTranslationId } from 'src/utils/getNotificationStatusCodeTranslationId';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export interface NotificationsListItemProps extends WithStyles<typeof styles> {
    notification: TOrderNotification;
    isUnread: boolean;
    onClick: (notification: TOrderNotification) => void;
}

const NotificationsListItem = ({ classes, notification, isUnread, onClick }: NotificationsListItemProps) => {
    const intl = useIntl();

    const wrapWithTooltipContainer = (date: DeliveryDate, disableTooltip: boolean = false) => (
        <div style={{ display: 'inline-flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
            <span className={classes.notificationValue}>{date.text}</span>

            {date.isTooltipNeeded && !disableTooltip && (
                <LightTooltip title={date.tooltip} style={{ marginLeft: 4 }}>
                    <InfoIcon fontSize="small" />
                </LightTooltip>
            )}
        </div>
    );

    const generateDescription = () => {
        switch (notification.changedField) {
        case OrderNotificationChangedField.STATUS: {
            const current = (
                <span className={classes.notificationValue}>
                    {intl.formatMessage({ id: getNotificationStatusCodeTranslationId(notification.currentValue) })}
                </span>
            );

            if (notification.previousValue === '') {
                return intl.formatMessage({ id: 'notifications.general_status_change_single' }, { current });
            }

            const previous = (
                <span className={classes.notificationValue}>
                    {intl.formatMessage({ id: getNotificationStatusCodeTranslationId(notification.previousValue) })}
                </span>
            );

            return intl.formatMessage({ id: 'notifications.general_status_change' }, { previous, current });
        }

        case OrderNotificationChangedField.DELIVERY_DATE: {
            const current = getFormattedDeliveryDate(notification.currentValue);

            if (notification.previousValue === '') {
                return intl.formatMessage(
                    { id: 'notifications.general_delivery_date_change_single' },
                    {
                        current: wrapWithTooltipContainer(current),
                    },
                );
            }

            const previous = getFormattedDeliveryDate(notification.previousValue);
            return intl.formatMessage(
                { id: 'notifications.general_delivery_date_change' },
                {
                    previous: wrapWithTooltipContainer(previous, true),
                    current: wrapWithTooltipContainer(current),
                },
            );
        }

        default:
            return '';
        }
    };

    // This function returns date differences as human-readable strings like `3 minutes ago` or `15 days ago` etc.
    const generateHumanReadableDate = () => {
        const now = dayjs();
        const createdAt = dayjs(notification.createdAt);
        const difference = createdAt.diff(now);
        return dayjs.duration(difference).humanize(true);
    };

    return (
        <div
            className={classes.root}
            data-testid={`notifications-list-item-${notification.notificationId}`}
            onClick={() => onClick(notification)}
        >
            <div
                className={classNames(classes.indicator, isUnread ? classes.unreadIndicator : classes.readIndicator)}
            />
            <div>
                <Typography variant="body2" className={classes.order}>
                    {`${intl.formatMessage({ id: 'order.title' })}: ${notification.erpOrderId}`}
                </Typography>

                <Typography variant="body1" className={classes.description}>
                    <>{generateDescription()}</>
                </Typography>

                <Typography
                    variant="body2"
                    className={classes.createdAt}
                    title={dayjs(notification.createdAt).format('LLL')}
                >
                    {generateHumanReadableDate()}
                </Typography>
            </div>
        </div>
    );
};

function styles({ palette, spacing }: Theme) {
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: `1px solid ${palette.divider}`,
            paddingTop: spacing(1.5),
            paddingBottom: spacing(1.5),
            cursor: 'pointer',

            '&:first-child': {
                borderTop: `1px solid ${palette.divider}`,
            },
        },

        indicator: {
            alignSelf: 'flex-start',

            '&::before': {
                content: '""',
                display: 'inline-block',
                width: spacing(1),
                height: spacing(1),
                borderRadius: spacing(0.5),
                marginRight: spacing(2),
            },
        },

        readIndicator: {
            '&::before': {
                backgroundColor: palette.primary.light,
            },
        },

        unreadIndicator: {
            '&::before': {
                backgroundColor: palette.secondary.main,
            },
        },

        order: {
            marginTop: '1px',
        },

        notificationValue: {
            fontStyle: 'oblique',
        },

        description: {
            marginTop: spacing(1),
            marginBottom: spacing(1),
        },

        createdAt: {
            marginTop: spacing(0.5),
            color: palette.grey[500],
        },
    });
}

export default withStyles(styles)(NotificationsListItem);
