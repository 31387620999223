import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const MenuProps = {
    PaperProps: {
        sx: {
            minWidth: 150,
        },
    },
};

interface MultipleSelectCheckmarksProps {
    id: string;
    label: ReactNode;
    options: any[];
    useIntlFormatting?: boolean;
    selectedValues: string[];
    setSelectedValues: (value: string[]) => void;
    hideLabel?: boolean;
}

const MultipleSelectCheckmarks = ({
    id,
    label,
    options,
    useIntlFormatting = false,
    selectedValues,
    setSelectedValues,
    hideLabel = false,
}: MultipleSelectCheckmarksProps) => {
    const { palette } = useTheme();
    const intl = useIntl();

    const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
        const {
            target: { value },
        } = event;
        setSelectedValues(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 150, maxWidth: 300 }}>
            <InputLabel id={`${id}-label`} sx={{ zIndex: hideLabel ? 0 : 1 }}>
                {label}
            </InputLabel>
            <Select
                labelId={`${id}-label`}
                id={id}
                multiple
                value={selectedValues}
                onChange={handleChange}
                input={<OutlinedInput label={label} />}
                renderValue={selected =>
                    selected
                        .map(option => (useIntlFormatting ? intl.formatMessage({ id: `${id}_${option}` }) : option))
                        .join(', ')
                }
                MenuProps={MenuProps}
                sx={{ backgroundColor: palette.background.default }}
            >
                {options.map(option => (
                    <MenuItem key={option} value={option}>
                        <Checkbox checked={selectedValues.indexOf(option) > -1} color="secondary" />
                        <ListItemText
                            primary={useIntlFormatting ? <FormattedMessage id={`${id}_${option}`} /> : option}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultipleSelectCheckmarks;
