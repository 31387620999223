import axios from 'axios';
import { flow } from 'mobx';
import { applySnapshot, types } from 'mobx-state-tree';
import CONFIG from 'src/utils/config';
import { ImagesFolder, ProductCategoryTypes } from 'src/utils/constants';

const getImagesNames = async (id: string, type: ProductCategoryTypes): Promise<Record<string, string>[]> => {
    const response = await axios.get(`https://res.cloudinary.com/${CONFIG.CDN_NAME}/image/list/${id}.json`);
    return response.data.resources
        .filter((imageData: { public_id: string }) => imageData.public_id.includes(ImagesFolder[type]))
        .map((imageData: { public_id: string; format: string }) => ({
            public_id: imageData.public_id,
            format: imageData.format,
        }));
};

const formatImageURL = ({ public_id, format }: { public_id: string; format: string }): string => {
    return `https://res.cloudinary.com/${CONFIG.CDN_NAME}/image/upload/v1/${public_id}.${format}`;
};

const imageModel = types
    .model('imageModel', {
        id: types.identifier,
        type: types.maybe(types.enumeration(Object.values(ProductCategoryTypes))),
        url: types.string,
        thumbnail: types.string,
        gallery: types.optional(types.array(types.string), []),
    })
    .actions(self => ({
        loadUrl: flow(function* (id: string, type: ProductCategoryTypes = ProductCategoryTypes.PART) {
            if (id && self.url === '') {
                try {
                    const imageData: any = yield getImagesNames(id, type);
                    const gallery = imageData.map(formatImageURL);
                    const url = gallery[0];

                    if (url) {
                        const thumbnail = url.replace('/image/upload/', '/image/upload/c_thumb,w_500,g_face/');
                        applySnapshot(self, { id, url, thumbnail, gallery });
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }),
    }))
    .actions(self => ({
        afterCreate() {
            self.loadUrl(self.id, self.type);
        },
    }));

const MediaAssetsStore = types
    .model('MediaAssets', {
        images: types.optional(types.map(imageModel), {}),
    })
    .views(self => ({
        gallery: (id: string): string[] => {
            const image = self.images.get(id);
            return image && image.gallery.length > 0 ? [...image.gallery] : [];
        },
    }))
    .views(self => ({
        isGallery: (id: string): boolean => self.gallery(id).length > 0 && self.gallery(id).length !== 1,
        thumbnail: (id: string, index = 0) => {
            const image = self.images.get(id);
            return image ? self.gallery(id)[index] : '';
        },
        image(id: string) {
            const image = self.images.get(id);
            return image ? image.url : '';
        },
    }))
    .actions(self => ({
        loadImage(id: string, type?: ProductCategoryTypes) {
            if (!self.images.has(id)) {
                self.images.set(id, { id, type, url: '', thumbnail: '', gallery: [] });
            }
        },
    }));

export default MediaAssetsStore;
