import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import Button, { ButtonProps } from './Button';

const BackButton: FC<ButtonProps> = props => {
    return (
        <Button
            labelTextId="button.back"
            startIcon={<FontAwesomeIcon icon={faLongArrowLeft} size="2x" />}
            data-testid="back-button"
            {...props}
        />
    );
};

export default BackButton;
