import { Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { TParentBikeModel } from 'src/stores/types/ParentBike';

interface WarrantyDetailsProps extends WithStyles<typeof styles> {
    product: TParentBikeModel;
    shortText?: boolean;
}

const WarrantyDetails = ({ classes, product, shortText }: WarrantyDetailsProps) => {
    return product.dateSold && product.warrantyLimit ? (
        <div className={classes.warranty}>
            {product.isWarrantyExpired && (
                <Typography variant="body1" className={classes.noWarrantyMessage}>
                    {!shortText && (
                        <>
                            <FormattedMessage id="returns.noWarrantyMessage1" />
                            <b>
                                {' '}
                                <FormattedMessage id="returns.noWarrantyMessage2" />{' '}
                            </b>
                        </>
                    )}
                    <FormattedMessage id="returns.noWarrantyMessage3" />
                </Typography>
            )}
            <div>
                <Typography variant="body1" data-testid="sold-date">
                    <b>
                        <FormattedMessage id="returns.dateSold" />
                    </b>
                </Typography>
                <Typography variant="body1">{product.dateSold}</Typography>
            </div>
            {product.isWarrantyExpired && (
                <div>
                    <Typography variant="body1" data-testid="warranty_until">
                        <b>
                            <FormattedMessage id="returns.warrantyUntil" />
                        </b>
                    </Typography>
                    <Typography
                        data-testid="warranty-limit"
                        variant="body1"
                        className={`${product.isWarrantyExpired && classes.expired}`}
                    >
                        {product.warrantyLimit}
                    </Typography>
                </div>
            )}
        </div>
    ) : (
        <></>
    );
};

function styles({ palette }: Theme) {
    return createStyles({
        warranty: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '& div': {
                display: 'flex',
            },
            '& div b': {
                marginRight: '.5rem',
            },
        },
        noWarrantyMessage: {
            textAlign: 'center',
            margin: '1rem',
            '& b': {
                color: palette.secondary.main,
            },
        },
        expired: {
            color: palette.secondary.main,
        },
    });
}

export default withStyles(styles)(WarrantyDetails);
