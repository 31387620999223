import React, { PropsWithChildren } from 'react';
import Choose from 'src/components/conditional/Choose';
import Else from 'src/components/conditional/Else';
import When from 'src/components/conditional/When';

interface IfProps {
    condition: boolean | (() => boolean) | undefined;
    otherwise?: JSX.Element | (() => JSX.Element);
}

const If = ({ condition, otherwise, children }: PropsWithChildren<IfProps>) => (
    <Choose>
        <When condition={condition}>
            {children}
        </When>

        <Else>
            {(typeof otherwise === 'function') ? otherwise() : otherwise}
        </Else>
    </Choose>
);

export default If;
