import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const TableTitle = ({ isUnusedReturnTable = false }: { isUnusedReturnTable?: boolean }) => {
    return (
        <>
            <Typography variant="h6">
                <FormattedMessage id="invoices.title" />{' '}
                {isUnusedReturnTable && <FormattedMessage id="invoices.title.info" />}
            </Typography>
            {isUnusedReturnTable && (
                <Typography variant="caption">
                    <FormattedMessage id="invoices.subTitle" />
                </Typography>
            )}
        </>
    );
};
