import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Grid, IconButton, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { ButtonCustomVariant } from 'src/components/button/type';
import DefinitionsBox from 'src/components/DefinitionsBox';
import LightTooltip from 'src/components/LightTooltip';
import Loading from 'src/components/loading/Loading';
import RootStore from 'src/stores/RootStore';
import { TOrder } from 'src/stores/types/OrdersTypes';
import { getFormattedDeliveryDate } from 'src/utils/getFormattedDeliveryDate';
import OrderLines from './OrderLines';

interface OrderDetailPageProps extends WithStyles<typeof styles> {
    store?: typeof RootStore.Type;
}

const OrderDetailPage = inject(
    'store',
    'persistentStore',
    'user',
)(
    observer(({ classes, store }: OrderDetailPageProps) => {
        const intl = useIntl();
        const navigate = useNavigate();
        const location = useLocation();
        const { orderId } = useParams();
        const selectedOrder = store!.orders.selected as TOrder;
        const loading = store!.orders.isLoading;

        useEffect(() => {
            if (!store?.orders.isLoading && !selectedOrder && orderId) {
                const fetchOrder = async () => {
                    await store!.orders.getOneOrderById(orderId);
                };
                fetchOrder();
            }
        }, [orderId, selectedOrder, store]);

        let plannedDeliveryDate = <></>;

        if (selectedOrder?.plannedDeliveryDate) {
            const formattedValues = getFormattedDeliveryDate(selectedOrder!.plannedDeliveryDate);

            plannedDeliveryDate = (
                <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                    {formattedValues.text}

                    <LightTooltip title={formattedValues.tooltip} style={{ marginLeft: 4 }}>
                        <InfoIcon fontSize="small" />
                    </LightTooltip>
                </div>
            );
        }

        if (!selectedOrder && loading) {
            return <Loading small />;
        }
        if (!selectedOrder) {
            return (
                <Typography variant="body1" gutterBottom style={{ marginLeft: '20px' }}>
                    <FormattedMessage id="orders.notFound" />
                </Typography>
            );
        }

        let onStock = (
            <>
                {selectedOrder?.onStock ? (
                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                        <FormattedMessage id="labels.yes" />

                        <LightTooltip title={<FormattedMessage id="order.onStockTooltip" />} style={{ marginLeft: 4 }}>
                            <InfoIcon fontSize="small" />
                        </LightTooltip>
                    </div>
                ) : (
                    <FormattedMessage id="labels.no" />
                )}
            </>
        );

        const orderInfo = selectedOrder
            ? {
                orderNumber: selectedOrder!.name,
                orderDate: selectedOrder!.orderDate && dayjs(selectedOrder!.orderDate).format('DD/MM/YYYY'),
                plannedDeliveryDate,
                requestedDeliveryDate:
                      selectedOrder!.requestedDeliveryDate &&
                      dayjs(selectedOrder!.requestedDeliveryDate).format('DD/MM/YYYY'),
                onStock,
                totalAmountExVat: selectedOrder!.totalAmount && `€${selectedOrder!.totalAmount}`,
                status: selectedOrder!.status,
            }
            : {};

        const orderDelivery =
            selectedOrder!.name && selectedOrder!.shipping
                ? {
                    name: selectedOrder!.shipping.name || '',
                    street: selectedOrder.shipping.street || '',
                    postalCode: selectedOrder.shipping.postalCode,
                    city: selectedOrder.shipping.city,
                    country: selectedOrder.shipping.country,
                    contactName: selectedOrder.shipping.contactName,
                }
                : {};

        return (
            <>
                <>
                    <Grid container spacing={3} className={classes.header}>
                        <Grid item xs={12} md={4} lg={3}>
                            <Button
                                customVariant={ButtonCustomVariant.BACK_BUTTON}
                                onClick={() => navigate(-1)}
                                hideButton={location.key === 'default'}
                            />
                        </Grid>
                    </Grid>

                    <div>
                        <Typography variant="h5" color="inherit" noWrap>
                            <FormattedMessage id="orderDetail.title" />
                        </Typography>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        {!selectedOrder?.isSavingEdit &&
                            selectedOrder?.isEditable &&
                            (selectedOrder.isBeingEdited ? (
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        data-testid="save-edit-order"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => selectedOrder.saveEdit()}
                                        className={classes.editOrderButton}
                                    >
                                        <FormattedMessage id="order.saveEdit" />
                                    </Button>
                                    <Button
                                        data-testid="cancel-edit-order"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => selectedOrder.clearEdit()}
                                        className={classes.editOrderButton}
                                    >
                                        <FormattedMessage id="labels.cancel" />
                                    </Button>
                                </div>
                            ) : (
                                <Button
                                    data-testid="edit-order"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => selectedOrder.edit()}
                                    className={classes.editOrderButton}
                                >
                                    <FormattedMessage id="order.edit" />
                                </Button>
                            ))}

                        <IconButton
                            data-testid="close-order-detail-dialog"
                            aria-label={intl.formatMessage({ id: 'order.closeAriaLabel' })}
                            className={classes.closeButton}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </>
                {!selectedOrder?.isEditable && selectedOrder?.status && (
                    <div className={classes.temporaryExplanation}>
                        <Typography variant="body2">
                            <FormattedMessage id={`order.message${selectedOrder?.status.replace(/\s/g, '')}`} />
                        </Typography>
                    </div>
                )}
                <div>
                    {selectedOrder && selectedOrder.isSavingEdit ? (
                        <Loading small />
                    ) : (
                        <div className={classes.info}>
                            <Grid container spacing={3} style={{ paddingBottom: '35px' }}>
                                <Grid item xs={6}>
                                    <DefinitionsBox
                                        translationId="orderDetail.detail"
                                        data={orderInfo}
                                        selectedOrder={selectedOrder}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DefinitionsBox
                                        translationId="orderDetail.shipping"
                                        data={orderDelivery}
                                        selectedOrder={selectedOrder}
                                    />
                                </Grid>
                            </Grid>
                            {selectedOrder && selectedOrder?.lines?.length > 0 && (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <OrderLines order={selectedOrder} />
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    )}
                </div>
            </>
        );
    }),
);

function styles({ spacing, palette }: Theme) {
    return createStyles({
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        closeButton: {
            position: 'absolute',
            right: spacing(1),
            top: spacing(1),
            color: palette.grey[500],
        },
        editOrderButton: {
            paddingTop: '1em',
            paddingBottom: '1em',
            paddingLeft: '2em',
            paddingRight: '2em',
            margin: '0 auto',
            justifyContent: 'center',
            width: '160px',
            marginRight: '40px',
            marginTop: '-30px',
        },
        info: {
            margin: '1rem 0',
        },
        temporaryExplanation: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: spacing(4),

            '&::before': {
                content: '"*"',
                marginRight: spacing(1),
                color: palette.error.main,
            },
        },
    });
}

export default withStyles(styles)(OrderDetailPage);
