import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const ContactCaseCreated = () => {
    return (
        <Typography data-testid="toaster_contactSent" color="primary" align={'center'}>
            <Typography style={{ display: 'inline' }} color="secondary" component="span">
                <FontAwesomeIcon icon={faCheck} color='green' />
            </Typography>{' '}
            <FormattedMessage id="contact.caseSent" />
        </Typography>
    );
};

export default ContactCaseCreated;
