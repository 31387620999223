import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { applySnapshot, destroy, getParent, getSnapshot, types } from 'mobx-state-tree';
import ReactGA from 'react-ga';
import { NavigateFunction } from 'react-router-dom';
import { GACategories } from 'src/services/Analytics';
import Service from 'src/services/api/Api';
import { CreateOrder, OrderProduct } from 'src/services/api/models';
import Toast from 'src/services/Toasts';
import AnalyticsEcommerce from 'src/utils/analytics-ecommerce';
import {
    FREE_SHIPPING_THRESHOLD,
    ProductCategoryTypes,
    SHIPPING_FLAT_RATE,
    userStateDbColumns,
} from 'src/utils/constants';
import RootStore from './RootStore';
import { CartItemModel, TCartItem } from './types/CartItemModel';

dayjs.extend(minMax);

const CartStore = types
    .model('CartStore', {
        items: types.optional(types.array(CartItemModel), []),
        bundleWarning: types.optional(types.boolean, false),
    })
    .volatile(() => ({
        isLoaded: false,
    }))
    .views(self => ({
        get listItems() {
            return [...self.items].reverse();
        },
        get selectedItems() {
            return this.listItems.filter(item => item.isSelected);
        },
        get spareParts() {
            return this.listItems.filter(item => item.product.type !== ProductCategoryTypes.BIKE);
        },
        get sparePartsDeliveryDates() {
            return this.spareParts.map(item =>
                item.product!.productInformation.availableFrom
                    ? dayjs(item.product!.productInformation.availableFrom)
                    : dayjs(new Date()),
            );
        },
        get bikesShippingDate(): string {
            let shippingDates = deliveryDates(this.selectedItems);
            if (shippingDates.length > 0) {
                const oldestDate = dayjs.max(shippingDates);
                return `${oldestDate.toDate()}`;
            }
            return '';
        },
        get sparePartsShippingDate(): string {
            let shippingDates = deliveryDates(this.spareParts);
            if (shippingDates.length > 0) {
                const oldestDate = dayjs.max(shippingDates);
                return `${oldestDate.toDate()}`;
            }
            return '';
        },
        get isDesirableDeliveryDateNextYear() {
            const nextYear = dayjs().year() + 1;
            const desirableYear = dayjs(new Date(this.bikesShippingDate)).year();
            return desirableYear === nextYear;
        },
        filterItems(listTypes: ProductCategoryTypes[]) {
            return this.listItems.filter(item => listTypes.includes(item.product.type));
        },
        filterItemsByCartType(activeCartType: ProductCategoryTypes.BIKE | ProductCategoryTypes.PART) {
            const allowedItemTypes =
                activeCartType === ProductCategoryTypes.BIKE
                    ? [ProductCategoryTypes.BIKE]
                    : [ProductCategoryTypes.PART, ProductCategoryTypes.BATTERY, ProductCategoryTypes.MARKETING];
            return this.listItems.filter(item => {
                if (activeCartType === ProductCategoryTypes.BIKE) {
                    return item.isSelected && allowedItemTypes.includes(item.product.type);
                }
                return allowedItemTypes.includes(item.product.type);
            });
        },
    }))
    .views(self => ({
        isCartEmpty(activeCartType: ProductCategoryTypes.BIKE | ProductCategoryTypes.PART): boolean {
            if (activeCartType === ProductCategoryTypes.BIKE) {
                return (
                    self.selectedItems.filter(
                        item =>
                            activeCartType ===
                            (item.product.type === ProductCategoryTypes.BIKE
                                ? item.product.type
                                : ProductCategoryTypes.PART),
                    ).length === 0
                );
            }
            return (
                self.listItems.filter(
                    item =>
                        activeCartType ===
                        (item.product.type === ProductCategoryTypes.BIKE
                            ? item.product.type
                            : ProductCategoryTypes.PART),
                ).length === 0
            );
        },
        get totalItemsQuantity(): number {
            return self.listItems.length > 0
                ? self.listItems.map(item => item.quantity).reduce((acc, quantity) => acc + quantity, 0)
                : 0;
        },
        get totalBikesQuantity(): number {
            return self.listItems.length > 0
                ? self.listItems
                      .filter(item => item.product.type === ProductCategoryTypes.BIKE)
                      .map(item => item.quantity)
                      .reduce((acc, quantity) => acc + quantity, 0)
                : 0;
        },
        get totalSparePartsQuantity(): number {
            return self.listItems.length > 0
                ? self.listItems
                      .filter(item => item.product.type !== ProductCategoryTypes.BIKE)
                      .map(item => item.quantity)
                      .reduce((acc, quantity) => acc + quantity, 0)
                : 0;
        },
        totalPrice(activeCartType: ProductCategoryTypes.BIKE | ProductCategoryTypes.PART): number {
            const isActiveCartType = (type: ProductCategoryTypes) => {
                const itemType =
                    type === ProductCategoryTypes.BIKE ? ProductCategoryTypes.BIKE : ProductCategoryTypes.PART;
                return activeCartType === itemType;
            };

            const cartItems = activeCartType === ProductCategoryTypes.BIKE ? self.selectedItems : self.items;
            const filteredByType = cartItems.filter(item => isActiveCartType(item.product.type));
            return filteredByType.length > 0
                ? filteredByType
                      .map(item => (item.product ? item.totalItemsPrice(self.isDesirableDeliveryDateNextYear) : 0))
                      .reduce((acc, price) => acc + price)
                : 0;
        },
        get totalBikesInCart(): number {
            return self.selectedItems.reduce((acc, item) => {
                if (item.product.type === ProductCategoryTypes.BIKE) {
                    return acc + item.quantity;
                }
                return acc + 0;
            }, 0);
        },
        getCartProducts(activeCartType: ProductCategoryTypes.BIKE | ProductCategoryTypes.PART): OrderProduct[] {
            const products: OrderProduct[] = [];
            self.filterItemsByCartType(activeCartType).forEach((item: TCartItem) => {
                if (item.product.parentCode) {
                    products.push(
                        createOrderProduct(
                            item.product!.id,
                            item.quantity,
                            item.reference,
                            item.product.parentCode,
                            item.product.parentFrameNumber || '',
                        ),
                    );
                } else {
                    products.push(createOrderProduct(item.product!.id, item.quantity, item.reference));
                }
                if (item.battery && item.battery.code && item.battery.code !== 'N/A') {
                    products.push(createOrderProduct(item.battery.code, item.quantity, item.reference));
                }
            });
            return products;
        },
    }))
    .actions(self => ({
        setLoaded(value: boolean) {
            self.isLoaded = value;
        },

        async removeCartItem(item: TCartItem, sync: boolean = true) {
            destroy(item);

            // In some cases we don't want to send the request to update the storage immediately
            if (sync) {
                try {
                    await Service.updateStorageData(userStateDbColumns.cart, JSON.stringify(getSnapshot(self)));
                } catch (e) {
                    console.error(e);
                    Toast.PreferencesError();
                }
            }

            try {
                // Suppressing this operation separately because a faulty cart item can break this process in some rare cases
                const { product, quantity } = item;

                // Sending item removed from cart event to GA
                AnalyticsEcommerce.getInstance()
                    .clear()
                    .removeProductFromCart({
                        id: product.id,
                        name: product.name,
                        list: 'Shopping Cart',
                        brand: 'QWIC',
                        category: `${product.serie}/${product.model}`,
                        variant: product.productInformation?.color || undefined,
                        price: product.getPrice(),
                        quantity: quantity,
                    })
                    .sendPageView();
                // eslint-disable-next-line no-empty
            } catch (ignored) {}
        },
    }))
    .actions(self => ({
        async cleanCartItems(activeCartType: ProductCategoryTypes.BIKE | ProductCategoryTypes.PART) {
            self.filterItemsByCartType(activeCartType).forEach(CartItem => {
                if (CartItemModel.is(CartItem)) {
                    self.removeCartItem(CartItem, false);
                }
            });
            // Update storage only when all the items have been deleted
            this.updateCartDBSnapshot();
        },
        async addCartItem(newCartItem: TCartItem, quantity: number = 1) {
            let foundCartItem;
            if (self.items.length > 0) {
                foundCartItem = self.items.find(item => {
                    if (item.product && item.battery) {
                        return (
                            item.product.id === newCartItem.id &&
                            item.battery.code === newCartItem.battery!.code &&
                            item.product.productInformation.color === newCartItem.product!.productInformation.color &&
                            item.reference === newCartItem.reference
                        );
                    } else if (item.product) {
                        return (
                            item.product.id === newCartItem.id &&
                            item.product.productInformation.color === newCartItem.product!.productInformation.color &&
                            item.reference === newCartItem.reference
                        );
                    }
                    return false;
                });
            }
            if (foundCartItem) {
                foundCartItem.increaseQuantity(quantity);
            } else {
                if (newCartItem.product.parentFrameNumber) {
                    newCartItem.parentFrameNumber = newCartItem.product.parentFrameNumber;
                }
                self.items.push(newCartItem);
            }
            this.updateCartDBSnapshot();
            if (newCartItem) {
                const parent: typeof RootStore.Type = getParent(self);
                Toast.addItem(newCartItem.product.name, () => {
                    ReactGA.event({
                        category: GACategories.UI,
                        action: 'User opens the cart by clicking the toast',
                    });
                    parent.config.toggleCart();
                });
            }
        },
        async updateCartItem(cartItem: TCartItem, quantity: number) {
            const foundCartItem = self.items.find(item => {
                if (item.product && item.battery) {
                    return (
                        item.product.id === cartItem.id &&
                        item.battery.code === cartItem.battery!.code &&
                        item.product.productInformation.color === cartItem.product!.productInformation.color
                    );
                } else if (item.product) {
                    return (
                        item.product.id === cartItem.id &&
                        item.product.productInformation.color === cartItem.product!.productInformation.color
                    );
                }
                return false;
            });
            if (foundCartItem) {
                foundCartItem.setQuantity(quantity);
                this.updateCartDBSnapshot();
            }
        },
        async updateCartDBSnapshot() {
            try {
                await Service.updateStorageData(userStateDbColumns.cart, JSON.stringify(getSnapshot(self)));
            } catch (e) {
                console.error(e);
                Toast.PreferencesError();
            }
        },
        async updateCartFromDb(snapshot: string) {
            try {
                const rootStore: typeof RootStore.Type = getParent(self, 1);
                const data = JSON.parse(snapshot);

                applySnapshot(self, data);

                for (const item of self.items) {
                    const loadModelData =
                        (rootStore.products.bikes.size && !rootStore.products.bikes.has(item.id)) ||
                        item?.type === ProductCategoryTypes.BATTERY ||
                        item?.type === ProductCategoryTypes.PART ||
                        item?.type === ProductCategoryTypes.MARKETING;

                    if (loadModelData) {
                        await item.loadSparePartInModel();
                        // If it's a sparepart and the parent bike still exists but the spare part itself doesn't
                        if (
                            !rootStore.spareParts.list.has(item.id) &&
                            !rootStore.marketingMaterials.list.has(item.id)
                        ) {
                            self.removeCartItem(item);
                            continue;
                        }
                    }
                }

                self.setLoaded(true);
            } catch (e) {
                console.error(e);
                Toast.PreferencesError();
            }
        },
        async placeOrder(
            deliveryDate: Date,
            activeCartType: ProductCategoryTypes.BIKE | ProductCategoryTypes.PART,
            navigate: NavigateFunction,
        ): Promise<any> {
            const Products = self.getCartProducts(activeCartType);
            const rootStore: typeof RootStore.Type = getParent(self, 1);
            const OrderType = activeCartType === ProductCategoryTypes.BIKE ? 'Bike' : 'Part';
            let orderObject = {} as CreateOrder;
            if (activeCartType === ProductCategoryTypes.BIKE) {
                const RequestedDeliveryDate = dayjs(deliveryDate).format('YYYY-MM-DD');
                orderObject = {
                    RequestedDeliveryDate,
                    OrderType,
                    Products,
                };
            } else if (activeCartType === ProductCategoryTypes.PART) {
                const RequestedDeliveryDate = dayjs(new Date()).format('YYYY-MM-DD');
                orderObject = {
                    OrderType,
                    Products,
                    RequestedDeliveryDate,
                };
            }

            // QWIC 2.0 non-bike order customizations
            if (activeCartType !== ProductCategoryTypes.BIKE && self.totalPrice(activeCartType) < FREE_SHIPPING_THRESHOLD) {
                orderObject.DeliveryFee = SHIPPING_FLAT_RATE;
            }

            try {
                if (orderObject.OrderType) {
                    const response = await Service.placeOrder(orderObject);
                    if (response && response.status === 201) {
                        this.cleanCartItems(activeCartType);
                        rootStore.config.toggleCart();
                        navigate('/thank-you');
                    } else {
                        Toast.GeneralError();
                    }
                } else {
                    await new Promise((resolve, reject) => {
                        reject(`Please check order shape ${orderObject}`);
                    });
                }
            } catch (e) {
                console.error(e);
                Toast.PreferencesError();
            }
        },
        toggleBundleWarning(toggle: boolean = self.totalBikesInCart > 1 && self.totalBikesInCart < 4) {
            self.bundleWarning = toggle;
        },
    }));

const deliveryDates = (items: TCartItem[]) => {
    return items.map(item => {
        let date = item.product!.productInformation.availableFrom
            ? dayjs(item.product!.productInformation.availableFrom)
            : dayjs(new Date());
        if (item.battery) {
            // if bike has a battery attached, check battery availability date
            const batteryInfo = item.product.batteryInfo(item.battery!.code);
            if (batteryInfo && dayjs(batteryInfo.availableFrom || '').isAfter(date)) {
                date = dayjs(batteryInfo.availableFrom || '');
            }
        }
        return date;
    });
};

const createOrderProduct = (
    productCode: string,
    quantity: number,
    reference: string,
    ParentProductCode?: string,
    FrameNumber?: string,
) => {
    return ParentProductCode
        ? {
              ProductCode: productCode,
              Quantity: quantity,
              Reference: reference,
              ParentProductCode,
              FrameNumber,
          }
        : {
              ProductCode: productCode,
              Quantity: quantity,
              Reference: reference,
          };
};

export default CartStore;
