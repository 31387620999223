import { Theme } from '@mui/material/styles';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Store from 'src/stores/RootStore';
import { TMarketingMaterial } from 'src/stores/types/MarketingMaterialTypes';
import Loading from './loading/Loading';
import MarketingImage from './MarketingImage';

interface BannerProps extends WithStyles<typeof styles> {
    label?: boolean;
    store?: typeof Store.Type;
}

const Banner = inject('store')(
    observer(({ store, classes }: BannerProps) => {
        const renderState: Record<string, JSX.Element> = {
            LOADING: <Loading small />,
            SUCCESS: (
                // Kept in mapping for media carousel for future promotions
                <>
                    {store!.marketing.items.map((tool: TMarketingMaterial, index: number) =>
                        tool && index === 0 ? (
                            <div key={index} className={classes.bannerWrapper} data-testid="marketing-banner-wrapper">
                                <MarketingImage tool={tool} activeTool={store!.marketing.active} />
                            </div>
                        ) : null,
                    )}
                </>
            ),
            IDLE: <Loading />,
            // Don't show anything if there is any error
            ERROR: <></>,
        };

        return <>{renderState[store!.marketing.status]}</>;
    }),
);

function styles({ spacing }: Theme) {
    return createStyles({
        bannerWrapper: {
            width: '100%',
            paddingLeft: '8px',
            paddingRight: '8px',
            position: 'relative',
        },
    });
}

export default withStyles(styles)(Banner);
