import { FormHelperText, TextField, styled } from '@mui/material';
import {
    DatePicker as MuiDatePicker,
    MobileDatePicker as MuiMobileDatePicker,
    PickersDay,
    DatePickerProps,
} from '@mui/x-date-pickers';
import React, { ReactNode } from 'react';

const StyledWrapper = styled('div')<{ error?: any }>(({ theme, error }) => ({
    margin: '1rem 0',
    '& fieldset': {
        borderColor: error ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
    },
}));

const StyledPickersDay = styled(PickersDay)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
    },
}));
const StyledTextInput = styled(TextField)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    width: 150,
    margin: '8px',
}));

interface DatePickerCustomProps extends DatePickerProps<any> {
    CustomTextInput?: any;
    CustomErrorText?: ReactNode;
    error?: any;
    useMobilePicker?: boolean;
}

export default function DatePicker({
    CustomTextInput,
    CustomErrorText,
    error,
    useMobilePicker = false,
    ...props
}: DatePickerCustomProps) {
    return (
        <StyledWrapper error={error}>
            {useMobilePicker ? (
                <MuiMobileDatePicker
                    slots={{ day: StyledPickersDay, textField: CustomTextInput ?? StyledTextInput }}
                    {...props}
                />
            ) : (
                <MuiDatePicker
                    slots={{ day: StyledPickersDay, textField: CustomTextInput ?? StyledTextInput }}
                    {...props}
                />
            )}
            {error &&
                (CustomErrorText ? (
                    CustomErrorText
                ) : (
                    <FormHelperText error sx={{ margin: '3px 14px 0' }}>
                        {error}
                    </FormHelperText>
                ))}
        </StyledWrapper>
    );
}
