import CheckIcon from '@mui/icons-material/CheckCircle';
import { Button, Grid, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { GACategories } from 'src/services/Analytics';
import AnalyticsEcommerce, { ProductAndPromotionActions } from 'src/utils/analytics-ecommerce';

const PaymentProcessingPage = () => {
    useEffect(() => {
        ReactGA.event({
            category: GACategories.Payments,
            action: 'User navigates back from payment processing page',
        });

        // TODO We may need more information in this operation, evaluate again after collecting some data from prior steps (S.Ç)
        // Sending purchase event to GA
        AnalyticsEcommerce.getInstance().clear().setAction(ProductAndPromotionActions.PURCHASE).sendPageView();
    }, []);

    const navigate = useNavigate();

    return (
        <>
            <CssBaseline />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '80vh' }}
            >
                <Grid item xs={10}>
                    <Typography align="center" component="div">
                        <Typography data-testid="payment-processing-page" variant="h2" color="secondary">
                            <CheckIcon style={{ width: '80', height: '80' }} />{' '}
                        </Typography>
                        <Typography variant="h4" color="secondary" gutterBottom>
                            <FormattedMessage id="orders.thank_you_title" />
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <FormattedMessage id="invoices.processing" />
                        </Typography>
                        <Button
                            data-testid="review-invoices"
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={() => navigate('/invoices')}
                        >
                            <FormattedMessage id="invoices.review" />
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default PaymentProcessingPage;
