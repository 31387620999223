import { Dialog } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React from 'react';
import RootStore from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import { TInvoice, TInvoiceLine } from 'src/stores/types/Invoice';
import ReturnRequestForm from './ReturnRequestFormv2';

interface NewReturnRequestDialogProps {
    store?: typeof RootStore.Type;
    user?: typeof UserStore.Type;
}

const NewReturnRequestDialogV2 = inject(
    'user',
    'store',
)(
    observer(({ store, user }: NewReturnRequestDialogProps) => {
        const closeModal = () => {
            const selectedInvoice = store!.invoices.getSelectedInvoice;
            if (selectedInvoice) {
                selectedInvoice.setReturnInvoiceLineId('');
            }
            store!.spareParts.setSelectedSparePartId('');
        };

        const onEnter = (partId: string) => {
            store!.invoices.getInvoicesBySparePart(partId);
            store!.spareParts.getProductBrand(partId);
        };

        const isOpen = store!.returns.isReturnFormDialogOpen;
        const selectedSparePart = store!.spareParts.getSelectedSparePart;
        const invoice = store!.invoices.getSelectedInvoice as TInvoice;
        const returnInvoiceLine = invoice && (invoice.getReturnInvoiceLine as TInvoiceLine);
        const partToReturn =
            (selectedSparePart || returnInvoiceLine) &&
            store!.returns.parsePartToReturn(selectedSparePart || returnInvoiceLine, user!.preferredLang);
        const isEditing = store!.cartReturns.editing;

        return partToReturn || (isEditing && partToReturn) ? (
            <Dialog
                data-testid="return-request-dialog-v2"
                open={isOpen}
                onClose={store!.returns.toggleReturnFormDialog}
                aria-labelledby="return-request-dialog-v2-title"
                TransitionProps={{
                    onExited: closeModal,
                    onEnter: () => onEnter(partToReturn.id),
                }}
            >
                <ReturnRequestForm partToReturn={partToReturn} invoice={invoice} invoiceLine={returnInvoiceLine} />
            </Dialog>
        ) : (
            <></>
        );
    }),
);

export default NewReturnRequestDialogV2;
