import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ParentBikeInModal from 'src/components/ParentBikeInModal';
import ProductImage from 'src/components/ProductImage';
import { TSparePartToReturn } from 'src/stores/types/ReturnRequestModel';

interface ReturnDialogTitleProps {
    partToReturn: TSparePartToReturn;
}

export const ReturnDialogTitle = ({ partToReturn }: ReturnDialogTitleProps) => {
    return (
        <>
            <FormattedMessage id="returns.form_title" />
            {partToReturn.parentBike && <ParentBikeInModal bike={partToReturn.parentBike} />}
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <div>
                    <Typography variant="body1">{partToReturn.description}</Typography>
                    <Typography variant="body1" sx={{ opacity: 0.5 }}>{`# ${partToReturn.id}`}</Typography>
                </div>
                <div style={{ maxWidth: '80px' }}>
                    <ProductImage id={partToReturn.id} type={partToReturn.type} />
                </div>
            </Stack>
        </>
    );
};
