import { Grid } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { CardListItem } from 'src/components/CardListItem';
import Service from 'src/services/api/Api';
import UserStore from 'src/stores/UserStore';
import { DefaultValues } from 'src/utils/constants';

interface KnowledgeInfoProps {
    user?: typeof UserStore.Type;
}

const KnowledgeInfo = inject('user')(
    observer(({ user }: KnowledgeInfoProps) => {
        const intl = useIntl();
        let [searchParams] = useSearchParams();

        let redirectUrl = searchParams.get('redirectUrl') || '';

        useEffect(() => {
            if (redirectUrl) {
                getKnowledgeBaseUrl(redirectUrl);
            }
        }, [redirectUrl]);

        const getKnowledgeBaseUrl = async (redirectTo: string) => {
            try {
                const res = await Service.getKnowledgeBaseUrl(user!.preferredLang, redirectTo);
                if (res.data) {
                    window.open(res.data, '_blank');
                }
            } catch (e) {
                console.error(e);
            }
        };

        const ItemList = [
            {
                title: 'menu.knowledge',
                description: 'menu.knowledgeBase.description',
                link: '',
                onClickItem: () => getKnowledgeBaseUrl(redirectUrl),
                image: DefaultValues.KnowledgeBaseImage,
            },
            {
                title: 'menu.academy',
                description: 'menu.academy.description',
                link: 'https://qwic.docebosaas.com/pages/19/welkom-qwic-academy',
                image: DefaultValues.QwicAcademyImage,
                target: '_blank',
            },
            {
                title: 'menu.downloads',
                description: 'menu.downloads.description',
                link: intl.formatMessage({ id: 'sharepoint.downloads.url' }),
                image: DefaultValues.DownloadImage,
                target: '_blank',
            },
        ];

        return (
            <Grid container spacing={2} alignItems="stretch">
                {ItemList.map(item => (
                    <CardListItem
                        key={item.link}
                        itemLink={item.link}
                        itemTitle={intl.formatMessage({ id: item.title })}
                        itemImage={item.image}
                        unread
                        targetLink={item.target}
                        itemSubTitle={intl.formatMessage({ id: item.description })}
                        onClickItem={item.onClickItem}
                    />
                ))}
            </Grid>
        );
    }),
);

export default KnowledgeInfo;
