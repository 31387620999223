import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FieldProps } from 'formik';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { SearchType, sparePartSearchOptions } from 'src/utils/constants';

interface CategorySelectProps extends FieldProps {
    clearOnSelectChange?: () => void;
    setSearchInput: (value: string) => void;
    isReturn?: boolean;
}

const CategorySelect = ({
    form,
    field,
    clearOnSelectChange,
    setSearchInput,
    isReturn = false,
}: CategorySelectProps) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    const options = useMemo(() => {
        return isReturn
            ? sparePartSearchOptions
            : sparePartSearchOptions.filter(cat => cat.value !== SearchType.INVOICE_SEARCH);
    }, [isReturn]);

    const handleOnChange = (event: SelectChangeEvent) => {
        if (clearOnSelectChange) {
            clearOnSelectChange();
        }

        setSearchInput(event.target.value as string);
        setFieldValue(name, event.target.value);
    };

    return (
        <Select
            defaultValue="search.label_searchCategories"
            value={value}
            label="search.label_searchCategories"
            data-testid="toggle-search-by"
            onChange={handleOnChange}
            variant="outlined"
        >
            {options.map(
                category =>
                    category.display && (
                        <MenuItem key={category.value} value={category.value}>
                            <FormattedMessage id={category.selectLabelId} />
                        </MenuItem>
                    ),
            )}
        </Select>
    );
};

export default CategorySelect;
