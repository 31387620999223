import dayjs from 'dayjs';
import { flow, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';

export type TOrderLine = typeof OrderLine.Type;

export const OrderLine = types
    .model({
        code: types.string,
        description: types.string,
        reference: types.maybeNull(types.string),
        quantity: types.number,
        unitPrice: types.maybeNull(types.number),
        productType: types.maybeNull(types.string),
        total: types.maybeNull(types.number),
        discountAmount: types.maybeNull(types.number),
        discountPercentage: types.maybeNull(types.number),
        amountInclVAT: types.maybeNull(types.number),
        plannedDeliveryDate: types.string,
        requestedDeliveryDate: types.string,
        lineNumber: types.string,
        orderItemId: types.string,
        orderItemNumber: types.string,
        trackingUrl: types.maybeNull(types.string),
    })
    .volatile(self => ({
        tmpReference: '',
    }))
    .actions(self => ({
        setTmpReference(value: string | null) {
            if (!value) {
                self.tmpReference = '';
            } else {
                self.tmpReference = value;
            }
        },
        clearTmpLineRef() {
            self.tmpReference = '';
        },
    }));

const Shipping = types.model({
    city: types.optional(types.string, ''),
    contactName: types.optional(types.string, ''),
    country: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    postalCode: types.optional(types.string, ''),
    state: types.optional(types.string, ''),
    street: types.optional(types.string, ''),
});

type referenceObject = {
    orderItemId: string;
    reference: string;
};

export type TOrder = typeof Order.Type;

export const Order = types
    .model('Order', {
        name: types.identifier,
        orderDate: types.string,
        plannedDeliveryDate: types.optional(types.string, ''),
        requestedDeliveryDate: types.optional(types.string, ''),
        confirmedShipmentDate: types.optional(types.string, ''),
        totalAmount: types.optional(types.number, 0),
        totalAmountInclVAT: types.number,
        status: types.string,
        shipping: types.optional(Shipping, {}),
        lines: types.optional(types.array(types.late(() => OrderLine)), []),
        onStock: types.boolean,
    })
    .volatile(() => ({
        isBeingEdited: false,
        tmpSelectedOrderRequestedDeliveryDate: '',
        isSavingEdit: false,
    }))
    .views(self => ({
        get isEditable() {
            const today = dayjs();

            let confirmedShipmentDate = dayjs();

            // usedeliverydate c instead
            if (self.confirmedShipmentDate) {
                confirmedShipmentDate = dayjs(self.confirmedShipmentDate);
            } else if (self.confirmedShipmentDate === '') {
                // should be not editable due to syncing and processing = will be a separate story;
                return false;
            }

            return confirmedShipmentDate.isAfter(today);
        },
        getLineReferenceById(orderItemId: string) {
            const lines = self.lines.filter(line => line.orderItemId === orderItemId);
            return lines.length ? lines[0].tmpReference : '';
        },
    }))
    .actions(self => ({
        edit() {
            self.isBeingEdited = true;
            self.tmpSelectedOrderRequestedDeliveryDate = self.requestedDeliveryDate;
            self.lines.forEach(line => {
                line.setTmpReference(line.reference);
            });
        },
        clearEdit() {
            self.isBeingEdited = false;
            self.tmpSelectedOrderRequestedDeliveryDate = '';
            self.lines.forEach(line => line.clearTmpLineRef());
        },
        saveEdit: flow(function* () {
            self.isSavingEdit = true;
            let updatedOrder: any = null;

            if (
                self.tmpSelectedOrderRequestedDeliveryDate !== '' &&
                self.tmpSelectedOrderRequestedDeliveryDate !== self.requestedDeliveryDate
            ) {
                let response: any;
                try {
                    response = yield Service.updateOrder(self.name, {
                        requestedDeliveryDate: self.tmpSelectedOrderRequestedDeliveryDate,
                    });
                } catch (e) {
                    console.error(e);
                }
                if (response && response.data && Order.is(response.data)) {
                    updatedOrder = response.data;
                }
            }

            const mobxData: referenceObject[] = self.lines.map(line => ({
                orderItemId: line.orderItemId,
                reference: line.tmpReference,
            }));

            const orgData = self.lines.map(line => {
                return { orderItemId: line.orderItemId, reference: line.reference || '' };
            });

            var filterUpdatedLines = mobxData.filter(
                ({ reference: id1 }) => !orgData.some(({ reference: id2 }) => id2 === id1),
            );

            if (!equalRefObjects(mobxData, orgData)) {
                let response: any;

                try {
                    response = yield Service.updateOrderLine(self.name, {
                        references: filterUpdatedLines,
                    });
                } catch (e) {
                    console.error(e);
                }

                if (response && response.data && Order.is(response.data)) {
                    updatedOrder = response.data;
                }
            }

            if (updatedOrder && Order.is(updatedOrder)) {
                Object.keys(updatedOrder).forEach((key: any) => {
                    if (self.hasOwnProperty(key)) {
                        // @ts-ignore
                        self[key] = updatedOrder[key];
                    }
                });
            }
            self.isBeingEdited = false;
            self.tmpSelectedOrderRequestedDeliveryDate = '';
            self.lines.forEach(line => line.clearTmpLineRef());
            self.isSavingEdit = false;
        }),
        updateRequestedDeliveryDate: (date: Date) => {
            self.tmpSelectedOrderRequestedDeliveryDate = dayjs(date).format('YYYY-MM-DD');
        },
        updateLineReference: (orderItemId: string, reference: string) => {
            const lines = self.lines.filter(line => {
                return line.orderItemId === orderItemId;
            });
            if (lines.length) {
                lines[0].setTmpReference(reference);
            }
        },
    }));

// the same validation occurs in order controller in the backend
const equalRefObjects = (data: referenceObject[], originalData: referenceObject[]): boolean => {
    const sortedNew = data.sort(sortByOrderItemId);
    const sortedOld = originalData.sort(sortByOrderItemId);
    return sortedNew
        .map((obj, i) => {
            return obj.orderItemId === sortedOld[i].orderItemId && obj.reference === sortedOld[i].reference;
        })
        .reduce((acc: boolean, curr: boolean) => {
            return acc && curr;
        }, true);
};

const sortByOrderItemId = (a: referenceObject, b: referenceObject) => {
    return a.orderItemId.localeCompare(b.orderItemId);
};
