import { FormControlLabel, FormLabel, Radio } from '@mui/material';
import { Field } from 'formik';
import { RadioGroup } from 'formik-mui';
import { FormattedMessage } from 'react-intl';

interface BooleanRadioButtonProps {
    name: string;
    labelId: string;
    yesLabelId?: string;
    noLabelId?: string;
    isError: boolean;
    error?: string;
    disabled: boolean;
    disabledYes?: boolean;
    disabledNo?: boolean;
    reverseOptions?: boolean;
}

export const BooleanRadioButton = ({
    name,
    labelId,
    isError,
    error,
    disabled,
    disabledYes,
    disabledNo,
    yesLabelId = 'returns.form_label_yes',
    noLabelId = 'returns.form_label_no',
    reverseOptions,
}: BooleanRadioButtonProps) => {
    return (
        <>
            <FormLabel>
                <FormattedMessage id={labelId} />
            </FormLabel>
            <Field
                data-testid={`return-${name}-field`}
                sx={{
                    display: 'flex',
                    flexDirection: reverseOptions ? 'row-reverse' : 'row',
                    justifyContent: reverseOptions ? 'flex-end' : 'flex-start',
                    '& span': {
                        color: isError ? 'red' : 'inherit',
                    },
                }}
                name={name}
                margin="normal"
                component={RadioGroup}
                error={error}
            >
                <FormControlLabel
                    value="yes"
                    label={<FormattedMessage id={yesLabelId} />}
                    control={<Radio disabled={disabled || disabledYes} color="secondary" />}
                />
                <FormControlLabel
                    value="no"
                    label={<FormattedMessage id={noLabelId} />}
                    control={<Radio disabled={disabled || disabledNo} color="secondary" />}
                />
            </Field>
        </>
    );
};
