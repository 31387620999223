import dayjs from 'dayjs';
import { values } from 'mobx';
import { flow, getParent, hasParent, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';
import { ProductCategoryTypes, ViewModeEnum } from 'src/utils/constants';
import { logRejectedModel } from 'src/utils/logger';
import RootStore from '../RootStore';
import { CompatibleBikeModel } from './CompatibleBike';

export const batteryOption = types.model('batteryOption', {
    code: types.string,
    eanUpc: types.string,
    model: types.string,
    price: types.number,
});

export type TProductInformation = typeof ProductInformation.Type;

export const ProductInformation = types.model('productInformation', {
    color: types.maybeNull(types.string),
    availableFrom: types.optional(types.maybeNull(types.string), ''),
    imageUrl: types.maybeNull(types.string),
    name: types.string,
    consumerPrice: types.optional(types.number, 0),
    unitPrice: types.maybeNull(types.number),
    unitPriceNextYear: types.optional(types.number, 0),
    serialNumber: types.optional(types.string, ''),
    size: types.maybeNull(types.string),
    version: types.maybeNull(types.string),
    deprecated: types.optional(types.boolean, false),
    description: types.string,
    descriptionDE: types.maybeNull(types.string),
    inStock: types.maybeNull(types.boolean),
    stockQuantity: types.maybeNull(types.number),
    freeBatteryUpgrade: types.optional(types.boolean, false),
});

export type TBatteryModel = typeof BatteryModel.Type;

export const BatteryModel = types
    .model('battery', {
        id: types.identifier,
        model: types.string,
        serie: types.string,
        productInformation: ProductInformation,
    })
    .views(self => ({
        get price() {
            return self.productInformation.unitPrice;
        },
        get calculatedPrice() {
            let parent;
            const { consumerPrice, unitPrice } = self.productInformation;
            if (hasParent(self)) {
                parent = getParent<typeof RootStore.Type>(self, 3);
                if (parent.config.viewMode === ViewModeEnum.DEALER) {
                    return unitPrice;
                }
                if (parent.config.viewMode === ViewModeEnum.CONSUMER) {
                    return consumerPrice;
                }
                return consumerPrice;
            }
            return consumerPrice;
        },
    }));

export type TProductModel = typeof ProductModel.Type;

export const ProductModel = types
    .model('ProductModel', {
        batteryOptions: types.optional(
            types.maybeNull(types.array(types.late(() => types.reference(BatteryModel)))),
            [],
        ),
        id: types.identifier,
        parentCode: types.maybeNull(types.string),
        parentFrameNumber: types.maybeNull(types.string),
        model: types.maybeNull(types.string),
        serie: types.maybeNull(types.string),
        type: types.enumeration(Object.values(ProductCategoryTypes)),
        serialNumberRequiredForReturn: types.optional(types.boolean, false),
        productInformation: ProductInformation,
        category: types.maybeNull(types.string),
        composedName: types.optional(types.string, ''),
        warrantyExpiryDate: types.maybeNull(types.string),
        // for spare parts products only
        compatibleBikes: types.optional(types.map(CompatibleBikeModel), {}),
        brand: types.optional(types.string, ''),
    })
    .views(self => ({
        get name(): string {
            return self.productInformation.name;
        },
        // Get the product name to display in the list, grid and product details
        get displayName(): string {
            // Display bike name like serie+model
            if (self.type === ProductCategoryTypes.BIKE) {
                // For Atlas we diplay only model
                if (self.serie === 'Performance Q') {
                    return `${self.model}`;
                }

                return `${self.serie} ${self.model}`;
            }

            return self.productInformation.name;
        },
        get availableDateFrom(): string {
            const { availableFrom } = self.productInformation;
            return availableFrom !== null && Date.parse(availableFrom) ? availableFrom : '';
        },
        get price() {
            const { unitPrice } = self.productInformation;
            return unitPrice;
        },
        get priceNextYear() {
            const { unitPriceNextYear } = self.productInformation;
            return unitPriceNextYear;
        },
        get isWarrantyExpired(): boolean {
            const today = dayjs();
            return self.warrantyExpiryDate ? dayjs(self.warrantyExpiryDate).isBefore(today) : false;
        },
        formattedWarrantyExpiryDate() {
            return self.warrantyExpiryDate ? dayjs(self.warrantyExpiryDate).format('DD MMM YYYY') : null;
        },
        translatedDescription(lang: string) {
            return lang === 'de'
                ? self.productInformation.descriptionDE || self.productInformation.name
                : self.productInformation.description || self.productInformation.name;
        },
        batteryInfo(code: string | null) {
            if (!code || code === 'N/A' || !self.batteryOptions) {
                return null;
            }
            const battery = self.batteryOptions.filter(item => item.id === code)[0];
            return battery ? battery.productInformation : null;
        },
        get compatibleBikesNameList() {
            return values(self.compatibleBikes);
        },
        get isBatteryUpgradePromotion(): boolean {
            return self.productInformation.freeBatteryUpgrade;
        },
        get cheapestBattery() {
            if (!Array.isArray(self.batteryOptions)) {
                return null;
            }
            if (self.batteryOptions.length < 1) {
                return null;
            }
            return self.batteryOptions.reduce((a, b) =>
                Number(a.productInformation.unitPrice) < Number(b.productInformation.unitPrice) ? a : b,
            );
        },
    }))
    .views(self => ({
        getLatestDate(batteryCode: string | null = null) {
            let productInfo = self.productInformation;
            if (!batteryCode || batteryCode === 'empty') {
                return productInfo;
            }
            const selectedBattery = self.batteryOptions!.filter(item => item.id === batteryCode)[0];
            if (
                selectedBattery &&
                selectedBattery.productInformation &&
                !selectedBattery.productInformation.inStock &&
                selectedBattery.productInformation.availableFrom !== null &&
                dayjs(selectedBattery.productInformation.availableFrom).isAfter(productInfo.availableFrom || '')
            ) {
                productInfo = selectedBattery.productInformation;
            }
            return productInfo;
        },
        getPrice(batteryCode: string = '', nextYear: boolean = false) {
            const { unitPrice, unitPriceNextYear, consumerPrice } = self.productInformation;
            if (hasParent(self)) {
                let rootStore;
                // since levels differ depending on some factors, blindly look for the right one until we refactor this
                rootStore = getParent<typeof RootStore.Type>(self, 3);
                if (!rootStore.config) {
                    rootStore = getParent<typeof RootStore.Type>(self, 4);
                }
                const { config } = rootStore;
                if (self.type === ProductCategoryTypes.BATTERY || self.type === ProductCategoryTypes.PART) {
                    if (config.viewMode === ViewModeEnum.DEALER) {
                        return nextYear ? unitPriceNextYear : unitPrice;
                    }
                    if (config.viewMode === ViewModeEnum.CONSUMER) {
                        return consumerPrice;
                    }
                }
                // this is used in spareparts, maybe separate?? unitPrice and unitPriceNext year is literally
                // the same value so we are comparing a with a
                if (self.type === ProductCategoryTypes.BIKE && batteryCode && unitPrice !== null) {
                    const foundSelectedBattery = (
                        self.isBatteryUpgradePromotion && batteryCode !== 'empty'
                            ? self.cheapestBattery
                            : self.batteryOptions!.find(battery => battery.id === batteryCode)
                    ) as TBatteryModel;
                    if (BatteryModel.is(foundSelectedBattery)) {
                        const consumerPriceBattery = foundSelectedBattery.productInformation.consumerPrice;
                        const consumerPriceWithBattery = () => consumerPrice + consumerPriceBattery;
                        const dealerPriceBattery = foundSelectedBattery.productInformation.unitPrice || 0;
                        const dealerPriceWithBattery = () => unitPrice + dealerPriceBattery;
                        const dealerPriceNextYearBattery = foundSelectedBattery.productInformation.unitPriceNextYear;
                        const dealerPriceNextYearWithBattery = () => unitPriceNextYear + dealerPriceNextYearBattery;

                        if (config.viewMode === ViewModeEnum.DEALER) {
                            return nextYear ? dealerPriceNextYearWithBattery() : dealerPriceWithBattery();
                        }
                        if (config.viewMode === ViewModeEnum.CONSUMER) {
                            return consumerPriceWithBattery();
                        }
                    }
                }
                if (config.viewMode === ViewModeEnum.DEALER) {
                    return nextYear ? unitPriceNextYear : unitPrice;
                }
                return consumerPrice;
            }
            return 0;
        },
    }))
    .actions(self => ({
        getCompatibleBikesByPartId: flow(function* _getCompatibleBikesByPartId() {
            try {
                const response: any = yield Service.getCompatibleBikesByPartId(self.id);

                if (response && response.status === 200) {
                    const bikes = response.data;
                    bikes.forEach((bike: typeof CompatibleBikeModel.Type) => {
                        if (CompatibleBikeModel.is(bike)) {
                            self.compatibleBikes.set(bike.id, bike);
                        } else {
                            logRejectedModel(CompatibleBikeModel.name, bike);
                        }
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }),

        setBrand: (brand: string) => {
            self.brand = brand;
        },
    }));
