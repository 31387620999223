import { CloseRounded, Refresh } from '@mui/icons-material';
import { Button, Slide, Snackbar } from '@mui/material';
import { lighten, Theme } from '@mui/material/styles';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import React, { SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import errorIcon from '../../assets/error-icon.svg';

function SlideTransition(props: any) {
    return <Slide {...props} direction="left" />;
}

interface StorageLoadingErrorSnackbarProps extends WithStyles<typeof styles> {
    open: boolean;
    onRefresh: Function;
    onDismiss: Function;
}

const StorageLoadingErrorSnackbarComponent = ({
    open,
    onRefresh,
    onDismiss,
    classes,
}: StorageLoadingErrorSnackbarProps) => {
    const handleRefreshClick = (event: SyntheticEvent) => {
        event.preventDefault();
        onRefresh();
    };

    const handleCloseClick = (event: SyntheticEvent) => {
        event.preventDefault();
        onDismiss();
    };

    return (
        <Snackbar
            data-testid="storage-loading-error"
            open={open}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            TransitionComponent={SlideTransition}
        >
            <div className={classes.root}>
                <div className={classes.colorPart} />
                <div className={classes.leftPart}>
                    <img src={errorIcon} alt="" />
                </div>
                <div className={classes.centerPart}>
                    <div className={classes.title}>
                        <FormattedMessage id="snackbar.errorTitle" />
                    </div>
                    <div className={classes.message}>
                        <FormattedMessage id="snackbar.cartLoadErrorMessage" />
                    </div>
                </div>
                <div className={classes.rightPart}>
                    <Button className={classes.refresh} startIcon={<Refresh />} onClick={handleRefreshClick}>
                        <FormattedMessage id="snackbar.refreshButtonTitle" />
                    </Button>
                </div>

                <div
                    data-testid="storage-loading-error-close-button"
                    className={classes.close}
                    onClick={handleCloseClick}
                >
                    <CloseRounded fontSize={'small'} />
                </div>
            </div>
        </Snackbar>
    );
};

function styles({ spacing, palette }: Theme) {
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            borderRadius: spacing(0.5),
            backgroundColor: palette.common.white,
            height: '100px',
            marginTop: '72px',
            boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.5)',
            position: 'relative',
        },
        colorPart: {
            display: 'flex',
            alignItems: 'center',
            width: '10px',
            backgroundColor: '#ff8200',
            borderTopLeftRadius: spacing(0.5),
            borderBottomLeftRadius: spacing(0.5),
        },
        leftPart: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '30px',
            paddingRight: '30px',
        },
        centerPart: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingRight: spacing(2),
            maxWidth: '500px',
        },
        rightPart: {
            display: 'flex',
            alignItems: 'center',
            borderLeft: `1px solid ${palette.divider}`,
            padding: spacing(2),
        },
        title: {
            fontSize: '30px',
            lineHeight: '30px',
            marginBottom: '5px',
            color: lighten(palette.common.black, 0.3),
        },
        message: {
            fontSize: '16px',
            paddingLeft: '2px',
            color: lighten(palette.common.black, 0.5),
        },
        refresh: {
            textTransform: 'none',
            borderRadius: spacing(0.5),
            backgroundColor: '#ff8200',
            color: palette.common.white,
            fontSize: '16px',

            '&:hover': {
                backgroundColor: lighten('#ff8200', 0.3),
            },
        },
        close: {
            position: 'absolute',
            right: 0,
            top: 0,
            padding: spacing(0.3),
            lineHeight: 0,
            cursor: 'pointer',
        },
    });
}

const StorageLoadingErrorSnackbar = withStyles(styles)(StorageLoadingErrorSnackbarComponent);

export default StorageLoadingErrorSnackbar;
