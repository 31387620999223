import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProductImage from 'src/components/ProductImage';
import Tag from 'src/components/Tag';
import PersistentStore from 'src/stores/PersistentStore';
import Store from 'src/stores/RootStore';
import { TProductModel } from 'src/stores/types/ProductTypes';
import UserStore from 'src/stores/UserStore';
import { createTableValues, TableCell, TableColumnTypes } from 'src/utils/tableColumnUtils';
interface SparePartsProps extends WithStyles<typeof styles> {
    store?: typeof Store.Type;
    persistentStore?: typeof PersistentStore.Type;
    user?: typeof UserStore.Type;
    isReturn?: boolean;
    spareParts: TProductModel[];
}

const SparePartTable = inject(
    'store',
    'persistentStore',
    'user',
)(
    observer(({ store, persistentStore, user, classes, isReturn, spareParts }: SparePartsProps) => {
        const navigate = useNavigate();
        const buildSparePartsList = () => {
            const sparePartsData = spareParts.map((sparePart): TableCell[] => {
                return [
                    {
                        id: 'imageCDN',
                        value: `${sparePart.id}:${sparePart.type}`,
                        type: TableColumnTypes.custom,
                        customRender: value => {
                            const arrIdentifiers = value.split(':');
                            return (
                                <div style={{ maxWidth: '100px' }}>
                                    <ProductImage id={arrIdentifiers[0]} type={arrIdentifiers[1]} />
                                </div>
                            );
                        },
                    },
                    {
                        id: 'description',
                        value: {
                            text: `${sparePart.translatedDescription(user!.preferredLang)}`,
                            showDeprecated: isReturn && sparePart.productInformation.deprecated,
                        },
                        type: TableColumnTypes.custom,
                        customRender: (value: any) => {
                            if (value && value.text) {
                                return (
                                    <span>
                                        <p>{value.text.substring(0, 30) + '...'}</p>
                                        {value.showDeprecated && <Tag translationId="deprecated" />}
                                    </span>
                                );
                            } else {
                                return value ? value.substring(0, 30) + '...' : value;
                            }
                        },
                    },
                    { id: 'warrantyUntil', value: sparePart.formattedWarrantyExpiryDate() },
                    {
                        id: 'itemNumber',
                        value: sparePart.id,
                        type: TableColumnTypes.custom,
                        customRender: (value: any) => {
                            return <span data-testid={`product-card-${value}`}>{value}</span>;
                        },
                    },
                    { id: 'price', value: sparePart.getPrice(), type: TableColumnTypes.price },
                ];
            });

            return createTableValues(sparePartsData);
        };

        const renderSparePartTable = () => {
            const table = buildSparePartsList();
            const data = table.data;
            const columns = table.columns;

            const options: MUIDataTableOptions = {
                filterType: 'checkbox',
                selectableRows: 'none',
                responsive: 'vertical',
                pagination: true,
                filter: false,
                search: false,
                download: false,
                print: false,
                viewColumns: false,
                elevation: 0,
                sort: true,
                page: persistentStore!.sparePartsTable.tablePage,
                onChangePage: persistentStore!.sparePartsTable.setTablePage,
                rowsPerPage: persistentStore!.sparePartsTable.tableRowsPerPage,
                onChangeRowsPerPage: persistentStore!.sparePartsTable.setTableRowsPerPage,
                onRowClick: (rowData: any) => {
                    let sparePartId: string = '';
                    columns.forEach((column, index) => {
                        if (column.name === 'itemNumber') {
                            sparePartId = rowData[index].props.children;
                        }
                    });
                    if (isReturn) {
                        store!.returns.getReturnForm(sparePartId, user!.preferredLang);
                    } else {
                        store!.spareParts.showSparePartDetails(sparePartId, navigate);
                    }
                },
            };

            return <MUIDataTable title="" data={data} columns={columns} options={options} />;
        };

        return <div className={classes.root}>{renderSparePartTable()}</div>;
    }),
);

function styles() {
    return createStyles({
        root: {
            width: '100%',

            '& table > tbody > tr:hover': {
                cursor: 'pointer',
            },
        },
    });
}

export default withStyles(styles)(SparePartTable);
