import { Box, Divider, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Link from 'src/components/Link';
import ListViewToggle from 'src/components/ListViewToggle';
import NoItemFoundAfterSearch from 'src/components/NoItemFoundAfterSearch';
import ProductCard from 'src/components/ProductCard';
import TextFilter from 'src/components/TextFilter';
import { AppPageRoutes } from 'src/services/api/models';
import Store from 'src/stores/RootStore';
import { TProductModel } from 'src/stores/types/ProductTypes';
import UserStore from 'src/stores/UserStore';
import SparePartTable from './SparePartTable';

interface SparePartsListProps {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
    spareParts: TProductModel[];
    showTextFilter: boolean;
    textFilter: string;
    onChangeTextFilter: (value: string) => void;
    notFoundText: string;
    isReturn?: boolean;
}

const SparePartsList = inject(
    'store',
    'user',
)(
    observer(
        ({
            store,
            user,
            spareParts,
            showTextFilter,
            textFilter,
            onChangeTextFilter,
            notFoundText,
            isReturn = false,
        }: SparePartsListProps) => {
            const intl = useIntl();

            const [listView, setListView] = useState(false);

            const renderSpareParts = (parts: TProductModel[]) => {
                if (!parts.length) {
                    return <NoItemFoundAfterSearch searchedText={notFoundText} />;
                }

                return (
                    <Box data-testid="splist" display="flex" flexWrap="wrap">
                        {parts.map(item => (
                            <LazyLoadComponent key={item.id} delayTime={400}>
                                <ProductCard
                                    onCardClick={() =>
                                        isReturn
                                            ? store!.returns.getReturnForm(item.id, user!.preferredLang)
                                            : store!.spareParts.showSparePartDetails(item.id)
                                    }
                                    productId={item.id}
                                    type={item.type}
                                    name={item.translatedDescription(user!.preferredLang)}
                                    price={item.getPrice()}
                                    deprecated={item.productInformation.deprecated}
                                    warrantyExpiryDate={item.formattedWarrantyExpiryDate()}
                                    showWarrantyExpirydate={!!item.warrantyExpiryDate && isReturn}
                                    isInStock={!!item.productInformation.inStock}
                                    stockQuantity={item.productInformation.stockQuantity}
                                    availableFrom={item.productInformation.availableFrom}
                                />
                            </LazyLoadComponent>
                        ))}
                    </Box>
                );
            };

            return (
                <Box sx={{ marginTop: '1rem', position: 'relative' }}>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: showTextFilter ? 'space-between' : 'flex-end',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        {showTextFilter && (
                            <TextFilter
                                labelTextId="spareParts.filterText"
                                text={textFilter}
                                onChange={onChangeTextFilter}
                            />
                        )}
                        <ListViewToggle listView={listView} setListView={() => setListView(!listView)} />
                    </Box>
                    {listView ? (
                        <SparePartTable spareParts={spareParts} isReturn={isReturn} />
                    ) : (
                        renderSpareParts(spareParts)
                    )}

                    <Typography variant="caption" component="p">
                        <FormattedMessage id="spareParts.itemNotOnTheList.info" />
                    </Typography>
                    <Typography variant="caption" component="p">
                        <FormattedMessage
                            id="spareParts.itemNotOnTheList.contact"
                            values={{
                                contact: (
                                    <Link href={AppPageRoutes.CONTACT}>
                                        {intl.formatMessage({ id: 'contact.customerService' })}
                                    </Link>
                                ),
                            }}
                        />
                    </Typography>
                    <Typography variant="caption" component="p">
                        <FormattedMessage
                            id="spareParts.itemNotOnTheList.otherSearch"
                            values={{
                                link: (
                                    <Link href={`${AppPageRoutes.NEW_RETURN_REQUEST}?categories=sparePartSearch`}>
                                        {intl.formatMessage({ id: 'spareParts.itemNotOnTheList.otherSearch.link' })}
                                    </Link>
                                ),
                            }}
                        />
                    </Typography>
                </Box>
            );
        },
    ),
);

export default SparePartsList;
