import { faPresentation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Store from 'src/stores/RootStore';
import { ViewModeEnum } from 'src/utils/constants';

interface ViewModeSwitcherProps {
    store?: typeof Store.Type;
}

const ViewModeSwitcher = inject('store')(
    observer(({ store }: ViewModeSwitcherProps) => {
        const [dropdownSwitcher, setDropdownSwitcher] = React.useState(null as HTMLButtonElement | null);
        const viewModes = [ViewModeEnum.CONSUMER, ViewModeEnum.DEALER];

        const theme = useTheme();
        const onlyXS = useMediaQuery(theme.breakpoints.only('xs'));

        const close = () => {
            setDropdownSwitcher(null);
        };

        const selectViewMode = (mode: ViewModeEnum) => {
            close();
            store!.config.setViewMode(mode);
        };

        return (
            <>
                <Button
                    data-testid="view-mode-switcher"
                    color="inherit"
                    aria-owns={dropdownSwitcher ? 'language-menu' : ''}
                    aria-haspopup="true"
                    onClick={e => setDropdownSwitcher(e.currentTarget)}
                >
                    <FontAwesomeIcon icon={faPresentation} size="lg" style={{ marginRight: '5px' }} />
                    {!onlyXS && <FormattedMessage id={`mode.selected_view_${store!.config.viewMode.toLowerCase()}`} />}
                </Button>
                <Menu
                    id="view-mode-switcher-menu"
                    anchorEl={dropdownSwitcher}
                    open={!!dropdownSwitcher}
                    onClose={close}
                >
                    {viewModes.map(viewMode => {
                        return (
                            <MenuItem
                                onClick={() => selectViewMode(viewMode)}
                                key={viewMode}
                                selected={store!.config.selectedViewMode(viewMode)}
                                data-testid={`view-mode-${viewMode.toLowerCase()}`}
                            >
                                <FormattedMessage id={`mode.selected_view_${viewMode.toLowerCase()}`} />
                            </MenuItem>
                        );
                    })}
                </Menu>
            </>
        );
    }),
);

export default ViewModeSwitcher;
