import { keyframes } from '@emotion/react';

export const fadeInDownKeyFrames = keyframes`
from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
},
to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}
`;

export const spinKeyframes = keyframes`
to {
    transform: rotate(1turn)
},
`;

export const fadeInkeyframes = keyframes`
from {
    opacity: 0;
},
`;

export const fadeInOpacityKeyframe = keyframes`
from {
    opacity: 0;
},
to {
    opacity: 1;
}
`;
