import { Box, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Link from 'src/components/Link';

interface Description {
    label: string;
    link?: { label: string; value: string; url: string };
}

export interface StepItemProps {
    id: string;
    title: string;
    descriptions: Description[];
    image: string;
    position: number;
}

export const StepItem = ({ title, descriptions, image, position }: StepItemProps) => {
    const theme = useTheme();
    const isReverse = !!position;
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                flexDirection: isReverse ? 'row-reverse' : 'row',
                marginBottom: '2rem',
            }}
        >
            <Box
                sx={{
                    width: '55%',
                    backgroundColor: '#F5F3F0',
                    padding: isReverse ? '2rem 4rem 2rem 6rem' : '2rem 6rem 2rem 4rem',
                    [theme.breakpoints.down('md')]: {
                        width: '100%',
                    },
                }}
            >
                <Typography fontWeight="bold" gutterBottom>
                    <FormattedMessage id={title} />
                </Typography>
                {descriptions.map((des, index) => (
                    <Typography key={index} gutterBottom>
                        <FormattedMessage
                            id={des.label}
                            values={
                                des.link
                                    ? {
                                          [des.link.value]: (
                                              <Link href={des.link.url}>
                                                  <FormattedMessage id={des.link.label} />
                                              </Link>
                                          ),
                                      }
                                    : {}
                            }
                        />
                    </Typography>
                ))}
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    right: isReverse ? '' : 0,
                    left: isReverse ? 0 : '',
                    top: 0,
                    height: '100%',
                    width: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: isReverse ? 'flex-end' : 'flex-start',
                    [theme.breakpoints.down('md')]: {
                        display: 'none',
                    },
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '75%',
                        backgroundImage: `url(${image})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}
                />
            </Box>
        </Box>
    );
};
