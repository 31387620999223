const gup = (name: string) => {
    // doesn't like regex to CRA
    /* eslint-disable */
  name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
  const regexS = '[\\?&]' + name + '=([^&#]*)';
  /* eslint-enable */
    const regex = new RegExp(regexS);
    let results = regex.exec(window.location.href);
    if (results == null) {
        return '';
    } else {
        return results[1];
    }
};

export default gup;