import { Typography, Box } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/button/Button';

export const DoneForm = ({ handleClick }: { handleClick: () => void }) => (
    <Box data-testid='contact-done-form'>
        <Typography sx={{ marginBottom: '1rem' }}>
            <FormattedMessage id="contact.sent.message" />
        </Typography>
        <Button data-testid="contact-form-new-case-button" variant="contained" fullWidth onClick={handleClick}>
            <span>
                <FormattedMessage id="contact.send.new" />
            </span>
        </Button>
    </Box>
);
