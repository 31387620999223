import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface QuantitySelectorProps extends WithStyles<typeof styles> {
    quantity: number;
    maxQuantity?: number | null;
    updateQuantity: (val: number) => void;
    isLabelBelow?: boolean;
}

const QuantitySelector = ({ classes, isLabelBelow, updateQuantity, quantity, maxQuantity }: QuantitySelectorProps) => {
    const maxSelectableQuantity = Math.min(maxQuantity ?? 0, 50);
    return maxSelectableQuantity > 0 ? (
        <FormControl className={classes.quantityField}>
            {!isLabelBelow && (
                <FormHelperText sx={{ marginX: '0' }}>
                    <FormattedMessage id="select.quantity" />
                </FormHelperText>
            )}
            <Select
                id="quantity-item"
                data-testid="item-quantity"
                value={quantity}
                onChange={e => updateQuantity(Number(e.target.value))}
                variant="standard"
            >
                {Array.from(Array(maxSelectableQuantity).keys())
                    .map(x => x + 1)
                    .map((option: number) => {
                        return (
                            <MenuItem data-testid={`quantity-option-${option}`} key={option} value={option}>
                                {option}
                            </MenuItem>
                        );
                    })}
            </Select>
            {isLabelBelow && (
                <FormHelperText>
                    <FormattedMessage id="select.quantity" />
                </FormHelperText>
            )}
        </FormControl>
    ) : null;
};

function styles() {
    return createStyles({
        quantityField: {
            flex: 1,
        },
    });
}

export default withStyles(styles)(QuantitySelector);
