import { Grid, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import subDays from 'date-fns/subDays';
import dayjs from 'dayjs';
import { FormikErrors } from 'formik';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage } from 'react-intl';
import { isWeekday, MinDeliveryDate } from 'src/utils/deliveryDateMaths';

interface DeliveryDatePickerProps extends WithStyles<typeof styles> {
    today: dayjs.Dayjs;
    selectedDate: Date;
    setSelectedDate: (date: Date | null) => void;
    errors: FormikErrors<Date> | undefined;
    language: string;
    minDate?: Date;
}

const DeliveryDatePicker = ({
    classes,
    errors,
    language,
    selectedDate,
    setSelectedDate,
    today,
    minDate,
}: DeliveryDatePickerProps) => {
    const onDateChange = (date: Date) => {
        if (date && dayjs(date).isValid() && (dayjs(date).isAfter(today) || dayjs(date).isSame(today, 'day'))) {
            return setSelectedDate(date);
        }
    };

    return (
        <>
            <Typography color="textSecondary">
                <FormattedMessage id="cart.desirableDeliveryDate" />
            </Typography>
            <DatePicker
                minDate={minDate ?? subDays(today.toDate(), MinDeliveryDate())}
                dateFormat="dd/MM/yyyy"
                name="deliveryDate"
                selected={selectedDate}
                onChange={onDateChange}
                filterDate={isWeekday}
                className={classes.datePicker}
                locale={language}
            />

            {errors && (
                <Grid item sm={12}>
                    <Typography variant="body2" className={classes.alert}>
                        <>{errors}</>
                    </Typography>
                </Grid>
            )}
        </>
    );
};

function styles({ palette, typography }: Theme) {
    return createStyles({
        alert: {
            color: palette.secondary.main,
        },
        datePicker: {
            borderWidth: 0,
            fontSize: typography.fontSize,
            fontFamily: typography.fontFamily,
            borderBottom: `solid 1px ${palette.primary.main}`,
            padding: '0.3em 0',
        },
    });
}

export default withStyles(styles)(DeliveryDatePicker);
