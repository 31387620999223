import { flow, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';

const News = types.model({
    id: types.number,
    title: types.string,
    date: types.string,
    link: types.string,
    image: types.maybe(types.string),
});

const RecentNews = types
    .model('NewsStore', {
        list: types.optional(types.array(News), []),
        status: types.optional(types.enumeration('NewsState', ['IDLE', 'LOADING', 'ERROR', 'SUCCESS']), 'IDLE'),
        active: types.optional(types.number, 0),
    })
    .volatile(self => ({
        totalRecords: 0,
    }))
    .views(self => ({
        get items() {
            return self.list.length > 0 ? self.list.toJSON() : [];
        },
    }))
    .actions(self => {
        const getRecentNews = flow(function* (
            lang: string,
            search: string = '',
            limit: number = 20,
            offset: number = 0,
        ) {
            self.status = 'LOADING';
            self.list.clear();
            try {
                const response: any = yield Service.getRecentNews(lang, search, limit, offset);
                if (response.data && response.data.length > 0) {
                    self.list = response.data;

                    if (response.meta) {
                        self.totalRecords = response.meta.totalRecords;
                    }
                }
                self.status = 'SUCCESS';
            } catch (error) {
                console.error('error: ', error);
                self.status = 'ERROR';
            }
        });

        const setActiveNews = (index: number) => {
            self.active = index;
        };
        return {
            getRecentNews,
            setActiveNews,
        };
    });

export default RecentNews;
