import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

interface SuccessToasterProps {
    translationId: string;
    onClickHandler?: () => void;
}
const SuccessToaster = ({ translationId, onClickHandler }: SuccessToasterProps) => {
    const intl = useIntl();

    return (
        <Typography
            component={'div'}
            color="primary"
            data-testid="toaster-success"
            align={'center'}
            onClick={onClickHandler}
        >
            <Typography component={'div'} color="secondary" style={{ marginRight: '1rem', display: 'inline' }}>
                <FontAwesomeIcon icon={faCheck} />
            </Typography>

            {/* translationId can be either a translation id or a product name */}
            {intl.formatMessage({ id: translationId })}
        </Typography>
    );
};

export default SuccessToaster;
