import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';
import { inject, observer } from 'mobx-react';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-calendar/dist/Calendar.css';
import { FormattedMessage } from 'react-intl';
import UserStore from 'src/stores/UserStore';

interface FilterInvoicesBarProps extends WithStyles<typeof styles> {
    user?: typeof UserStore.Type;
    onDateRangeChange: (dateRange: Date[]) => void;
    maxDate: Date;
    minDate: Date;
    prefiltered?: boolean;
}

const FilterInvoicesBar = inject('user')(
    observer(({ classes, user, maxDate, minDate, onDateRangeChange, prefiltered }: FilterInvoicesBarProps) => {
        const calendarIcon = <FontAwesomeIcon icon={faCalendarAlt} size="2x" />;
        const language = user?.preferredLang !== 'en-GB' ? user?.preferredLang.substring(0, 2) : 'en-GB';

        return (
            <>
                <Typography className={classes.note} variant="body1">
                    <FormattedMessage id={prefiltered ? 'invoices.preselected' : 'invoices.note'} />
                </Typography>
                <DateRangePicker
                    locale={language}
                    format="dd/MM/y"
                    className={classes.date}
                    maxDate={new Date()}
                    value={[minDate, maxDate]}
                    onChange={onDateRangeChange}
                    calendarIcon={calendarIcon}
                    clearIcon={null}
                    tileClassName={classes.datePickerTitle}
                    disabled={prefiltered}
                />
            </>
        );
    }),
);

function styles({ typography, palette }: Theme) {
    return createStyles({
        note: {
            margin: '.5rem',
        },
        date: {
            ...typography.body1,
            '@global': {
                '.react-daterange-picker__wrapper': {
                    alignItems: 'center',
                    border: 'none',
                    fontSize: '1rem',
                    borderBottom: `solid 1px ${palette.primary.light}`,
                },
                '.react-daterange-picker__inputGroup': {
                    margin: '0 .5rem',
                },
                '.react-date-picker__inputGroup__divider': {
                    margin: '0 2px',
                },
                '.react-daterange-picker__inputGroup__day:not(.react-daterange-picker__inputGroup__input--hasLeadingZero)': {
                    minWidth: '20px',
                },
                '.react-daterange-picker__inputGroup__year': {
                    minWidth: '35px',
                },
                '.react-calendar__navigation__arrow, .react-calendar__navigation__label': {
                    fontFamily: typography.h6.fontFamily,
                    fontSize: '1rem',
                },
                '.react-calendar__tile--active': {
                    backgroundColor: palette.secondary.main,
                },
                '.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus': {
                    backgroundColor: palette.secondary.main,
                },
            },
            zIndex: 999,
        },
        datePickerTitle: {
            fontSize: '1rem',
        },
    });
}

export default withStyles(styles)(FilterInvoicesBar);
