import { Typography, useTheme } from '@mui/material';
import { Field, FieldProps, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { subYears } from 'date-fns';

import DatePicker from 'src/components/DatePicker';
import { FileUpload } from 'src/components/FileUpload';
import { BikesSuggestion } from 'src/components/SearchSpareParts';
import { OUT_OF_WARRANTY, ReturnItemFormValues } from '../ReturnRequestFormv2';
import { AutocompleteField } from './AutocompleteField';
import { BooleanRadioButton } from './BooleanRadioButton';
import { CustomCheckbox } from './CustomCheckbox';
import { CustomDatePickerTextField } from './CustomDatePickerTextField';
import { ReferenceField } from './ReferenceField';
import { ReturnReasonField } from './ReturnReasonField';
import { SerialNumberField } from './SerialNumberField';
import { WarningBoxLight } from 'src/components/WarningBoxLight';
import Link from 'src/components/Link';

import { TSparePartToReturn } from 'src/stores/types/ReturnRequestModel';

interface BrokenPartFeildsProps {
    partToReturn: TSparePartToReturn;
    bikeFrameSuggestions: BikesSuggestion[];
    getOptionsAutocomplete: (value: string) => void;
    preferredLang: string;
    needDescription: boolean;
    hideUpload?: boolean;
}

export const BrokenPartFeilds = ({
    partToReturn,
    bikeFrameSuggestions,
    getOptionsAutocomplete,
    preferredLang,
    needDescription,
    hideUpload = false,
    ...formikProps
}: BrokenPartFeildsProps & FormikProps<ReturnItemFormValues>) => {
    const intl = useIntl();
    const theme = useTheme();
    const [file, setFile] = useState<File>();

    const { values, errors, touched, setFieldValue, setFieldTouched, isSubmitting, submitCount, initialValues } =
        formikProps;

    const handleRemoveFile = (setFieldValue: any) => (fileName: string) => {
        setFile(undefined);
        setFieldValue('attachment', undefined);
    };

    return (
        <>
            <BooleanRadioButton
                name="isDamaged"
                labelId="returns.form_label_isDamaged"
                isError={!!errors.isDamaged && submitCount > 0}
                error={errors.isDamaged}
                disabled={isSubmitting}
            />

            {!!values.isDamaged &&
                (values.isDamaged === 'yes' ? (
                    <WarningBoxLight
                        titleKey="returns.form.warning.no.damage.battery"
                        messageKey="returns.form.warning.no.damage.battery.message"
                    />
                ) : (
                    <>
                        {!partToReturn.parentBike?.frameNumber && (
                            <>
                                <Typography variant="body1" component="p" paddingBottom="1rem">
                                    <FormattedMessage id="returns.form_frame_number_note" />
                                </Typography>

                                <AutocompleteField
                                    label={<FormattedMessage id="returns.form_label_frameNumber" />}
                                    name="frameNumber"
                                    list={bikeFrameSuggestions}
                                    error={touched.frameNumber ? errors.frameNumber : undefined}
                                    getOptionsAutocomplete={getOptionsAutocomplete}
                                    disabled={values.noFrameNumber}
                                    placeholder={intl.formatMessage({
                                        id: 'frameNumber.placeholder',
                                    })}
                                    defaultValue={initialValues?.frameNumber}
                                    {...formikProps}
                                />

                                <Field
                                    name="noFrameNumber"
                                    Label={{
                                        label: intl.formatMessage({
                                            id: 'returns.form_label_no_frameNumber',
                                        }),
                                    }}
                                    component={CustomCheckbox}
                                    type="checkbox"
                                    sx={{ color: 'secondary' }}
                                />

                                {values.noFrameNumber && (
                                    <Field
                                        label={<FormattedMessage id="returns.form_label_no_frameNumber_reason" />}
                                        name="reasonNoFramenumber"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        multiline
                                        rows={2}
                                        error={touched.reasonNoFramenumber ? errors.reasonNoFramenumber : undefined}
                                        component={TextField}
                                    />
                                )}
                            </>
                        )}

                        {(!!partToReturn.parentBike?.frameNumber || !!values.frameNumber || values.noFrameNumber) && (
                            <>
                                <ReturnReasonField
                                    returnReasons={partToReturn.returnReasons}
                                    preferredLang={preferredLang}
                                    needDescription={needDescription}
                                    {...formikProps}
                                />

                                <Field name="consumerPurchaseDate">
                                    {({ field, form: { touched, errors }, meta }: FieldProps) => (
                                        <DatePicker
                                            label={<FormattedMessage id="returns.form_label_date_of_sale" />}
                                            value={(field.value && new Date(field.value)) || null}
                                            onChange={val => {
                                                setFieldTouched(field.name, true);
                                                setFieldValue(field.name, val);
                                            }}
                                            onOpen={() => {
                                                setFieldTouched(field.name, true);
                                            }}
                                            CustomTextInput={CustomDatePickerTextField}
                                            maxDate={new Date()}
                                            minDate={subYears(new Date(), 10)}
                                            error={touched.consumerPurchaseDate ? errors.consumerPurchaseDate : ''}
                                            CustomErrorText={
                                                errors.consumerPurchaseDate === OUT_OF_WARRANTY ? (
                                                    <div style={{ marginTop: '1rem' }}>
                                                        <WarningBoxLight
                                                            titleKey="return_request.out_of_warranty"
                                                            messageKey="return_request.out_of_warranty_message"
                                                        />
                                                    </div>
                                                ) : undefined
                                            }
                                            useMobilePicker
                                        />
                                    )}
                                </Field>

                                {!hideUpload && (
                                    <FileUpload
                                        label={intl.formatMessage({
                                            id: 'returns.form_label_upload_invoice',
                                        })}
                                        value=""
                                        handleInputChange={(e: any) => {
                                            const currentFile = e.currentTarget.files[0];
                                            if (currentFile) {
                                                setFile(currentFile);
                                                setFieldValue('attachment', currentFile);
                                            }
                                        }}
                                        handleClickLabel={(e: any) => {
                                            setFieldTouched('attachment', true);
                                        }}
                                        isError={!!touched.attachment && !!errors.attachment}
                                        errorText={!!errors.attachment ? String(errors.attachment) : ''}
                                        uploadedFiles={file ? [file] : undefined}
                                        handleRemoveFile={handleRemoveFile(setFieldValue)}
                                    />
                                )}

                                <SerialNumberField {...formikProps} />

                                {values.reorder === 'yes' && (
                                    <Typography variant="body1" color={theme.palette.text.disabled}>
                                        <FormattedMessage id="returns.form_battery_replacement_note" />
                                    </Typography>
                                )}

                                <Field
                                    name="acceptBatteryBack"
                                    Label={{
                                        label: (
                                            <Typography variant="subtitle2" marginY={2}>
                                                <FormattedMessage id="returns.form_label_accept_battery_back" />
                                            </Typography>
                                        ),
                                    }}
                                    component={CustomCheckbox}
                                    type="checkbox"
                                    sx={{
                                        color:
                                            errors.acceptBatteryBack && touched.confirmFees ? '#d32f2f' : 'secondary',
                                    }}
                                />

                                <Field
                                    name="confirmFees"
                                    Label={{
                                        label: (
                                            <Typography variant="subtitle2">
                                                <FormattedMessage
                                                    id="returns.form_label_confirm_fees"
                                                    values={{
                                                        link: (
                                                            <Link
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                href={intl.formatMessage({
                                                                    id: 'returns.form_label_confirm_fees_link_href',
                                                                })}
                                                            >
                                                                {intl.formatMessage({
                                                                    id: 'returns.form_label_confirm_fees_link_name',
                                                                })}
                                                            </Link>
                                                        ),
                                                    }}
                                                />
                                            </Typography>
                                        ),
                                    }}
                                    component={CustomCheckbox}
                                    type="checkbox"
                                    sx={{ color: errors.confirmFees && touched.confirmFees ? '#d32f2f' : 'secondary' }}
                                />

                                <ReferenceField {...formikProps} />
                            </>
                        )}
                    </>
                ))}
        </>
    );
};
