import { types } from 'mobx-state-tree';

export type TBikeAsset = typeof BikeAsset.Type;

export const BikeAsset = types
    .model({
        id: types.string,
        name: types.string,
        frameNumber: types.maybeNull(types.string),
        displayUrl: types.maybeNull(types.string),
        createdDate: types.maybeNull(types.string),
        description: types.string,
        itemNumber: types.string,
        color: types.maybeNull(types.string),
        size: types.maybeNull(types.string),
        version: types.maybeNull(types.string),
    })
    .volatile(self => ({
        tmpReference: '',
    }))
    .actions(self => ({
        setTmpReference(value: string | null) {
            if (!value) {
                self.tmpReference = '';
            } else {
                self.tmpReference = value;
            }
        },
        clearTmpLineRef() {
            self.tmpReference = '';
        },
    }));
