import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import useUserGuiding from 'src/services/userGuiding';
import PersistentStore from 'src/stores/PersistentStore';
import RootStore from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';

interface LanguageSwitcherProps {
    persistentStore?: typeof PersistentStore.Type;
    store?: typeof RootStore.Type;
    user?: typeof UserStore.Type;
    isLoginPage?: boolean;
}

const LanguageSwitcher = inject(
    'store',
    'persistentStore',
    'user',
)(
    observer(({ persistentStore, store, user, isLoginPage = false }: LanguageSwitcherProps) => {
        const [languageDropdown, setLanguageDropdown] = React.useState(null as HTMLButtonElement | null);
        const languageList = [
            { title: 'language.dutch', locale: 'nl-NL' },
            { title: 'language.german', locale: 'de' },
            { title: 'language.english', locale: 'en-GB' },
        ];

        const { readyState: userGuidingReadyState, identifyUserGuiding } = useUserGuiding();

        const theme = useTheme();
        const onlyXS = useMediaQuery(theme.breakpoints.only('xs'));

        const close = () => {
            setLanguageDropdown(null);
        };

        const selectLanguage = useCallback(
            (locale: string) => {
                close();

                // Before logingin user doesn't have token to send preferences
                if (isLoginPage) {
                    user?.setLangPreferenceWithoutRequest(locale);
                } else {
                    user?.changeUserPreferences({ preferredLang: locale });
                }

                // Sending attribute app_language to UserGuiding
                if (userGuidingReadyState && user?.userId) {
                    identifyUserGuiding(user.userId, { app_language: locale });
                }
            },
            [userGuidingReadyState, user, identifyUserGuiding],
        );

        return (
            <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: isLoginPage ? 'center' : 'flex-start' }}
            >
                <Typography variant="body1">
                    <FormattedMessage id="login.select_language" />
                </Typography>

                <Button
                    color="inherit"
                    aria-owns={languageDropdown ? 'language-menu' : ''}
                    aria-haspopup="true"
                    data-testid="language-switcher"
                    onClick={e => setLanguageDropdown(e.currentTarget)}
                >
                    <FontAwesomeIcon icon={faGlobe} size="lg" style={{ marginRight: '5px' }} />

                    {!onlyXS && <FormattedMessage id="language.label" />}
                </Button>
                <Menu id="language-menu" anchorEl={languageDropdown} open={!!languageDropdown} onClose={close}>
                    {languageList.map(item => {
                        return (
                            <MenuItem
                                onClick={() => selectLanguage(item.locale)}
                                style={{ paddingTop: '11px', paddingBottom: '11px' }}
                                key={item.locale}
                                data-testid={`lang-${item.locale}`}
                                selected={user?.isLangSelected(item.locale)}
                            >
                                <FormattedMessage id={item.title} />
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        );
    }),
);

export default LanguageSwitcher;
