import { Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export interface NoItemFoundAfterSearchProps extends WithStyles<typeof styles> {
    searchedText: string;
}

const NoItemFoundAfterSearch = ({ classes, searchedText }: NoItemFoundAfterSearchProps) => {
    return (
        <div className={classes.notFound}>
            <Typography variant="body1" color="secondary">
                <FormattedMessage id="returns.cantFind" />
                {` "${searchedText}". `}
                <FormattedMessage id="returns.cantFind_part2" />
            </Typography>
        </div>
    );
};

function styles() {
    return createStyles({
        '@keyframes fadeInDown': {
            from: {
                opacity: 0,
                transform: 'translate3d(0, -30px, 0)',
            },
            to: {
                opacity: 1,
                transform: 'translate3d(0, 0, 0)',
            },
        },
        notFound: {
            display: 'flex',
            width: '100%',
            minHeight: '300px',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            animation: '$fadeInDown 0.5s ease-out',
            textAlign: 'center',
        },
    });
}

export default withStyles(styles)(NoItemFoundAfterSearch);
