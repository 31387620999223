import { faArrowDown, faArrowUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell as MuiTableCell, TablePagination, Typography } from '@mui/material';
import 'dayjs/locale/de';
import 'dayjs/locale/nl';
import { FilterType, MUIDataTableColumn, MUIDataTableFilterOptions } from 'mui-datatables';
import * as React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import CurrencyFormat from 'src/components/currency/Currency';

export enum TableColumnTypes {
    text = 'text',
    custom = 'custom',
    date = 'date',
    price = 'price',
}

export interface TableCell {
    id: string;
    value: any;
    type?: TableColumnTypes;
    customRender?: (value?: any, tableMeta?: any, updateValue?: any) => React.ReactElement;
    sort?: boolean;
    filter?: boolean;
    filterList?: string[];
    filterType?: FilterType;
    filterOptions?: MUIDataTableFilterOptions;
    display?: string;
}

const defaultCell: TableCell = {
    id: '',
    value: '',
    type: TableColumnTypes.text,
    sort: true,
    filter: false,
    display: 'true',
};

// parse all cells of the first row to grab columns
const parseCellColumn = (cell: TableCell): MUIDataTableColumn => {
    const column = { ...defaultCell, ...cell };
    let columnOption: MUIDataTableColumn = {
        name: column.id,
        options: {
            sort: column.sort,
            filter: false,
            filterList: column.filterList,
            filterType: column.filterType,
            filterOptions: column.filterOptions,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
                const sort = column.sort;
                return (
                    <MuiTableCell
                        style={sort ? { cursor: 'pointer' } : {}}
                        onClick={() => sort && handleToggleColumn(columnMeta.index)}
                        key={column.id}
                        sortDirection={sortOrder.direction}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="subtitle1" fontWeight={600}>
                                <FormattedMessage id={`column.${column.id}`}>
                                    {children => {
                                        return <React.Fragment>{children}</React.Fragment>;
                                    }}
                                </FormattedMessage>
                            </Typography>
                            {columnMeta.name === sortOrder.name && sortOrder.direction === 'desc' ? (
                                <FontAwesomeIcon
                                    style={{ marginLeft: '.5rem', color: 'black' }}
                                    icon={faArrowDown}
                                    size="lg"
                                />
                            ) : columnMeta.name === sortOrder.name && sortOrder.direction === 'asc' ? (
                                <FontAwesomeIcon
                                    style={{ marginLeft: '.5rem', color: 'black' }}
                                    icon={faArrowUp}
                                    size="lg"
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </MuiTableCell>
                );
            },
            customBodyRender: (value, data) => {
                return <div data-testid={`table-cell-${data.columnData.label}-${data.rowIndex}`}>{value}</div>;
            },
        },
    };

    // overwrite columnOptions properties
    if (column.type === TableColumnTypes.date) {
        columnOption = {
            ...columnOption,
            options: {
                ...columnOption.options,
                customBodyRender: (value: string) => {
                    if (!value) {
                        return <FormattedMessage id="labels.toConfirm" />;
                    }

                    if (Date.parse(value)) {
                        const renderValues = {
                            day: <FormattedDate value={value} day="2-digit" />,
                            month: <FormattedDate value={value} month="2-digit" />,
                            year: <FormattedDate value={value} year="numeric" />,
                        };
                        return <FormattedMessage id="intl.date" values={renderValues} />;
                    } else {
                        const renderValues = {
                            first_day: <FormattedDate value={value.split(' / ')[0]} day="2-digit" />,
                            first_month: <FormattedDate value={value.split(' / ')[0]} month="2-digit" />,
                            first_year: <FormattedDate value={value.split(' / ')[0]} year="numeric" />,
                            last_day: <FormattedDate value={value.split(' / ')[1]} day="2-digit" />,
                            last_month: <FormattedDate value={value.split(' / ')[1]} month="2-digit" />,
                            last_year: <FormattedDate value={value.split(' / ')[1]} year="numeric" />,
                        };
                        return <FormattedMessage id="intl.daterange" values={renderValues} />;
                    }
                },
            },
        } as MUIDataTableColumn;
    } else if (column.type === TableColumnTypes.custom) {
        columnOption = {
            ...columnOption,
            options: {
                ...columnOption.options,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return column.customRender ? column.customRender(value, tableMeta, updateValue) : '';
                },
            },
        } as MUIDataTableColumn;
    } else if (column.type === TableColumnTypes.price) {
        columnOption = {
            ...columnOption,
            options: {
                ...columnOption.options,
                customBodyRender: (value: any) => {
                    return value || value === 0 ? <CurrencyFormat value={value} /> : '';
                },
            },
        } as MUIDataTableColumn;
    }

    if (column.filter) {
        columnOption = {
            ...columnOption,
            options: {
                ...columnOption.options,
                filter: true,
                filterOptions: { fullWidth: true },
                customFilterListOptions: {
                    render: (value: any) => {
                        const text = value.text ? value.text : value;
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="caption">
                                    <FormattedMessage id={`column.${column.id}`} />
                                </Typography>
                                <Typography variant="caption">: {text}</Typography>
                            </div>
                        );
                    },
                },
            },
        } as MUIDataTableColumn;
    }

    if (column.display) {
        columnOption = {
            ...columnOption,
            options: {
                ...columnOption.options,
                display: column.display,
            },
        } as MUIDataTableColumn;
    }

    return columnOption;
};

export const createTableValues = (tableRows: TableCell[][]): { data: any; columns: MUIDataTableColumn[] } => {
    const data: any[] = [];
    let columns: MUIDataTableColumn[] = [];

    if (tableRows.length) {
        columns = tableRows[0].map(parseCellColumn);
        tableRows.forEach((row: TableCell[]) => {
            data.push(row.map(cell => cell.value));
        });
    }

    return { data, columns };
};

interface CustomPaginationFooterProps {
    rowCount: number;
    page: number;
    rowsPerPage: number;
    changeRowsPerPage: (page: string | number) => void;
    changePage: (newPage: number) => void;
}

export const CustomPaginationFooter = ({
    rowCount,
    page,
    rowsPerPage,
    changeRowsPerPage,
    changePage,
}: CustomPaginationFooterProps): React.ReactElement => (
    <tbody>
        <tr>
            <TablePagination
                data-testid="Pagination"
                style={{ borderBottom: 'none' }}
                count={rowCount}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={(_, pageNumber) => changePage(pageNumber)}
                onRowsPerPageChange={event => changeRowsPerPage(event.target.value)}
            />
        </tr>
    </tbody>
);

export const defaultSort = (data: any[], colIndex: number, order: string): any[] => {
    if (order === 'asc') {
        data.sort((a: any, b: any) => {
            if (a.data[colIndex] === null) {
                a.data[colIndex] = '';
            }
            if (b.data[colIndex] === null) {
                b.data[colIndex] = '';
            }
            return String(a.data[colIndex]).localeCompare(String(b.data[colIndex]));
        });
    } else {
        data.sort((a: any, b: any) => {
            if (a.data[colIndex] === null) {
                a.data[colIndex] = '';
            }
            if (b.data[colIndex] === null) {
                b.data[colIndex] = '';
            }
            return String(b.data[colIndex]).localeCompare(String(a.data[colIndex]));
        });
    }
    return data;
};

export const customInvoiceSort = (data: any[], colIndex: number, order: string): any[] => {
    // sorting Date
    if (colIndex === 6 || colIndex === 7) {
        if (order === 'asc') {
            data.sort((a: any, b: any) => {
                return new Date(a.data[colIndex]).getTime() - new Date(b.data[colIndex]).getTime();
            });
        } else {
            data.sort((a: any, b: any) => {
                return new Date(b.data[colIndex]).getTime() - new Date(a.data[colIndex]).getTime();
            });
        }
        // Sorting text fields
    } else if (colIndex === 1 || colIndex === 2 || colIndex === 3) {
        if (order === 'asc') {
            data.sort((a: any, b: any) => {
                return a.data[colIndex]?.localeCompare(b.data[colIndex]);
            });
        } else {
            data.sort((a: any, b: any) => {
                return b.data[colIndex]?.localeCompare(a.data[colIndex]);
            });
        }
        // Sorting numbers
    } else if (colIndex === 4 || colIndex === 5) {
        if (order === 'asc') {
            data.sort((a: any, b: any) => {
                return a.data[colIndex] - b.data[colIndex];
            });
        } else {
            data.sort((a: any, b: any) => {
                return b.data[colIndex] - a.data[colIndex];
            });
        }
    }
    return data;
};
