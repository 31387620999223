import deburr from 'lodash/deburr';
import some from 'lodash/some';

const normalize = (str: string) => deburr(str.toLowerCase());

// This helps to check if the val:string is included
// in any of the property value of the obj:object
// It returns true/false
export const includesValue = (val: string, obj: Record<string, any>) => {
    const search = normalize(val);
    return some(obj, v => v && normalize(v).includes(search));
};
