import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter } from 'src/utils/stringUtils';

dayjs.extend(localeData);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(weekOfYear);

type ParseableDate = string | dayjs.Dayjs | Date;

export interface DeliveryDate {
    text: string | JSX.Element;
    tooltip: string | JSX.Element;
    isTooltipNeeded: boolean;
}

export const getFormattedDeliveryDate = (parseableDate: ParseableDate): DeliveryDate => {
    const date = dayjs(parseableDate),
        now = dayjs(Date.now());

    if (date.isAfter(now.add(3, 'months'), 'day')) {
        const month = dayjs().localeData().months()[date.month()];

        return {
            text: capitalizeFirstLetter(month),
            tooltip: (
                <FormattedMessage
                    id="order.deliveryMonthTooltip"
                    values={{ monthName: capitalizeFirstLetter(month) }}
                />
            ),
            isTooltipNeeded: true,
        };
    }

    if (date.isBetween(now.add(1, 'week'), now.add(3, 'months'), 'day')) {
        return {
            text: <FormattedMessage id="order.deliveryWeek" values={{ weekNumber: date.week() }} />,
            tooltip: (
                <FormattedMessage
                    id="order.deliveryWeekTooltip"
                    values={{
                        startDate: date.startOf('week').format('DD/MM/YYYY'),
                        endDate: date.endOf('week').format('DD/MM/YYYY'),
                    }}
                />
            ),
            isTooltipNeeded: true,
        };
    }

    return {
        text: <FormattedDate value={date.toDate()} day="2-digit" month="2-digit" year="numeric" />,
        tooltip: (
            <FormattedMessage id="order.preciseDeliveryDateTooltip" values={{ date: date.format('DD/MM/YYYY') }} />
        ),
        isTooltipNeeded: false,
    };
};
