import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import { TBatteryModel } from 'src/stores/types/ProductTypes';

interface ConsumerBatteryPriceProps {
    value: string | number;
    batteryList: TBatteryModel[];
    currentIndex: number;
}
const isNumber = (v: string | number): number => Number(v);

/* eslint-disable */
export function ConsumerBatteryPriceFormat({ currentIndex, batteryList }: ConsumerBatteryPriceProps) {
    let preCalculatedValue = 0;
    if (currentIndex > 0) {
        preCalculatedValue =
            batteryList[currentIndex].productInformation.consumerPrice -
            batteryList[0].productInformation.consumerPrice;
    }
    return (
        <FormattedNumber
            value={isNumber(Math.round(preCalculatedValue))}
            style="currency"
            currency="EUR"
            currencyDisplay="symbol"
        >
            {(formatted: string) => formatted}
        </FormattedNumber>
    );
}
/* eslint-enable */

export default ConsumerBatteryPriceFormat;
