import { Box, Theme, Typography, useTheme } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { subDays } from 'date-fns';
import nl from 'date-fns/locale/nl';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { inject, observer } from 'mobx-react';
import React from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';
import RootStore from 'src/stores/RootStore';
import { TOrder } from 'src/stores/types/OrdersTypes';
import UserStore from 'src/stores/UserStore';
import { getMinDeliveryDate, isWeekday } from 'src/utils/deliveryDateMaths';
import WarningBox from './WarningBox';

// Register date picker languages
registerLocale('nl', nl);
setDefaultLocale('nl');

export interface DefinitionsBoxProps extends WithStyles<typeof styles> {
    translationId?: string;
    data: Record<string, any>;
    user?: typeof UserStore.Type;
    store?: typeof RootStore.Type;
    className?: string;
    selectedOrder?: TOrder;
}

// @TODO: name of component does not explain what is done in component properly
const DefinitionsBox = inject(
    'store',
    'user',
)(
    observer(
        ({ data, translationId = undefined, user, store, className, classes, selectedOrder }: DefinitionsBoxProps) => {
            const intl = useIntl();
            const { palette } = useTheme();
            const language = user && user.preferredLang !== 'en-GB' ? user.preferredLang.substring(0, 2) : 'en';
            const labels = Object.keys(data);
            const renderDefinitionListItem = () => {
                return labels.map((label: string) => {
                    let value = '-';

                    if (typeof data[label] === 'boolean') {
                        value = data[label]
                            ? intl.formatMessage({ id: 'labels.yes' })
                            : intl.formatMessage({ id: 'labels.no' });
                    } else if (data[label]) {
                        value = data[label];
                    }

                    // TODO: bad implementation, need other component for requestedDeliveryDate
                    if (label === 'requestedDeliveryDate' && selectedOrder?.isBeingEdited) {
                        const tmpRequestedDeliveryDate = dayjs(selectedOrder?.tmpSelectedOrderRequestedDeliveryDate);
                        const requestedDeliveryDate = dayjs(selectedOrder?.requestedDeliveryDate);
                        const plannedDeliveryDate = dayjs(selectedOrder.plannedDeliveryDate);
                        const onStock = selectedOrder.onStock;

                        const isRequestedDDAfter = requestedDeliveryDate.isAfter(plannedDeliveryDate);

                        // Pick from what date we can change the requested delirery date on the date picker
                        const pickedDate = !onStock && isRequestedDDAfter ? requestedDeliveryDate : plannedDeliveryDate;

                        // Count 2 buffer days for today
                        const pickedMinDate = pickedDate.isAfter(dayjs().add(2, 'day'))
                            ? pickedDate.toDate()
                            : dayjs().toDate();

                        const minDeliveryDate = getMinDeliveryDate(pickedMinDate);
                        const minDate = subDays(pickedMinDate, minDeliveryDate);

                        const maxRescheduleInMonths = 1;
                        const maxDate = plannedDeliveryDate.add(maxRescheduleInMonths, 'month').toDate();

                        return (
                            <React.Fragment key={label}>
                                <dt title={intl.formatMessage({ id: `labels.${label}` })}>
                                    <FormattedMessage id={`labels.${label}`}>
                                        {text => {
                                            return <Typography variant="subtitle2">{`${text}`}</Typography>;
                                        }}
                                    </FormattedMessage>
                                </dt>
                                <dd>
                                    <DatePicker
                                        data-testid="order-editable-RDD"
                                        renderCustomHeader={({
                                            date,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                        }) => (
                                            <div style={{ maxWidth: '385px' }}>
                                                <WarningBox
                                                    messageKey="warning.movingDateMessage"
                                                    titleKey="warning.title"
                                                />
                                                <WarningBox
                                                    messageKey="warning.orderOnStock"
                                                    titleKey="warning.reminder"
                                                />
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <button
                                                        aria-label="Previous Month"
                                                        className={
                                                            'react-datepicker__navigation react-datepicker__navigation--previous'
                                                        }
                                                        onClick={decreaseMonth}
                                                        disabled={prevMonthButtonDisabled}
                                                    >
                                                        <span
                                                            className={
                                                                'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
                                                            }
                                                        />
                                                    </button>
                                                    <span className="react-datepicker__current-month">
                                                        {date.toLocaleString('en-US', {
                                                            month: 'long',
                                                            year: 'numeric',
                                                        })}
                                                    </span>
                                                    <button
                                                        aria-label="Next Month"
                                                        className={
                                                            'react-datepicker__navigation react-datepicker__navigation--next'
                                                        }
                                                        onClick={increaseMonth}
                                                    >
                                                        <span
                                                            className={
                                                                'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'
                                                            }
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        dateFormat="dd/MM/yyyy"
                                        name="deliveryDate"
                                        selected={tmpRequestedDeliveryDate.toDate()}
                                        onChange={(e: Date) => {
                                            store!.orders.selected!.updateRequestedDeliveryDate(e);
                                        }}
                                        filterDate={isWeekday}
                                        className={classes.datePicker}
                                        popperClassName={classes.datePickerCalendarActive}
                                        locale={language}
                                    />
                                </dd>
                            </React.Fragment>
                        );
                    }

                    return (
                        <React.Fragment key={label}>
                            <dt title={intl.formatMessage({ id: `labels.${label}` })}>
                                <Typography color={palette.primary.main}>
                                    <FormattedMessage id={`labels.${label}`} />:
                                </Typography>
                            </dt>
                            <dd>
                                {typeof value === 'string' ? (
                                    <Typography>{value}</Typography>
                                ) : (
                                    <Box padding="4px">{value}</Box>
                                )}
                            </dd>
                        </React.Fragment>
                    );
                });
            };

            return (
                <div className={className}>
                    {translationId && (
                        <>
                            <Typography variant="h6">
                                <FormattedMessage id={translationId} />
                            </Typography>
                            <hr />
                        </>
                    )}

                    {isEmpty(data) ? (
                        <Typography>
                            <FormattedMessage id="definition_box.noInformation" />
                        </Typography>
                    ) : (
                        <dl className={classes.dl}>
                            {data && labels ? (
                                renderDefinitionListItem()
                            ) : (
                                <div>
                                    <FormattedMessage id="definition_box.loading" />
                                </div>
                            )}
                        </dl>
                    )}
                </div>
            );
        },
    ),
);

function styles({ palette, typography }: Theme) {
    return createStyles({
        dl: {
            display: 'grid',
            gridTemplateColumns: 'max-content auto',
            alignItems: 'center',
            color: palette.secondary.light,

            '& > dt': {
                gridColumnStart: '1',

                '& > h6::after': {
                    content: '":"',
                },
            },
            '& > dd': {
                gridColumnStart: '2',
            },
        },
        datePicker: {
            fontSize: typography.fontSize,
            fontFamily: typography.fontFamily,
            border: `solid 1px ${palette.primary.main}`,
            borderRadius: '4px',
            padding: '0.3em 0',
            width: '110px',
            textAlign: 'center',
        },
        datePickerCalendarActive: {
            '& .react-datepicker__triangle': {
                borderBottomColor: `${palette.secondary.main} !important`,
            },
            '& .react-datepicker__month': {
                opacity: 1,
            },
            '& .react-datepicker__header--custom': {
                paddingTop: '0px',
            },
            '& .react-datepicker__navigation': {
                top: 'auto !important',
                marginTop: '10px',
            },
            '& .react-datepicker__current-month': {
                marginTop: '5px',
            },
            '& .react-datepicker__navigation-icon--previous': {
                content: '<',
            },
            '& .react-datepicker__navigation--next': {
                content: '>',
            },
        },
    });
}

export default withStyles(styles)(DefinitionsBox);
