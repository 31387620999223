import { FormControl, Input, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export interface TextFilterProps {
    labelTextId: string;
    text: string;
    onChange: (value: string) => void;
}

const TextFilter = ({ onChange, text, labelTextId }: TextFilterProps) => {
    const theme = useTheme();
    return (
        <FormControl
            sx={{
                margin: theme.spacing(1),
                minWidth: 250,
                maxWidth: 300,
                flex: 1,
            }}
        >
            <InputLabel htmlFor="text-filter" sx={{ left: '-15px', top: '5px' }}>
                <FormattedMessage id={labelTextId} />
            </InputLabel>
            <Input
                data-testid="text-filter"
                id="text-filter"
                value={text}
                onChange={({ target }) => onChange(target.value)}
                sx={{ minHeight: '42px' }}
            />
        </FormControl>
    );
};

export default TextFilter;
