import { Stack, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'src/components/Link';
import PageLoader from 'src/components/PageLoader';
import { fadeInDownKeyFrames } from 'src/components/styles';
import { AppPageRoutes } from 'src/services/api/models';
import Store from 'src/stores/RootStore';
import ReturnsList from './ReturnsList';

interface ReturnRequestsProps {
    store?: typeof Store.Type;
}

const ReturnRequests = inject('store')(
    observer(({ store }: ReturnRequestsProps) => {
        const intl = useIntl();

        useEffect(() => {
            store!.returns.getReturnRequests();
        }, []);

        return (
            <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                    <Typography variant="h5">
                        <FormattedMessage id="menu.viewReturns" />
                    </Typography>
                    <Typography variant="subtitle2">
                        <FormattedMessage
                            id="viewReturns.contact"
                            values={{
                                linkText: (
                                    <Link href={AppPageRoutes.CONTACT}>
                                        {intl.formatMessage({ id: 'contact.customerService' })}
                                    </Link>
                                ),
                            }}
                        />
                    </Typography>
                </Stack>
                <div style={{ animation: `${fadeInDownKeyFrames} 0.5s ease-out`, transition: 'all .3s ease' }}>
                    {store!.returns.loading ? <PageLoader /> : <ReturnsList />}
                </div>
            </>
        );
    }),
);

export default ReturnRequests;
