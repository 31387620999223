import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import DatePicker from 'src/components/DatePicker';
import TableFiltersBar from 'src/components/TableFiltersBar';
import MultipleSelectCheckmarks from 'src/components/MultipleSelectCheckmarks';
import QueryParams from 'src/services/QueryParamsService';
import { CreditingStatusKeys, ShippingStatusKeys } from './helper';

interface ReturnFiltersProps {
    warrantyFilter: string[];
    setWarrantyFilter: React.Dispatch<React.SetStateAction<string[]>>;
    shippingFilter: string[];
    setShippingFilter: React.Dispatch<React.SetStateAction<string[]>>;
    dateFrom: string | null;
    setDateFrom: React.Dispatch<React.SetStateAction<string | null>>;
    dateTo: string | null;
    setDateTo: React.Dispatch<React.SetStateAction<string | null>>;
    searchText: string;
    setSearchText: (text: string) => void;
}

const ReturnFilters = ({
    warrantyFilter,
    setWarrantyFilter,
    shippingFilter,
    setShippingFilter,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    searchText,
    setSearchText,
}: ReturnFiltersProps) => {
    const { palette } = useTheme();
    const intl = useIntl();
    const location = useLocation();
    const inputSearchRef = useRef<HTMLInputElement>(null);

    const [onlyInProgress, setOnlyInProgress] = useState(false);
    const [openFilters, setOpenFilters] = useState(true); // Open filters by default
    const [openSearch, setOpenSearch] = useState(false);

    // Current filters values from url
    const queries = useMemo(
        () =>
            QueryParams.getQueryParams(
                [
                    'warrantyStatus',
                    'shippingStatus',
                    'dateFrom',
                    'dateTo',
                    'onlyInProgress',
                    'returnSearch',
                ],
                location.search,
            ),
        [location.search],
    );

    const valueDateFrom = useMemo(() => (dateFrom ? new Date(dateFrom) : null), [dateFrom]);
    const valueDateTo = useMemo(() => (dateTo ? new Date(dateTo) : null), [dateTo]);
    const valuesWarrantyFilter = useMemo(
        () => (onlyInProgress ? [] : warrantyFilter),
        [onlyInProgress, warrantyFilter],
    );

    const handleChangeSearch = (newValue: string) => {
        setSearchText(newValue);
        QueryParams.setQueryParams({ returnSearch: newValue });
    };

    const resetFilters = () => {
        setWarrantyFilter([]);
        setShippingFilter([]);
        setDateFrom(null);
        setDateTo(null);
        setOnlyInProgress(false);

        QueryParams.setQueryParams({
            warrantyStatus: '',
            shippingStatus: '',
            dateFrom: '',
            dateTo: '',
            onlyInProgress: '',
        });
    };

    useEffect(() => {
        // Set filters from url to filters on the first mount
        if (queries?.onlyInProgress === 'true') {
            setOnlyInProgress(true);
            // Definition in progress:
            // Warranty status either Pending, More info needed or waiting for inspection.
            setWarrantyFilter(['0', '1', '3']);
            setOpenFilters(false); // Close filters if in progress is on
        } else if (queries?.returnSearch) {
            if (typeof queries.returnSearch === 'string') {
                setOpenSearch(true);
                setSearchText(queries.returnSearch);
                setOpenFilters(false); // Close filters if search is on
            }
        } else {
            if (queries?.warrantyStatus) {
                Array.isArray(queries.warrantyStatus)
                    ? setWarrantyFilter(queries.warrantyStatus)
                    : setWarrantyFilter([queries.warrantyStatus]);
            }
            if (queries?.shippingStatus) {
                Array.isArray(queries.shippingStatus)
                    ? setShippingFilter(queries.shippingStatus)
                    : setShippingFilter([queries.shippingStatus]);
            }
            if (queries?.dateFrom) {
                if (typeof queries.dateFrom === 'string') {
                    setDateFrom(queries.dateFrom);
                }
            }
            if (queries?.dateTo) {
                if (typeof queries.dateTo === 'string') {
                    setDateTo(queries.dateTo);
                }
            }
        }
    }, []);

    useEffect(() => {
        // Open filters if there is any prefilled values
        if (
            (warrantyFilter.length || shippingFilter.length || dateFrom || dateTo) &&
            !onlyInProgress
        ) {
            setOpenFilters(true);
        }
    }, [warrantyFilter.length, shippingFilter.length, dateFrom, dateTo, onlyInProgress]);

    const handleToggleFilters = () => {
        handleChangeSearch('');
        setOpenSearch(false);
        resetFilters();
        setOpenFilters(!openFilters);
    };

    const handleChangeWarrantyStatus = (value: string[]) => {
        setWarrantyFilter(value);
        QueryParams.setQueryParams({ warrantyStatus: value });
    };

    const handleChangeShippingStatus = (value: string[]) => {
        setShippingFilter(value);
        QueryParams.setQueryParams({ shippingStatus: value });
    };

    const handleChangeDateFrom = (newDate: Date) => {
        setDateFrom(newDate.toISOString());
        QueryParams.setQueryParams({ dateFrom: newDate.toISOString() });
    };

    const handleChangeDateTo = (newDate: Date) => {
        setDateTo(newDate.toISOString());
        QueryParams.setQueryParams({ dateTo: newDate.toISOString() });
    };

    const handleChangeInProgressToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Reset all other filters
        resetFilters();
        setOpenFilters(false);
        handleChangeSearch('');
        setOpenSearch(false);

        setOnlyInProgress(e.target.checked);

        if (e.target.checked) {
            // Definition in progress:
            // Warranty status either Pending, More info needed or waiting for inspection.
            setWarrantyFilter(['0', '1', '3']);
            QueryParams.setQueryParams({ onlyInProgress: 'true' });
        } else {
            setWarrantyFilter([]);
            QueryParams.setQueryParams({ onlyInProgress: '' });
        }
    };

    const handleToggleSearch = useCallback(() => {
        resetFilters();
        setOpenFilters(false);
        handleChangeSearch('');

        setOpenSearch(!openSearch);

        // Focus on the input when open search
        if (!openSearch && inputSearchRef.current) {
            inputSearchRef.current.focus();
        }
    }, [openSearch, inputSearchRef.current]);

    return (
        <div>
            <TableFiltersBar
                switcherValue={onlyInProgress}
                onChangeSwitcher={handleChangeInProgressToggle}
                switcherLabel={<FormattedMessage id="returns.filters.inProgress" />}
                openFilters={openFilters}
                onClickFilters={handleToggleFilters}
                openSearch={openSearch}
                onSearchChange={handleChangeSearch}
                inputSearchRef={inputSearchRef}
                onClickSearchIcon={handleToggleSearch}
                searchValue={searchText}
            />

            <Box
                sx={{
                    transition: 'visibility .2s, height .5s ease',
                    height: openFilters ? 'auto' : 0,
                    visibility: openFilters ? 'visible' : 'hidden',
                    backgroundColor: palette.primary.light,
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    padding: '0 1rem',
                }}
            >
                <Typography paddingRight="8px">
                    <FormattedMessage id="return.filters" />
                </Typography>
                <DatePicker
                    key="return-filter-date-from"
                    label={intl.formatMessage({ id: 'return.filters.dateFrom' })}
                    maxDate={valueDateTo ?? new Date()}
                    value={valueDateFrom}
                    onChange={handleChangeDateFrom}
                />
                <DatePicker
                    key="return-filter-date-to"
                    label={intl.formatMessage({ id: 'return.filters.dateTo' })}
                    minDate={valueDateFrom}
                    maxDate={new Date()}
                    value={valueDateTo}
                    onChange={handleChangeDateTo}
                />
                <MultipleSelectCheckmarks
                    key="creditingStatus"
                    id="creditingStatus"
                    label={<FormattedMessage id="column.warranty" />}
                    options={CreditingStatusKeys}
                    useIntlFormatting
                    selectedValues={valuesWarrantyFilter}
                    setSelectedValues={handleChangeWarrantyStatus}
                    hideLabel={!openFilters}
                />
                <MultipleSelectCheckmarks
                    key="shippingStatus"
                    id="shippingStatus"
                    label={<FormattedMessage id="column.shipping" />}
                    options={ShippingStatusKeys}
                    useIntlFormatting
                    selectedValues={shippingFilter}
                    setSelectedValues={handleChangeShippingStatus}
                    hideLabel={!openFilters}
                />

                <Typography
                    color="secondary"
                    sx={{ textDecoration: 'underline', cursor: 'pointer', paddingLeft: '.5rem' }}
                    onClick={() => resetFilters()}
                >
                    <FormattedMessage id="returns.filters.reset" />
                </Typography>
            </Box>
        </div>
    );
};

export default ReturnFilters;
