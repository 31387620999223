import { inject, observer } from 'mobx-react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AppPageRoutes } from 'src/services/api/models';
import PersistentStore from 'src/stores/PersistentStore';
import Store from 'src/stores/RootStore';
import { TReturnRequest } from 'src/stores/types/ReturnRequestModel';
import {
    createTableValues,
    CustomPaginationFooter,
    defaultSort,
    TableCell,
    TableColumnTypes,
} from 'src/utils/tableColumnUtils';
import UserStore from 'src/stores/UserStore';
import ProductImage from 'src/components/ProductImage';
import { ProductCategoryTypes } from 'src/utils/constants';

interface ShipItemsTableProps {
    store?: typeof Store.Type;
    persistentStore?: typeof PersistentStore.Type;
    user?: typeof UserStore.Type;
}

const ShipItemsTable = inject(
    'store',
    'persistentStore',
    'user',
)(
    observer(({ store, persistentStore, user }: ShipItemsTableProps) => {
        const navigate = useNavigate();
        const intl = useIntl();
        const { readyToShipItems } = store?.returns;

        const buildShipItemsList = () => {
            const returnItemsData = readyToShipItems.map((returnedItem: TReturnRequest, index: number): TableCell[] => [
                {
                    id: 'imageCDN',
                    value: `${returnedItem.productCode}:${ProductCategoryTypes.PART}`,
                    type: TableColumnTypes.custom,
                    customRender: value => {
                        const arrIdentifiers = value.split(':');
                        return (
                            <div style={{ maxWidth: '100px' }}>
                                <ProductImage id={arrIdentifiers[0]} type={arrIdentifiers[1]} />
                            </div>
                        );
                    },
                },
                { id: 'id', value: returnedItem.id, display: 'false' },
                { id: 'caseNumber', value: `${returnedItem.name}/${returnedItem.code}` }, // (should be unique per row)
                { id: 'product', value: returnedItem.product },
                { id: 'serialNumber', value: returnedItem.serialNumber },
                { id: 'frameNumber', value: returnedItem.frameNumber },
                { id: 'reference', value: returnedItem.reference },
                { id: 'value', value: returnedItem.unitPrice },
            ]);
            return createTableValues(returnItemsData);
        };

        const table = buildShipItemsList();
        const data = table.data;
        const columns = table.columns;

        const options: MUIDataTableOptions = {
            // filterType: 'multiselect',
            selectableRows: 'none',
            responsive: 'vertical',
            pagination: true,
            filter: false,
            search: false,
            download: false,
            print: false,
            viewColumns: false,
            elevation: 0,
            sort: false,
            page: persistentStore!.shipItemsTable.tablePage,
            onChangePage: persistentStore!.shipItemsTable.setTablePage,
            rowsPerPage: persistentStore!.shipItemsTable.tableRowsPerPage,
            onChangeRowsPerPage: persistentStore!.shipItemsTable.setTableRowsPerPage,
            onCellClick: (colData, cellMeta) => {
                columns.forEach((column, index) => {
                    if (column.name === 'id') {
                        navigate(
                            AppPageRoutes.RETURN_REQUESTS_ITEM_DETAILS.replace(
                                ':itemId',
                                data[cellMeta.dataIndex][index],
                            ),
                        );
                    }
                });
            },
            customSort: defaultSort,
            customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
                return (
                    <CustomPaginationFooter
                        rowCount={count}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        changeRowsPerPage={changeRowsPerPage}
                        changePage={changePage}
                    />
                );
            },
            textLabels: {
                body: { noMatch: intl.formatMessage({ id: 'table.textLabels.noMatch' }) },
            },
        };
        return <MUIDataTable title={''} data={data} columns={columns} options={options} />;
    }),
);

export default ShipItemsTable;
