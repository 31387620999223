import { faExclamationTriangle, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface WarningBoxProps extends WithStyles<typeof styles> {
    messageKey: string;
    titleKey: string;
    closeBundleWarning?(): void;
}

const WarningBox = ({ classes, titleKey, messageKey, closeBundleWarning }: WarningBoxProps) => {
    return (
        <div data-testid="warning-box" className={classes.box}>
            <FontAwesomeIcon className={classes.alertIcon} icon={faExclamationTriangle} size="lg" />
            <div className={classes.textContainer}>
                <Typography variant={'body2'} className={classes.message}>
                    <FormattedMessage id={titleKey} />
                </Typography>
                <Typography variant={'body2'} className={classes.message}>
                    <FormattedMessage id={messageKey} />
                </Typography>
                {closeBundleWarning && (
                    <Button className={classes.closeButton} onClick={closeBundleWarning} data-testid="warning-close">
                        <FontAwesomeIcon icon={faTimes} size="lg" />
                    </Button>
                )}
            </div>
        </div>
    );
};

function styles({ palette }: Theme) {
    return createStyles({
        box: {
            backgroundColor: palette.secondary.main,
            color: 'white',
            padding: '5px',
            paddingRight: '10px',
            maxHeight: '100px',
            position: 'relative',
            lineHeight: '1.25em',
        },
        closeButton: {
            position: 'absolute',
            top: '3px',
            right: '3px',
            color: 'white',
        },
        alertIcon: {
            position: 'absolute',
            top: '35%',
            left: '25px',
        },
        textContainer: {
            marginLeft: '60px',
            marginRight: '60px',
        },
        message: {
            color: 'white',
        },
    });
}

export default withStyles(styles)(inject('store')(observer(WarningBox)));
