import { Card, CardMedia, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { values } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'src/components/loading/Loading';
import FileUploadCode from 'src/pages/modules/returns/fileUpload/FileUploadCode';
import { AppPageRoutes } from 'src/services/api/models';
import Store from 'src/stores/RootStore';
import { TReturnRequest } from 'src/stores/types/ReturnRequestModel';

interface ReturnRequestSelectItemPageProps extends WithStyles<typeof styles> {
    caseNumber?: string;
    store?: typeof Store.Type;
}

// TODO: this page will be remove soon
const ReturnRequestSelectItemPage = inject('store')(
    observer(({ classes, store }: ReturnRequestSelectItemPageProps) => {
        const intl = useIntl();
        const navigate = useNavigate();
        const { caseNumber } = useParams();

        const [status, setStatus] = useState<'initialized' | 'started' | 'finished' | 'error'>('initialized');
        const [loadingError, setLoadingError] = useState<string | undefined>(undefined);
        const [selectedRequests, setSelectedRequests] = useState<TReturnRequest[]>([]);

        const isLoading: boolean = store?.returns.loading;

        useEffect(() => {
            store!.returns.getReturnRequests();
            setStatus('started');
        }, [store]);

        useEffect(() => {
            if (status === 'started' && !isLoading) {
                const allReturnRequests = values(store?.returns.returnsList) as TReturnRequest[];
                const filteredReturnRequests = allReturnRequests.filter(
                    returnRequest => returnRequest.caseNumber === caseNumber,
                );

                if (filteredReturnRequests.length === 0) {
                    setLoadingError(intl.formatMessage({ id: 'returnRequest.details.notFound' }));
                    setStatus('error');
                } else {
                    if (filteredReturnRequests.length === 1) {
                        // Only one item, automatically redirect to file upload page
                        navigate(
                            AppPageRoutes.RETURN_REQUESTS_ITEM_DETAILS.replace(
                                ':itemId',
                                filteredReturnRequests[0].id,
                            ),
                        );
                    } else {
                        setSelectedRequests(filteredReturnRequests);
                    }

                    setStatus('finished');
                }
            }
        }, [navigate, caseNumber, intl, isLoading, status, store]);

        const renderLoading = () => (
            <div className={classes.loading}>
                <Typography variant="body2" align="center">
                    <FormattedMessage id="loading.pleasewait" />
                </Typography>

                <div className={classes.loadingAnimation}>
                    <Loading />
                </div>
            </div>
        );

        const redirectToFileUploadPage = (id: string) => {
            navigate(AppPageRoutes.RETURN_REQUESTS_ITEM_DETAILS.replace(':itemId', id));
        };

        const renderRequestList = () => (
            <div>
                <ul className={classes.list}>
                    {selectedRequests.map(selectedRequest => (
                        <li key={selectedRequest.code}>
                            <Card
                                className={classes.requestCard}
                                onClick={() => redirectToFileUploadPage(selectedRequest.id)}
                            >
                                <CardMedia className={classes.requestCardQR}>
                                    <FileUploadCode type="item" variant="compact" id={selectedRequest.id} />
                                </CardMedia>

                                <div>
                                    <Typography className={classes.header} variant="body2" gutterBottom>
                                        {selectedRequest.code}
                                    </Typography>
                                    <Typography variant="body2">{selectedRequest.product}</Typography>
                                    <Typography variant="body2">{selectedRequest.productCode}</Typography>
                                </div>
                            </Card>
                        </li>
                    ))}
                </ul>
            </div>
        );

        const renderError = () => (
            <div className={classes.error}>
                <Typography variant="body2" align="center">
                    {loadingError}
                </Typography>
            </div>
        );

        return (
            <div className={classes.root}>
                <Typography variant="h5" gutterBottom>
                    <FormattedMessage id="additionalFiles.selectPageTitle" />
                </Typography>

                {status === 'started' && renderLoading()}
                {status === 'finished' && renderRequestList()}
                {status === 'error' && renderError()}
            </div>
        );
    }),
);

function styles({ typography }: Theme) {
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        loading: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '40px',
        },
        loadingAnimation: {
            transform: 'scale(.5)',
            marginTop: '-40px',
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            margin: '0 -24px',
            padding: 0,

            '& li': {
                listStyle: 'none',
                marginBottom: '1rem',
                cursor: 'pointer',

                '& > div:first-child': {
                    padding: '1rem 24px',
                    transition: 'background-color 250ms',
                },

                '&:hover > div:first-child': {
                    backgroundColor: '#E9E9E9',
                },
            },
        },
        requestCard: {
            display: 'flex',
            alignItems: 'center',
            padding: '1rem',
            boxShadow: 'inherit',
        },
        requestCardQR: {
            width: '100px',
            marginRight: '1rem',
        },
        header: {
            fontWeight: typography.fontWeightBold,
        },
        error: {
            display: 'flex',
            marginTop: '1rem',
        },
    });
}

export default withStyles(styles)(ReturnRequestSelectItemPage);
