import React, { PropsWithChildren } from 'react';

interface WhenProps {
    condition: boolean | (() => boolean) | undefined;
}

const When = ({ condition, children }: PropsWithChildren<WhenProps>) => {
    const isConditionValid = (typeof condition === 'function') ? condition() : !!(condition);

    if (isConditionValid) {
        return (
            <>
                {children}
            </>
        );
    }

    return null;
};

export default When;
