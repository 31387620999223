import { AppPageRoutes } from 'src/services/api/models';
import { StepItemProps } from './StepItem';

enum ImagesValues {
    Step1 = 'https://res.cloudinary.com/dmsstxj50/image/upload/s--2VL_YNpz--/c_scale,h_600/v1699261831/centraal/media/making_a_new_return.jpg',
    Step2 = 'https://res.cloudinary.com/dmsstxj50/image/upload/s--t4sO36c6--/c_scale,h_600/v1695208869/centraal/media/qwic_kb_image.jpg',
    Step3 = 'https://res.cloudinary.com/dmsstxj50/image/upload/s--nGYQ2MZc--/c_scale,h_600/v1699261874/centraal/media/return_request_submitted.jpg',
    Step4 = 'https://res.cloudinary.com/dmsstxj50/image/upload/s--jPbkzV5d--/c_scale,h_600/v1699261896/centraal/media/stap_4-_view_return_status.jpg',
    Step5 = 'https://res.cloudinary.com/dmsstxj50/image/upload/s--mYelzLeR--/c_scale,h_600/v1699261885/centraal/media/ship_return_request.jpg',
    Step6 = 'https://res.cloudinary.com/dmsstxj50/image/upload/s--432mYtLc--/c_scale,h_600/v1699261859/centraal/media/other.jpg',
}

export const StepsValues: StepItemProps[] = [
    {
        id: 'step1',
        title: 'returns_process_info_step1_title',
        descriptions: [
            { label: 'returns_process_info_step1_description1' },
            { label: 'returns_process_info_step1_description2' },
        ],
        image: ImagesValues.Step1,
        position: 0,
    },
    {
        id: 'step2',
        title: 'returns_process_info_step2_title',
        descriptions: [
            { label: 'returns_process_info_step2_description1' },
            { label: 'returns_process_info_step2_description2' },
        ],
        image: ImagesValues.Step2,
        position: 1,
    },
    {
        id: 'step3',
        title: 'returns_process_info_step3_title',
        descriptions: [
            { label: 'returns_process_info_step3_description1' },
            { label: 'returns_process_info_step3_description2' },
        ],
        image: ImagesValues.Step3,
        position: 0,
    },
    {
        id: 'step4',
        title: 'returns_process_info_step4_title',
        descriptions: [
            { label: 'returns_process_info_step4_description1' },
            { label: 'returns_process_info_step4_description2' },
        ],
        image: ImagesValues.Step4,
        position: 1,
    },
    {
        id: 'step5',
        title: 'returns_process_info_step5_title',
        descriptions: [{ label: 'returns_process_info_step5_description1' }],
        image: ImagesValues.Step5,
        position: 0,
    },
    {
        id: 'step6',
        title: 'returns_process_info_step6_title',
        descriptions: [
            { label: 'returns_process_info_step6_description1' },
            {
                label: 'returns_process_info_step6_description2',
                link: { label: 'contact.page.label', value: 'contact', url: AppPageRoutes.CONTACT },
            },
        ],
        image: ImagesValues.Step6,
        position: 1,
    },
];
