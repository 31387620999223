import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedDate, FormattedMessage } from 'react-intl';

interface EarliestShippingDateProps {
    earliestShippingDate: string;
    today: dayjs.Dayjs;
    totalBikes: number;
}

const EarliestShippingDate = ({ earliestShippingDate, today, totalBikes }: EarliestShippingDateProps) => {
    return ( earliestShippingDate && totalBikes < 4 ? (
        <Typography color="secondary" variant="body1">
            {dayjs(earliestShippingDate).isBefore(today) ? (
                <span>
                    <FormattedMessage id="cart.availableNow" />
                </span>
            ) : (
                <>
                    <span>
                        <FormattedMessage id="cart.calculatedDeliveryDate" />
                    </span>
                    {/* adds one whitespace between text and date */}{' '}
                    <span>
                        <FormattedDate year="numeric" month="short" day="2-digit" value={earliestShippingDate} />
                    </span>
                </>
            )}
        </Typography>
    ) : (
        <Typography color="textSecondary" variant="body1">
            <span>
                <FormattedMessage id="cart.noShippingDateAvailable" />
            </span>
        </Typography>
    ));
};

export default EarliestShippingDate;
