import WarningIcon from '@mui/icons-material/Warning';
import { Button, Card, Grid, Theme, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React, { MouseEvent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import ProductImage from 'src/components/ProductImage';
import FileUploadCode from 'src/pages/modules/returns/fileUpload/FileUploadCode';
import { CreatedCase } from 'src/stores/CartReturnsStore';
import Store from 'src/stores/RootStore';
import { ProductCategoryTypes } from 'src/utils/constants';
import ConfirmationIcon from '../../../assets/confirmation-icon.svg';

interface ThankReturnsYouPageProps extends WithStyles<typeof styles> {
    store?: typeof Store.Type;
    theme: Theme;
}

const ThankReturnsYouPage = observer(
    inject('store')(({ store, classes, theme: { palette } }: ThankReturnsYouPageProps) => {
        const navigate = useNavigate();

        const cases = store?.cartReturns.createdCases;

        const renderHeader = useCallback(
            () => (
                <>
                    <Typography className={classes.header} variant="h4" color="secondary" align="center" gutterBottom>
                        <FormattedMessage id="returns.thank_you_title" />
                    </Typography>

                    <Typography className={classes.description} variant="body2" align="center" gutterBottom>
                        <FormattedMessage id="returns.thank_you_message" />
                    </Typography>
                </>
            ),
            [classes],
        );

        const renderCases = () => (
            <>
                <div className={classes.instructions}>
                    <WarningIcon className={classes.instructionsIcon} />
                    <Typography className={classes.instructionsMessage} variant="body2" align="left">
                        <strong>
                            <FormattedMessage id="returnsThankYouPage.important" />
                        </strong>
                        <FormattedMessage id="returnsThankYouPage.instructions" />
                    </Typography>
                </div>

                {cases.map((createdCase: typeof CreatedCase.Type, index: number) => (
                    <Card elevation={0} key={createdCase.caseNumber} className={classes.boxContainer}>
                        <div
                            data-testid={`thank-you-return-category-${createdCase.returnCategory}`}
                            className={classes.boxInfo}
                        >
                            <div className={classes.boxInfoHeader}>
                                <FileUploadCode
                                    className={classes.qr}
                                    variant="compact"
                                    type="case"
                                    withLink={true}
                                    id={createdCase.caseNumber}
                                    backgroundColor={palette.primary.light}
                                />

                                <div className={classes.boxInfoHeaderRight}>
                                    <div className={classes.boxInfoHeaderTop}>
                                        <Typography color="secondary" variant="body1">
                                            <FormattedMessage id="returnsThankYouPage.box" />
                                            {index + 1}
                                        </Typography>
                                        <Typography variant="body1">
                                            <FormattedMessage id="returnsThankYouPage.type" />
                                            <strong>{createdCase.returnCategory}</strong>
                                        </Typography>
                                    </div>

                                    <Typography className={classes.caseNumber} variant="body1">
                                        <FormattedMessage id="returnsThankYouPage.caseNumber" />
                                        <strong>{createdCase.caseNumber}</strong>
                                    </Typography>

                                    <Typography className={classes.boxInfoMessage} variant="body1">
                                        <FormattedMessage id="returnsThankYouPage.shouldContains" />
                                    </Typography>
                                </div>
                            </div>

                            <div className={classes.boxItemContainer}>
                                {createdCase.items.map(product => {
                                    if (!product.productCode) {
                                        return null;
                                    }

                                    return (
                                        <div
                                            data-testid={`thank-you-return-${product.productCode}`}
                                            key={`${createdCase.caseNumber}_${product.productCode}`}
                                            className={classes.boxItem}
                                        >
                                            <div className={classes.boxItemImage}>
                                                <ProductImage
                                                    id={product.productCode}
                                                    type={
                                                        product.productType === 'Accu'
                                                            ? ProductCategoryTypes.BATTERY
                                                            : ProductCategoryTypes.PART
                                                    }
                                                />
                                            </div>

                                            <div className={classes.boxItemInfo}>
                                                <Typography variant="body1">
                                                    <FormattedMessage id="returnsThankYouPage.productCode" />
                                                    <strong>{product.productCode}</strong>
                                                </Typography>
                                                <Typography variant="body1">{product.productName}</Typography>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Card>
                ))}
            </>
        );

        const handleCloseButtonClick = (event: MouseEvent) => {
            event.preventDefault();
            store!.spareParts.searchResult.clearResult();
            navigate('/return-requests');
        };

        return (
            <>
                <CssBaseline />
                <Grid className={classes.container} container spacing={0}>
                    <Grid item xs={12} sm={2}>
                        <img className={classes.icon} src={ConfirmationIcon} alt="" />
                    </Grid>

                    <Grid className={classes.content} item xs={12} sm={10}>
                        {renderHeader()}

                        {!!cases.length && renderCases()}

                        <Button
                            data-testid="thank-you-return-close"
                            className={classes.button}
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={handleCloseButtonClick}
                        >
                            <FormattedMessage id="returns.thank_you_cta" />
                        </Button>
                    </Grid>
                </Grid>
            </>
        );
    }),
);

function styles({ palette }: Theme) {
    return createStyles({
        '@keyframes fadeIn': {
            from: {
                opacity: 0,
            },
            to: {
                opacity: 1,
            },
        },
        container: {
            minHeight: '80vh',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
        },
        icon: {
            marginBottom: '1rem',
        },
        header: {
            fontSize: '2.5rem',
            color: '#fdaa5b',
        },
        description: {
            width: '25ch',
            alignSelf: 'center',
        },
        boxContainer: {
            display: 'flex',
            padding: '.5rem',
            margin: '.5rem 0',
            animationDuration: '1s',
            animationFillMode: 'both',
            animationName: '$fadeIn',
            transition: 'background-color .3s ease-out',
            backgroundColor: '#F5F5F5',
            borderRadius: '8px',
            border: `1px solid ${palette.primary.light}`,

            '&:hover': {
                backgroundColor: palette.primary.light,
            },

            '& strong': {
                color: palette.secondary.main,
                marginLeft: '.5rem',
            },
        },
        boxImage: {
            fontSize: '4rem',
            marginRight: '1rem',
        },
        boxInfo: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            textAlign: 'left',
            padding: '1rem',
        },
        boxInfoHeader: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        boxInfoHeaderRight: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        boxInfoHeaderTop: {
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
        },
        boxInfoMessage: {
            margin: '.5rem 0',
        },
        boxItemContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem',
        },
        boxItem: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        boxItemImage: {
            maxWidth: '100px',
            display: 'flex',
            borderRadius: '4px',
            marginRight: '1rem',
            border: `1px solid ${palette.primary.light}`,
        },
        boxItemInfo: {
            flex: 1,
        },
        instructions: {
            display: 'flex',
            alignItems: 'center',
            margin: '2rem auto',
        },
        instructionsIcon: {
            color: '#fdaa5b',
            width: 28,
            height: 24,
            marginRight: '.5rem',
        },
        instructionsMessage: {
            lineHeight: '1rem',

            '& strong ': {
                display: 'block',
                textTransform: 'uppercase',
            },
        },
        caseNumber: {
            fontSize: '1.2rem',
        },
        button: {
            marginTop: '5rem',
            alignSelf: 'flex-end',
            backgroundColor: '#fdaa5b',
            color: palette.common.white,
            textTransform: 'none',

            '&:hover': {
                backgroundColor: palette.secondary.main,
            },
        },
        qr: {
            marginRight: '1rem',
        },
    });
}

export default withStyles(styles, { withTheme: true })(ThankReturnsYouPage);
