import {
    Button as MUIButton,
    ButtonProps as MUIButtonProps,
    IconButton,
    IconButtonProps,
    Tooltip,
    TooltipProps,
    Typography,
    CircularProgress,
} from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import BackButton from './BackButton';
import { ButtonCustomVariant, TButtonCustomVariant } from './type';

interface CustomButtonProps {
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    labelTextId?: string;
    customVariant?: TButtonCustomVariant;
    tooltipTitle?: TooltipProps['title'];
    hideButton?: boolean;
    isLoading?: boolean;
}

export type ButtonProps = MUIButtonProps & CustomButtonProps & IconButtonProps;

export const Button: FC<ButtonProps> = ({
    classes,
    startIcon,
    endIcon,
    labelTextId,
    customVariant,
    tooltipTitle = '',
    hideButton = false,
    isLoading = false,
    children,
    ...props
}) => {
    // TODO: remake this with createTheme after migation to MUI v5
    if (customVariant && customVariant === ButtonCustomVariant.BACK_BUTTON) {
        return hideButton ? null : <BackButton classes={classes} {...props} />;
    }

    if (customVariant && customVariant === ButtonCustomVariant.ICON_BUTTON && children) {
        return (
            <Tooltip title={tooltipTitle}>
                <IconButton {...props} data-testid="icon-button" size="large">
                    {children}
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <MUIButton {...props}>
            {isLoading ? (
                <CircularProgress size={20} color="primary" />
            ) : (
                <>
                    {startIcon && startIcon}
                    {labelTextId && (
                        <Typography style={{ textAlign: 'center', margin: '0.5rem' }} variant="body1">
                            <FormattedMessage id={labelTextId} />
                        </Typography>
                    )}
                    {children && children}
                    {endIcon && endIcon}
                </>
            )}
        </MUIButton>
    );
};

export default Button;
