import { Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import IOSSwitch from 'src/components/IOSSwitch';

const useStyles = makeStyles({
    profileSection: {
        marginBottom: '10px',
        flex: 1,
    },
    switch: {
        marginLeft: '0.5rem',
    },
});

interface UserSettingsProps {
    hideConsumerSwitcher?: boolean;
    showConsumerPrices?: boolean;
    onChangeShowConsumerPrices: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const UserSettings = ({
    hideConsumerSwitcher = false,
    showConsumerPrices = false,
    onChangeShowConsumerPrices,
}: UserSettingsProps) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.profileSection}>
            <Typography gutterBottom variant="h6">
                <FormattedMessage id="profile.user_settings_title" />
            </Typography>

            <LanguageSwitcher />

            {!hideConsumerSwitcher && (
                <Stack direction="row" alignItems="center">
                    <Typography variant="body1" data-testid="show-consumer-by-default">
                        <FormattedMessage id="profile.show_consumer_mode_by_default" />
                    </Typography>
                    <IOSSwitch
                        className={classes.switch}
                        checked={showConsumerPrices}
                        onChange={onChangeShowConsumerPrices}
                    />
                </Stack>
            )}
        </Grid>
    );
};
