import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import React from 'react';
import ProductImage from 'src/components/ProductImage';
import Store from 'src/stores/RootStore';
import { TProductModel } from 'src/stores/types/ProductTypes';
import UserStore from 'src/stores/UserStore';
import { createTableValues, TableCell, TableColumnTypes } from 'src/utils/tableColumnUtils';

interface MarketingMaterialsTableProps extends WithStyles<typeof styles> {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
    items: TProductModel[];
}

const MarketingMaterialsTable = inject(
    'store',
    'user',
)(
    observer(({ store, user, classes, items }: MarketingMaterialsTableProps) => {
        const buildList = () => {
            const productsData = items.map((item): TableCell[] => {
                return [
                    {
                        id: 'imageCDN',
                        value: `${item.id}:${item.type}`,
                        type: TableColumnTypes.custom,
                        customRender: value => {
                            const arrIdentifiers = value.split(':');
                            return (
                                <div style={{ maxWidth: '100px' }}>
                                    <ProductImage id={arrIdentifiers[0]} type={arrIdentifiers[1]} />
                                </div>
                            );
                        },
                    },
                    {
                        id: 'description',
                        value: `${item.translatedDescription(user!.preferredLang)}`,
                        type: TableColumnTypes.custom,
                        customRender: value => {
                            return value ? value.substring(0, 30) + '...' : value;
                        },
                    },
                    {
                        id: 'itemNumber',
                        value: item.id,
                        type: TableColumnTypes.custom,
                        customRender: (value: any) => {
                            return <span data-testid={`product-card-${value}`}>{value}</span>;
                        },
                    },
                    { id: 'price', value: item.price, type: TableColumnTypes.price },
                ];
            });

            return createTableValues(productsData);
        };

        const renderTable = () => {
            const { data, columns } = buildList();

            const options: MUIDataTableOptions = {
                filterType: 'checkbox',
                selectableRows: 'none',
                responsive: 'vertical',
                pagination: false,
                filter: false,
                search: false,
                download: false,
                print: false,
                viewColumns: false,
                elevation: 0,
                sort: true,
                onRowClick: (rowData: any) => {
                    let selectedItemId: string = '';
                    columns.forEach((column, index) => {
                        if (column.name === 'itemNumber') {
                            selectedItemId = rowData[index].props.children;
                        }
                    });

                    store!.marketingMaterials.setSelectedItemId(selectedItemId);
                    store!.marketingMaterials.toggleMarketingMaterialDetailsDialog();
                },
            };

            return <MUIDataTable title="" data={data} columns={columns} options={options} />;
        };

        return (
            <div className={classes.root} data-testid="marketing-materials-table">
                {renderTable()}
            </div>
        );
    }),
);

function styles() {
    return createStyles({
        root: {
            width: '100%',

            '& table > tbody > tr:hover': {
                cursor: 'pointer',
            },
        },
    });
}

export default withStyles(styles)(MarketingMaterialsTable);
