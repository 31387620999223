import { CircularProgress, Theme } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';

interface PageLoaderProps extends WithStyles<typeof styles> {}

const PageLoader = ({ classes }: PageLoaderProps) => {
    return (
        <div className={classes.holder}>
            <CircularProgress color="secondary" size={30} />
        </div>
    );
};

function styles({ spacing }: Theme) {
    return createStyles({
        holder: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flex: 1,
        },
    });
}

export default withStyles(styles)(PageLoader);
