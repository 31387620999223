import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';

interface RemoveItemProps {
    translationId: string;
}

const RemoveItem = observer(({ translationId }: RemoveItemProps) => {
    const intl = useIntl();

    return (
        <Typography data-testid="toaster-success-remove" align={'center'} color="primary">
            <Typography style={{ display: 'inline' }} color="secondary" component="span">
                <FontAwesomeIcon icon={faTimes} />
            </Typography>

            {intl.formatMessage({ id: translationId })}
        </Typography>
    );
});

export default RemoveItem;
