import Fuse from 'fuse.js';
import deburr from 'lodash/deburr';
import sortBy from 'lodash/sortBy';
import { values } from 'mobx';
import { flow, getParent, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';
import { SparePart } from 'src/types/commons/products';
import { ReturnFlowType, SearchType, SparePartCategory } from 'src/utils/constants';
import { logRejectedModel } from 'src/utils/logger';
import RootStore from './RootStore';
import { BikeInServiceModel } from './types/BikeInService';
import { ParentBikeModel } from './types/ParentBike';
import { ProductModel, TProductModel } from './types/ProductTypes';

const BikeAutocompleteModel = types.model('BikeAutocomplete', {
    frameNumber: types.string,
    name: types.string,
});

const SearchedSparePartsModel = types
    .model('SearchedSparePartsModel', {
        parentBike: types.maybeNull(ParentBikeModel),
        spareParts: types.optional(types.array(types.late(() => types.reference(ProductModel))), []),
    })
    .actions(self => ({
        setParentBike(searchResult: any) {
            if (ParentBikeModel.is(searchResult.parentBike)) {
                self.parentBike = searchResult.parentBike;
            }
        },
        clearResult() {
            self.parentBike = null;
            self.spareParts.clear();
        },
        addSparePart(sparePartId: string) {
            self.spareParts.push(sparePartId);
        },
    }));

const SparePartsStore: any = types
    .model('sparePartsStore', {
        searchResult: types.optional(SearchedSparePartsModel, {}),
        list: types.optional(types.map(ProductModel), {}), // bucket to hold spare parts used across the app
        bikesInService: types.optional(types.map(BikeInServiceModel), {}),
        bikesListAutocomplete: types.optional(types.map(BikeAutocompleteModel), {}),
    })
    .volatile(() => ({
        loading: false,
        autocompleteLoading: false,
        selectedSparePartId: '',
        isSparePartDetailDialogOpen: false,
        isSparePartDetailDialogFromCart: false,
        selectedSearchType: SearchType.FRAME_NUMBER,
    }))
    .actions(self => ({
        filterSparePartResults: (searchWord: string, showDeprecated: boolean): TProductModel[] => {
            let parts = values(self.searchResult.spareParts) as TProductModel[];

            if (!parts.length) {
                return [];
            }

            // filter out deprecated
            if (!showDeprecated) {
                parts = parts.filter(part => part.productInformation.deprecated === false);
            }
            const parent: typeof RootStore.Type = getParent(self);

            // TODO: remove this unused
            if (parent.returns.selectedReturnType !== ReturnFlowType.NONE) {
                parts = parts.filter((part: TProductModel) => part.type === parent.returns.selectedReturnType);
            }

            if (searchWord !== '' && searchWord.length > 2) {
                let fuse;
                if (searchWord.match(/^(BA)?[0-9]+/i)) {
                    fuse = new Fuse(parts, {
                        threshold: 0.1,
                        distance: 990,
                        minMatchCharLength: 3,
                        keys: ['id'],
                    });
                } else {
                    fuse = new Fuse(parts, {
                        shouldSort: true,
                        threshold: 0.6,
                        location: 0,
                        distance: 100,
                        minMatchCharLength: 1,
                        keys: ['name', 'model', 'serie', 'size', 'color', 'version'],
                    });
                }

                const inputValue = deburr(searchWord.trim()).toLowerCase();
                return fuse.search(inputValue).map(suggestion => suggestion.item);
            }

            return sortBy(parts, ['name']);
        },

        setSelectedSparePartId: (selectedSparePartId: string) => {
            self.selectedSparePartId = selectedSparePartId;
        },

        toggleSparePartDetailDialog: (isSparePartDetailDialogFromCart: boolean = false) => {
            self.isSparePartDetailDialogOpen = !self.isSparePartDetailDialogOpen;

            // if dialog is open
            if (self.isSparePartDetailDialogOpen) {
                self.isSparePartDetailDialogFromCart = isSparePartDetailDialogFromCart;
                if (!self.isSparePartDetailDialogFromCart) {
                    self.isSparePartDetailDialogOpen = true;
                }
            } else {
                // if dialog is closed
                if (!self.isSparePartDetailDialogFromCart) {
                    self.isSparePartDetailDialogOpen = false;
                }
            }
        },

        getSparePartsBy: flow(function* (bikeIdentifier: string, type: SearchType, isSearchResult: boolean) {
            self.loading = true;
            const rootStore = getParent<typeof RootStore.Type>(self, 1);

            try {
                let response: any;

                switch (type) {
                    case SearchType.FRAME_NUMBER:
                        response = yield Service.getSparePartsByFrameNumber(bikeIdentifier);
                        break;
                    case SearchType.PRODUCT_CODE:
                        response = yield Service.getSparePartsByProductCode(bikeIdentifier);
                        break;
                    case SearchType.SPARE_PART_SEARCH:
                        response = yield Service.getAllSparePartsBySearchTerm(bikeIdentifier);
                        break;
                    default:
                        break;
                }

                const { data, meta } = response;

                if (data) {
                    if (meta?.parentBike && meta?.parentBike.name && meta?.parentBike.productCode) {
                        const parentBike = {
                            id: bikeIdentifier,
                            parentBike: {
                                id: bikeIdentifier,
                                name: meta.parentBike.name,
                                imageUrl: meta.parentBike.imageUrl || '',
                                productCode: meta.parentBike.productCode,
                                frameNumber: meta.parentBike.frameNumber,
                                shipmentDate: meta.parentBike.shipmentDate,
                                warrantyExpiryDate: meta.parentBike.warrantyExpiryDate,
                            },
                            spareParts: [],
                        };

                        if (isSearchResult) {
                            self.searchResult.setParentBike(parentBike);
                        }
                    }

                    data.forEach((sparePart: SparePart) => {
                        if (ProductModel.is(sparePart)) {
                            // load image via cdn
                            rootStore.mediaAssets.loadImage(sparePart.id);
                            sparePart.productInformation.imageUrl = rootStore.mediaAssets.thumbnail(sparePart.id);
                            // set sparePart info
                            sparePart.parentFrameNumber = meta?.parentBike?.frameNumber;
                            sparePart.parentCode = meta?.parentBike?.productCode;
                            if (!sparePart.category) {
                                sparePart.category = SparePartCategory.GENERAL;
                            }
                            self.list.set(sparePart.id, sparePart);
                            if (isSearchResult) {
                                self.searchResult.addSparePart(sparePart.id);
                            }
                        } else {
                            logRejectedModel(ProductModel.name, sparePart);
                        }
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                self.loading = false;
            }
        }),

        getSparePartsByPartId: flow(function* (partId: string) {
            self.loading = true;
            const rootStore = getParent<typeof RootStore.Type>(self, 1);

            try {
                const response: any = yield Service.getSparePartsByPartId(partId);

                if (response?.data) {
                    if (ProductModel.is(response.data)) {
                        // load image via cdn
                        rootStore.mediaAssets.loadImage(response.data.id);
                        response.data.productInformation.imageUrl = rootStore.mediaAssets.thumbnail(response.data.id);
                        if (!response.data.category) {
                            response.data.category = SparePartCategory.GENERAL;
                        }
                        self.list.set(response.data.id, response.data);
                    } else {
                        logRejectedModel(ProductModel.name, response.data);
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                self.loading = false;
            }
        }),

        getBikesInService: flow(function* _getBikesInService() {
            self.loading = true;
            try {
                const response: any = yield Service.getBikesInService();

                if (response && response.status === 200) {
                    const bikes = response.data;
                    bikes.forEach((bike: typeof BikeInServiceModel.Type) => {
                        if (BikeInServiceModel.is(bike)) {
                            self.bikesInService.set(bike.id, bike);
                        } else {
                            logRejectedModel(BikeInServiceModel.name, bike);
                        }
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                self.loading = false;
            }
        }),

        getBikesAutocomplete: flow(function* _getBikesAutocomplete(frameNumber: string) {
            self.autocompleteLoading = true;
            self.bikesListAutocomplete.clear();

            try {
                const response = yield Service.getBikesAutocomplete(frameNumber);
                if (response && response.status === 200) {
                    const bikes = response.data;
                    bikes.forEach((bike: typeof BikeAutocompleteModel.Type) => {
                        if (BikeAutocompleteModel.is(bike)) {
                            self.bikesListAutocomplete.set(bike.frameNumber, bike);
                        } else {
                            logRejectedModel(BikeAutocompleteModel.name, bike);
                        }
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                self.autocompleteLoading = false;
            }
        }),

        setSelectedSearchType(type: SearchType) {
            self.selectedSearchType = type;
        },

        getProductBrand: flow(function* (productCode: string) {
            try {
                const response: any = yield Service.getProductBrandByProductCode(productCode);

                if (response && response.status === 200) {
                    self.list.get(productCode)?.setBrand(response.data);
                }
            } catch (error) {
                console.error(error);
            }
        }),
    }))
    .views(self => ({
        get getSelectedSparePart() {
            return self.list.get(self.selectedSparePartId);
        },
        get bikesInServiceList() {
            const bikes = Array.from(self.bikesInService.values());
            return bikes.length
                ? bikes.map(bike => ({
                      id: bike.id,
                      imageUrl: bike.imageUrl,
                      label: `${bike.model} ${bike.serie} ${bike.version} ${bike.size} ${bike.color}`,
                  }))
                : [];
        },
        get bikesAutocomleteList() {
            const bikes = Array.from(self.bikesListAutocomplete.values());
            return bikes.length
                ? bikes.map(bike => ({
                      id: bike.frameNumber,
                      label: bike.frameNumber,
                      description: bike.name,
                  }))
                : [];
        },
    }))
    .actions(self => ({
        showSparePartDetails: (productCode: string) => {
            self.setSelectedSparePartId(productCode);
            self.toggleSparePartDetailDialog(false);
        },
    }));

export default SparePartsStore;
