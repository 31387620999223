import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Theme } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { FormattedMessage } from 'react-intl';
import { GACategories } from 'src/services/Analytics';
import Service from 'src/services/api/Api';
import { TInvoice } from 'src/stores/types/Invoice';
import AnalyticsEcommerce, { ProductAndPromotionActions } from 'src/utils/analytics-ecommerce';
import { createTableValues, TableCell, TableColumnTypes } from 'src/utils/tableColumnUtils';

interface InvoicePaymentDialogProps extends WithStyles<typeof styles> {
    isOpen: boolean;
    close: () => void;
    selectedInvoicesIds: string[];
    getSelectedInvoices: () => TInvoice[];
    invoices: any;
}

const InvoicePaymentDialog = ({
    classes,
    isOpen,
    close,
    selectedInvoicesIds,
    invoices,
    getSelectedInvoices,
}: InvoicePaymentDialogProps) => {
    useEffect(() => {
        if (isOpen) {
            // TODO: fetch lines only for sellected invoices
            invoices!.forEach((invoice: any) => invoice.getInvoiceLines());
        }
    }, [invoices, isOpen]);

    const payInvoices = async () => {
        ReactGA.event({
            category: GACategories.Payments,
            action: 'User created a payment and is redirected',
        });

        const selectedInvoices: TInvoice[] = getSelectedInvoices();

        // Sending checkout event to GA
        AnalyticsEcommerce.getInstance()
            .clear()
            .with(instance => {
                selectedInvoices.forEach(async selectedInvoice => {
                    if (!selectedInvoice.lines?.size) {
                        // Waiting for getting invoice lines
                        await selectedInvoice.getInvoiceLines();
                    }

                    selectedInvoice.lines?.forEach(line => {
                        instance.addProduct({
                            id: line.id,
                            name: line.description,
                            list: 'Shopping Cart',
                            brand: 'QWIC',
                            price: line.totalAmountInclVAT,
                            quantity: line.quantity,
                        });
                    });
                });
            })
            .setAction(ProductAndPromotionActions.CHECKOUT)
            .sendPageView();

        let response = await Service.createPayment(selectedInvoicesIds);
        window.location.href = response.data.url;
    };

    const buildTable = () => {
        const selectedInvoices: TInvoice[] = getSelectedInvoices();
        return createTableValues(
            selectedInvoices.map((invoice: any): TableCell[] => {
                return [
                    { id: 'invoiceNumber', value: invoice.name },
                    { id: 'totalAmountExclVAT', value: invoice.totalAmountExclVAT, type: TableColumnTypes.price },
                    { id: 'totalAmountInclVAT', value: invoice.totalAmountInclVAT, type: TableColumnTypes.price },
                    { id: 'discountAmount', value: invoice.discountAmount, type: TableColumnTypes.price },
                ];
            }),
        );
    };

    const renderTable = () => {
        const table = buildTable();
        const data = table.data;
        const columns = table.columns;
        const options: MUIDataTableOptions = {
            selectableRows: 'none',
            responsive: 'vertical',
            filter: false,
            download: false,
            search: false,
            viewColumns: false,
            pagination: false,
            rowHover: true,
            elevation: 0,
            sort: true,
            print: false,
            rowsPerPage: 100,
        };

        return <MUIDataTable title="" data={data} columns={columns} options={options} />;
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={isOpen}
            scroll="body"
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                <FormattedMessage id="invoicePaymentDetail.title" />
                <IconButton aria-label="Close" className={classes.closeButton} onClick={close} size="large">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {renderTable()}

                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={6}>
                        <Button onClick={close} variant="outlined" fullWidth className={classes.button}>
                            <FormattedMessage id="returns.form_label_cancel" />
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Button
                            data-testid="returns-add-item"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={payInvoices}
                            className={classes.button}
                        >
                            <FormattedMessage id="invoice.payNow" />
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

function styles({ spacing, palette }: Theme) {
    return createStyles({
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        button: {
            paddingTop: '1em',
            paddingBottom: '1em',
            paddingLeft: '2em',
            paddingRight: '2em',
            margin: '0 auto',
            justifyContent: 'center',
        },
        closeButton: {
            position: 'absolute',
            right: spacing(1),
            top: spacing(1),
            color: palette.grey[500],
        },
        info: {
            margin: '1rem 0',
        },
        dl: {
            display: 'grid',
            gridTemplateColumns: 'max-content auto',
        },
        dt: {
            gridColumnStart: '1',
        },
        dd: {
            gridColumnStart: '2',
        },
    });
}

export default withStyles(styles)(InvoicePaymentDialog);
