function getLanguage() {
    let lang = navigator.language;

    if (lang.split('-')[0] === 'de') {
        lang = 'de';
    } else if (lang.split('-')[0] === 'en') {
        lang = 'en-GB';
    } else {
        lang = 'nl-NL';
    }

    return lang;
}

export default getLanguage;
