import { withAuthenticationRequired } from '@auth0/auth0-react';
import localStorage from 'localstorage-ttl';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Loading from 'src/components/loading/Loading';
import { AppPageRoutes } from 'src/services/api/models';
import RoleBaseAccess from 'src/services/RoleBaseAccess';
import { TEN_MINUTES } from 'src/utils/constants';
import { StorageNameSpace } from 'src/utils/storageNameSpace';

const ProtectedOutlet: React.FC<any> = ({ isTokenLoaded, children }: any) => {
    const location = useLocation();

    if (!isTokenLoaded) {
        return <Loading />;
    }

    if (!RoleBaseAccess.isRouteAllowed() && location.pathname !== AppPageRoutes.UNAUTHORIZED) {
        // Saving the url to redirect after login, 10 minutes of TTL
        localStorage.set(StorageNameSpace.REDIRECTION, location.pathname, TEN_MINUTES);

        window.location.replace(AppPageRoutes.UNAUTHORIZED);
    }

    return <>{children ? children : <Outlet />}</>;
};

export const ProtectedRoute = withAuthenticationRequired(ProtectedOutlet, { onRedirecting: () => <Loading /> });
