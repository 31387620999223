import { useAuth0 } from '@auth0/auth0-react';
import { Button, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Loading from 'src/components/loading/Loading';
import UserStore from 'src/stores/UserStore';
import withRoot from '../withRoot';

interface UnauthorizedProps extends WithStyles<typeof styles> {
    user?: typeof UserStore.Type;
    asComponent?: boolean;
}

const Unauthorized = inject('user')(
    observer(({ classes, user, asComponent = false }: UnauthorizedProps) => {
        const { logout } = useAuth0();

        const handleLogout = () => {
            if (user) {
                user.cleanLogin();
                logout({
                    returnTo: window.location.origin,
                });
            }
        };

        return (
            <>
                <main className={asComponent ? classes.layoutAsComponent : classes.layout}>
                    <Loading error={true} />
                    <Typography variant="h4" gutterBottom>
                        <FormattedMessage id="unauthorized.title" />
                    </Typography>

                    {!asComponent && (
                        <div className={classes.buttons}>
                            <Button
                                onClick={handleLogout}
                                color="primary"
                                variant="contained"
                                className={classes.button}
                            >
                                <FormattedMessage id="notfound.goToLogin" />
                            </Button>
                        </div>
                    )}
                </main>
            </>
        );
    }),
);

function styles() {
    return createStyles({
        layout: {
            height: 'calc(100vh - 200px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        layoutAsComponent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        buttons: {
            textAlign: 'center',
        },
        button: {
            padding: '1rem 2rem',
            margin: '1rem',
            minWidth: '320px',
        },
    });
}

export default withRoot(withStyles(styles)(Unauthorized));
