import { Button, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AppPageRoutes } from 'src/services/api/models';
import Loading from '../components/loading/Loading';
import UserStore from '../stores/UserStore';
import withRoot from './modules/withRoot';

interface NotFoundProps extends WithStyles<typeof styles> {
    user?: typeof UserStore.Type;
}

const NotFound = inject('user')(
    observer(({ classes, user }: NotFoundProps) => {
        const isLoggedIn = user && user.userId;
        const navigate = useNavigate();

        return (
            <>
                <main
                    className={classNames(classes.layout, {
                        [classes.layoutLoggedIn]: isLoggedIn,
                    })}
                >
                    <Loading error={true} />

                    <Typography component="h3" variant="h3" gutterBottom>
                        <FormattedMessage id="notfound.title" />
                    </Typography>
                    <Typography component="h6" variant="h6">
                        <FormattedMessage id="notfound.subtitle" />
                    </Typography>

                    <div className={classes.buttons}>
                        {isLoggedIn ? (
                            <Button
                                onClick={() => navigate(AppPageRoutes.DASHBOARD)}
                                color="primary"
                                variant="contained"
                                className={classes.button}
                            >
                                <FormattedMessage id="notfound.goToDashboard" />
                            </Button>
                        ) : (
                            <Button
                                onClick={() => navigate('/')}
                                color="primary"
                                variant="contained"
                                className={classes.button}
                            >
                                <FormattedMessage id="notfound.goToLogin" />
                            </Button>
                        )}
                    </div>
                </main>
            </>
        );
    }),
);

function styles() {
    return createStyles({
        layout: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        layoutLoggedIn: {
            height: 'calc(100vh - 200px)',
        },
        buttons: {
            textAlign: 'center',
            marginTop: '2rem',
        },
        button: {
            padding: '1rem 2rem',
            margin: '1rem',
            minWidth: '320px',
        },
    });
}

export default withRoot(withStyles(styles)(NotFound));
