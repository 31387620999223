import { faNewspaper } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, List, Paper, Typography, styled } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Loading from 'src/components/loading/Loading';
import { fadeInDownKeyFrames } from 'src/components/styles';
import Store from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import NewsWidget, { ENewsLocation } from '../news/NewsWidget';
import DashboardSlideShow from './DashboardSlideShow';
import Link from 'src/components/Link';
import { AppPageRoutes } from 'src/services/api/models';

const Root = styled('div')(({ theme }) => ({
    margin: `-${theme.spacing(3)}`,
    flexGrow: 1,
    display: 'flex',
}));
const StyledPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flex: 1,
    borderRadius: 0,
    animation: `${fadeInDownKeyFrames} 1s ease-out`,
}));

interface DashboardProps {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
}

const DashboardPage = inject(
    'store',
    'user',
)(
    observer(({ store, user }: DashboardProps) => {
        const lang = user!.preferredLang;
        useEffect(() => {
            store!.marketing.setActiveMarketingTool('DASHBOARD');
            store!.news.getRecentNews(lang, '', 3);
            store!.marketing.getMarketingBanners(lang);
        }, [store, lang]);

        const renderState: Record<string, JSX.Element> = {
            LOADING: <Loading small />,
            SUCCESS: <NewsWidget location={ENewsLocation.DASHBOARD} />,
            IDLE: <Loading />,
            ERROR: (
                <Typography variant="body1" gutterBottom style={{ marginLeft: '20px' }}>
                    <FormattedMessage id="dashboard.something_went_wrong" />
                </Typography>
            ),
        };

        return (
            <Root>
                <Grid container data-testid="dashboard">
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        lg={4}
                        style={{ padding: '1rem 2rem 1rem 2rem', boxSizing: 'border-box' }}
                    >
                        <Grid display="flex" item>
                            <StyledPaper elevation={0}>
                                <List style={{ flex: 1 }}>
                                    <Typography data-testid="news-title" padding="0 1rem" variant="h5" gutterBottom>
                                        <FontAwesomeIcon size="sm" icon={faNewspaper} style={{ marginRight: '10px' }} />
                                        <FormattedMessage id="dashboard.newsTitle" />
                                    </Typography>
                                    {renderState[store!.news.status]}
                                </List>
                            </StyledPaper>
                        </Grid>
                        <Link href={AppPageRoutes.NEWS}>
                            <Typography>
                                <FormattedMessage id="dashboard.news.more" />
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid
                        container
                        style={{ padding: '1rem 2rem 1rem 2rem', boxSizing: 'border-box' }}
                        item
                        xs={12}
                        sm={12}
                        md={7}
                        lg={8}
                    >
                        <Grid display="flex" item xs={12}>
                            <Paper
                                elevation={0}
                                style={{ display: 'flex', flex: 1, borderRadius: 0, position: 'relative' }}
                            >
                                <DashboardSlideShow />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Root>
        );
    }),
);

export default DashboardPage;
