import { faBicycle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import Loading from 'src/components/loading/Loading';

const somethingWentWrong = {
    'en-GB': 'Something went wrong, please try again later',
    'nl-NL': 'Er is iets misgegaan, probeer het later opnieuw',
    de: 'Etwas ist schief gelaufen. Bitte versuchen Sie es später erneut',
};

const checkNetwork = {
    'en-GB': 'Please check your network connection',
    'nl-NL': 'Controleer uw netwerkverbinding',
    de: 'Bitte überprüfen Sie Ihre Netzwerkverbindung',
};

const getFallbackMessage = (message: any, lang: string) => (message[`${lang}`] ? message[`${lang}`] : message.en);

interface InitialLoadingProps {
    lang: string;
    loading: boolean;
}

// TODO: This is a mess
const InitialLoading = ({ lang, loading }: InitialLoadingProps) => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
            data-testid="initial-loading-container"
        >
            {loading ? (
                <Loading />
            ) : (
                <>
                    <FontAwesomeIcon color={'#ccc'} icon={faBicycle} size="10x" />
                    <Typography variant="h5">{getFallbackMessage(somethingWentWrong, lang)}</Typography>
                    <Typography component="p">{getFallbackMessage(checkNetwork, lang)}</Typography>
                </>
            )}
        </Grid>
    );
};

export default InitialLoading;
