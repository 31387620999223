import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import NotificationsListItem from 'src/components/NotificationsListItem';
import { AppPageRoutes } from 'src/services/api/models';
import Store from 'src/stores/RootStore';
import { TOrderNotification } from 'src/stores/types/NotificationsTypes';

export interface NotificationsListProps extends WithStyles<typeof styles> {
    notifications: TOrderNotification[];
    store?: typeof Store.Type;
    calculateUnreadStatus: (notification: TOrderNotification) => boolean;
    closeDrawer: () => void;
}

const NotificationsList = inject('store')(
    observer(({ classes, store, notifications, calculateUnreadStatus, closeDrawer }: NotificationsListProps) => {
        const navigate = useNavigate();

        const handleNotificationClick = (notification: TOrderNotification) => {
            closeDrawer();

            const redirectURL = AppPageRoutes.ORDERS;
            navigate(redirectURL);
        };

        const renderHeader = () => (
            <Grid item className={classes.notificationsHeader}>
                <Typography component="h1" variant="h6">
                    <FormattedMessage id="notifications.title" />

                    <Button
                        className={classes.allNotificationsButton}
                        variant="text"
                        onClick={() => {
                            closeDrawer();
                            navigate(AppPageRoutes.ORDER_NOTIFICATIONS);
                        }}
                    >
                        <Typography component="span" variant="body2">
                            <FormattedMessage id="notifications.allNotificationsTitle" />
                        </Typography>
                    </Button>
                </Typography>

                <Button onClick={() => closeDrawer()} data-testid="notifications-close">
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                </Button>
            </Grid>
        );

        const renderListOfNotifications = () => {
            if (!notifications.length) {
                return (
                    <Typography variant="body2" className={classes.notificationsNotFound}>
                        <FormattedMessage id="notifications.notFound" />
                    </Typography>
                );
            }

            return (
                <Grid item className={classes.notificationsList}>
                    {notifications.map(notification => (
                        <NotificationsListItem
                            key={notification.notificationId}
                            notification={notification}
                            isUnread={calculateUnreadStatus(notification)}
                            onClick={handleNotificationClick}
                        />
                    ))}
                </Grid>
            );
        };

        return (
            <Grid
                container
                spacing={3}
                className={classes.notificationsContainer}
                data-testid="notifications-list-items"
            >
                {renderHeader()}
                {renderListOfNotifications()}
            </Grid>
        );
    }),
);

function styles({ spacing }: Theme) {
    return createStyles({
        notificationsContainer: {
            padding: '10px 20px 0 20px',
            flexDirection: 'column',
            height: '100%',
            flexWrap: 'inherit',
        },

        notificationsHeader: {
            flex: 'none',
            display: 'flex',
            justifyContent: 'space-between',
        },

        allNotificationsButton: {
            textTransform: 'none',
            marginLeft: spacing(2),
        },

        notificationsNotFound: {
            marginLeft: '12px',
        },

        notificationsList: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
        },
    });
}

export default withStyles(styles)(NotificationsList);
