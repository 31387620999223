import { values } from 'mobx';
import { applySnapshot, destroy, flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { NavigateFunction } from 'react-router-dom';
import Service from 'src/services/api/Api';
import Toast from 'src/services/Toasts';
import { ReturnRequestData, SparePartsReturn } from 'src/types/commons/returns';
import { SparePartCategory, UPLOAD_SOURCE, userStateDbColumns } from 'src/utils/constants';
import RootStore from './RootStore';
import { CartReturnItemModel } from './types/ReturnCartItemModel';

const CreatedCaseItem = types.model('CreatedCaseItem', {
    bikeSerialString: types.maybeNull(types.string),
    id: types.string,
    itemSerialString: types.maybeNull(types.string),
    name: types.string,
    productCode: types.string,
    productName: types.string,
    productType: types.string,
});

export const CreatedCase = types.model('CreatedCase', {
    caseId: types.string,
    caseNumber: types.string,
    createdDate: types.string,
    items: types.optional(types.array(CreatedCaseItem), []),
    returnCategory: types.string,
});

const Store: any = types
    .model('CartReturnsStore', {
        items: types.optional(types.map(CartReturnItemModel), {}),
        bundleWarning: types.optional(types.boolean, false),
        bundleWarningBatteries: types.optional(types.boolean, false),
        nonReturnablePartsWarning: types.optional(types.boolean, false),
        item: types.maybe(types.late(() => types.safeReference(CartReturnItemModel))),
        createdCases: types.optional(types.array(CreatedCase), []),
    })
    .volatile(() => ({
        sending: false,
        editing: false,
        isUploading: false,
    }))
    .views(self => ({
        get getItems() {
            return values(self.items);
        },
        get totalItemsQuantity(): number {
            return self.items.size;
        },
        get selectedItems() {
            return this.getItems.filter((item: any) => item.isSelected);
        },
        get isValidCart() {
            // Limit to 5 return items
            if (!this.selectedItems.length || this.selectedItems.length > 5) {
                return false;
            }
            let hasError = [];
            self.items.forEach((item: typeof CartReturnItemModel.Type) => {
                if (item.fileError) {
                    hasError.push(item.productCode);
                }
            });
            return hasError.length === 0;
        },
    }))
    .actions(self => {
        const getItemToEdit = () => {
            if (!self.editing) {
                return undefined;
            }

            if (self.item && !self.items.has(self.item.id)) {
                clearItemToEdit();
            }

            return self.item;
        };
        const updateStorageData = async () => {
            try {
                await Service.updateStorageData(userStateDbColumns.returnItemsCart, JSON.stringify(getSnapshot(self)));
            } catch (e) {
                console.error(e);
                Toast.PreferencesError();
            }
        };

        const clearItemToEdit = () => {
            const parent: typeof RootStore.Type = getParent(self);
            self.editing = false;
            self.item = undefined;
            const selectedInvoice = parent.invoices.getSelectedInvoice;
            if (selectedInvoice) {
                selectedInvoice.setReturnInvoiceLineId('');
            }
            parent.invoices.setSelectedInvoiceId('');
            parent.spareParts.setSelectedSparePartId('');
        };
        const showBundleWarningBatteries = () => {
            self.bundleWarningBatteries = true;
        };
        const showBundleWarning = () => {
            self.bundleWarning = true;
        };
        const showNonReturnablePartsWarning = () => {
            self.nonReturnablePartsWarning = true;
        };
        const closeBundleWarning = () => {
            self.bundleWarning = false;
        };
        const closeBundleWarningBatteries = () => {
            self.bundleWarningBatteries = false;
        };
        const closeNonReturnablePartsWarning = () => {
            self.nonReturnablePartsWarning = false;
        };

        const addCartItem = (returnItem: typeof CartReturnItemModel.Type) => {
            self.items.set(returnItem.id, returnItem);
            updateStorageData();
        };

        const addCartItemWithUpload = flow(function* (
            returnItem: typeof CartReturnItemModel.Type,
            onSucess?: () => void,
        ) {
            self.isUploading = true;
            const file = returnItem.attachment;
            const data = new FormData();
            data.append('source', UPLOAD_SOURCE.RETURN_INVOICE);
            if (file) {
                data.append('file', file, file.name);
            }

            try {
                const response: any = yield Service.uploadFile(data);

                if (response.data) {
                    const { ...item } = returnItem;
                    item.attachment = response.data;

                    self.items.set(returnItem.id, item);
                    updateStorageData();
                }

                self.isUploading = false;
                if (onSucess) {
                    onSucess();
                }
            } catch (e) {
                console.error(e, `error uploading invoice`);
                Toast.GeneralError();
            }
        });

        const updateCartItem = (returnItem: typeof CartReturnItemModel.Type) => {
            self.items.set(returnItem.id, returnItem);
            clearItemToEdit();
            updateStorageData();
        };

        const removeCartItem = (item: typeof CartReturnItemModel.Type) => {
            if (item) {
                Toast.removeItem('return_request.itemRemovedMessage');
            }
            destroy(item);
            if (self.items.size === 0) {
                self.bundleWarning = false;
            }
            updateStorageData();
        };

        const cleanSomeCartItems = (items: ReturnRequestData[]) => {
            items.forEach(item => {
                self.items.delete(item.id);
            });
            updateStorageData();
        };

        const sendReturnItemsByCategory = flow(function* (
            category: SparePartCategory,
            returnItems: ReturnRequestData[],
            sparePartsReturnStatus: SparePartsReturn,
            uploads?: string[],
        ) {
            try {
                const response: any = yield Service.createReturnRequest({
                    category,
                    returnItems,
                    sparePartsReturnStatus,
                    uploads,
                });

                if (response.status === 201) {
                    cleanSomeCartItems(returnItems);
                    self.createdCases.push(response.data);
                }

                return true;
            } catch (e) {
                console.error(e, `error creating a return request by category: ${category}`);
                Toast.GeneralError();

                return false;
            }
        });

        type ReturnItemExtended = {
            item: ReturnRequestData;
            sparePartsReturnStatus: SparePartsReturn;
            uploads?: string[];
        };

        function chunkArray<T>(array: T[], chunkSize = 10): T[][] {
            const result: T[][] = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                result.push(array.slice(i, i + chunkSize));
            }
            return result;
        }

        const sendReturnItemsV2 = flow(function* (navigate: NavigateFunction) {
            self.sending = true;
            self.createdCases.clear();

            const requestData: any[] = self.getItems.slice();

            const groupedItems: Record<string, ReturnItemExtended[]> = {};
            for (const item of requestData) {
                let sparePartsReturnStatus;
                try {
                    const response: any = yield Service.getSparePartsReturnStatus(item.productCode);
                    if (response.status !== 200 || !response.data) {
                        throw new Error('Error getting spare parts return status');
                    }
                    sparePartsReturnStatus = response.data;
                } catch (e) {
                    self.sending = false;
                    console.error(e, `Error fetching spare parts return status for ${item.productCode}`);
                    Toast.GeneralError();
                    return;
                }

                const uploads = item.attachment ? [item.attachment] : undefined;

                const groupKey = [item.category, sparePartsReturnStatus].join('|');
                groupedItems[groupKey] = groupedItems[groupKey] || [];
                groupedItems[groupKey].push({ item, sparePartsReturnStatus, uploads });
            }

            const requestsArray = [];
            for (const groupOfItems of Object.values(groupedItems)) {
                // Backend fails if we send more than 10 items in a single return request, so we chunk them
                for (const group of chunkArray(groupOfItems)) {
                    // Batteries should be one item per case
                    if (group[0].item.category === SparePartCategory.BATTERIES) {
                        for (const itemExtended of group) {
                            const { item, sparePartsReturnStatus, uploads } = itemExtended;
                            requestsArray.push(
                                sendReturnItemsByCategory(item.category!, [item], sparePartsReturnStatus, uploads),
                            );
                        }
                    } else {
                        requestsArray.push(
                            sendReturnItemsByCategory(
                                group[0].item.category!,
                                group.map(item => item.item),
                                group[0].sparePartsReturnStatus,
                                group.flatMap(item => item.uploads || []),
                            ),
                        );
                    }
                }
            }

            try {
                const response: any = yield Promise.all(requestsArray);

                if (!response.some((res: boolean) => res === false)) {
                    const parent: typeof RootStore.Type = getParent(self);
                    parent.config.toggleCartReturns();
                    navigate('/thank-you-returns');
                }
            } catch (e) {
                console.error(e, 'error creating a return request');
                Toast.GeneralError();
            }
            self.sending = false;
        });

        const updateCartFromDb = (snapshot: string) => {
            try {
                const parent: typeof RootStore.Type = getParent(self, 1);
                const data = JSON.parse(snapshot);
                Object.keys(data.items).forEach(index => {
                    const item: typeof CartReturnItemModel.Type = data.items[index];
                    if (!CartReturnItemModel.is(item)) {
                        delete data.items[index];
                    }
                });
                applySnapshot(self, data);
                values(self.items).forEach(
                    flow(function* (item: any) {
                        const fromInvoice = !!item?.invoiceId && !!item?.invoiceLineId;
                        if (fromInvoice) {
                            // If it's a sparepart belongs to an invoice and both the invoice and the invoiceLine don't exist
                            // Get invoive by Id, then get invoive lines
                            if (!parent.invoices.list.has(item.invoiceId)) {
                                yield item.getInvoiceObject();
                            }
                            const invoice = parent.invoices.list.get(item.invoiceId);

                            // If we still have invoice but none of it's lines, request for invoice lines
                            if (invoice && !invoice.lines.has(item.invoiceLineId)) {
                                yield invoice.getInvoiceLines();
                            }

                            // If no invoice and invoice lines exist, remove from return cart
                            if (!invoice || (invoice && !invoice.lines.has(item.invoiceLineId))) {
                                removeCartItem(item);
                            }
                        } else {
                            // If it's a sparepart and the parent bike still exists but the spare part itself doesn't
                            yield item.loadSparePartInModel();

                            if (!parent.spareParts.list.has(item.productCode)) {
                                removeCartItem(item);
                            }
                        }
                    }),
                );
            } catch (e) {
                console.error(e);
                Toast.PreferencesError();
            }
        };

        const editCartItem = flow(function* (cartItem: typeof CartReturnItemModel.Type) {
            self.editing = true;
            const parent: typeof RootStore.Type = getParent(self);
            const isFromInvoice = !!cartItem?.invoiceId && !!cartItem?.invoiceLineId;
            if (isFromInvoice) {
                const invoiceLoaded = parent.invoices.list.get(cartItem.invoiceId);
                if (!invoiceLoaded) {
                    yield cartItem.getInvoiceObject();
                }
                parent.invoices.setSelectedInvoiceId(cartItem.invoiceId);
                const invoice = parent.invoices.list.get(cartItem.invoiceId);
                if (invoice) {
                    invoice.setReturnInvoiceLineId(cartItem.invoiceLineId);
                }
            } else {
                parent.spareParts.setSelectedSparePartId(cartItem.productCode);
            }
            if (CartReturnItemModel.is(cartItem)) {
                self.item = cartItem;
            }

            parent.returns.toggleReturnFormDialog();
        });

        return {
            showBundleWarning,
            showBundleWarningBatteries,
            showNonReturnablePartsWarning,
            closeBundleWarning,
            closeBundleWarningBatteries,
            closeNonReturnablePartsWarning,
            addCartItem,
            addCartItemWithUpload,
            removeCartItem,
            cleanSomeCartItems,
            sendReturnItemsByCategory,
            sendReturnItemsV2,
            updateCartFromDb,
            editCartItem,
            updateCartItem,
            getItemToEdit,
            clearItemToEdit,
        };
    });

export default Store;
