import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';

interface BaseErrorProps {
    translationId?: string;
    freeText?: string;
    translationParameters?: Record<string, any>;
}
const BaseError = observer(({ translationId, freeText, translationParameters }: BaseErrorProps) => {
    const intl = useIntl();

    return (
        <Typography align={'center'} color="primary">
            <Typography style={{ marginRight: '1rem', display: 'inline' }} color="secondary" component="span">
                <FontAwesomeIcon icon={faTimes} />
            </Typography>

            {translationId && intl.formatMessage({ id: translationId }, translationParameters)}
            {freeText && freeText}
        </Typography>
    );
});

export default BaseError;
