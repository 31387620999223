import { MenuItem } from '@mui/material';
import { Field } from 'formik';
import { TextField, Select } from 'formik-mui';
import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { RequiredField } from './types';

export const CustomRequriedField = ({ targetName }: { targetName: string }) => {
    const intl = useIntl();
    return RequiredField[targetName] ? (
        <Field
            data-testid={`contact-form-${RequiredField[targetName].name}-field`}
            label={intl.formatMessage({ id: RequiredField[targetName].intl })}
            name={RequiredField[targetName].name}
            id={RequiredField[targetName].name}
            margin="normal"
            variant="outlined"
            placeholder={
                RequiredField[targetName].placeholderIntl
                    ? intl.formatMessage({ id: RequiredField[targetName].placeholderIntl })
                    : undefined
            }
            component={TextField}
        />
    ) : null;
};

interface CustomSelectFieldProps {
    id: string;
    label: string;
    options: any[];
}

export const CustomSelectField = ({ id, label, options }: CustomSelectFieldProps) => {
    return (
        <Field
            formControl={{ sx: { marginTop: '1rem' } }}
            data-testid={`contact-form-${id}-field`}
            label={label}
            name={id}
            id={id}
            variant="outlined"
            component={Select}
        >
            {options.map(option => (
                <MenuItem value={option.value} key={option.value} data-testid={option.value}>
                    <FormattedMessage id={option.label} />
                </MenuItem>
            ))}
        </Field>
    );
};
