import { Grid, Typography, GridProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export const GridCell = ({
    textID,
    children,
    alignRight = false,
    textEllipsis = false,
    ...props
}: { textID?: string; children?: ReactNode; alignRight?: boolean; textEllipsis?: boolean } & GridProps) => (
    <Grid item {...props}>
        <Typography
            variant="subtitle1"
            align={alignRight ? 'right' : 'inherit'}
            lineHeight="1rem"
            sx={
                textEllipsis
                    ? {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }
                    : undefined
            }
        >
            {textID && <FormattedMessage id={textID} />}
            {children && children}
        </Typography>
    </Grid>
);
