import InfoIcon from '@mui/icons-material/Info';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { Grid, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { ButtonCustomVariant } from 'src/components/button/type';
import DefinitionsBox from 'src/components/DefinitionsBox';
import LightTooltip from 'src/components/LightTooltip';
import Loading from 'src/components/loading/Loading';
import PageLoader from 'src/components/PageLoader';
import RootStore from 'src/stores/RootStore';
import { TInvoice } from 'src/stores/types/Invoice';
import UserStore from 'src/stores/UserStore';
import InvoiceLines from './InvoiceLines';

interface InvoiceDetailsProps extends WithStyles<typeof styles> {
    store?: typeof RootStore.Type;
    user?: typeof UserStore.Type;
}

const InvoiceDetails = inject(
    'store',
    'user',
)(
    observer(({ classes, store, user }: InvoiceDetailsProps) => {
        const today = dayjs();
        const { invoiceId } = useParams();
        const navigate = useNavigate();
        const location = useLocation();
        let [searchParams] = useSearchParams();

        const lang = user!.preferredLang;
        const invoice = store!.invoices.getInvoiceByID(invoiceId || '') as TInvoice;
        const loading = store!.invoices.loading;

        let isFromUnusedReturnTable = searchParams.get('isFromUnusedReturnTable') === 'true';

        useEffect(() => {
            // Fetch invoice if it is not in the store yet
            if (!store?.invoices.loading && !invoice && invoiceId) {
                const fetchInvoice = async () => {
                    await store!.invoices.getInvoiceByInvoiceId(invoiceId);
                };

                fetchInvoice();
                store?.invoices.setSelectedInvoiceId(invoiceId);
            }

            // Fetch return reasons if they are not in the store
            if (!store?.returns.loading && store?.returns.isReturnReasonsEmpty) {
                store!.returns.getReturnReasons();
            }
        }, []);

        useEffect(() => {
            // Fetch lines in the invoice
            if (invoice && !invoice.loading) {
                invoice.getInvoiceLines();
            }
        }, [invoice]);

        // TODO: Make a proper error showing component for not found item with header, go back button

        if (!invoice && loading) {
            return <Loading small />;
        }
        if (!invoice) {
            return (
                <Typography variant="body1" gutterBottom style={{ marginLeft: '20px' }}>
                    <FormattedMessage id="invoices.notFound" />
                </Typography>
            );
        }

        const allowReturnOnHover =
            isFromUnusedReturnTable || dayjs(invoice.documentDate).isAfter(today.subtract(6, 'week'));

        const invoiceInfo = {
            invoiceNumber: invoice.name,
            orderNumber: invoice.orderCode,
            invoiceDate: invoice.documentDate && dayjs(invoice.documentDate).format('DD/MM/YYYY'),
            dueDate: invoice.dueDate && dayjs(invoice.dueDate).format('DD/MM/YYYY'),
            paymentTerms: invoice.paymentTermsDescription,
            totalAmountInclVAT: `€${invoice.totalAmountInclVAT}`,
            totalAmountExclVAT: `€${invoice.totalAmountExclVAT}`,
            reference: invoice.reference,
            externalDocument: invoice.externalDocument,
        };

        const invoiceBilling = {
            name: invoice.billing!.name,
            street: invoice.billing!.street,
            postalCode: invoice.billing!.postalCode,
            city: invoice.billing!.city,
            country: invoice.billing!.country,
            contactName: invoice.billing!.contactName,
        };

        const tooltip = <FormattedMessage id="invoice.no_return_allowed" />;

        return (
            <>
                <Grid container spacing={3} className={classes.header}>
                    <Grid item xs={12} md={4} lg={3}>
                        <Button
                            customVariant={ButtonCustomVariant.BACK_BUTTON}
                            onClick={() => navigate(-1)}
                            hideButton={location.key === 'default'}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Button
                            data-testid="pdf-download"
                            onClick={() => invoice.downloadPDF(lang)}
                            disabled={invoice.isDownloadingPDF}
                            fullWidth
                            color="primary"
                            variant="contained"
                            labelTextId="download.pdf"
                            endIcon={<PdfIcon />}
                        />
                    </Grid>
                </Grid>

                <div>
                    <Typography variant="h5" color="inherit" noWrap>
                        <FormattedMessage id="invoiceDetail.title" />
                        <LightTooltip title={tooltip} style={{ marginLeft: 4 }}>
                            <InfoIcon fontSize="small" />
                        </LightTooltip>
                    </Typography>
                </div>

                <div className={classes.info}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <DefinitionsBox translationId="invoiceDetail.detail" data={invoiceInfo} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DefinitionsBox translationId="billing.billing_address" data={invoiceBilling} />
                        </Grid>
                    </Grid>
                    {!invoice.loading ? (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {invoice.lines.size > 0 && (
                                    <InvoiceLines
                                        lines={invoice.getLinesArray}
                                        type={invoice.type}
                                        returnInvoiceLine={(id: string) => invoice.setReturnInvoiceLineId(id)}
                                        allowReturnOnHover={allowReturnOnHover}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    ) : (
                        <PageLoader />
                    )}
                </div>
            </>
        );
    }),
);

function styles({ spacing, palette }: Theme) {
    return createStyles({
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        info: {
            margin: '1rem 0',
        },
    });
}

export default withStyles(styles)(InvoiceDetails);
