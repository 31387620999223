import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface ReturnDialogActionsProps {
    isSubmitting?: boolean;
    isEditing?: boolean;
    onCancelClick?: () => void;
    disabled?: boolean;
}

export const ReturnDialogActions = ({ isSubmitting, isEditing, onCancelClick, disabled }: ReturnDialogActionsProps) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <Button
                    disabled={isSubmitting}
                    onClick={onCancelClick}
                    variant="outlined"
                    fullWidth
                    sx={{ padding: '1em 2em', justifyContent: 'space-between' }}
                >
                    <FormattedMessage id="returns.form_label_cancel" />
                </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Button
                    data-testid="returns-add-item"
                    disabled={disabled}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ padding: '1em 2em', justifyContent: 'space-between' }}
                >
                    {isSubmitting ? (
                        <CircularProgress size={20} color="secondary" />
                    ) : isEditing ? (
                        <FormattedMessage id="returns.form_label_update" />
                    ) : (
                        <>
                            <FormattedMessage id="returns.form_label_add" />
                            <FontAwesomeIcon icon={faPlus} />
                        </>
                    )}
                </Button>
            </Grid>
        </Grid>
    );
};
