import CloseIcon from '@mui/icons-material/Close';
import BikeIcon from '@mui/icons-material/DirectionsBike';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/button/Button';
import ColorDot from 'src/components/ColorDot';
import NoItemFoundAfterSearch from 'src/components/NoItemFoundAfterSearch';
import TextFilter from 'src/components/TextFilter';
import type { TCompatibleBikeModel } from 'src/stores/types/CompatibleBike';
import { includesValue } from 'src/utils/findStringInObjValues';

interface CompalibleBikesProps {
    bikesList: Array<TCompatibleBikeModel>;
}

const CompalibleBikes: FC<React.PropsWithChildren<CompalibleBikesProps>> = ({ bikesList }) => {
    const { spacing } = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [textFilter, setTextFilter] = useState('');

    const filteredList = useMemo(() => {
        return bikesList.filter(bike => includesValue(textFilter, bike));
    }, [textFilter, bikesList]);

    const handleCloseDialog = () => setIsOpen(false);

    return (
        <>
            <Button
                sx={{ width: '100%', marginTop: '1rem' }}
                variant="outlined"
                onClick={() => setIsOpen(true)}
                data-testid="compatible-bikes-button"
            >
                <BikeIcon sx={{ marginRight: spacing(1) }} />
                <FormattedMessage id="spareParts.compatibleBikes" />
            </Button>

            <Dialog open={isOpen} onClose={handleCloseDialog} fullWidth aria-labelledby="compatible-bikes-moddal">
                <DialogTitle
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 0 }}
                    id="compatible-bikes-dialog-top"
                >
                    <Typography data-testid="compatible-bikes-dialog-title" variant="h5">
                        <FormattedMessage id="spareParts.compatibleBikes" />
                    </Typography>
                    <IconButton
                        aria-label="Close"
                        onClick={handleCloseDialog}
                        data-testid="close-compatible-bikes-dialog"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent style={{ minHeight: '400px' }}>
                    <TextFilter
                        labelTextId="spareParts.compatibleBikes.searchBike"
                        text={textFilter}
                        onChange={setTextFilter}
                    />
                    {filteredList.length ? (
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, minmax(186px, 1fr))',
                                columnGap: '2rem',
                                margin: '1rem 0',
                            }}
                        >
                            {filteredList.map(bike => (
                                <div key={bike.id} style={{ display: 'flex', alignItems: 'center' }}>
                                    <ColorDot color={bike.color || ''} />
                                    <Typography variant="body1">{`${bike.id} - ${bike.serie} ${bike.model}`}</Typography>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <NoItemFoundAfterSearch searchedText={textFilter} />
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CompalibleBikes;
