import { faList, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import ReactGA from 'react-ga';
import { FormattedMessage } from 'react-intl';
import { GACategories } from 'src/services/Analytics';

interface ListViewToggleProps extends WithStyles<typeof styles> {
    listView: boolean;
    setListView: any;
}

const ListViewToggle = ({ classes, listView, setListView }: ListViewToggleProps) => {
    const onClick = () => {
        if (!listView) {
            ReactGA.event({
                category: GACategories.UI,
                action: 'User navigates to see the list view',
            });
        }
        setListView();
    };

    return (
        <div className={classes.viewListSwitcher}>
            <Typography className={classes.viewListSwitcherLabel} variant="subtitle1">
                <FormattedMessage id="labels.viewMode" />
            </Typography>
            <Button
                data-testid="set-grid-view"
                onClick={setListView}
                className={`${classes.switchViewButton} ${!listView && classes.switchViewButtonActive}`}
            >
                <FontAwesomeIcon className={classes.icon} icon={faThLarge} size="sm" />
            </Button>
            <Button
                data-testid="set-list-view"
                onClick={onClick}
                className={`${classes.switchViewButton} ${listView && classes.switchViewButtonActive}`}
            >
                <FontAwesomeIcon className={classes.icon} icon={faList} size="sm" />
            </Button>
        </div>
    );
};
function styles({ palette }: Theme) {
    return createStyles({
        icon: {
            marginLeft: '.2rem',
        },
        viewListSwitcher: {
            alignSelf: 'center',
            justifySelf: 'flex-end',
            display: 'flex',
        },
        viewListSwitcherLabel: {
            alignSelf: 'center',
            justifySelf: 'flex-end',
            display: 'flex',
        },
        switchViewButton: {
            fontSize: '1.5rem',
            minWidth: '30px',
            padding: '.5rem',
            '& svg': {
                margin: 0,
            },
        },
        switchViewButtonActive: {
            color: palette.secondary.main,
        },
    });
}
export default withStyles(styles)(ListViewToggle);
