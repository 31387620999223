import { useEffect, useState } from 'react';

interface WindowSize {
    width?: number;
    height?: number;
}

export default function useWindowSize() {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Initialize listener on mount
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            // Uninitialize listener on unmount
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
}
