import { applySnapshot, getParent, getSnapshot, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';
import Toast from 'src/services/Toasts';
import { userStateDbColumns } from 'src/utils/constants';
import RootStore from './RootStore';
import { ProductModel, TProductModel } from './types/ProductTypes';

const FavoritesStore = types
    .model('FavoritesStore', {
        products: types.optional(types.map(types.late(() => types.reference(ProductModel))), {}),
    })
    .views(self => ({
        isFavorite(product: TProductModel) {
            return self.products.has(product.id);
        },
    }))
    .actions(self => ({
        async toggleProduct(product: TProductModel) {
            if (self.isFavorite(product)) {
                try {
                    self.products.delete(product.id);
                    const snap = JSON.stringify(getSnapshot(self));
                    await Service.updateStorageData(userStateDbColumns.favorites, snap);
                    Toast.FavoriteRemove();
                } catch (e) {
                    // revert if backend fails
                    self.products.set(product.id, product.id);
                    Toast.PreferencesError();
                }
            } else {
                try {
                    self.products.set(product.id, product.id);
                    const snap = JSON.stringify(getSnapshot(self));
                    await Service.updateStorageData(userStateDbColumns.favorites, snap);
                    Toast.FavoriteAdd();
                } catch (e) {
                    // revert if backend fails
                    self.products.delete(product.id);
                    Toast.PreferencesError();
                }
            }
        },
        async loadFaves(snap: string) {
            try {
                let updateFavoriteStore = false; // Indicate to update favorite store in the DB

                const snapshot = JSON.parse(snap);

                const parent: typeof RootStore.Type = getParent(self);
                // Remove from favorite list deprecated bikes
                Object.keys(snapshot.products).forEach((product: string) => {
                    if (parent.products.bikes.size && !parent.products.bikes.has(product)) {
                        delete snapshot.products[product];
                        updateFavoriteStore = true;
                    }
                });

                applySnapshot(self, snapshot);
                
                if (updateFavoriteStore) {
                    await Service.updateStorageData(userStateDbColumns.favorites, JSON.stringify(getSnapshot(self)));
                }
            } catch (e) {
                console.error(e);
                Toast.PreferencesError();
            }
        },
    }));

export default FavoritesStore;
