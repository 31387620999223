import { Chip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { injectIntl } from 'react-intl';
import { TProductInformation } from 'src/stores/types/ProductTypes';

interface ComposedProductAvailabilityLabelProps {
    intl: any;
    bikeInfo: TProductInformation;
    batteryInfo: TProductInformation | null;
}

const ComposedProductAvailabilityLabel = ({ bikeInfo, batteryInfo, intl }: ComposedProductAvailabilityLabelProps) => {
    let bikeAvailabilityDate = intl.formatMessage({
        id: bikeInfo.inStock
            ? 'status.inStock'
            : bikeInfo.availableFrom == null
                ? 'status.Unavailable'
                : 'status.outStock',
    });

    let batteryAvailabilityDate = null;
    if (batteryInfo) {
        batteryAvailabilityDate = intl.formatMessage({
            id: batteryInfo!.inStock
                ? 'status.inStock'
                : batteryInfo!.availableFrom == null
                    ? 'status.Unavailable'
                    : 'status.outStock',
        });
    }

    let deliveryDate = null;
    if (!bikeInfo.inStock && bikeInfo.availableFrom !== null) {
        deliveryDate = dayjs(bikeInfo.availableFrom || '');
    }
    if (batteryInfo && !batteryInfo.inStock && batteryInfo.availableFrom !== null) {
        if (!deliveryDate) {
            deliveryDate = dayjs(batteryInfo.availableFrom || '');
        } else if (dayjs(batteryInfo.availableFrom || '').isAfter(deliveryDate)) {
            deliveryDate = dayjs(batteryInfo.availableFrom || '');
        }
    }

    return (
        <>
            <Typography
                style={{
                    backgroundColor: '#e0e0e0',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    fontSize: '0.8125rem',
                }}
                data-testid="product-availability-badge"
                variant="subtitle2"
                gutterBottom
            >
                {`${intl.formatMessage({ id: 'returns.bike' })}: ${bikeAvailabilityDate}`}
                <br />
                {batteryInfo && `${intl.formatMessage({ id: 'returns.typeBattery' })}: ${batteryAvailabilityDate}`}
            </Typography>
            {deliveryDate && (
                <Chip
                    data-testid="product-availability-date-badge"
                    style={{ lineHeight: 'inherit' }}
                    label={`${intl.formatMessage({
                        id: 'status.expectedAvailabilityFrom',
                    })} ${deliveryDate.format('DD/MM/YYYY')}`}
                />
            )}
        </>
    );
};

export default injectIntl(ComposedProductAvailabilityLabel);
