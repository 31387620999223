import localStorage from 'localstorage-ttl';

import { NavigateFunction } from 'react-router-dom';
import { AppPageRoutes } from 'src/services/api/models';
import { StorageNameSpace } from 'src/utils/storageNameSpace';

export const redirectAfterLogin = (navigate: NavigateFunction) => {
    const redirectionUrl = localStorage.get(StorageNameSpace.REDIRECTION);

    if (redirectionUrl) {
        // Clearing redirection from local storage

        localStorage.set(StorageNameSpace.REDIRECTION, undefined);

        navigate(redirectionUrl);
    } else {
        navigate(AppPageRoutes.DASHBOARD);
    }
};
