// Use this service to write or modify the search params

// @getQueryParams (with @keys : string[] are an array of params key)
// It will return the current value from URL for those keys passed
// Example: current search params is '?key=value&key1=value1'
// We call QueryParams.getQueryParams(['key', 'key1']);
// we will get {key: 'value', key1: 'value1'}
// For changing those values we can use @setQueryParams
// Example: QueryParams.setQueryParams({key: 'newValue' })
// The search params url will change to '?key=newValue&key1=value1'
class QueryParams {
    private searchObj: Record<string, string>;
    private extraQueries: string[] = ['token', 'code', 'state'];

    constructor() {
        const currentSearchObj = Object.fromEntries(new URLSearchParams(window.location.search));
        Object.keys(currentSearchObj).forEach(key => {
            if (this.extraQueries.includes(key)) {
                delete currentSearchObj[key];
            }
        });

        this.searchObj = currentSearchObj;
    }

    parseSearchObj(searchString: string) {
        const currentSearchObj = Object.fromEntries(new URLSearchParams(searchString));
        Object.keys(currentSearchObj).forEach(key => {
            if (this.extraQueries.includes(key)) {
                delete currentSearchObj[key];
            }
        });

        this.searchObj = currentSearchObj;
    }

    getQueryParams(keys: string[], searchString?: string) {
        if (searchString) {
            this.parseSearchObj(searchString);
        }

        const obj: Record<string, undefined | string | string[]> = {};

        keys.forEach(key => {
            const currentValueKey = this.searchObj[key];

            const parseCurrentValue = currentValueKey?.split('+').map(v => decodeURIComponent(v));

            if (parseCurrentValue && parseCurrentValue.length < 2) {
                obj[key] = parseCurrentValue[0];
            } else {
                obj[key] = parseCurrentValue;
            }
        });

        return obj;
    }

    setQueryParams(newValues: Record<string, undefined | string | string[]>) {
        for (const key in newValues) {
            if (newValues[key]?.length) {
                let tempNewValue = newValues[key];

                // if value is an array, join it to a string with +
                if (Array.isArray(tempNewValue)) {
                    tempNewValue = tempNewValue.map(v => encodeURIComponent(v)).join('+');
                }

                if (tempNewValue) {
                    // modify the value in the search object
                    this.searchObj[key] = tempNewValue;
                }
            } else {
                // if no new value, delete the key from the search object
                delete this.searchObj[key];
            }
        }

        const searchParams = new URLSearchParams(this.searchObj);
        const searchString = searchParams.toString();

        if (searchString) {
            const newUrl = window.location.origin + window.location.pathname + `?${searchString.toString()}`;

            // write modified search object to URL without refreshing page
            window.history.pushState({}, '', newUrl);
        } else {
            const cleanUrl = window.location.origin + window.location.pathname;
            window.history.pushState({}, '', cleanUrl);
        }
    }

    resetQueryParams() {
        this.searchObj = {};
    }
}

export default new QueryParams();
