export interface TranslationCell {
    columnId: string;
    sourceStatus?: string;
    dependencyStatus?: string;
    value: string;
}

export interface TranslationItem {
    id: string;
    cells: TranslationCell[];
}

export interface Translations {
    [key: string]: {
        [key: string]: string;
    };
}

export const langColumnIdMap: Record<string, string> = {
    de: 'de',
    enGB: 'en-GB',
    nlNL: 'nl-NL',
};