import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Store from 'src/stores/RootStore';
import ProductDetailsContent from './ProductDetailsContent';

interface ProductDetailsDialogProps extends WithStyles<typeof styles> {
    store?: typeof Store.Type;
    setCartTypeToSparePart: () => void;
}

const ProductDetailsDialog = inject('store')(
    observer(({ store, classes, setCartTypeToSparePart }: ProductDetailsDialogProps) => {
        const [quantity, setQuantity] = useState(1);
        const [reference, setReference] = useState('');

        const { isMarketingMaterialDetailDialogOpen: isOpen, selectedItem } = store!.marketingMaterials;

        const handleCloseDialog = () => {
            // close dialog
            store!.marketingMaterials.toggleMarketingMaterialDetailsDialog();
            // reset state
            setQuantity(1);
            setReference('');
        };

        return (
            <Dialog
                open={isOpen}
                scroll="body"
                onClose={handleCloseDialog}
                fullWidth
                maxWidth="sm"
                aria-labelledby="product-details-dialog-title"
                data-testid="product-details-dialog"
            >
                <DialogTitle id="product-detail-dialog-top">
                    <Typography data-testid="product-details-dialog-title" variant="h5">{`# ${
                        store!.marketingMaterials.selectedItemId
                    }`}</Typography>

                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={handleCloseDialog}
                        data-testid="close-product-details-dialog"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {selectedItem ? (
                        <ProductDetailsContent
                            product={selectedItem}
                            onCancelButton={handleCloseDialog}
                            reference={reference}
                            updateReference={setReference}
                            quantity={quantity}
                            updateQuantity={setQuantity}
                            setCartTypeToSparePart={setCartTypeToSparePart}
                        />
                    ) : (
                        <FormattedMessage id="dialog.productNotFound" />
                    )}
                </DialogContent>
            </Dialog>
        );
    }),
);

function styles({ spacing, palette }: Theme) {
    return createStyles({
        closeButton: {
            position: 'absolute',
            right: spacing(1),
            top: spacing(1),
            color: palette.grey[500],
        },
    });
}

export default withStyles(styles)(ProductDetailsDialog);
