import { types } from 'mobx-state-tree';

export type TMarketingMaterial = typeof MarketingMaterial.Type;

export const MarketingMaterial = types.model('MarketingMaterialModel', {
    id: types.number,
    caption: types.string,
    alt_text: types.string,
    image: types.maybe(types.string),
});
