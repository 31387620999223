import { faTruck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import { deepmerge } from '@mui/utils';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Button from 'src/components/button/Button';
import { ButtonCustomVariant } from 'src/components/button/type';
import CurrencyFormat from 'src/components/currency/Currency';
import { defaultTheme } from 'src/pages/modules/withRoot';
import { AppModules } from 'src/services/api/models';
import RoleBaseAccess from 'src/services/RoleBaseAccess';
import RootStore from 'src/stores/RootStore';
import { TOrder, TOrderLine } from 'src/stores/types/OrdersTypes';
import { GridCell } from './GridCell';

interface OrderLinesProps {
    store?: typeof RootStore.Type;
    order: TOrder;
}

const OrderLines = inject(
    'user',
    'store',
)(
    observer(({ store, order }: OrderLinesProps) => {
        const isDisplayDealerPrices = RoleBaseAccess.isModuleAllowed(AppModules.UNIT_PRICE_IN_ORDER_DETAIL_POPUP);
        const lines = order?.lines;

        return (
            <>
                <Grid
                    container
                    sx={{
                        borderBottom: `1px solid ${theme.palette.secondary.light}`,
                        paddingBottom: '.5rem',
                        marginBottom: '.5rem',
                    }}
                >
                    <GridCell xs={1} textID="orders.no" />
                    <GridCell xs={isDisplayDealerPrices ? 4 : 6} textID="orders.description" />
                    <GridCell xs={isDisplayDealerPrices ? 2 : 4} textID="orders.reference" />
                    <GridCell xs={0.5} textID="orders.quantity" alignRight />

                    {isDisplayDealerPrices && (
                        <>
                            <GridCell xs={1} textID="orders.unitPrice" alignRight />
                            <GridCell xs={1} textID="orders.discount" alignRight />
                            <GridCell xs={2} textID="orders.total" alignRight />
                            <GridCell xs={0.5} textID="orders.trackOrder" alignRight />
                        </>
                    )}
                </Grid>

                {/* TODO Verify the lines duplicated? or probably should change quantity */}
                {lines &&
                    lines.map((line: TOrderLine, index: number) => (
                        <Grid
                            key={`orderLine_${line.code}_${index}`}
                            container
                            sx={{ margin: '.5rem 0', alignItems: 'center', opacity: line.productType === '' ? 0.6 : 1 }}
                        >
                            <GridCell xs={1}>{line.code}</GridCell>
                            <GridCell xs={isDisplayDealerPrices ? 4 : 6} textEllipsis>
                                {line.description}
                            </GridCell>

                            {order?.isBeingEdited ? (
                                <Grid item xs={isDisplayDealerPrices ? 2 : 4}>
                                    <ThemeProvider theme={theme}>
                                        <TextField
                                            data-testid={`editable-order-line-${index}`}
                                            InputProps={{
                                                sx: {
                                                    border: `1px solid ${theme.palette.primary.main}`,
                                                    '&.Mui-focused': {
                                                        border: `1px solid ${theme.palette.secondary.main}`,
                                                    },
                                                    '& > fieldset': {
                                                        border: 'none',
                                                    },
                                                },
                                            }}
                                            inputProps={{
                                                maxLength: 20,
                                            }}
                                            id={`referencebox${index}`}
                                            variant="outlined"
                                            color="secondary"
                                            value={store!.orders.selected!.getLineReferenceById(line.orderItemId)}
                                            onChange={event => {
                                                store!.orders.selected!.updateLineReference(
                                                    line.orderItemId,
                                                    event.target.value,
                                                );
                                            }}
                                        />
                                    </ThemeProvider>
                                </Grid>
                            ) : (
                                <GridCell xs={isDisplayDealerPrices ? 2 : 4} textEllipsis>
                                    {line.reference}
                                </GridCell>
                            )}

                            <GridCell xs={0.5} alignRight>
                                {line.quantity || 0}
                            </GridCell>

                            {isDisplayDealerPrices && (
                                <>
                                    <GridCell xs={1} alignRight>
                                        <CurrencyFormat value={line.unitPrice || 0} />
                                    </GridCell>
                                    <GridCell xs={1} alignRight>{`${line.discountPercentage || 0} %`}</GridCell>
                                    <GridCell xs={2} alignRight>
                                        <CurrencyFormat value={line.total || 0} />
                                    </GridCell>
                                    <Grid xs={0.5} display="flex" justifyContent="center">
                                        {line.trackingUrl ? (
                                            <a target="_blank" rel="noopener noreferrer" href={line.trackingUrl}>
                                                <Button customVariant={ButtonCustomVariant.ICON_BUTTON} color="primary">
                                                    <FontAwesomeIcon icon={faTruck} size="xs" />
                                                </Button>
                                            </a>
                                        ) : (
                                            '-'
                                        )}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    ))}
            </>
        );
    }),
);

const theme = createTheme(
    deepmerge(defaultTheme, {
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        padding: '4px',
                    },
                },
            },
        },
    }),
);

export default OrderLines;
