import { AppModules, AppPageRoutes } from 'src/services/api/models';
import { RoleForbiddenAccessDefinitionsType } from 'src/services/RoleBaseAccess';

export enum StageEnviroments {
    LOCAL = 'localhost',
    DEVELOPMENT = 'dev',
    STAGING = 'stage',
    PRODUCTION = 'prod',
}

export enum ViewModeEnum {
    CONSUMER = 'CONSUMER',
    DEALER = 'DEALER',
}

export enum UserRoles {
    MECHANIC = 'Mechanic',
    EMPLOYEE = 'Employee',
    OWNER = 'Owner',
    RETAIL = 'B2Retailer',
}

export enum SearchType {
    FRAME_NUMBER = 'frameNumber',
    PRODUCT_CODE = 'productCode',
    SPARE_PART_SEARCH = 'sparePartSearch',
    INVOICE_SEARCH = 'invoiceSearch',
}

export enum SparePartCategory {
    GENERAL = 'General',
    BATTERIES = 'Batteries',
    OTHER = 'Other',
    UNUSED = 'Unused',
}

export enum ReturnFlowType {
    NONE = 'none',
    PART = 'sparePart',
    BATTERY = 'battery',
    UNUSED = 'unused',
}

export enum CartReturnItemParenType {
    invoice = 'invoice',
    bike = 'bike',
}

export enum AvailabilityOptions {
    NOW = 'now',
    TWO_WEEKS = 'two_weeks',
    THREE_WEEKS = 'three_weeks',
    ONE_MONTH = 'one_month',
    OTHER = 'other',
}

export type AvailabilityOptionsKeys = keyof typeof AvailabilityOptions;

export enum ProductCategoryTypes {
    BIKE = 'bike',
    PART = 'sparePart',
    BATTERY = 'battery',
    MARKETING = 'Marketing',
}

// Image folder name in https://cloudinary.com
export const ImagesFolder = {
    [ProductCategoryTypes.BIKE]: 'bikes',
    [ProductCategoryTypes.PART]: 'parts',
    [ProductCategoryTypes.BATTERY]: 'parts',
    [ProductCategoryTypes.MARKETING]: 'marketing-materials',
};

export const SeriesList: Record<string, string> = {
    All: '',
    Atlas: 'Performance Q',
    'Premium Q': 'Premium Q',
    Mira: 'Mira',
    'Premium I': 'Premium I',
    Performance: 'Performance',
    Premium: 'Premium',
    Favorites: 'Favorites',
};

export enum DefaultValues {
    ImageNotFound = 'https://qwic.nl/app/uploads/2018/06/Performance_RD10speed_Illustrated_Blueprints_Orange_WEB-670x473.jpg',
    BatteryUpgradeLogo = 'https://qwic.nl/app/uploads/2023/06/battery-upgrade-icon.png',
    KnowledgeBaseImage = 'https://res.cloudinary.com/dmsstxj50/image/upload/s--t4sO36c6--/c_scale,h_600/v1695208869/centraal/media/qwic_kb_image.jpg',
    QwicAcademyImage = 'https://res.cloudinary.com/dmsstxj50/image/upload/s--jgFLNWhM--/c_scale,h_600/v1695208735/centraal/media/qwic_academy_image.jpg',
    DownloadImage = 'https://res.cloudinary.com/dmsstxj50/image/upload/s--hOvu3A40--/c_scale,h_600/v1695210415/centraal/media/qwic_downloadds_image.jpg',
}

export enum userStateDbColumns {
    favorites = 'Favorites',
    cart = 'Cart',
    returnItemsCart = 'ReturnItemsCart',
}

export enum GPSActivationStatus {
    OK = 'OK',
    ERROR_NOT_EXIST_FR = 'ERROR_NOT_EXIST_FR',
    ERROR_NOT_ATLAS_BIKE = 'ERROR_NOT_ATLAS_BIKE',
    ERROR_MISSING_FN_OR_IMEI = 'ERROR_MISSING_FN_OR_IMEI',
    ERROR_REPEATED_IMEI = 'ERROR_REPEATED_IMEI',
    ERROR_MESSAGE_NO_VALUE = 'ERROR_MESSAGE_NO_VALUE',
    ERROR_MESSAGE_NO_GENERAL_SETTINGS = 'ERROR_MESSAGE_NO_GENERAL_SETTINGS',
}

export const GPSActivationTranslationKeys = {
    [GPSActivationStatus.OK]: 'gps.form.success',
    [GPSActivationStatus.ERROR_NOT_EXIST_FR]: 'gps.form.errors.not_exist_frame_number',
    [GPSActivationStatus.ERROR_NOT_ATLAS_BIKE]: 'gps.form.errors.not_atlas_bike',
    [GPSActivationStatus.ERROR_MISSING_FN_OR_IMEI]: 'gps.form.errors.missing_frame_number_or_imei',
    [GPSActivationStatus.ERROR_REPEATED_IMEI]: 'gps.form.errors.used_imei',
    [GPSActivationStatus.ERROR_MESSAGE_NO_VALUE]: 'gps.form.errors.message_no_value',
    [GPSActivationStatus.ERROR_MESSAGE_NO_GENERAL_SETTINGS]: 'gps.form.errors.message_no_general_settings',
};

export const OrderNotificationChangedField = {
    DELIVERY_DATE: 'Delivery_date__c',
    STATUS: 'Status',
};

export const TEN_MINUTES = 1000 * 60 * 10;

export const sparePartSearchOptions = [
    {
        value: SearchType.FRAME_NUMBER,
        inputLabelId: 'search.input_label_frameNumbers',
        selectLabelId: 'search.select_label_frameNumbers',
        display: false,
    },
    {
        value: SearchType.PRODUCT_CODE,
        inputLabelId: 'search.input_label_models',
        selectLabelId: 'search.select_label_models',
        display: true,
    },
    {
        value: SearchType.SPARE_PART_SEARCH,
        inputLabelId: 'search.input_label_spareParts',
        selectLabelId: 'search.select_label_spareParts',
        display: true,
    },
    {
        value: SearchType.INVOICE_SEARCH,
        inputLabelId: 'search.input_label_invoice',
        selectLabelId: 'search.select_label_invoice',
        display: true,
    },
];

export const DEFAULT_ITEMS_PER_PAGE = 20;

export const RoleForbiddenAccessDefinitions: RoleForbiddenAccessDefinitionsType = {
    [UserRoles.OWNER]: {
        routes: [],
        modules: [],
    },
    [UserRoles.MECHANIC]: {
        routes: [AppPageRoutes.INVOICES, AppPageRoutes.ORDER_NOTIFICATIONS],
        modules: [AppModules.VIEW_MODE_TOGGLE, AppModules.UNIT_PRICE_IN_ORDER_DETAIL_POPUP],
    },
    [UserRoles.EMPLOYEE]: {
        routes: [
            AppPageRoutes.INVOICES,
            AppPageRoutes.RETURN_REQUESTS,
            AppPageRoutes.NEW_RETURN_REQUEST,
            AppPageRoutes.ORDERS,
        ],
        modules: [AppModules.DASHBOARD_RECENT_ORDERS, AppModules.REQUEST_RETURN_ITEMS, AppModules.ORDER_PRODUCTS],
    },
    [UserRoles.RETAIL]: {
        routes: [AppPageRoutes.PRODUCTS],
        modules: [AppModules.ORDER_BIKES],
    },
};

export const UserAuth0Namespace = 'https://dealer.qwic.nl/customClaims';

export const salesForceLangCode: { [key: string]: string } = {
    'nl-NL': 'nl_NL',
    'en-GB': 'en_GB',
    de: 'de',
};

export const MIN_CHAR_SPARE_PARTS_SEARCH = 3;

export enum dotColorMapInvoicePaymentStatus {
    Open = 'blue',
    Pending = 'yellow',
    Failed = 'orange',
    Paid = 'green',
    Overdue = 'red',
}

export const oneMB = 1024 * 1024;

// This type is use for translation id for return info box
enum InfoArticlesType {
    'Q_BATTERY' = 'q_battery',
    'PREMIUM_I_BATTERY' = 'premium_i_battery',
    'PERFORMANCE_BATTERY' = 'performance_battery',
    'BAFANG_M420_MOTOR' = 'bafang_m420_motor',
    'BAFANG_M200_MOTOR' = 'bafang_m200_motor',
    'BAFANG_C18_DISPLAY' = 'bafang_c18_display',
    'CROCO_DISPLAY' = 'croco_display',
}

// Articles translation id for every item type
export const InfoArticlesItems: Record<string, string[]> = {
    [InfoArticlesType.Q_BATTERY]: [`${InfoArticlesType.Q_BATTERY}.item1`, `${InfoArticlesType.Q_BATTERY}.item2`],
    [InfoArticlesType.PREMIUM_I_BATTERY]: [
        `${InfoArticlesType.PREMIUM_I_BATTERY}.item1`,
        `${InfoArticlesType.PREMIUM_I_BATTERY}.item2`,
        `${InfoArticlesType.PREMIUM_I_BATTERY}.item3`,
    ],
    [InfoArticlesType.PERFORMANCE_BATTERY]: [
        `${InfoArticlesType.PERFORMANCE_BATTERY}.item1`,
        `${InfoArticlesType.PERFORMANCE_BATTERY}.item2`,
        `${InfoArticlesType.PERFORMANCE_BATTERY}.item3`,
    ],
    [InfoArticlesType.BAFANG_M420_MOTOR]: [
        `${InfoArticlesType.BAFANG_M420_MOTOR}.item1`,
        `${InfoArticlesType.BAFANG_M420_MOTOR}.item2`,
        `${InfoArticlesType.BAFANG_M420_MOTOR}.item3`,
    ],
    [InfoArticlesType.BAFANG_M200_MOTOR]: [
        `${InfoArticlesType.BAFANG_M200_MOTOR}.item1`,
        `${InfoArticlesType.BAFANG_M200_MOTOR}.item2`,
        `${InfoArticlesType.BAFANG_M200_MOTOR}.item3`,
    ],
    [InfoArticlesType.BAFANG_C18_DISPLAY]: [
        `${InfoArticlesType.BAFANG_C18_DISPLAY}.item1`,
        `${InfoArticlesType.BAFANG_C18_DISPLAY}.item2`,
    ],
    [InfoArticlesType.CROCO_DISPLAY]: [
        `${InfoArticlesType.CROCO_DISPLAY}.item1`,
        `${InfoArticlesType.CROCO_DISPLAY}.item2`,
        `${InfoArticlesType.CROCO_DISPLAY}.item3`,
    ],
};

// Mapping translation id key to product code
export const InfoArticlesMap: Record<string, string> = {
    BA00100: InfoArticlesType.Q_BATTERY,
    BA00101: InfoArticlesType.Q_BATTERY,
    BA00105: InfoArticlesType.Q_BATTERY,
    BA00106: InfoArticlesType.Q_BATTERY,
    BA00125: InfoArticlesType.PREMIUM_I_BATTERY,
    BA00130: InfoArticlesType.PREMIUM_I_BATTERY,
    BA00060: InfoArticlesType.PERFORMANCE_BATTERY,
    '11920': InfoArticlesType.BAFANG_M420_MOTOR,
    '12006': InfoArticlesType.BAFANG_M420_MOTOR,
    '12007': InfoArticlesType.BAFANG_M420_MOTOR,
    '12008': InfoArticlesType.BAFANG_M420_MOTOR,
    '12207': InfoArticlesType.BAFANG_M200_MOTOR,
    '12354': InfoArticlesType.BAFANG_M200_MOTOR,
    '11921': InfoArticlesType.BAFANG_C18_DISPLAY,
    '12453': InfoArticlesType.BAFANG_C18_DISPLAY,
    '11649': InfoArticlesType.CROCO_DISPLAY,
    '12291': InfoArticlesType.CROCO_DISPLAY,
};

export const FREE_SHIPPING_THRESHOLD = 150;
export const SHIPPING_FLAT_RATE = 7.5;

export enum UPLOAD_SOURCE {
    RETURN_INVOICE = 'returns-request-invoice',
    RETURN_BATTERY_PHOTO = 'returns-request-battery-photo',
    CONTACT_FORM = 'contact-form',
}

// Supplier that accept claims directly
export const DirectSupplierBrands = ['bafang', 'brose', 'enviolo', 'shimano', 'magura', 'axa'];
