import { Field, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { FormattedMessage } from 'react-intl';
import { ReturnItemFormValues } from '../ReturnRequestFormv2';

export const ReferenceField = ({ errors, ...formikProps }: FormikProps<ReturnItemFormValues>) => {
    return (
        <Field
            label={<FormattedMessage id="returns.form_label_reference" />}
            name="reference"
            variant="outlined"
            fullWidth
            margin="normal"
            error={errors.reference}
            component={TextField}
        />
    );
};
