import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import localStorage from 'localstorage-ttl';
import makeInspectable from 'mobx-devtools-mst';
import { Provider } from 'mobx-react';
import { onSnapshot } from 'mobx-state-tree';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import Auth0ProviderWithHistory from 'src/auth0/Auth0ProviderWithHistory';
import './css/fonts.css';
import './css/swiper-overrides.css';
import App from './pages/App';
import Security from './services/Security';
import ModalStore from './stores/ModalStore';
import PersistentStore from './stores/PersistentStore';
import Store from './stores/RootStore';
import UserStore from './stores/UserStore';
import CONFIG from './utils/config';
import { StorageNameSpace } from './utils/storageNameSpace';

let initialUserState = {};
let initialPersistentState = {};

if (Cookies.get(StorageNameSpace.USER)) {
    const userStoreJSON = Cookies.getJSON(StorageNameSpace.USER);
    if (UserStore.is(userStoreJSON)) {
        initialUserState = userStoreJSON;
    }
}

if (!!localStorage.get(StorageNameSpace.PERSISTENT)) {
    initialPersistentState = JSON.parse(localStorage.get(StorageNameSpace.PERSISTENT));
}

const store = Store.create({});
const persistentStore = PersistentStore.create(initialPersistentState);
const userStore = UserStore.create(initialUserState);

// inspect mst stores with mobx-devtools-mst
makeInspectable(store);
makeInspectable(persistentStore);
makeInspectable(userStore);

// Initializing correct date from previous data, if available
if (!!localStorage.get(StorageNameSpace.ORDER_NOTIFICATIONS)) {
    const { unreadFrom } = JSON.parse(localStorage.get(StorageNameSpace.ORDER_NOTIFICATIONS));
    if (dayjs(unreadFrom).isValid()) {
        store.orderNotifications.changeUnreadFromDateValue(dayjs(unreadFrom));
    }
}

// * Expires in 2 hours
// * It needs to be configured in salesforce profiles as well
// * this is cleaned up after users log out
onSnapshot(userStore, snapshot => {
    Security.saveUser(snapshot);
});

// * Does NOT expire
// * this is not cleaned up after users log out
onSnapshot(persistentStore, snapshot => {
    localStorage.set(StorageNameSpace.PERSISTENT, JSON.stringify(snapshot));
});

// Sentry init
let dsn;
if (CONFIG.ADD_SENTRY_RELEASE) {
    dsn = CONFIG.SENTRY_DSN;
    console.info('Set dsn for Sentry init');
}

Sentry.init({
    dsn,
    environment: CONFIG.APP_ENVIRONMENT,
    dist: CONFIG.SENTRY_RELEASE,
    release: CONFIG.SENTRY_RELEASE,
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
        // Tracking browser performance
        new BrowserTracing({
            // Integration with react router v6
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        }),

        new Sentry.Replay(),
    ],
});

// @ts-ignore
window.triggerSentry = () => {
    setTimeout(() => {
        throw new Error('Testing Sentry!');
    }, 100);
};

ReactDOM.render(
    <Provider persistentStore={persistentStore} store={store} user={userStore} modal={ModalStore}>
        <BrowserRouter>
            <Auth0ProviderWithHistory>
                <App />
            </Auth0ProviderWithHistory>
        </BrowserRouter>
    </Provider>,
    document.querySelector('#root'),
);
