// A Map of existing crediting statuses, with number value keys
export const CreditingStatusMap = new Map();

CreditingStatusMap.set(0, { value: 0, color: 'default' }); // Pending
CreditingStatusMap.set(1, { value: 1, color: 'warning' }); // More information needed
CreditingStatusMap.set(2, { value: 2, color: 'success' }); // Approved
CreditingStatusMap.set(3, { value: 3, color: 'default' }); // Waiting for battery inspection
CreditingStatusMap.set(10, { value: 10, color: 'error' }); // Out-of-warranty, leniency pending
CreditingStatusMap.set(11, { value: 11, color: 'success' }); // Battery updated
CreditingStatusMap.set(12, { value: 12, color: 'error' }); // No defect found
CreditingStatusMap.set(13, { value: 13, color: 'error' }); // No warranty cover
CreditingStatusMap.set(14, { value: 14, color: 'error' }); // Denied
CreditingStatusMap.set(21, { value: 21, color: 'success' }); // Leniency applied

export const CreditingStatusKeys = Array.from(CreditingStatusMap.keys()).map(v => String(v));

export const RefundStatuses = ['Pending', '100%', '60%', '40%', '30%', '0%'];

// A Map of existing shipping statuses, with number value keys
export const ShippingStatusMap = new Map();

ShippingStatusMap.set(1, { value: 1, color: 'default' }); // Pending
ShippingStatusMap.set(2, { value: 2, color: 'default' }); // No pick-up needed
ShippingStatusMap.set(3, { value: 3, color: 'default' }); // Minimum return value not yet reached
ShippingStatusMap.set(10, { value: 10, color: 'warning' }); // Send part back
ShippingStatusMap.set(20, { value: 20, color: 'default' }); // Pick-up requested
ShippingStatusMap.set(21, { value: 21, color: 'default' }); // Collected
ShippingStatusMap.set(22, { value: 22, color: 'default' }); // Arrived
ShippingStatusMap.set(30, { value: 30, color: 'default' }); // Battery returned to dealer

export const ShippingStatusKeys = Array.from(ShippingStatusMap.keys()).map(v => String(v));
