import { Box, Typography, useTheme } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import UserStore from 'src/stores/UserStore';
import { ContactForm } from './ContactForm';
import { ContactInformation } from './ContactInformation';

interface ContactProps {
    user?: typeof UserStore.Type;
}

const Contact = inject('user')(
    observer(({ user }: ContactProps) => {
        const { spacing } = useTheme();
        const lang = user?.preferredLang;

        return (
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: spacing(2) }}>
                    <Typography variant="h5">
                        <FormattedMessage id="menu.contact" />
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexWrap: 'wrap',
                        gap: '2rem',
                        justifyContent: 'space-between',
                    }}
                >
                    <ContactForm />
                    <ContactInformation lang={lang} />
                </Box>
            </Box>
        );
    }),
);

export default Contact;
