import { Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { TProductModel } from 'src/stores/types/ProductTypes';
import ProductCard from './ProductCard';

interface ProductListProps extends WithStyles<typeof styles> {
    products: TProductModel[];
    selectProduct: (itemCode: string) => void;
}

const ProductGrid = ({ classes, products, selectProduct }: ProductListProps) => (
    <div data-testid="products-grid">
        {products.length > 0 ? (
            products.map((item: TProductModel) => (
                <ProductCard key={item.id} product={item} selectProduct={selectProduct} />
            ))
        ) : (
            <Typography className={classes.emptyList} variant="h6" color="secondary">
                <FormattedMessage id="products.no_items_available" />
            </Typography>
        )}
    </div>
);

function styles() {
    return createStyles({
        emptyList: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '500px',
        },
    });
}

export default withStyles(styles)(ProductGrid);
