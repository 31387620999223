import { styled, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import SearchSpareParts from 'src/components/SearchSpareParts';
import { fadeInDownKeyFrames } from 'src/components/styles';
import QueryParams from 'src/services/QueryParamsService';
import Store from 'src/stores/RootStore';
import { SearchType } from 'src/utils/constants';
import SparePartsList from './SparePartsList';

const StyledForm = styled('div')(() => ({
    animation: `${fadeInDownKeyFrames} 0.5s ease-out`,
    transition: 'all .3s ease',
    margin: '1rem 0',
}));

interface SparePartsPageProps {
    path: string;
    store?: typeof Store.Type;
}

const SparePartsPage = inject('store')(
    observer(({ store }: SparePartsPageProps) => {
        const [sparePartFilterText, setSparePartFilterText] = useState('');
        const [searchedTermText, setSearchedTermText] = useState('');

        const clearSearchResults = () => {
            setSearchedTermText('');
            setSparePartFilterText('');
            store!.spareParts.searchResult.clearResult();
            QueryParams.setQueryParams({ searchInput: '', categories: '' });
        };

        const spareParts = useMemo(
            () => store!.spareParts.filterSparePartResults(sparePartFilterText, false),
            [store!.spareParts.searchResult.spareParts.length, sparePartFilterText], // eslint-disable-line
        );

        const showTextFilter = store!.spareParts.selectedSearchType !== SearchType.SPARE_PART_SEARCH;

        return (
            <div>
                <Typography variant="h5">
                    <FormattedMessage id="menu.spare_parts" />
                </Typography>

                <StyledForm>
                    <SearchSpareParts
                        clearResults={clearSearchResults}
                        setSearchedTermText={setSearchedTermText}
                        parentBike={store!.spareParts.searchResult.parentBike}
                    />
                </StyledForm>
                {!store!.spareParts.loading && searchedTermText !== '' && (
                    <SparePartsList
                        spareParts={spareParts}
                        showTextFilter={showTextFilter}
                        textFilter={sparePartFilterText}
                        onChangeTextFilter={setSparePartFilterText}
                        notFoundText={sparePartFilterText || searchedTermText}
                    />
                )}
            </div>
        );
    }),
);

const pageWithTrackWindowScroll = trackWindowScroll(SparePartsPage);

export default pageWithTrackWindowScroll;
