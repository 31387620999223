import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { TCartReturnItemModel } from 'src/stores/types/ReturnCartItemModel';
import { CartItem } from './CartItem';

interface RemoveItemDialogProps {
    open: boolean;
    item?: TCartReturnItemModel;
    onCancel: () => void;
    onConfirm: () => void;
}

export const RemoveItemDialog = ({ open, item, onCancel, onConfirm }: RemoveItemDialogProps) => {
    return (
        <Dialog open={open && !!item} maxWidth="xs">
            <DialogTitle>
                <FormattedMessage id="returnsCart.delete.warning.title" />
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: 0 }}>{item && <CartItem item={item} />}</DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', padding: '1rem 24px' }}>
                <Button variant="outlined" fullWidth onClick={onCancel}>
                    <FormattedMessage id="labels.cancel" />
                </Button>
                <Button variant="contained" fullWidth onClick={onConfirm}>
                    <FormattedMessage id="labels.remove" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
