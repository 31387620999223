import { CardMedia, FormControl, MenuItem, Paper, Typography, styled } from '@mui/material';
import Downshift from 'downshift';
import { FormikProps } from 'formik';
import Fuse from 'fuse.js';
import deburr from 'lodash/deburr';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useIntl } from 'react-intl';
import bikeNotFoundImage from 'src/assets/bike-notfound.png';
import { GACategories } from 'src/services/Analytics';
import Store from 'src/stores/RootStore';
import { SearchType, sparePartSearchOptions } from 'src/utils/constants';
import { convertImageToThumbnail } from 'src/utils/convertImageSize';
import { useDebounce } from 'src/utils/useDebounce';
import { BikeAutocompleteListItem } from './BikeAutocompleteListItem';
import InputField from './InputField';
import PageLoader from '../PageLoader';

const StyledPaper = styled(Paper)(() => ({
    minHeight: '60px',
    maxHeight: 'calc(50vh - 5em)',
    width: 'fit-content',
    minWidth: '350px',
    overflow: 'scroll',
}));

export interface BikesSuggestion {
    label: string;
    id: string;
    imageUrl?: string | null;
    description?: string;
}

interface FormValues {
    searchInput: string;
    categories?: SearchType;
}

interface SearchPartsInputProps {
    store?: typeof Store.Type;
    setSearchedTermText: (id: string) => void;
    searchInput: string;
    setSearchInput: (value: string) => void;
}

const SearchPartsInput = inject('store')(
    observer(
        ({
            store,
            setSearchedTermText,
            searchInput,
            setSearchInput,
            ...props
        }: SearchPartsInputProps & FormikProps<FormValues>) => {
            const intl = useIntl();

            const { values, setFieldValue, isSubmitting, initialValues, setFieldError } = props;
            const { loading, autocompleteLoading, selectedSearchType } = store!.spareParts;

            useEffect(() => {
                if (!store!.spareParts.bikesInService.size) {
                    store!.spareParts.getBikesInService();
                }
            }, [store]);

            // fire on click on option
            const handleChangeDownShift = (selected: BikesSuggestion | null) => {
                if (selectedSearchType === SearchType.PRODUCT_CODE && selected) {
                    // cleanup
                    setSearchedTermText('');
                    store!.spareParts.searchResult.clearResult();

                    // select bike
                    setSearchedTermText(selected.id);
                    setSearchInput(selected.label);
                    store!.spareParts.getSparePartsBy(selected.id, selectedSearchType, true);

                    // Sending search spare parts string to GA
                    ReactGA.event({
                        category: GACategories.SpareParts,
                        action: 'User searched by model in spare parts page',
                        label: selected.id,
                    });
                }

                if (selectedSearchType === SearchType.FRAME_NUMBER && selected) {
                    setSearchInput(selected.id);
                }
            };

            const handleOnBlur = (e: any) => {
                e.preventDefault();
                setFieldValue('searchInput', e.target.value?.trim());
            };

            const handleOnKeyDown = (event: any) => {
                if (event.key === 'Enter') {
                    // Prevent Downshift's default 'Enter' behavior.
                    event.nativeEvent.preventDownshiftDefault = true;

                    setFieldValue('searchInput', event.target.value?.trim());
                }
            };

            const handleOuterClick = (state: any) => {
                // Set downshift state to the updated formik value from handleOnBlur
                state.setState({
                    inputValue: values.searchInput,
                });
            };

            const getSuggestions = (suggestions: BikesSuggestion[]) => (input: any) => {
                let fuse = new Fuse(suggestions, {
                    shouldSort: true,
                    threshold: 0.6,
                    location: 0,
                    distance: 100,
                    minMatchCharLength: 3,
                    keys: ['label', 'id'],
                });

                const normalizedInput = deburr(input.trim()).toLowerCase();
                const results = fuse.search(normalizedInput).map(suggestion => suggestion.item);

                return [...results];
            };

            let findBikeModelSuggestions = getSuggestions(store!.spareParts.bikesInServiceList);
            let bikeFrameSuggestions = store!.spareParts.bikesAutocomleteList;

            // Use debounce to throttle requests on input change
            const debouncedSearchInput = useDebounce(searchInput, 1000);

            const handleAutocompleteFrameNumber = (frameNumber: string) => {
                if (frameNumber.length > 2) {
                    store?.spareParts.getBikesAutocomplete(frameNumber);
                }
            };

            // Auto request bikes for autocomplete search by frame number
            useEffect(() => {
                if (selectedSearchType === SearchType.FRAME_NUMBER) {
                    handleAutocompleteFrameNumber(debouncedSearchInput);
                }
            }, [debouncedSearchInput, selectedSearchType]);

            return (
                <FormControl sx={{ minWidth: 250, flex: 1 }}>
                    <Downshift
                        onChange={handleChangeDownShift}
                        inputId="searchInput"
                        itemToString={item => (item ? item.label : '')}
                        onOuterClick={handleOuterClick}
                        initialInputValue={initialValues.searchInput}
                    >
                        {({ getInputProps, getItemProps, getMenuProps, highlightedIndex, inputValue, isOpen }) => (
                            <div>
                                <InputField
                                    loading={loading || isSubmitting}
                                    disableSearch={isSubmitting}
                                    hideSearchButton={selectedSearchType === SearchType.PRODUCT_CODE}
                                    inputLabelId={
                                        sparePartSearchOptions.find(
                                            (value: { value: string }) => value.value === selectedSearchType,
                                        )?.inputLabelId || ''
                                    }
                                    placeholder={
                                        selectedSearchType === SearchType.FRAME_NUMBER
                                            ? intl.formatMessage({ id: 'frameNumber.placeholder' })
                                            : ''
                                    }
                                    {...getInputProps({
                                        onBlur: handleOnBlur,
                                        onKeyDown: handleOnKeyDown,
                                        value: searchInput,
                                        onChange: (e: any) => {
                                            setSearchInput(e.target.value);
                                            setFieldError('searchInput', undefined);
                                        },
                                    })}
                                />

                                <div {...getMenuProps()} style={{ position: 'absolute', zIndex: 999 }}>
                                    {isOpen && selectedSearchType === SearchType.PRODUCT_CODE && (
                                        <StyledPaper square>
                                            {findBikeModelSuggestions(inputValue).map((suggestion, index: number) => {
                                                return (
                                                    <MenuItem
                                                        {...getItemProps({ item: suggestion })}
                                                        key={suggestion.id}
                                                        sx={{ display: 'flex', alignItems: 'center', height: 'auto' }}
                                                        selected={highlightedIndex === index}
                                                        component="div"
                                                        style={{ fontWeight: 400 }}
                                                        data-testid={`search-by-model-result-${index}`}
                                                    >
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ width: '80px', height: 'auto', marginRight: '.5rem' }}
                                                            src={
                                                                suggestion.imageUrl
                                                                    ? convertImageToThumbnail(suggestion.imageUrl)
                                                                    : bikeNotFoundImage
                                                            }
                                                            title={suggestion.label}
                                                        />
                                                        <Typography>{suggestion.label}</Typography>
                                                    </MenuItem>
                                                );
                                            })}
                                        </StyledPaper>
                                    )}
                                    {isOpen &&
                                        !!bikeFrameSuggestions.length &&
                                        selectedSearchType === SearchType.FRAME_NUMBER && (
                                            <StyledPaper>
                                                {autocompleteLoading && <PageLoader />}

                                                {!autocompleteLoading &&
                                                    bikeFrameSuggestions.map((bike: BikesSuggestion) => (
                                                        <BikeAutocompleteListItem
                                                            {...getItemProps({ item: bike })}
                                                            key={bike.id}
                                                            bikeLabel={bike.label}
                                                            bikeDescription={bike.description}
                                                        />
                                                    ))}
                                            </StyledPaper>
                                        )}
                                </div>
                            </div>
                        )}
                    </Downshift>
                </FormControl>
            );
        },
    ),
);

export default SearchPartsInput;
