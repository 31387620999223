import ReactGA from 'react-ga';

interface AnyData {
    [key: string]: any;
}

export interface ImpressionData extends AnyData {
    id?: string;
    name?: string;
    list?: string;
    brand?: string;
    category?: string;
    variant?: string;
    position?: number;
    price?: number | null;
}

export interface ProductData extends AnyData {
    id?: string;
    name?: string;
    list?: string;
    brand?: string;
    category?: string;
    variant?: string;
    position?: number;
    price?: number | null;
    quantity?: number;
    coupon?: string;
}

export interface PromotionData extends AnyData {
    id?: string;
    name?: string;
    position?: number;
    creative?: string;
}

export interface ActionData extends AnyData {
    id: string;
    affiliation?: string;
    revenue?: number;
    tax?: number;
    shipping?: number;
    coupon?: string;
    list?: string;
    step?: number;
    option?: string;
}

export enum ProductAndPromotionActions {
    CLICK = 'click',
    DETAIL = 'detail',
    ADD = 'add',
    REMOVE = 'remove',
    CHECKOUT = 'checkout',
    CHECKOUT_OPTION = 'checkout_option',
    PURCHASE = 'purchase',
    REFUND = 'refund',
    PROMO_CLICK = 'promo_click',
}

export default class AnalyticsEcommerce {
    private static instance: AnalyticsEcommerce;

    private constructor() {
        // Set default currency code to EUR
        ReactGA.ga('set', 'currencyCode', 'EUR');
    }

    public static getInstance(): AnalyticsEcommerce {
        if (!AnalyticsEcommerce.instance) {
            AnalyticsEcommerce.instance = new AnalyticsEcommerce();
        }

        return AnalyticsEcommerce.instance;
    }

    send() {
        ReactGA.ga('send', '', '');
    }

    sendPageView() {
        ReactGA.ga('send', 'pageview', '');
    }

    with(callback: (instance: AnalyticsEcommerce) => void): this {
        callback(this);
        return this;
    }

    addImpression(data: ImpressionData): this {
        ReactGA.ga('ec:addImpression', data);
        return this;
    }

    setAction(action: ProductAndPromotionActions, ...args: string[]): this {
        ReactGA.ga('ec:setAction', action, ...args);
        return this;
    }

    addProduct(product: ProductData): this {
        ReactGA.ga('ec:addProduct', product);
        return this;
    }

    addProductToCart(product: ProductData): this {
        return this.addProduct(product).setAction(ProductAndPromotionActions.ADD);
    }

    removeProductFromCart(product: ProductData): this {
        return this.addProduct(product).setAction(ProductAndPromotionActions.REMOVE);
    }

    clear(): this {
        ReactGA.ga('ec:clear');
        return this;
    }
}
