import { FormControl, FormControlLabel, InputLabel, Stack } from '@mui/material';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import { FormattedMessage, useIntl } from 'react-intl';
import { GACategories } from 'src/services/Analytics';
import QueryParams from 'src/services/QueryParamsService';
import { MIN_CHAR_SPARE_PARTS_SEARCH, SearchType } from 'src/utils/constants';
import * as Yup from 'yup';
import Store from '../../stores/RootStore';
import IOSSwitch from '../IOSSwitch';
import SearchPartsInput from './SearchPartsInput';
import CategorySelect from './SearchSelect';

interface SearchSparePartFormProps {
    store?: typeof Store.Type;
    clearResults: () => void;
    setSearchedTermText: (text: string) => void;
    isReturnFlow: boolean;
}
interface FormValues {
    searchInput: string;
    categories?: SearchType;
}

const SearchSparePartForm = inject('store')(
    observer(({ store, isReturnFlow, clearResults, setSearchedTermText }: SearchSparePartFormProps) => {
        const intl = useIntl();

        const [isFrameNumberSearch, setIsFrameNumberSearch] = useState(true);
        const [searchInput, setSearchInput] = useState('');

        const { selectedSearchType } = store!.spareParts;

        const initialFormValues = useMemo(() => {
            // Current filters values from url
            const queries = QueryParams.getQueryParams(['categories', 'searchInput']);

            return {
                searchInput: queries?.searchInput || '', // i.e.'EFY6014543'
                categories: queries?.categories,
            } as FormValues;
        }, []);

        useEffect(() => {
            if (initialFormValues.categories) {
                store!.spareParts.setSelectedSearchType(initialFormValues.categories);
                setIsFrameNumberSearch(false);
            }
            if (initialFormValues.searchInput) {
                setSearchedTermText(initialFormValues.searchInput);
                setSearchInput(initialFormValues.searchInput);
                searchSpareParts(initialFormValues);
            }
        }, [initialFormValues.searchInput, initialFormValues.categories, setSearchedTermText, store]); // eslint-disable-line

        useEffect(() => {
            return () => {
                // reset selectedSearchType on unmount
                store!.spareParts.setSelectedSearchType(SearchType.FRAME_NUMBER);
            };
        }, [store]);

        const handleToggleFrameNumberSearch = (value: boolean, setFieldValue: any) => {
            setIsFrameNumberSearch(value);
            clearResults();
            setSearchInput('');

            if (value) {
                store!.spareParts.setSelectedSearchType(SearchType.FRAME_NUMBER);
                setFieldValue('categories', undefined);
            } else {
                store!.spareParts.setSelectedSearchType(SearchType.PRODUCT_CODE);
                QueryParams.setQueryParams({ categories: SearchType.PRODUCT_CODE });
                setFieldValue('categories', SearchType.PRODUCT_CODE);
            }
        };

        const handleSetSearchedTermText = useCallback(
            (value: string) => {
                setSearchedTermText(value);
                QueryParams.setQueryParams({ searchInput: value });
            },
            [setSearchedTermText],
        );

        const searchSpareParts = async (values: FormValues, actions?: FormikHelpers<FormValues>) => {
            // cleanup
            handleSetSearchedTermText('');
            store!.spareParts.searchResult.clearResult();

            // no values.categories means search by frame number by default
            // by FrameNumber search should be toUpperCase()
            const parsedSearchTerm = !values.categories ? values.searchInput.toUpperCase() : values.searchInput;

            // set search term
            handleSetSearchedTermText(parsedSearchTerm);

            // no values.categories means search by frame number by default
            store!.spareParts
                .getSparePartsBy(parsedSearchTerm, values.categories ?? SearchType.FRAME_NUMBER, true)
                .then(() => {
                    actions?.setSubmitting(false);
                });

            // Sending search spare parts string to GA
            ReactGA.event({
                category: GACategories.SpareParts,
                action: 'User searched in spare parts page',
                label: parsedSearchTerm,
            });
        };

        const handleSetSelectedSearchType = useCallback(
            (value: string) => {
                store!.spareParts.setSelectedSearchType(value);
                QueryParams.setQueryParams({ categories: value });
            },
            [store],
        );

        const getValidationSchema = () => {
            return Yup.object().shape({
                searchInput: Yup.string()
                    .required(intl.formatMessage({ id: 'validation.fieldRequired' }))
                    .min(
                        MIN_CHAR_SPARE_PARTS_SEARCH,
                        intl.formatMessage({ id: 'validation.minChar' }, { minChar: MIN_CHAR_SPARE_PARTS_SEARCH }),
                    )
                    .test(
                        'searchInput',
                        intl.formatMessage({ id: 'validation.invalidFormat' }),
                        function (value: string = '') {
                            if (selectedSearchType === SearchType.SPARE_PART_SEARCH) {
                                return /^[^\']*$/.test(value);
                            }
                            if (selectedSearchType === SearchType.FRAME_NUMBER) {
                                return /^\w+$/.test(value);
                            }
                            return true;
                        },
                    ),
                categories: Yup.string(),
            });
        };

        return (
            <Stack
                data-testid="search-spare-parts-form"
                alignItems="center"
                justifyContent="center"
                sx={{
                    width: {
                        xs: '100%',
                        lg: '50%',
                    },
                }}
            >
                <Formik
                    initialValues={initialFormValues}
                    validationSchema={getValidationSchema}
                    onSubmit={searchSpareParts}
                >
                    {formikProps => {
                        return (
                            <Form>
                                <Stack maxWidth="400px" gap="1rem">
                                    {!isFrameNumberSearch && (
                                        <FormControl sx={{ width: '350px' }}>
                                            <InputLabel variant="outlined">
                                                <FormattedMessage id={'search.label_searchCategories'} />
                                            </InputLabel>
                                            <Field
                                                name="categories"
                                                component={CategorySelect}
                                                clearOnSelectChange={() => {
                                                    clearResults();
                                                    setSearchInput('');
                                                }}
                                                setSearchInput={handleSetSelectedSearchType}
                                                isReturn={isReturnFlow}
                                            />
                                        </FormControl>
                                    )}

                                    {selectedSearchType !== SearchType.INVOICE_SEARCH && (
                                        <SearchPartsInput
                                            setSearchedTermText={handleSetSearchedTermText}
                                            searchInput={searchInput}
                                            setSearchInput={setSearchInput}
                                            {...formikProps}
                                        />
                                    )}

                                    <FormControl>
                                        <FormControlLabel
                                            data-testid="toggle-spare-parts-search"
                                            control={
                                                <IOSSwitch
                                                    sx={{ m: 1 }}
                                                    checked={isFrameNumberSearch}
                                                    onChange={e =>
                                                        handleToggleFrameNumberSearch(
                                                            e.target.checked,
                                                            formikProps.setFieldValue,
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <FormattedMessage
                                                    id={
                                                        isReturnFlow
                                                            ? 'search.spareParts.newReturn.frameNumberToggle.label'
                                                            : 'search.spareParts.frameNumberToggle.label'
                                                    }
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Stack>
                            </Form>
                        );
                    }}
                </Formik>
            </Stack>
        );
    }),
);

export default SearchSparePartForm;
