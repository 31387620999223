import CheckIcon from '@mui/icons-material/CheckCircle';
import { Button, Grid, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AppPageRoutes } from 'src/services/api/models';

const ThankYouPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <CssBaseline />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '80vh' }}
            >
                <Grid item xs={10}>
                    <Typography align="center" component="div">
                        <Typography data-testid="thank-you-page" variant="h2" color="secondary">
                            <CheckIcon style={{ width: '80', height: '80' }} />{' '}
                        </Typography>
                        <Typography variant="h4" color="secondary" gutterBottom>
                            <FormattedMessage id="orders.thank_you_title" />
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <FormattedMessage id="orders.thank_you_message" />
                        </Typography>
                        <Button size="large" color="primary" variant="contained" onClick={() => navigate('/products')}>
                            <FormattedMessage id="orders.continue_orders" />
                        </Button>{' '}
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={() => navigate(AppPageRoutes.ORDERS)}
                        >
                            <FormattedMessage id="orders.thank_you_cta" />
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default ThankYouPage;
