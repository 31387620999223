import { Box } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import NoItemFoundAfterSearch from 'src/components/NoItemFoundAfterSearch';
import ProductCard from 'src/components/ProductCard';
import Store from 'src/stores/RootStore';
import { TProductModel } from 'src/stores/types/ProductTypes';
import UserStore from 'src/stores/UserStore';

interface MarketingMaterialsGridProps {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
    items: TProductModel[];
    textFilter?: string;
}

const MarketingMaterialsGrid = inject(
    'store',
    'user',
)(
    observer(({ store, user, items, textFilter }: MarketingMaterialsGridProps) => {
        const showDetailsPopup = (id: string) => {
            store!.marketingMaterials.setSelectedItemId(id);
            store!.marketingMaterials.toggleMarketingMaterialDetailsDialog();
        };

        if (!items.length && textFilter) {
            return <NoItemFoundAfterSearch searchedText={textFilter} />;
        }

        return (
            <Box data-testid="marketing-materials-grid" display="flex" flexWrap="wrap">
                {items.map(item => (
                    <LazyLoadComponent key={item.id} delayTime={400}>
                        <ProductCard
                            productId={item.id}
                            onCardClick={() => showDetailsPopup(item.id)}
                            type={item.type}
                            name={item.translatedDescription(user!.preferredLang)}
                            price={item.price}
                            isInStock={!!item.productInformation.inStock}
                            stockQuantity={item.productInformation.stockQuantity}
                            availableFrom={item.productInformation.availableFrom}
                        />
                    </LazyLoadComponent>
                ))}
            </Box>
        );
    }),
);

export default MarketingMaterialsGrid;
