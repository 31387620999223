/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param isMetric True to use metric (SI) units, aka powers of 1000. False to use binary (IEC), aka powers of 1024.
 * @param digits Number of decimal places to display.
 *
 * @return Formatted string.
 */
const formatFileSize = (bytes: number, isMetric: boolean = false, digits: number = 1) => {
    const threshold = isMetric ? 1000 : 1024;

    if (Math.abs(bytes) < threshold) {
        return bytes + ' B';
    }

    const units = isMetric
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    let unitIndex = -1;
    const roundMultiplier = 10 ** digits;

    do {
        bytes /= threshold;
        unitIndex++;
    } while (
        Math.round(Math.abs(bytes) * roundMultiplier) / roundMultiplier >= threshold &&
        unitIndex < units.length - 1
    );

    return `${bytes.toFixed(digits)} ${units[unitIndex]}`;
};

export default formatFileSize;
