export const capitalizeFirstLetter = (value: string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1);
};

// This fuction truncate long string in the middle and replace with @separator
// Example: truncateLongFileName('longlonglong.jpeg', 16) => 'longlon...g.jpeg'
export const truncateLongFileName = (fullStr: string, strLen: number, separator: string = '...') => {
    if (fullStr.length <= strLen) {
        return fullStr;
    }

    const sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2);

    return fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars);
};
