import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import localStorage from 'localstorage-ttl';
import uniqBy from 'lodash/uniqBy';
import { flow, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';
import { OrderNotification, TOrderNotification } from 'src/stores/types/NotificationsTypes';
import { logRejectedModel } from 'src/utils/logger';
import { StorageNameSpace } from 'src/utils/storageNameSpace';

const sortByDate = (a: TOrderNotification, b: TOrderNotification) => {
    return dayjs(b.createdAt).diff(dayjs(a.createdAt), 'milliseconds');
};

const OrderNotificationsStore = types
    .model('OrderNotificationsStore', {
        items: types.optional(types.array(OrderNotification), []),
        itemsFromDateRange: types.optional(types.array(OrderNotification), []),
        isLoading: types.optional(types.boolean, false),
    })
    .volatile(() => ({
        latestNotificationDate: dayjs().subtract(14, 'days'), // For initially receiving the last 14 days of notifications
        unreadFromDate: dayjs().subtract(14, 'days'), // Initially 14 days, will immediately get changed after creation
    }))
    .views(self => ({
        get unreadNotificationsCount() {
            return self.items.reduce((acc, current) => {
                return dayjs(current.createdAt).isAfter(self.unreadFromDate) ? acc + 1 : acc;
            }, 0);
        },
    }))
    .actions(self => ({
        // @ts-ignore
        requestNewNotifications: flow(function* () {
            self.isLoading = true;

            try {
                // @ts-ignore
                const response: AxiosResponse<TOrderNotification[]> = yield Service.getOrderNotifications(
                    self.latestNotificationDate,
                );

                if (response.data) {
                    // Saving the operation time for using in the next call
                    self.latestNotificationDate = dayjs();

                    response.data.forEach((notification: TOrderNotification) => {
                        if (OrderNotification.is(notification)) {
                            self.items.push(notification);
                        } else {
                            logRejectedModel(OrderNotification.name, notification);
                        }
                    });

                    // Removing all possible duplicates, sorting all the notifications and replace them with the original (possibly) unsorted values
                    self.items.replace(uniqBy(self.items, 'notificationId').sort(sortByDate));

                    // Saving new unread from date into local storage
                    localStorage.set(
                        StorageNameSpace.ORDER_NOTIFICATIONS,
                        JSON.stringify({
                            unreadFrom: self.unreadFromDate.toJSON(),
                        }),
                    );
                }
            } catch (error) {
                console.error(error);
            }

            self.isLoading = false;
        }),

        // @ts-ignore
        requestNotificationsFromDateRange: flow(function* (from: Date, to: Date) {
            self.isLoading = true;

            try {
                // @ts-ignore
                const response: AxiosResponse<TOrderNotification[]> = yield Service.getOrderNotifications(
                    dayjs(from),
                    dayjs(to),
                );

                if (response.data) {
                    self.itemsFromDateRange.clear();

                    response.data.forEach((notification: TOrderNotification) => {
                        if (OrderNotification.is(notification)) {
                            self.itemsFromDateRange.push(notification);
                        } else {
                            logRejectedModel(OrderNotification.name, notification);
                        }
                    });

                    // Removing all possible duplicates, sorting all the notifications and replace them with the original (possibly) unsorted values
                    self.itemsFromDateRange.replace(uniqBy(self.itemsFromDateRange, 'notificationId').sort(sortByDate));
                }
            } catch (error) {
                console.error(error);
            }

            self.isLoading = false;
        }),

        readAllNotifications() {
            self.unreadFromDate = dayjs();

            localStorage.set(
                StorageNameSpace.ORDER_NOTIFICATIONS,
                JSON.stringify({
                    unreadFrom: self.unreadFromDate.toJSON(),
                }),
            );
        },

        isNotificationUnread(notification: TOrderNotification) {
            return dayjs(notification.createdAt).isAfter(self.unreadFromDate);
        },

        changeUnreadFromDateValue(date: dayjs.Dayjs) {
            self.unreadFromDate = date;
        },
    }));

export default OrderNotificationsStore;
