import { createStyles } from '@mui/styles';
import React from 'react';

interface LogoProps {
    width?: number;
    style?: any;
    logoColor?: any;
    logoQtail?: any;
}

const style = createStyles({
    logoColor: {
        fill: '#1c1c1c',
    },
    logoQtail: {
        fill: '#ff8a00',
    },
});

export const Logo = (props: LogoProps) => {
    /* eslint-disable */
    return (
        <div style={props.style}>
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 78 20"
                width={props.width || 90}
            >
                <path
                    style={props.logoColor ? props.logoColor : style.logoColor}
                    d="M7.044 0H3.479A3.477 3.477 0 0 0 0 3.479v13.043A3.476 3.476 0 0 0 3.479 20h10.869L10 15.652H5.217a.87.87 0 0 1-.869-.869V5.218a.869.869 0 0 1 .869-.87h11.3a.869.869 0 0 1 .87.87v8.26l4.348 4.348V3.479A3.477 3.477 0 0 0 18.26 0H7.044z"
                />
                <path
                    d="M10.615 14.093l5.292 5.292a2.419 2.419 0 0 0 1.484.615h3.478c.478 0 .593-.276.255-.614l-5.292-5.292a2.414 2.414 0 0 0-1.484-.615h-3.479c-.477 0-.593.276-.254.615"
                    style={props.logoQtail ? props.logoQtail : style.logoQtail}
                />
                <path
                    style={props.logoColor ? props.logoColor : style.logoColor}
                    d="M25.376 20h21.58a3.476 3.476 0 0 0 3.477-3.479V0h-4.347v14.782a.869.869 0 0 1-.87.87h-5.652V0h-4.348v15.652H28.7V0h-4.353v20zM53.042 0h4.348v19.999h-4.348zm11.304 5.217a.869.869 0 0 1 .87-.869h12.173V0H63.476A3.477 3.477 0 0 0 60 3.479v13.042A3.477 3.477 0 0 0 63.476 20h13.913v-4.349H65.216a.868.868 0 0 1-.87-.868V5.217z"
                />
            </svg>
        </div>
    );
};

export const QLogo = (props: LogoProps) => {
    return (
        <div style={props.style}>
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 20"
                width={props.width || 90}
            >
                <path
                    style={props.logoColor ? props.logoColor : style.logoColor}
                    d="M7.044 0H3.479A3.477 3.477 0 0 0 0 3.479v13.043A3.476 3.476 0 0 0 3.479 20h10.869L10 15.652H5.217a.87.87 0 0 1-.869-.869V5.218a.869.869 0 0 1 .869-.87h11.3a.869.869 0 0 1 .87.87v8.26l4.348 4.348V3.479A3.477 3.477 0 0 0 18.26 0H7.044z"
                />
                <path
                    d="M10.615 14.093l5.292 5.292a2.419 2.419 0 0 0 1.484.615h3.478c.478 0 .593-.276.255-.614l-5.292-5.292a2.414 2.414 0 0 0-1.484-.615h-3.479c-.477 0-.593.276-.254.615"
                    style={props.logoQtail ? props.logoQtail : style.logoQtail}
                />
            </svg>
        </div>
    );
};

