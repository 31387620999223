import { MenuItem } from '@mui/material';
import { Field, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { FormattedMessage } from 'react-intl';
import { TReturnReason } from 'src/stores/types/ReturnRequestModel';
import { ReturnItemFormValues } from '../ReturnRequestFormv2';

interface ReturnReasonFieldProps {
    returnReasons?: TReturnReason[];
    preferredLang: string;
    isUnusedFlow?: boolean;
    needDescription?: boolean;
}

export const ReturnReasonField = ({
    returnReasons,
    preferredLang,
    isUnusedFlow = false,
    needDescription = false,
    ...formikProps
}: ReturnReasonFieldProps & FormikProps<ReturnItemFormValues>) => {
    const { values, errors } = formikProps;

    return (
        <>
            {returnReasons && (
                <Field
                    label={<FormattedMessage id="returns.form_label_reason" />}
                    name="reason"
                    select
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    data-testid="returns-reason"
                    component={TextField}
                    value={values.reason}
                >
                    {isUnusedFlow
                        ? returnReasons
                              .filter(reason => reason.reasonEN.includes('unused return'))
                              .map((reason: TReturnReason, index: number) => {
                                  return (
                                      <MenuItem
                                          data-testid={`returns-reason-option-${index}`}
                                          key={reason.id}
                                          value={reason.id}
                                      >
                                          {reason.translated(preferredLang)}
                                      </MenuItem>
                                  );
                              })
                        : returnReasons
                              .filter(reason => !reason.reasonEN.includes('unused return'))
                              .map((reason: TReturnReason, index: number) => {
                                  return (
                                      <MenuItem
                                          data-testid={`returns-reason-option-${
                                              reason.needDescription ? 'other' : index
                                          }`}
                                          key={reason.id}
                                          value={reason.id}
                                      >
                                          {reason.translated(preferredLang)}
                                      </MenuItem>
                                  );
                              })}
                </Field>
            )}

            {needDescription && (
                <Field
                    label={<FormattedMessage id="returns.form_label_description" />}
                    name="reasonDescription"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={3}
                    error={errors.reasonDescription}
                    component={TextField}
                />
            )}
        </>
    );
};
