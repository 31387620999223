import { CardMedia } from '@mui/material';
import Typography from '@mui/material/Typography';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Image from 'src/components/Image';
import PersistentStore from 'src/stores/PersistentStore';
import { TProductModel } from 'src/stores/types/ProductTypes';
import { DefaultValues } from 'src/utils/constants';
import { convertImageToThumbnail } from 'src/utils/convertImageSize';
import { createTableValues, TableCell, TableColumnTypes } from 'src/utils/tableColumnUtils';

interface ProductsProps extends WithStyles<typeof styles> {
    persistentStore?: typeof PersistentStore.Type;
    products: TProductModel[];
    selectProduct: (itemCode: string) => void;
}

const ProductList = inject('persistentStore')(
    observer(({ classes, persistentStore, products, selectProduct }: ProductsProps) => {
        const intl = useIntl();

        const buildProductList = (productList: TProductModel[]) => {
            const formatDate = (value: any): any => {
                return value
                    ? intl.formatDate(value, { year: 'numeric', month: 'long', day: 'numeric' })
                    : intl.formatMessage({ id: 'status.Unavailable' });
            };

            const data = productList.map((product): TableCell[] => {
                const productImage = product?.productInformation?.imageUrl;
                const imageUrl = productImage ? convertImageToThumbnail(productImage) : DefaultValues.ImageNotFound;

                return [
                    {
                        id: 'image',
                        value: imageUrl,
                        type: TableColumnTypes.custom,

                        customRender: (value: any, tableMeta: any) => {
                            // This function is getting called here asynchronously, so we need to calculate 'isDeprecated' here in this function
                            const isDeprecated = productList[tableMeta.rowIndex].productInformation.deprecated;

                            return (
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    {isDeprecated && (
                                        <div className={classes.soldOut}>
                                            <Typography variant="subtitle1" className={classes.soldOutText}>
                                                <FormattedMessage id="status.soldOut" />
                                            </Typography>
                                        </div>
                                    )}

                                    <CardMedia
                                        component={(props: any) => (
                                            <Image {...props} src={value} fallbackSrc={DefaultValues.ImageNotFound} />
                                        )}
                                        title={product.productInformation.name}
                                        style={{
                                            maxHeight: '100px',
                                            width: 'auto',
                                            minWidth: !productImage ? '150px' : '120px',
                                            opacity: isDeprecated ? 0.3 : 1,
                                        }}
                                    />
                                </div>
                            );
                        },
                    },
                    { id: 'name', value: product.displayName },
                    {
                        id: 'description',
                        value: `${product.productInformation.version}, ${product.productInformation.size}, ${product.productInformation.color}`,
                    },
                    {
                        id: 'itemNumber',
                        value: product.id,
                        customRender: (value: any) => {
                            return <span data-testid={`sparePart-card-${value}`}>{value}</span>;
                        },
                    },
                    { id: 'price', value: product.getPrice(), type: TableColumnTypes.price },
                    {
                        id: 'availabilityDate',
                        value: product.availableDateFrom,
                        type: TableColumnTypes.custom,
                        customRender: (value: string) => {
                            return formatDate(value);
                        },
                    },
                ];
            });

            return createTableValues(data);
        };

        const renderProductTable = () => {
            const table = buildProductList(products);
            const data = table.data;
            const columns = table.columns;

            const options: MUIDataTableOptions = {
                selectableRows: 'none',
                responsive: 'vertical',
                pagination: false,
                filter: false,
                search: false,
                download: false,
                print: false,
                viewColumns: false,
                elevation: 0,
                sort: true,
                onRowClick: (rowData: any) => {
                    columns.forEach((column, index) => {
                        if (column.name === 'itemNumber') {
                            const itemNumber = rowData[index].props.children;
                            selectProduct(itemNumber);
                        }
                    });
                },
                setRowProps: (row, dataIndex, rowIndex): any => {
                    const isDeprecated = products[rowIndex].productInformation.deprecated;

                    return {
                        className: isDeprecated ? classes.deprecated : '',
                    };
                },
            };

            return <MUIDataTable title="" data={data} columns={columns} options={options} />;
        };

        return (
            <div data-testid="products-table" className={classes.root}>
                {renderProductTable()}
            </div>
        );
    }),
);

function styles() {
    return createStyles({
        root: {
            position: 'relative',
            width: '100%',

            '& table > tbody > tr:hover': {
                cursor: 'pointer',
            },
        },
        deprecated: {
            'pointer-events': 'none',
            '& td': {
                color: 'rgba(0, 0, 0, .3)',
            },
        },
        soldOut: {
            zIndex: 1,
            position: 'absolute',
            textAlign: 'center',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '4px 8px',
            borderRadius: '3px',
            backgroundColor: 'white',
            boxShadow: '1px 1px 5px -3px black',
            color: 'black',
        },
        soldOutText: {
            whiteSpace: 'nowrap',
        },
    });
}

export default withStyles(styles)(ProductList);
