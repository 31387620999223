import { Stack, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PageLoader from 'src/components/PageLoader';
import { fadeInDownKeyFrames } from 'src/components/styles';
import Store from 'src/stores/RootStore';
import ShipItemsTable from './ShipItemsTable';
import UserStore from 'src/stores/UserStore';
import NotFound from 'src/pages/NotFound';

interface ShipReturnsProps {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
}

const ShipReturns = inject(
    'store',
    'user',
)(
    observer(({ store, user }: ShipReturnsProps) => {
        useEffect(() => {
            if (!store?.returns?.returnsList?.size) {
                store!.returns.getReturnRequests();
            }
        }, []);

        return user?.useNewReturnRequest ? (
            <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                    <Typography variant="h5">
                        <FormattedMessage id="menu.shipReturns" />
                    </Typography>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="shipReturns.subtitle" />
                    </Typography>
                </Stack>
                <Typography variant="h6">
                    <FormattedMessage id="shipReturns.shipItems.title" />
                </Typography>

                <div style={{ animation: `${fadeInDownKeyFrames} 0.5s ease-out`, transition: 'all .3s ease' }}>
                    {store!.returns.loading ? <PageLoader /> : <ShipItemsTable />}
                </div>
            </>
        ) : (
            <NotFound />
        );
    }),
);

export default ShipReturns;
