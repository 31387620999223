import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
    Box,
    Card,
    CardActionArea,
    CardActions,
    CardMedia,
    Grid,
    ListItem,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { Logo } from 'src/components/Logo';

const StyledListItem = styled(ListItem)(({}) => ({
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    borderLeft: '5px solid #FFF',
    '&.Mui-selected': {
        color: '#000 !important',
        borderLeft: '5px solid #EE7728',
        a: {
            color: '#000 !important',
        },
    },
    '&.Mui-selected:hover': {
        color: '#000 !important',
        borderLeft: '5px solid #EE7728',
    },
    '&.Mui-selected:focus': {
        color: '#000 !important',
        borderLeft: '5px solid #EE7728',
    },
}));

interface CardListItemProps {
    fullWidth?: boolean;
    onMouseEnter?: () => void;
    onTouchEndCapture?: () => void;
    isActive?: boolean;
    itemLink: string;
    targetLink?: string;
    onClickItem?: () => void;
    itemImage?: string;
    unread?: boolean;
    itemTitle: string;
    itemSubTitle?: ReactNode;
}

export const CardListItem = ({
    fullWidth = false,
    onMouseEnter,
    onTouchEndCapture,
    isActive = false,
    itemLink,
    targetLink = '_blank',
    onClickItem,
    itemImage,
    unread,
    itemTitle,
    itemSubTitle,
}: CardListItemProps) => {
    const { spacing } = useTheme();
    return (
        <Grid style={{ display: 'flex' }} item xs={12} md={fullWidth ? 12 : 6} lg={fullWidth ? 12 : 4}>
            <Card
                onMouseEnter={onMouseEnter}
                onTouchEndCapture={onTouchEndCapture}
                sx={{
                    width: '100%',
                    borderLeft: isActive ? '5px solid #EE7728' : undefined,
                    overflow: isActive ? 'hidden' : 'auto',
                    borderRadius: '4px',
                }}
            >
                <CardActionArea
                    href={itemLink}
                    onClick={onClickItem}
                    target={targetLink}
                    rel="noopener noreferrer"
                    style={{ height: '100%' }}
                >
                    <div
                        style={{
                            marginLeft: isActive ? '-5px' : '0px',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {itemImage ? (
                            <CardMedia component="img" height="140" image={itemImage} sx={{ borderRadius: '4px' }} />
                        ) : (
                            <div
                                style={{
                                    height: '140px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Logo />
                            </div>
                        )}
                        <CardActions>
                            <StyledListItem>
                                <ListItemText
                                    primary={
                                        <div style={{ display: 'flex', marginRight: spacing(1) }}>
                                            <Box
                                                sx={{
                                                    alignSelf: 'flex-start',
                                                    marginTop: spacing(1),

                                                    '&::before': {
                                                        content: '""',
                                                        display: 'block',
                                                        width: spacing(1),
                                                        height: spacing(1),
                                                        borderRadius: spacing(0.5),
                                                        marginRight: spacing(1),
                                                        backgroundColor: unread ? '#EE7728' : '#eee',
                                                    },
                                                }}
                                            />

                                            <Typography>{itemTitle}</Typography>
                                        </div>
                                    }
                                    secondary={
                                        <Typography variant="caption" sx={{ opacity: 0.5, marginLeft: spacing(2) }}>
                                            {itemSubTitle}
                                        </Typography>
                                    }
                                />
                                <OpenInNewIcon fontSize="small" sx={{ marginTop: '10px' }} />
                            </StyledListItem>
                        </CardActions>
                    </div>
                </CardActionArea>
            </Card>
        </Grid>
    );
};
