import { Divider, Typography, styled } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import PageLoader from 'src/components/PageLoader';
import SearchSpareParts from 'src/components/SearchSpareParts';
import { fadeInDownKeyFrames } from 'src/components/styles';
import QueryParams from 'src/services/QueryParamsService';
import Store from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import { SearchType, UserRoles } from 'src/utils/constants';
import SparePartsList from '../spareParts/SparePartsList';
import Unauthorized from '../authentication/Unauthorized';
import Invoices from '../invoices/Invoices';

const StyledForm = styled('div')(() => ({
    animation: `${fadeInDownKeyFrames} 0.5s ease-out`,
    transition: 'all .3s ease',
    margin: '1rem 0',
}));

interface NewReturnProps {
    user?: typeof UserStore.Type;
    store?: typeof Store.Type;
}

const NewReturn = inject(
    'store',
    'user',
)(
    observer(({ user, store }: NewReturnProps) => {
        const [sparePartFilterText, setSparePartFilterText] = useState('');
        const [searchedTermText, setSearchedTermText] = useState('');

        const { selectedSearchType } = store!.spareParts;

        const clearSearchResults = () => {
            setSearchedTermText('');
            setSparePartFilterText('');
            store!.spareParts.searchResult.clearResult();
            QueryParams.setQueryParams({ searchInput: '', categories: '' });
        };

        useEffect(() => {
            store!.returns.getReturnReasons();
        }, []); // eslint-disable-line

        const renderUnusedReturnInvoices = () => {
            const isMechanic = user?.role === UserRoles.MECHANIC;

            if (isMechanic) {
                return <Unauthorized asComponent={true} />;
            }

            return (
                <>
                    <Divider sx={{ marginBottom: '1rem' }} />
                    <Invoices isUnusedReturnTable />
                </>
            );
        };

        const spareParts = useMemo(
            () => store!.spareParts.filterSparePartResults(sparePartFilterText, true),
            [store!.spareParts.searchResult.spareParts.length, sparePartFilterText], // eslint-disable-line
        );

        return (
            <>
                <Typography variant="h5">
                    <FormattedMessage id="menu.newReturn" />
                </Typography>

                <StyledForm>
                    <SearchSpareParts
                        setSearchedTermText={setSearchedTermText}
                        isReturnFlow
                        clearResults={clearSearchResults}
                        parentBike={store!.spareParts.searchResult.parentBike}
                    />
                </StyledForm>

                {store!.spareParts.loading && <PageLoader />}

                {!store!.spareParts.loading &&
                    searchedTermText !== '' &&
                    selectedSearchType !== SearchType.INVOICE_SEARCH && (
                        <SparePartsList
                            spareParts={spareParts}
                            showTextFilter={selectedSearchType !== SearchType.SPARE_PART_SEARCH}
                            textFilter={sparePartFilterText}
                            onChangeTextFilter={setSparePartFilterText}
                            notFoundText={sparePartFilterText || searchedTermText}
                            isReturn
                        />
                    )}

                {selectedSearchType === SearchType.INVOICE_SEARCH && renderUnusedReturnInvoices()}
            </>
        );
    }),
);

export default NewReturn;
