import React from 'react';
import { FormattedNumber } from 'react-intl';

export interface CurrencyFormatProps {
    value: string | number;
}
const isNumber = (v: string | number): number => Number(v);
/* eslint-disable */
export function CurrencyFormat(props: CurrencyFormatProps) {
    return (
        <FormattedNumber
            value={isNumber(props.value)}
            style="currency"
            currency="EUR"
            currencyDisplay="symbol"
        >
            {(formatted: string) => formatted}
        </FormattedNumber>
    );
}
/* eslint-enable */
export default CurrencyFormat;
