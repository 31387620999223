import { Info } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'src/components/Link';
import { InfoArticlesItems, InfoArticlesMap } from 'src/utils/constants';

interface InfoItemProps {
    descriptionId: string;
    linkTextId?: string;
    linkId?: string;
}

const InfoItem = ({ descriptionId, linkTextId, linkId }: InfoItemProps) => {
    const intl = useIntl();
    const linkTextTranslation = intl.formatMessage({ id: linkTextId });
    const linkTranslation = intl.formatMessage({ id: linkId });

    return (
        <li>
            <Typography variant="body2">
                <FormattedMessage
                    id={descriptionId}
                    values={{
                        linkText:
                            linkTextTranslation && linkTranslation ? (
                                <Link target="_blank" rel="noreferrer" href={linkTranslation}>
                                    {linkTextTranslation}
                                </Link>
                            ) : (
                                ''
                            ),
                    }}
                />
            </Typography>
        </li>
    );
};

interface ReturnInfoBoxProps {
    isReturn?: boolean;
    productCode: string;
}

export const ReturnInfoBox = ({ isReturn = true, productCode }: ReturnInfoBoxProps) => {
    const { palette } = useTheme();
    const translationId = InfoArticlesMap[productCode];
    const articlesItems = InfoArticlesItems[translationId];

    if (!translationId || !articlesItems) {
        return null;
    }

    return (
        <>
            {!isReturn && <Divider sx={{ marginY: '1rem' }} />}
            <Box
                sx={{
                    backgroundColor: palette.grey[200],
                    padding: '1rem',
                    marginX: isReturn ? '24px' : 0,
                    marginTop: isReturn ? '1rem' : 0,
                }}
            >
                <Box display="flex">
                    <Info color="secondary" sx={{ marginRight: '1rem' }} />
                    <Typography>
                        <FormattedMessage
                            id={isReturn ? 'returnInfoBox.label.returnRequest' : 'returnInfoBox.label.sparePart'}
                        />
                    </Typography>
                </Box>

                <ul style={{ paddingInlineStart: '1rem' }}>
                    {articlesItems.map((item: string) => (
                        <InfoItem
                            key={item}
                            descriptionId={`returnInfoBox.${item}.description`}
                            linkTextId={`returnInfoBox.${item}.linkText`}
                            linkId={`returnInfoBox.${item}.link`}
                        />
                    ))}
                </ul>
            </Box>
            <Divider sx={{ marginTop: '1rem' }} />
        </>
    );
};
