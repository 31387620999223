import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { ButtonCustomVariant } from 'src/components/button/type';
import { StepsValues } from './constants';
import { StepItem } from './StepItem';

const ReturnsProcessInfo = () => {
    const navigate = useNavigate();

    return (
        <Stack>
            <Stack direction="row" alignItems="center" marginBottom="1rem">
                <Button customVariant={ButtonCustomVariant.BACK_BUTTON} onClick={() => navigate(-1)} />
                <Typography variant="h6" marginLeft="1rem">
                    <FormattedMessage id="returns_process_info" />
                </Typography>
            </Stack>
            <Stack sx={{ maxWidth: '954px', margin: '0 auto' }}>
                {StepsValues.map(step => (
                    <StepItem key={step.id} {...step} />
                ))}
            </Stack>
        </Stack>
    );
};

export default ReturnsProcessInfo;
