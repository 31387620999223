import { useAuth0 } from '@auth0/auth0-react';
import { Button, CssBaseline, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import IconEnter from 'src/components/iconEnter';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { Logo } from 'src/components/Logo';
import PersistentStore from 'src/stores/PersistentStore';
import { redirectAfterLogin } from 'src/utils/redirectAfterLogin';
import withRoot from '../withRoot';
interface LoginPageProps extends WithStyles<typeof styles> {
    persistentStore?: typeof PersistentStore.Type;
}

const LoginPage = inject('persistentStore')(
    observer(({ classes, persistentStore }: LoginPageProps) => {
        const navigate = useNavigate();
        const { loginWithRedirect, isAuthenticated } = useAuth0();

        useEffect(() => {
            if (isAuthenticated) {
                // Redirecting to previously requested page after the login operation
                redirectAfterLogin(navigate);
            }
        }, [navigate, isAuthenticated]);

        return (
            <>
                <CssBaseline />
                <main className={classes.layout}>
                    <div className={classes.paper}>
                        <Typography align="center" gutterBottom component="div">
                            <Logo width={200} />

                            <LanguageSwitcher persistentStore={persistentStore!} isLoginPage />
                        </Typography>

                        <Button
                            data-testid="loginBtn"
                            id="sign-in-button"
                            onClick={() => loginWithRedirect()}
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            <FormattedMessage id="application.sign_in" />
                            <IconEnter color="white" />
                        </Button>
                    </div>
                </main>
            </>
        );
    }),
);

function styles({ breakpoints, spacing }: Theme) {
    return createStyles({
        '@keyframes fadeInDown': {
            from: {
                opacity: 0,
                transform: 'translate(0, -50px)',
            },
            to: {
                opacity: 1,
                transform: 'translate(0, 0)',
            },
        },
        layout: {
            width: '100vw',
            height: '100vh',
            display: 'flex', // Fix IE11 issue.
            marginLeft: spacing(3),
            marginRight: spacing(3),
            [breakpoints.up(8 * 6 + 400)]: {
                width: 400,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        submit: {
            fontSize: '1rem',
            padding: '0.5rem 1rem',
        },
        icon: {
            marginLeft: '1rem',
        },
        paper: {
            display: 'flex',
            flex: '1',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            animationDuration: '1s',
            animationFillMode: 'both',
            animationName: '$fadeInDown',
        },
    });
}

export default withRoot(withStyles(styles)(LoginPage));
