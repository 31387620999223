import { SearchOutlined } from '@mui/icons-material';
import { InputAdornment, Pagination, TextField, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import debounce from 'lodash/debounce';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import If from 'src/components/conditional/If';
import PageLoader from 'src/components/PageLoader';
import Store from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import { DEFAULT_ITEMS_PER_PAGE } from 'src/utils/constants';
import NewsWidget, { ENewsLocation } from './NewsWidget';

interface NewsProps extends WithStyles<typeof styles> {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
}

const News = inject(
    'store',
    'user',
)(
    observer(({ store, user, classes }: NewsProps) => {
        const intl = useIntl();
        const [searchString, setSearchString] = useState<string>();
        const [offset, setOffset] = useState(0);

        const lang = user!.preferredLang;

        useEffect(() => {
            store!.news.getRecentNews(lang, searchString, DEFAULT_ITEMS_PER_PAGE, offset);
        }, [store, lang, offset]);

        // Setting this function as a ref, so we wont lose the functions reference between render cycles
        // This function is debouncing the actual call so we are eliminating some unnecessary calls
        const debouncedSearchFunc = useRef(
            debounce((language: string, search: string) => {
                store!.news.getRecentNews(language, search);
            }, 500),
        );

        // Calling our debounced function when the user change search query
        useEffect(() => {
            if (lang && searchString !== undefined) {
                setOffset(0);
                debouncedSearchFunc.current(lang, searchString);
            }
        }, [searchString, lang]);

        return (
            <div className={classes.root}>
                <div className={classes.newsHeader}>
                    <Typography variant="h5">
                        <FormattedMessage id="menu.news" />
                    </Typography>

                    <TextField
                        className={classes.searchField}
                        data-testid="search-input"
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlined fontSize="small" />
                                </InputAdornment>
                            ),
                            inputMode: 'search',
                        }}
                        value={searchString}
                        placeholder={intl.formatMessage({ id: 'news.searchDescription' })}
                        onChange={event => setSearchString(event.target.value)}
                        variant="standard"
                    />
                </div>

                <If condition={store!.news.status === 'SUCCESS'} otherwise={<PageLoader />}>
                    <NewsWidget location={ENewsLocation.NEWSPAGE} />

                    <div className={classes.paginationContainer}>
                        <Pagination
                            className={classes.pagination}
                            shape="rounded"
                            size="small"
                            color="secondary"
                            count={Math.ceil(store!.news.totalRecords / DEFAULT_ITEMS_PER_PAGE)}
                            onChange={(event, page) => {
                                setOffset((page - 1) * DEFAULT_ITEMS_PER_PAGE);
                            }}
                            page={Math.round(offset / DEFAULT_ITEMS_PER_PAGE) + 1}
                        />
                    </div>
                </If>
            </div>
        );
    }),
);

function styles({ spacing, palette }: Theme) {
    return createStyles({
        root: {},

        newsHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: spacing(2),
        },

        searchField: {
            marginLeft: spacing(2),
        },

        paginationContainer: {
            textAlign: 'center',
            marginTop: '1em',
            position: 'absolute',
            bottom: 0,
            left: 240,
            right: 0,
        },
        pagination: {
            background: palette.background.default,
            padding: '0.5em 1em',
            display: 'inline-block',
            boxShadow: '0 0 15px rgba(0,0,0,.15)',
        },
    });
}

export default withStyles(styles)(News);
