import { useAuth0 } from '@auth0/auth0-react';
import { faSignOut, faTimes, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip, Grid, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PageLoader from 'src/components/PageLoader';
import RootStore from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import { UserDataAuth, UserDataAuthParsed } from 'src/types/commons/users';
import { UserAuth0Namespace, UserRoles } from 'src/utils/constants';
import { ProfileSection } from './ProfileSection';
import { UserSettings } from './UserSettings';

interface ProfilePageProps extends WithStyles<typeof styles> {
    path: string;
    user?: typeof UserStore.Type;
    store?: typeof RootStore.Type;
    closeDrawer: () => void;
}

const ProfilePage = inject(
    'user',
    'store',
)(
    observer(({ classes, closeDrawer, user, store }: ProfilePageProps) => {
        const { logout, user: userAuth0, isLoading } = useAuth0<UserDataAuth>();

        const [userData, setUserData] = useState({
            name: '',
            email: '',
            phone: '',
            account: {
                accountNumber: '',
                name: '',
                billingCountry: '',
                billingPostalCode: '',
                billingStreet: '',
                isPremiumQDealer: false,
            },
            address: {
                city: '',
                country: '',
                postalCode: '',
                street: '',
            },
        });

        useEffect(() => {
            if (!isLoading && userAuth0) {
                const metaUser: UserDataAuthParsed = JSON.parse(userAuth0[UserAuth0Namespace]);

                if (metaUser?.salesforce) {
                    const { salesforce: sfUser } = metaUser;

                    setUserData({
                        name: userAuth0.name || '',
                        email: userAuth0.email || '',
                        phone: userAuth0.phone_number || '',
                        account: {
                            accountNumber: sfUser.account?.erpId || '',
                            name: sfUser.account?.name || '',
                            billingCountry: sfUser.billingAddress?.country || '',
                            billingPostalCode: sfUser.billingAddress?.postalCode || '',
                            billingStreet: sfUser.billingAddress?.street || '',
                            isPremiumQDealer: sfUser.account?.isPremiumDealer || false,
                        },
                        address: {
                            city: sfUser.billingAddress?.city || '',
                            country: sfUser.billingAddress?.country || '',
                            street: sfUser.billingAddress?.street || '',
                            postalCode: sfUser.billingAddress?.postalCode || '',
                        },
                    });
                }
            }
        }, [isLoading, userAuth0]);

        const handleLogout = () => {
            user?.cleanLogin();
            logout({
                returnTo: window.location.origin,
            });
        };

        const handleChangeShowConsumerPrices = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
            const data = {
                defaultToConsumerMode: event.target.checked,
            };
            user?.changeUserPreferences(data);
        }, []);

        return (
            <Grid container spacing={3} className={classes.profileContainer}>
                {isLoading ? (
                    <PageLoader />
                ) : (
                    <>
                        <Grid item className={classes.profileHeader}>
                            <Typography component="h1" variant="h6">
                                <FormattedMessage id="profile.title" />
                            </Typography>

                            <Button onClick={() => closeDrawer()} data-testid="profile-close">
                                <FontAwesomeIcon icon={faTimes} size="lg" />
                            </Button>
                        </Grid>

                        <Grid item className={classes.profilePicture}>
                            <FontAwesomeIcon icon={faUser} size="3x" />
                            <Typography variant="h5" className={classes.profileName}>
                                {userData.name} <span className={classes.userRole}>{`[${user!.role}]`}</span>
                            </Typography>
                            {userData.account.isPremiumQDealer && (
                                <FormattedMessage id="dealer.premiumQ">
                                    {text => (
                                        <Chip className={classes.premiumQDealer} color="secondary" label={`${text}`} />
                                    )}
                                </FormattedMessage>
                            )}
                        </Grid>

                        <hr className={classes.hr} />

                        <Grid item className={classes.profileContent}>
                            <ProfileSection
                                titleId="profile.user_title"
                                content={[
                                    { id: 'profile.name', value: userData.name },
                                    { id: 'profile.email', value: userData.email },
                                    { id: 'profile.phone', value: userData.phone },
                                ]}
                            />

                            <ProfileSection
                                titleId="profile.account_title"
                                content={[
                                    { id: 'profile.account_name', value: userData.account.name },
                                    { id: 'profile.account_number', value: userData.account.accountNumber },
                                ]}
                            />

                            <ProfileSection
                                titleId="profile.billing_address_title"
                                content={[
                                    { id: 'profile.billing_street', value: userData.account.billingStreet },
                                    { id: 'profile.billing_country', value: userData.account.billingCountry },
                                    { id: 'profile.billing_postalcode', value: userData.account.billingPostalCode },
                                ]}
                            />

                            <UserSettings
                                hideConsumerSwitcher={user?.role === UserRoles.MECHANIC}
                                showConsumerPrices={user?.showConsumerPricesEnabled}
                                onChangeShowConsumerPrices={handleChangeShowConsumerPrices}
                            />
                        </Grid>
                    </>
                )}
                <Grid className={classes.profileFooter}>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleLogout}
                            className={classes.cta}
                            data-testid="sign-out"
                        >
                            <FormattedMessage id="menu.logout" />
                            <FontAwesomeIcon icon={faSignOut} className={classes.buttonIcon} />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }),
);

function styles({ palette }: Theme) {
    return createStyles({
        profileContainer: {
            padding: '10px 20px 0 20px',
            flexDirection: 'column',
            height: '100%',
            flexWrap: 'inherit',
        },
        userRole: {
            fontSize: '1rem',
            padding: '0 .2rem',
        },
        profileHeader: {
            flex: 'none',
            display: 'flex',
            justifyContent: 'space-between',
        },
        profilePicture: {
            textAlign: 'center',
            flex: '1',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        profileContent: {
            flex: '4',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
        },
        profileFooter: {
            padding: '0 10px',
            flex: 'none',
        },
        profileName: {
            margin: '10px 0 10px 0',
            display: 'flex',
            alignItems: 'baseline',
        },
        hr: {
            width: '100%',
            border: 'none',
            flex: 'none',
            height: '1px',
            backgroundColor: palette.primary.light,
        },
        cta: {
            padding: '1em 1.5em',
            marginLeft: '12px',
        },
        buttonIcon: {
            position: 'absolute',
            right: '1.5em',
            transform: 'scale(1.2)',
        },
        premiumQDealer: {
            lineHeight: 'inherit',
        },
    });
}

export default withStyles(styles)(ProfilePage);
