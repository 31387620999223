import { types } from 'mobx-state-tree';

export type TCompatibleBikeModel = typeof CompatibleBikeModel.Type;

export const CompatibleBikeModel = types.model('CompatibleBikeModel', {
    id: types.string,
    serie: types.maybeNull(types.string),
    model: types.maybeNull(types.string),
    version: types.maybeNull(types.string),
    size: types.maybeNull(types.string),
    name: types.string,
    description: types.string,
    descriptionDE: types.maybeNull(types.string),
    color: types.maybeNull(types.string),
    imageUrl: types.maybeNull(types.string),
});
