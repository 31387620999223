import { FormControl, Input, InputLabel, Theme } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDebounce } from 'src/utils/useDebounce';

interface ProductSearchProps extends WithStyles<typeof styles> {
    resetPagination: () => void;
    setTextFilter: (value: string) => void;
    initSearchFilterValue?: string | string[];
}

const ProductSearch = ({ classes, resetPagination, setTextFilter, initSearchFilterValue }: ProductSearchProps) => {
    const [inputValue, setInputValue] = useState(initSearchFilterValue || '');

    const debouncedValue = useDebounce(inputValue, 1000);

    useEffect(() => {
        if (typeof debouncedValue === 'string') {
            setTextFilter(debouncedValue);
            resetPagination();
        }
    }, [debouncedValue]);

    return (
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="component-simple">
                <FormattedMessage id="product.search_label" />
            </InputLabel>
            <Input
                data-testid="search-product-input"
                className={classes.Input}
                id="component-simple"
                value={inputValue}
                onChange={({ target }) => {
                    setInputValue(target.value);
                }}
            />
        </FormControl>
    );
};

function styles({ spacing }: Theme) {
    return createStyles({
        formControl: {
            margin: spacing(1),
            minWidth: 250,
            flex: 1,
        },
        Input: {
            minHeight: '42px',
        },
    });
}

export default withStyles(styles)(ProductSearch);
