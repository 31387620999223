import config from 'src/utils/config';
import { validateFramenumber } from 'src/utils/validateFrameNumber';
import {
    IValues,
    ContactCaseSubject,
    ContactCaseReasonL4,
    ContactCaseQueue,
    ContactCaseType,
    ContactCaseReasonL2,
    RequiredField,
} from './types';

export const initialValues: IValues = {
    subject: '',
    description: '',
    reasonL3: '',
    reasonL4: '',
    orderNumber: '',
    frameNumber: '',
    returnCaseNumber: '',
    workOrderNumber: '',
    batteryModel: '',
    attachments: [],
};

export const validation = (formatMessage: any) => (values: IValues) => {
    const {
        subject,
        description,
        reasonL4,
        orderNumber,
        frameNumber,
        returnCaseNumber,
        batteryModel,
        workOrderNumber,
        attachments,
    } = values;
    let errors: Record<string, string> = {};

    const errorMessages: Record<string, string> = {
        requiredField: formatMessage({ id: 'contact.requiredField' }),
        invalidFormat: formatMessage({ id: 'contact.invalidFormatField' }),
        file: formatMessage({ id: 'contact.fileTooLarge' }, { size: config.CONTACT_MAX_FILE_SIZE_MB }),
    };

    if (!subject) {
        errors.subject = errorMessages.requiredField;
    }
    if (!description) {
        errors.description = errorMessages.requiredField;
    }
    if (subject === ContactCaseSubject.ORDER && !orderNumber) {
        errors.orderNumber = errorMessages.requiredField;
    }
    if (orderNumber && !/^VO[0-9]+$/.test(orderNumber)) {
        errors.orderNumber = errorMessages.invalidFormat;
    }
    if (subject === ContactCaseSubject.TECHNICAL && !frameNumber) {
        errors.frameNumber = errorMessages.requiredField;
    }
    if (frameNumber && !validateFramenumber(frameNumber)) {
        errors.frameNumber = errorMessages.invalidFormat;
    }
    if (subject === ContactCaseSubject.RETURN && !returnCaseNumber) {
        errors.returnCaseNumber = errorMessages.requiredField;
    }
    if (returnCaseNumber && !/^[0-9]+$/.test(returnCaseNumber)) {
        errors.returnCaseNumber = errorMessages.invalidFormat;
    }
    if (subject === ContactCaseSubject.PRODUCT_INFO && reasonL4 === ContactCaseReasonL4.BATTERY && !batteryModel) {
        errors.batteryModel = errorMessages.requiredField;
    }
    if (batteryModel && !/^BA[0-9]+$/.test(batteryModel)) {
        errors.batteryModel = errorMessages.invalidFormat;
    }
    if (subject === ContactCaseSubject.WORKSTATUS_ORDER && !workOrderNumber) {
        errors.workOrderNumber = errorMessages.requiredField;
    }
    if (workOrderNumber && !/^[0-9]+$/.test(workOrderNumber)) {
        errors.workOrderNumber = errorMessages.invalidFormat;
    }

    if (attachments && attachments.length) {
        const sizeSums = attachments.reduce((sums, curr) => sums + curr.size, 0);

        if (sizeSums > config.CONTACT_MAX_FILE_SIZE) {
            errors.attachments = errorMessages.file;
        }
    }

    return errors;
};

export const parseData = (values: IValues) => {
    const { subject, description, reasonL3, reasonL4, attachments } = values;
    const queue = ContactCaseQueue[subject] ? ContactCaseQueue[subject] : ContactCaseQueue.OTHER;

    const data = new FormData();
    if (attachments && attachments.length) {
        attachments.forEach(file => data.append('attachments', file, file.name));
    }

    data.append('subject', subject);
    data.append('description', description);
    data.append('queue', queue);

    if (subject === ContactCaseSubject.TECHNICAL) {
        data.delete('type');
    } else {
        data.append('type', ContactCaseType.DEALER_CS);
    }

    if (ContactCaseReasonL2[subject]) {
        data.append('reasonL2', ContactCaseReasonL2[subject]);
    } else {
        data.delete('reasonL2');
    }

    if (subject === ContactCaseSubject.PRODUCT_INFO) {
        if (reasonL3) {
            data.append('reasonL3', reasonL3);
        }

        if (reasonL4) {
            data.append('reasonL4', reasonL4);

            if (RequiredField[reasonL4]) {
                const fieldName = RequiredField[reasonL4].name;
                // @ts-ignore
                data.append(fieldName, values[fieldName]);
            } else {
                data.delete(RequiredField[ContactCaseReasonL4.BATTERY].name);
            }
        }
    } else {
        data.delete('reasonL3');
        data.delete('reasonL4');
        data.delete(RequiredField[ContactCaseReasonL4.BATTERY].name);
    }

    if (RequiredField[subject]) {
        const fieldName = RequiredField[subject].name;
        // @ts-ignore
        data.append(fieldName, values[fieldName]);
    }

    return data;
};
