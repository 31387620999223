import { flow, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';
import { BikeAsset, TBikeAsset } from './types/BikesTypes';

const Bikes = types
    .model('BikesStore', {
        // TODO: use separete BikeAsset type for list and detailedList
        list: types.optional(types.map(BikeAsset), {}),
        detailedList: types.optional(types.map(BikeAsset), {}),
    })
    .volatile(() => ({
        isLoading: false,
        isLoaded: false,
        isActiveColumn: '',
        totalItems: 0,
    }))
    .views(self => ({
        get all() {
            return self.list;
        },
        getBikeById: (id: string) => {
            return self.detailedList.get(id);
        },
    }))
    .actions(self => ({
        getBikes: flow(function* (
            // dateStart: string = '2016-01-01T01:01:01Z',
            // dateEnd: string = '2022-01-01T01:01:01Z',
            limit: number = 1000,
            offset: number = 0,
        ) {
            self.list.clear();
            self.isLoading = true;
            try {
                const response: any = yield Service.getBikeAssets(limit, offset);

                if (response.data) {
                    const parsedBikes = response.data.map((bikeModelResponse: any) => bikeModelResponse.bike);
                    parsedBikes.forEach((bike: TBikeAsset) => {
                        self.list.set(bike.id, bike);
                    });
                    self.isLoaded = true;
                }
                if (response.meta && response.meta.totalRecords) {
                    self.totalItems = response.meta.totalRecords;
                }
            } catch (error) {
                console.error(error);
            }

            self.isLoading = false;
        }),
        getOneBikeById: flow(function* (SFId: string) {
            self.isLoading = true;

            try {
                const response: any = yield Service.getOneBikeAssetById(SFId);
                const bike: TBikeAsset = response.data.bike;
                if (response && response.status === 200 && bike) {
                    self.detailedList.set(bike.id, bike);
                }
                self.isLoaded = true;
            } catch (error) {
                console.error(error);
            } finally {
                self.isLoading = false;
            }
        }),
        setColumnValue: (toggle: string) => {
            self.isActiveColumn = toggle;
        },
    }));

export default Bikes;
