import { UserRoles } from '../utils/constants';
import { AppModules, AppPageRoutes } from './api/models';

export type RoleForbiddenAccessDefinitionsType = {
    [key in UserRoles]: {
        routes: AppPageRoutes[];
        modules: AppModules[];
    };
};

/*
 * Routes are validated automatically in ProtectedRoute.tsx, App.tsx and MenuListItems.tsx
 * Modules need manual validations where is required
 *
 * The Routes/Modules defined in the follow Object are the forbidden ones
 */

class RoleBaseAccess {
    private userRole: UserRoles | null = null;
    private roleDefinitions: RoleForbiddenAccessDefinitionsType | null = null;

    setUserRole(userRole: UserRoles) {
        this.userRole = userRole;
    }

    setDefinitions(roleDefinitions: RoleForbiddenAccessDefinitionsType) {
        this.roleDefinitions = roleDefinitions;
    }

    isModuleAllowed(appModule: AppModules) {
        if (
            !this.userRole ||
            !this.roleDefinitions ||
            (this.userRole && this.roleDefinitions && !this.roleDefinitions[this.userRole])
        ) {
            return false;
        }

        return !this.roleDefinitions[this.userRole].modules.includes(appModule);
    }

    isRouteAllowed(appRoute: AppPageRoutes = window.location.pathname as AppPageRoutes) {
        if (
            !this.userRole ||
            !this.roleDefinitions ||
            (this.userRole && this.roleDefinitions && !this.roleDefinitions[this.userRole])
        ) {
            return false;
        }

        return !this.roleDefinitions[this.userRole].routes.includes(appRoute);
    }
}

export default new RoleBaseAccess();
