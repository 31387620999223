import { Stack, Typography, useTheme } from '@mui/material';

export const BikeAutocompleteListItem = ({ bikeLabel, bikeDescription, ...props }: any) => {
    const theme = useTheme();
    return (
        <Stack
            {...props}
            sx={{
                padding: '0.5rem',
                cursor: 'pointer',
                '&:hover': { background: '#eee' },
                alignItems: 'flex-start !important',
            }}
        >
            <Typography>{bikeLabel}</Typography>
            <Typography variant="caption" color={theme.palette.secondary.light}>
                {bikeDescription}
            </Typography>
        </Stack>
    );
};
