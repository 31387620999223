import { types } from 'mobx-state-tree';

const PersistentTableConfig = types
    .model('', {
        tableRowsPerPage: types.optional(types.number, 10),
        tablePage: types.optional(types.number, 0),
    })
    .actions(self => ({
        setTableRowsPerPage(rowsPerPage: number) {
            self.tablePage = 0;
            self.tableRowsPerPage = rowsPerPage;
        },
        setTablePage(page: number) {
            self.tablePage = page;
        },
    }));

// Store pagination info from tables
const PersistentStore = types
    .model('PersistentStore', {
        bikeDashboardTable: types.optional(PersistentTableConfig, {}),
        productsTable: types.optional(PersistentTableConfig, {}),
        sparePartsTable: types.optional(PersistentTableConfig, {}),
        ordersTable: types.optional(PersistentTableConfig, {}),
        invoicesTable: types.optional(PersistentTableConfig, {}),
        returnsTable: types.optional(PersistentTableConfig, {}),
        shipItemsTable: types.optional(PersistentTableConfig, {}),
    });

export default PersistentStore;
