import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import useHotjar from 'src/services/hotjar/index';
import useUserGuiding from 'src/services/userGuiding';
import UserStore from 'src/stores/UserStore';
import CONFIG from 'src/utils/config';

// Separate using custom hooks services to prevent rerender from App
interface IntegrationServicesProps {
    user?: typeof UserStore.Type;
}

export const IntegrationServices = inject('user')(
    observer(({ user }: IntegrationServicesProps) => {
        const { initHotjar } = useHotjar();
        const { initUserGuiding, readyState: userGuidingReadyState, identifyUserGuiding } = useUserGuiding();

        const lang = user?.preferredLang;
        const userID = user!.userId;

        // Initializing Hotjar
        useEffect(() => {
            const hotjarId = parseInt(String(CONFIG.HOTJAR_ID), 10);
            const hotjarVersion = parseInt(String(CONFIG.HOTJAR_VERSION), 10);
            initHotjar(hotjarId, hotjarVersion, false, console.info);
        }, [initHotjar]);

        // Initializing UserGuiding
        useEffect(() => {
            initUserGuiding(console.info);
        }, [initUserGuiding]);

        useEffect(() => {
            // Sending attribute app_language to UserGuiding
            if (userGuidingReadyState && lang && userID) {
                identifyUserGuiding(userID, { app_language: lang });
            }
        }, [lang, userID, identifyUserGuiding, userGuidingReadyState]);

        return <></>;
    }),
);
