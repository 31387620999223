import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab, Stack, useTheme } from '@mui/material';
import { GetInputPropsOptions } from 'downshift';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PageLoader from 'src/components/PageLoader';

interface InputFieldProps {
    loading: boolean;
    inputLabelId: string;
    disableSearch: boolean;
    hideSearchButton?: boolean;
}

const InputField = ({
    loading,
    inputLabelId,
    disableSearch,
    hideSearchButton = false,
    ref,
    ...other
}: InputFieldProps & GetInputPropsOptions) => {
    const { palette } = useTheme();

    return (
        <Stack direction="row">
            <Field
                data-testid="search-by-input"
                name="searchInput"
                label={<FormattedMessage id={inputLabelId} />}
                variant="outlined"
                component={TextField}
                style={{ minWidth: '350px', color: palette.primary.dark }}
                InputProps={{
                    inputref: ref,
                    ...other,
                }}
            />
            {!hideSearchButton && (
                <Fab
                    sx={{ marginLeft: '-2em' }}
                    color="primary"
                    disabled={disableSearch}
                    type="submit"
                    data-testid="search-submit"
                >
                    {loading ? <PageLoader /> : <FontAwesomeIcon icon={faSearch} size="lg" />}
                </Fab>
            )}
        </Stack>
    );
};

export default InputField;
