export enum OrderNotificationStatusCode {
    DRAFT = 'Draft',
    ACTIVATED = 'Activated',
    PROCESSED = 'Processed',
    BACK_ORDER = 'Back Order',
    PRE_ORDER = 'Pre Order',
    CREDIT_CHECK = 'Credit Check',
    PROFORMA_PAYMENT = 'Proforma Payment',
    READY_FOR_SHIPMENT = 'Ready for shipment',
    WAITING_FOR_CUSTOMER = 'Waiting for customer',
    OFFICE_DELIVERY = 'Office delivery',
    SHIPPED = 'Shipped',
    ON_DEMAND = 'On Demand',
    RECEIVED_AT_EXPERIENCE_CENTER = 'Received at Experience Center',
    HANDED_OVER_TO_SERVICE_PARTNER = 'Handed over to Service Partner',
    READY_FOR_PICKUP = 'Ready for pick-up',
    HANDED_OVER_TO_CUSTOMER = 'Handed over to Customer',
    CANCELLED = 'Cancelled',
}

export type OrderNotificationStatusCodeKeys = keyof typeof OrderNotificationStatusCode;

export interface OrderNotification {
    id: string;
    erpOrderId: string; // Navision ID
    crmOrderId: string; // Salesforce ID
    crmAccountNumber: string; // Account number for dealer (max 40 chars)
    crmAccountId: string; // Account id for dealer (min 15 chars, max 18)
    crmOrderUpdatedAt: string; // Order Updated date; Format ISO 8601: yyyy-mm-ddTHH:mm:ssZ
    createdAt: string; // Notification created date; Format ISO 8601: yyyy-mm-ddTHH:mm:ssZ
    changedField: string; // Changed field name
    previousValue: string;
    currentValue:  string;
}
