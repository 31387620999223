import { Button, Card, Grid, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { subDays } from 'date-fns';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import CurrencyFormat from 'src/components/currency/Currency';
import Loading from 'src/components/loading/Loading';
import WarningBox from 'src/components/WarningBox';
import RootStore from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import { ProductCategoryTypes, FREE_SHIPPING_THRESHOLD, SHIPPING_FLAT_RATE } from 'src/utils/constants';
import { MinDeliveryDate } from 'src/utils/deliveryDateMaths';
import DeliveryDatePicker from './DeliveryDatePicker';
import EarliestShippingDate from './EarliestShippingDate';

interface CartDetailsProps extends WithStyles<typeof styles> {
    store?: typeof RootStore.Type;
    user?: typeof UserStore.Type;
    activeCartType: ProductCategoryTypes.BIKE | ProductCategoryTypes.PART;
    totalPrice: number;
}

const CartDetails = inject(
    'store',
    'user',
)(
    observer(({ classes, store, user, activeCartType, totalPrice }: CartDetailsProps) => {
        const navigate = useNavigate();

        const isActiveCartBikes = activeCartType === ProductCategoryTypes.BIKE;
        const earliestShippingDate = isActiveCartBikes
            ? store!.cart.bikesShippingDate
            : store!.cart.sparePartsShippingDate;
        const isEmpty = !store!.cart.items.length;
        const isActiveCartEmpty = store!.cart.isCartEmpty(activeCartType);

        const freeShippingWarning =
            !isActiveCartEmpty
            && !isActiveCartBikes
            && totalPrice < FREE_SHIPPING_THRESHOLD;
        const [freeShippingWarningClosed, setFreeShippingWarningClosed] = useState(false);

        const isSubmitDisabled = isActiveCartBikes
            ? isActiveCartEmpty || store!.cart.bundleWarning || store!.cart.totalBikesInCart > 3
            : isActiveCartEmpty || (freeShippingWarning && !freeShippingWarningClosed);
        const today = dayjs();
        const language = user && user?.preferredLang !== 'en-GB' ? user?.preferredLang.substring(0, 2) : 'en';

        const initialValues = () => ({
            deliveryDate: subDays(
                (earliestShippingDate ? dayjs(earliestShippingDate) : today).toDate(),
                MinDeliveryDate()
            )
        });
        return (
            <Card className={classes.card}>
                <Formik
                    initialValues={initialValues()}
                    enableReinitialize
                    validate={values => {
                        let errors: any = {};
                        if (isActiveCartBikes && !values.deliveryDate) {
                            errors.deliveryDate = (
                                <span>
                                    <FormattedMessage id="cart.error_required" />
                                </span>
                            );
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        store!.cart.placeOrder(values.deliveryDate, activeCartType, navigate).then(() => {
                            setSubmitting(false);
                        });
                    }}
                >
                    {({ values, errors, submitForm, isSubmitting, setFieldValue }) => (
                        <Form>
                            {isSubmitting ? (
                                <Grid
                                    container
                                    style={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <>
                                        <Typography>
                                            <FormattedMessage id="cart.placing_order" />
                                        </Typography>
                                        <Loading />
                                    </>
                                </Grid>
                            ) : (
                                <Grid container spacing={3}>
                                    <Grid item sm={12}>
                                        <div className={classes.earliestShippingDate}>
                                            {!isEmpty && store!.cart.totalBikesInCart < 4 && isActiveCartBikes && (
                                                <EarliestShippingDate
                                                    earliestShippingDate={earliestShippingDate}
                                                    today={today}
                                                    totalBikes={store!.cart.totalBikesInCart}
                                                />
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item sm={6}>
                                        {!isSubmitting && isActiveCartBikes && (
                                            <DeliveryDatePicker
                                                today={today}
                                                minDate={earliestShippingDate ? values.deliveryDate : undefined}
                                                selectedDate={values.deliveryDate}
                                                setSelectedDate={(date: Date | null) => {
                                                    setFieldValue('deliveryDate', date);
                                                }}
                                                errors={errors.deliveryDate}
                                                language={language}
                                            />
                                        )}
                                    </Grid>

                                    <Grid item sm={6}>
                                        <>
                                            <Typography align="right">
                                                <FormattedMessage id="cart.total" />
                                            </Typography>

                                            <Typography variant={'h6'} align="right">
                                                <CurrencyFormat value={totalPrice + (freeShippingWarning ? SHIPPING_FLAT_RATE : 0.0)} />
                                            </Typography>
                                        </>
                                    </Grid>

                                    {store!.cart.isDesirableDeliveryDateNextYear && store!.config.isDealer && (
                                        <Grid item sm={12}>
                                            <Typography variant={'body1'} className={classes.alert}>
                                                <FormattedMessage id="cart.nextYearPricesApplied" />
                                            </Typography>
                                        </Grid>
                                    )}

                                    <Grid item sm={12} className={classes.buttonWrapper}>
                                        <div data-testid="bundled-bikes-message" className={classes.messageWrapper}>
                                            {store!.cart.bundleWarning &&
                                            store!.cart.totalBikesInCart > 1 &&
                                            store!.cart.totalBikesInCart < 4 &&
                                            isActiveCartBikes ? (
                                                    <WarningBox
                                                        messageKey="warning.bundledBikes"
                                                        titleKey="warning.title"
                                                        closeBundleWarning={() => store!.cart.toggleBundleWarning(false)}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            {!store!.cart.bundleWarning &&
                                            store!.cart.totalBikesInCart > 3 &&
                                            isActiveCartBikes ? (
                                                    <WarningBox
                                                        messageKey="warning.maxthreebikes"
                                                        titleKey="warning.title"
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            {freeShippingWarning && !freeShippingWarningClosed ? (
                                                    <WarningBox
                                                        messageKey="warning.freeShippingWarning"
                                                        titleKey="warning.title"
                                                        closeBundleWarning={() => setFreeShippingWarningClosed(true)}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                        </div>
                                        <Button
                                            data-testid="submit-order"
                                            disabled={isSubmitDisabled || isSubmitting}
                                            variant="contained"
                                            color="primary"
                                            onClick={submitForm}
                                            fullWidth
                                        >
                                            <FormattedMessage id="cart.place_order" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Form>
                    )}
                </Formik>
            </Card>
        );
    }),
);

function styles({ breakpoints, palette }: Theme) {
    return createStyles({
        alert: {
            color: palette.secondary.main,
        },
        card: {
            flexGrow: 1,
            maxWidth: '100%',
            margin: '0 0 1.5%',
            padding: '0',
            boxShadow: 'none',
            [breakpoints.down('sm')]: {
                maxWidth: '100%',
                margin: '0 0 10%',
            },
            overflow: 'visible',
        },
        earliestShippingDate: {
            marginBottom: '1rem',
        },
        buttonWrapper: {
            position: 'relative',
        },
        messageWrapper: {
            position: 'absolute',
            bottom: '100%',
            left: 24,
            zIndex: 1,
        },
    });
}

export default withStyles(styles)(CartDetails);
