import { CardMedia } from '@mui/material';
import React from 'react';
import { TMarketingMaterial } from 'src/stores/types/MarketingMaterialTypes';

interface ImageProps {
    tool?: TMarketingMaterial;
    activeTool: string;
}
const MarketingImage = ({ tool, activeTool }: ImageProps) => {
    const handleOnClick = () => {
        if (tool?.caption) {
            try {
                // check if it is is a valid url
                const redirectUrl = new URL(tool.caption);

                window.open(redirectUrl, '_blank');
            } catch (e) {
                console.error(e);
            }
        }
    };
    return (
        <CardMedia
            data-testid="marketing-image"
            component="img"
            height="140"
            image={tool?.image}
            onClick={handleOnClick}
            sx={{
                height: 'auto',
                width: '100%',
                zIndex: 1,
                transition: 'all .3s ease',
                margin: '0 auto',
                display: tool?.alt_text === activeTool ? 'flex' : 'none',
                opacity: tool?.alt_text === activeTool ? 1 : 0,
                cursor: tool?.caption ? 'pointer' : 'auto',
            }}
        />
    );
};

export default MarketingImage;
