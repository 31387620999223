/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Sentry from '@sentry/browser';
import config from 'src/utils/config';
import CONFIG from './config';
import { StageEnviroments } from './constants';

export const logRejectedModel = (modelName: string, rejectedObject: any) => {
    if (CONFIG.APP_ENVIRONMENT !== StageEnviroments.PRODUCTION) {
        console.error(rejectedObject);
        console.error('rejected by model');
    } else if (CONFIG.ADD_SENTRY_RELEASE) {
        // TODO Is it better to log these into cloudwatch?
        Sentry.captureMessage('Object rejected by mobx', { extra: { modelName, rejectedModel: rejectedObject } });
    }
};

export const logFaultyProperty = (obj: any) => {
    if (CONFIG.APP_ENVIRONMENT !== StageEnviroments.PRODUCTION) {
        console.error(obj);
        console.error('faulty property');
    } else if (CONFIG.ADD_SENTRY_RELEASE) {
        // TODO Is it better to log these into cloudwatch?
        Sentry.captureMessage('Mobx model has wrong config', { extra: { faultyProperty: obj } });
    }
};

export const logBigFileUpload = (size: number) => {
    if (CONFIG.APP_ENVIRONMENT !== StageEnviroments.PRODUCTION) {
        console.error(`Error upload: file is bigger than ${config.RETURN_REQUEST_MAX_INVOICE_FILE_SIZE}`);
    } else if (CONFIG.ADD_SENTRY_RELEASE) {
        Sentry.captureMessage(`File is bigger than ${config.RETURN_REQUEST_MAX_INVOICE_FILE_SIZE}`, {
            extra: { size },
        });
    }
};

export const logHotjarError = (obj: any) => {
    if (CONFIG.APP_ENVIRONMENT !== StageEnviroments.PRODUCTION) {
        console.error(obj);
    } else if (CONFIG.ADD_SENTRY_RELEASE) {
        Sentry.captureMessage('Hotjar error', { extra: { hotjarError: obj } });
    }
};

export const logUserGuidingError = (obj: any) => {
    if (CONFIG.APP_ENVIRONMENT !== StageEnviroments.PRODUCTION) {
        console.error(obj);
    } else if (CONFIG.ADD_SENTRY_RELEASE) {
        Sentry.captureMessage('User guiding error', { extra: { userGuidingError: obj } });
    }
};
