import React from 'react';

export const Chains = () => {
    /* eslint-disable */
    return (
        <div>
            <svg id="chains" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 107">
                <g fill="none" fillRule="evenodd">
                    <g transform="rotate(12 71.36 1196.346)">
                        <path
                            fill="#DADADA"
                            d="M72.897 33.807c-5.79-4.76-12.245-7.14-19.363-7.14-7.117 0-13.571 2.38-19.362 7.14C30.562 37.623 25.47 40 19.828 40 8.878 40 0 31.046 0 20S8.877 0 19.828 0c5.609 0 10.673 2.35 14.28 6.125 5.848 4.806 12.324 7.208 19.426 7.208 7.103 0 13.579-2.402 19.427-7.208C76.567 2.35 81.632 0 87.24 0c10.95 0 19.828 8.954 19.828 20s-8.877 20-19.828 20c-5.642 0-10.733-2.377-14.344-6.193z"
                        />
                        <ellipse
                            cx="19.167"
                            fill="#FFF"
                            cy="20.667"
                            stroke="#FFF"
                            strokeWidth="10"
                            rx="9.914"
                            ry="10"
                        />
                        <ellipse cx="87.902" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                    </g>
                    <g transform="rotate(-1 3510.712 -22630.123)">
                        <path
                            fill="#DADADA"
                            d="M72.897 33.807c-5.79-4.76-12.245-7.14-19.363-7.14-7.117 0-13.571 2.38-19.362 7.14C30.562 37.623 25.47 40 19.828 40 8.878 40 0 31.046 0 20S8.877 0 19.828 0c5.609 0 10.673 2.35 14.28 6.125 5.848 4.806 12.324 7.208 19.426 7.208 7.103 0 13.579-2.402 19.427-7.208C76.567 2.35 81.632 0 87.24 0c10.95 0 19.828 8.954 19.828 20s-8.877 20-19.828 20c-5.642 0-10.733-2.377-14.344-6.193z"
                        />
                        <ellipse cx="19.167" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                        <ellipse cx="87.902" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                    </g>
                    <g transform="rotate(-58 302.164 -368.334)">
                        <path
                            fill="#DADADA"
                            d="M72.897 33.807c-5.79-4.76-12.245-7.14-19.363-7.14-7.117 0-13.571 2.38-19.362 7.14C30.562 37.623 25.47 40 19.828 40 8.878 40 0 31.046 0 20S8.877 0 19.828 0c5.609 0 10.673 2.35 14.28 6.125 5.848 4.806 12.324 7.208 19.426 7.208 7.103 0 13.579-2.402 19.427-7.208C76.567 2.35 81.632 0 87.24 0c10.95 0 19.828 8.954 19.828 20s-8.877 20-19.828 20c-5.642 0-10.733-2.377-14.344-6.193z"
                        />
                        <ellipse cx="19.167" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                        <ellipse cx="87.902" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                    </g>
                    <g transform="rotate(42 79.539 201.609)">
                        <path
                            fill="#DADADA"
                            d="M72.897 33.807c-5.79-4.76-12.245-7.14-19.363-7.14-7.117 0-13.571 2.38-19.362 7.14C30.562 37.623 25.47 40 19.828 40 8.878 40 0 31.046 0 20S8.877 0 19.828 0c5.609 0 10.673 2.35 14.28 6.125 5.848 4.806 12.324 7.208 19.426 7.208 7.103 0 13.579-2.402 19.427-7.208C76.567 2.35 81.632 0 87.24 0c10.95 0 19.828 8.954 19.828 20s-8.877 20-19.828 20c-5.642 0-10.733-2.377-14.344-6.193z"
                        />
                        <ellipse cx="19.167" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                        <ellipse cx="87.902" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                    </g>
                    <g transform="rotate(-18 144.12 -196.027)">
                        <path
                            fill="#DADADA"
                            d="M72.897 33.807c-5.79-4.76-12.245-7.14-19.363-7.14-7.117 0-13.571 2.38-19.362 7.14C30.562 37.623 25.47 40 19.828 40 8.878 40 0 31.046 0 20S8.877 0 19.828 0c5.609 0 10.673 2.35 14.28 6.125 5.848 4.806 12.324 7.208 19.426 7.208 7.103 0 13.579-2.402 19.427-7.208C76.567 2.35 81.632 0 87.24 0c10.95 0 19.828 8.954 19.828 20s-8.877 20-19.828 20c-5.642 0-10.733-2.377-14.344-6.193z"
                        />
                        <ellipse cx="19.167" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                        <ellipse cx="87.902" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                    </g>
                    <g transform="rotate(-34 135.982 27.36)">
                        <path
                            fill="#DADADA"
                            d="M72.897 33.807c-5.79-4.76-12.245-7.14-19.363-7.14-7.117 0-13.571 2.38-19.362 7.14C30.562 37.623 25.47 40 19.828 40 8.878 40 0 31.046 0 20S8.877 0 19.828 0c5.609 0 10.673 2.35 14.28 6.125 5.848 4.806 12.324 7.208 19.426 7.208 7.103 0 13.579-2.402 19.427-7.208C76.567 2.35 81.632 0 87.24 0c10.95 0 19.828 8.954 19.828 20s-8.877 20-19.828 20c-5.642 0-10.733-2.377-14.344-6.193z"
                        />
                        <ellipse cx="19.167" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                        <ellipse cx="87.902" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                    </g>
                    <g transform="rotate(-153 34.96 50.709)">
                        <path
                            fill="#DADADA"
                            d="M72.897 33.807c-5.79-4.76-12.245-7.14-19.363-7.14-7.117 0-13.571 2.38-19.362 7.14C30.562 37.623 25.47 40 19.828 40 8.878 40 0 31.046 0 20S8.877 0 19.828 0c5.609 0 10.673 2.35 14.28 6.125 5.848 4.806 12.324 7.208 19.426 7.208 7.103 0 13.579-2.402 19.427-7.208C76.567 2.35 81.632 0 87.24 0c10.95 0 19.828 8.954 19.828 20s-8.877 20-19.828 20c-5.642 0-10.733-2.377-14.344-6.193z"
                        />
                        <ellipse cx="19.167" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                        <ellipse cx="87.902" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                    </g>
                    <g transform="rotate(-33 201.776 -271.47)">
                        <path
                            fill="#DADADA"
                            d="M72.897 33.807c-5.79-4.76-12.245-7.14-19.363-7.14-7.117 0-13.571 2.38-19.362 7.14C30.562 37.623 25.47 40 19.828 40 8.878 40 0 31.046 0 20S8.877 0 19.828 0c5.609 0 10.673 2.35 14.28 6.125 5.848 4.806 12.324 7.208 19.426 7.208 7.103 0 13.579-2.402 19.427-7.208C76.567 2.35 81.632 0 87.24 0c10.95 0 19.828 8.954 19.828 20s-8.877 20-19.828 20c-5.642 0-10.733-2.377-14.344-6.193z"
                        />
                        <ellipse cx="19.167" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                        <ellipse cx="87.902" cy="20.667" stroke="#FFF" strokeWidth="3" rx="9.914" ry="10" />
                    </g>
                </g>
            </svg>
        </div>
    );
    /* eslint-enable */
};
