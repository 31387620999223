import { InfoOutlined, InfoRounded } from '@mui/icons-material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import LightTooltip from 'src/components/LightTooltip';
import { getFormattedDeliveryDate } from 'src/utils/getFormattedDeliveryDate';

interface ProductStockIndicatorProps extends WithStyles<typeof styles> {
    isInStock: boolean;
    isInDialog?: boolean;
    stockQuantity?: number | null;
    availableFrom?: string | null;
    dark?: boolean;
}

const ProductStockIndicator = ({
    isInStock = false,
    isInDialog = false,
    stockQuantity = 0,
    availableFrom = '',
    dark = false,
    classes,
}: ProductStockIndicatorProps) => {
    const intl = useIntl();

    let content,
        tooltip,
        computedClassName = classes.inStock,
        testId = 'inStock';

    if (availableFrom) {
        tooltip = getFormattedDeliveryDate(availableFrom).tooltip;
    }

    if (isInStock) {
        const availableQuantity = stockQuantity || 0;

        if (availableQuantity > 10) {
            content = intl.formatMessage({ id: 'status.inStock' });
        } else if (availableQuantity <= 10 && availableQuantity >= 5) {
            computedClassName = classes.lowStock;
            testId = 'lowStock';
            content = intl.formatMessage({ id: 'status.lowStock' });
            tooltip = intl.formatMessage({ id: 'status.lowStockTooltip' });
        } else if (availableQuantity < 5) {
            computedClassName = classes.criticalStock;
            testId = 'criticalStock';
            content = intl.formatMessage({ id: 'status.criticalStock' });
            tooltip = intl.formatMessage({ id: 'status.criticalStockTooltip' });
        }
    } else {
        const message = intl.formatMessage({ id: 'status.expectedAvailabilityFrom' });
        const date = availableFrom
            ? intl.formatDate(availableFrom, { year: 'numeric', month: 'long', day: 'numeric' })
            : intl.formatMessage({ id: 'status.Unavailable' });

        computedClassName = classes.noStock;
        testId = 'noStock';
        content = isInDialog && availableFrom ? `${message} ${date}` : date;
    }

    const computedClassNames = classNames(computedClassName, {
        [classes.container]: !isInDialog,
        [classes.dialogContainer]: isInDialog,
    });

    return (
        <>
            <span data-testid={`stock-indicator-${testId}`} className={computedClassNames}>
                {content}
            </span>

            {tooltip && (
                // @ts-ignore
                <LightTooltip title={tooltip} aria-label={tooltip}>
                    {dark ? (
                        <InfoRounded className={classes.infoIcon} fontSize="small" />
                    ) : (
                        <InfoOutlined className={classes.infoIcon} fontSize="small" />
                    )}
                </LightTooltip>
            )}
        </>
    );
};

function styles() {
    return createStyles({
        container: {
            marginLeft: '-2px',
            padding: '2px 4px',
            borderRadius: '2px',
            fontSize: '16px',
        },
        dialogContainer: {
            marginLeft: '-2px',
            padding: '6px 10px',
            borderRadius: '2px',
            fontSize: '16px',
        },
        inStock: {
            backgroundColor: '#0FAB79',
            color: 'white',
        },
        lowStock: {
            backgroundColor: '#ffeb46',
        },
        criticalStock: {
            backgroundColor: '#e61d1d',
            color: 'white',
        },
        noStock: {
            backgroundColor: '#d8d8d8',
        },
        infoIcon: {
            verticalAlign: 'middle',
            marginBottom: '3px',
            marginLeft: '8px',
            cursor: 'pointer',
        },
    });
}

const ProductStockIndicatorWithStyles = withStyles(styles)(ProductStockIndicator);

export default ProductStockIndicatorWithStyles;
