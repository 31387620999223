import { Field, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { FormattedMessage } from 'react-intl';
import { ReturnItemFormValues } from '../ReturnRequestFormv2';

export const SerialNumberField = ({ errors, touched, ...formikProps }: FormikProps<ReturnItemFormValues>) => {
    return (
        <Field
            label={<FormattedMessage id="returns.form_label_serialNumber" />}
            name="serialNumber"
            variant="outlined"
            fullWidth
            margin="normal"
            error={touched.serialNumber ? errors.serialNumber : undefined}
            component={TextField}
        />
    );
};
