import { Box, Typography, useTheme } from '@mui/material';
import { Field, FieldProps, FormikProps } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import uniqBy from 'lodash/uniqBy';
import { useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { subYears } from 'date-fns';
import { TextField } from 'formik-mui';

import RootStore from 'src/stores/RootStore';
import { TInvoiceBySparePart } from 'src/stores/InvoicesStore';
import { TSparePartToReturn } from 'src/stores/types/ReturnRequestModel';
import { WarningBoxLight } from 'src/components/WarningBoxLight';
import DatePicker from 'src/components/DatePicker';
import Link from 'src/components/Link';
import { FileUpload } from 'src/components/FileUpload';
import { DirectSupplierBrands } from 'src/utils/constants';

import { OUT_OF_WARRANTY, ReturnItemFormValues } from '../ReturnRequestFormv2';
import { BooleanRadioButton } from './BooleanRadioButton';
import { CustomCheckbox } from './CustomCheckbox';
import InvoiceNumberListPicker from './InvoiceNumberListPicker';
import { CustomDatePickerTextField } from './CustomDatePickerTextField';
import { ReturnReasonField } from './ReturnReasonField';
import { ReferenceField } from './ReferenceField';

interface SparePartFieldsProps {
    store?: typeof RootStore.Type;
    invoicesBySparePart?: TInvoiceBySparePart[];
    partToReturn: TSparePartToReturn;
    hideUpload?: boolean;
    preferredLang: string;
    needDescription: boolean;
    fromInvoice?: boolean;
}

export const SparePartFields = inject('store')(
    observer(
        ({
            store,
            invoicesBySparePart,
            partToReturn,
            hideUpload,
            preferredLang,
            needDescription,
            fromInvoice,
            ...formikProps
        }: SparePartFieldsProps & FormikProps<ReturnItemFormValues>) => {
            const intl = useIntl();
            const theme = useTheme();

            const [file, setFile] = useState<File>();

            const selectedSparePart = store!.spareParts.getSelectedSparePart;

            const isDirectClaim = useMemo(
                () => DirectSupplierBrands.includes(selectedSparePart?.brand),
                [selectedSparePart?.brand],
            );

            const handleRemoveFile = (setFieldValue: any) => (fileName: string) => {
                setFile(undefined);
                setFieldValue('attachment', undefined);
            };

            const { values, errors, touched, setFieldValue, setFieldTouched, isSubmitting, submitCount } = formikProps;

            return (
                <div>
                    <div style={{ paddingBottom: '1rem' }}>
                        <BooleanRadioButton
                            name="isBroken"
                            labelId="returns.form_label_isUnused"
                            yesLabelId="returns.form_label_isUnused_no"
                            noLabelId="returns.form_label_isUnused_yes"
                            isError={!!errors.isBroken && submitCount > 0}
                            error={errors.isBroken}
                            disabled={isSubmitting}
                            disabledYes={fromInvoice}
                            reverseOptions
                        />
                    </div>

                    {values.isBroken === 'no' && invoicesBySparePart && (
                        <>
                            {fromInvoice ? (
                                <Field
                                    label={<FormattedMessage id="returns.form_label_invoiceNumber" />}
                                    name="invoiceNumber"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    error={errors.invoiceNumber}
                                    component={TextField}
                                    disabled
                                />
                            ) : (
                                <InvoiceNumberListPicker
                                    items={invoicesBySparePart ? uniqBy(invoicesBySparePart, 'invoiceId') : undefined}
                                    value={values.invoiceNumber}
                                    onChange={(value: string) => setFieldValue('invoiceNumber', value)}
                                    error={touched.invoiceNumber && !!errors.invoiceNumber}
                                    helperText={touched.invoiceNumber ? errors.invoiceNumber : ''}
                                />
                            )}

                            <Typography variant="body1" color={theme.palette.text.disabled} marginTop={2}>
                                <FormattedMessage id="returns.form_part_unused_note" />
                            </Typography>

                            <Field
                                name="confirmFees"
                                Label={{
                                    label: (
                                        <Typography variant="subtitle2" marginY={2}>
                                            <FormattedMessage id="returns.form_label_confirm_fees_unused" />
                                        </Typography>
                                    ),
                                }}
                                component={CustomCheckbox}
                                type="checkbox"
                                sx={{
                                    color: errors.confirmFees && touched.confirmFees ? '#d32f2f' : 'secondary',
                                }}
                            />
                        </>
                    )}

                    {values.isBroken === 'no' && !invoicesBySparePart && !fromInvoice && (
                        <WarningBoxLight titleKey="returns.form.warning.unused.parts" />
                    )}

                    {values.isBroken === 'yes' && isDirectClaim && (
                        <WarningBoxLight
                            titleKey="returns.form.warning.used.part.supplier"
                            messageKey="returns.form.warning.used.part.supplier.message"
                            messageKeyValues={{
                                here: (
                                    <Link
                                        target="_blank"
                                        rel="noreferrer"
                                        href={intl.formatMessage({
                                            id: `returns.form.warning.used.part.supplier.message.link.href_${selectedSparePart.brand}`,
                                        })}
                                    >
                                        {intl.formatMessage({
                                            id: 'returns.form.warning.used.part.supplier.message.link',
                                        })}
                                    </Link>
                                ),
                            }}
                        />
                    )}

                    {values.isBroken === 'yes' && !isDirectClaim && (
                        <>
                            <Field name="consumerPurchaseDate">
                                {({ field, form: { touched, errors }, meta }: FieldProps) => (
                                    <DatePicker
                                        label={<FormattedMessage id="returns.form_label_date_of_sale" />}
                                        value={(field.value && new Date(field.value)) || null}
                                        onChange={val => {
                                            setFieldTouched(field.name, true);
                                            setFieldValue(field.name, val);
                                        }}
                                        onOpen={() => {
                                            setFieldTouched(field.name, true);
                                        }}
                                        CustomTextInput={CustomDatePickerTextField}
                                        maxDate={new Date()}
                                        minDate={subYears(new Date(), 10)}
                                        error={touched.consumerPurchaseDate ? errors.consumerPurchaseDate : ''}
                                        CustomErrorText={
                                            errors.consumerPurchaseDate === OUT_OF_WARRANTY ? (
                                                <div style={{ marginTop: '1rem' }}>
                                                    <WarningBoxLight
                                                        titleKey="return_request.out_of_warranty"
                                                        messageKey="return_request.out_of_warranty_message"
                                                    />
                                                </div>
                                            ) : undefined
                                        }
                                        useMobilePicker
                                    />
                                )}
                            </Field>

                            {!hideUpload && (
                                <Box sx={{ marginY: 2 }}>
                                    <FileUpload
                                        label={intl.formatMessage({
                                            id: 'returns.form_label_upload_invoice',
                                        })}
                                        value=""
                                        handleInputChange={(e: any) => {
                                            const currentFile = e.currentTarget.files[0];
                                            if (currentFile) {
                                                setFile(currentFile);
                                                setFieldValue('attachment', currentFile);
                                            }
                                        }}
                                        handleClickLabel={(e: any) => {
                                            setFieldTouched('attachment', true);
                                        }}
                                        isError={!!touched.attachment && !!errors.attachment}
                                        errorText={!!errors.attachment ? String(errors.attachment) : ''}
                                        uploadedFiles={file ? [file] : undefined}
                                        handleRemoveFile={handleRemoveFile(setFieldValue)}
                                    />
                                </Box>
                            )}

                            <ReturnReasonField
                                returnReasons={partToReturn.returnReasons}
                                preferredLang={preferredLang}
                                needDescription={needDescription}
                                {...formikProps}
                            />

                            <ReferenceField {...formikProps} />

                            <Field
                                name="confirmFees"
                                Label={{
                                    label: (
                                        <Typography variant="subtitle2" marginY={2}>
                                            <FormattedMessage id="returns.form_label_confirm_fees_used" />
                                        </Typography>
                                    ),
                                }}
                                component={CustomCheckbox}
                                type="checkbox"
                                sx={{
                                    color: errors.confirmFees && touched.confirmFees ? '#d32f2f' : 'secondary',
                                }}
                            />
                        </>
                    )}
                </div>
            );
        },
    ),
);
