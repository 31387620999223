import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { values } from 'mobx';
import { flow, getParent, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';
import { ReturnFlowType, SparePartCategory } from '../utils/constants';
import ModalStore, { ModalType } from './ModalStore';
import RootStore from './RootStore';
import { InvoiceLine, TInvoiceLine } from './types/Invoice';
import { ProductModel, TProductModel } from './types/ProductTypes';
import { ReturnReason, ReturnRequest, TReturnReason, TReturnRequest } from './types/ReturnRequestModel';

const Returns: any = types
    .model('ReturnsStore', {
        returnsList: types.optional(types.map(ReturnRequest), {}),
        returnReasons: types.optional(types.map(ReturnReason), {}),
    })
    .volatile(self => ({
        isReturnFormDialogOpen: false,
        loading: false,
        selectedReturnType: ReturnFlowType.NONE,
        warrantyChecked: false,
    }))
    .views(self => ({
        getReturnReasonById(returnReasonId: string) {
            return self.returnReasons.get(returnReasonId);
        },
        getReturnReasonsByCategory(category: string | null) {
            if (!category) {
                category = SparePartCategory.GENERAL;
            }
            return Array.from(self.returnReasons.values())
                .filter((reason: any) => reason.category === category)
                .sort((reason1: TReturnReason, reason2: TReturnReason) => {
                    return (
                        (reason2.reasonEN.includes('Otherwise') ? -1 : 0) -
                            (reason1.reasonEN.includes('Otherwise') ? -1 : 0) ||
                        reason1.reasonEN.localeCompare(reason2.reasonEN) ||
                        reason1.reasonNL.localeCompare(reason2.reasonNL) ||
                        reason1.reasonDE.localeCompare(reason2.reasonDE)
                    );
                });
        },
        get isReturnReasonsEmpty() {
            return isEmpty(values(self.returnReasons));
        },
        get returnListValues() {
            return values(self.returnsList);
        },
        getReturnById(returnId: string) {
            return values(self.returnsList).find((item: any) => item.id === returnId);
        },
        get readyToShipItems() {
            // Status 3 === 'Minimum value not yet reached', 10 === 'Send part back'
            return values(self.returnsList).filter((item: any) => item.pickUpStatus === 3 || item.pickUpStatus === 10);
        },
    }))
    .actions(self => ({
        setReturnType: (type: ReturnFlowType) => {
            self.selectedReturnType = type;
        },
        downloadPDF: flow(function* (id: string, lang: string) {
            try {
                const response: any = yield Service.getReturnRequestPdf(id, lang);
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `returnRequest-${id}.pdf`);
                document.body.appendChild(link);
                link.click();
            } catch (ex) {
                ModalStore.set({
                    title: 'notifications.error_title',
                    message: 'notifications.error_message',
                    type: ModalType.ERROR,
                    buttonText: 'notifications.error_ok',
                });
            }
        }),
        getReturnReasons: flow(function* () {
            self.loading = true;
            try {
                const response: any = yield Service.getReturnReasons();
                if (response && response.status === 200) {
                    response.data.forEach((reason: TReturnReason) => {
                        self.returnReasons.set(reason.id, reason);
                    });
                }
            } catch (error) {
                console.error({ error });
            } finally {
                self.loading = false;
            }
        }),

        parsePartToReturn: (sparePart: TProductModel | TInvoiceLine, lang: string) => {
            const rootStore = getParent<typeof RootStore.Type>(self, 1);

            if (ProductModel.is(sparePart)) {
                return {
                    id: sparePart.id,
                    type: sparePart.type,
                    deprecated: sparePart.productInformation.deprecated,
                    imageUrl: sparePart.productInformation.imageUrl,
                    description: sparePart.translatedDescription(lang),
                    serialNumber: sparePart.productInformation.serialNumber,
                    serialNumberRequiredForReturn: sparePart.serialNumberRequiredForReturn,
                    parentBike: rootStore.spareParts.searchResult.parentBike,
                    returnReasons: self.getReturnReasonsByCategory(sparePart.category),
                    category: sparePart.category || SparePartCategory.GENERAL,
                    warrantyExpiryDate: sparePart.warrantyExpiryDate,
                    isWarrantyExpired: sparePart.isWarrantyExpired,
                };
            } else if (InvoiceLine.is(sparePart)) {
                rootStore.mediaAssets.loadImage(sparePart.code);
                return {
                    id: sparePart.code,
                    type: sparePart.productType,
                    deprecated: false,
                    imageUrl: rootStore.mediaAssets.thumbnail(sparePart.code),
                    description: sparePart.description,
                    serialNumberRequiredForReturn: sparePart.serialNumberRequiredForReturn,
                    parentBike: null,
                    returnReasons: self.getReturnReasonsByCategory(sparePart.category),
                    category: sparePart.category || SparePartCategory.GENERAL,
                    warrantyExpiryDate: null,
                    isWarrantyExpired: false,
                };
            }

            return undefined;
        },

        toggleReturnFormDialog: () => {
            self.isReturnFormDialogOpen = !self.isReturnFormDialogOpen;
            const rootStore: typeof RootStore.Type = getParent(self);
            if (!self.isReturnFormDialogOpen && rootStore.cartReturns.editing) {
                rootStore.cartReturns.clearItemToEdit();
            }
        },

        getReturnRequests: flow(function* (limit?: number, offset?: number) {
            self.loading = true;
            try {
                const response: any = yield Service.getReturnRequests(limit, offset);

                if (response && response.status === 200) {
                    self.returnsList.clear(); // TODO: Not sure why we need to clear in order to update/re-render the table with new values
                    response.data.forEach((elem: TReturnRequest) => {
                        self.returnsList.set(elem.code, elem);
                    });
                }
                self.loading = false;
            } catch (error) {
                console.error(error);
                self.loading = false;
            }
        }),

        getReturnRequestById: flow(function* (returnId: string) {
            self.loading = true;
            try {
                const response: any = yield Service.getReturnRequestById(returnId);

                if (response && response.status === 200) {
                    const item = response.data;
                    self.returnsList.set(item.code, item);
                }
                self.loading = false;
            } catch (error) {
                console.error(error);
                self.loading = false;
            }
        }),
    }))
    .actions(self => ({
        confirmWarrantyChecked: () => {
            self.warrantyChecked = true;
            self.toggleReturnFormDialog();
        },
    }))
    .actions(self => ({
        getReturnForm: (productCode: string, lang: string) => {
            self.warrantyChecked = false;
            const rootStore = getParent<typeof RootStore.Type>(self, 1);
            rootStore.spareParts.setSelectedSparePartId(productCode);
            const partToReturn = self.parsePartToReturn(rootStore.spareParts.getSelectedSparePart!, lang);

            if (partToReturn?.warrantyExpiryDate && dayjs(partToReturn?.warrantyExpiryDate).isBefore(dayjs())) {
                ModalStore.set({
                    title: 'returns.noWarrantyWarning',
                    message: 'returns.noWarrantyMessage',
                    type: ModalType.CONFIRMATION,
                    buttonText: 'returns.yesButton',
                    onConfirm: () => self.confirmWarrantyChecked(),
                });
            } else {
                self.warrantyChecked = true;
                self.toggleReturnFormDialog();
            }
        },
    }));

export default Returns;
