import * as React from 'react';
import { toast } from 'react-toastify';
import BaseErrors from 'src/components/toasts/baseErrors';
import ContactCaseSent from 'src/components/toasts/contactCaseSent';
import RemoveItem from 'src/components/toasts/removeItem';
import Success from 'src/components/toasts/success';

const toastConfig = {
    position: toast.POSITION.BOTTOM_CENTER,
    hideProgressBar: true,
    autoClose: 3000,
};

class ToastService {
    addItem(translationId: string, onClickHandler?: () => void) {
        toast(<Success translationId={translationId} onClickHandler={onClickHandler} />, { ...toastConfig });
    }

    removeItem(translationId: string) {
        toast(<RemoveItem translationId={translationId} />, { ...toastConfig });
    }

    ErrorDownloadPdf() {
        toast(<BaseErrors translationId="error.pdfDownload" />, { ...toastConfig });
    }

    GeneralError() {
        toast(<BaseErrors translationId="error.general" />, { ...toastConfig });
    }

    PreferencesError() {
        toast(<BaseErrors translationId="error.dbStoreProblem" />, { ...toastConfig });
    }

    FreeTextError(textError: string) {
        toast(<BaseErrors freeText={textError} />, { ...toastConfig });
    }

    CartQuantitiesAdjusted(textError: string) {
        toast(<BaseErrors translationId="cart.quantitiesAdjusted" freeText={textError} />, { ...toastConfig, autoClose: false });
    }

    FavoriteAdd() {
        toast(<Success translationId="toast.favoriteAdded" />, { ...toastConfig });
    }

    FavoriteRemove() {
        toast(<Success translationId="toast.favoriteRemoved" />, { ...toastConfig });
    }

    contactCaseSent() {
        toast(<ContactCaseSent />, { ...toastConfig });
    }

    gpsFormSuccess(translationId: string) {
        toast(<Success translationId={translationId} />, { ...toastConfig });
    }

    gpsFormError(translationId: string) {
        toast(<BaseErrors translationId={translationId} />, { ...toastConfig });
    }
}

const Toast = new ToastService();
export default Toast;
