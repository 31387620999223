import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppPageRoutes } from 'src/services/api/models';
import CONFIG from 'src/utils/config';

const Auth0ProviderWithHistory: FC = ({ children }) => {
    const { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } = CONFIG;
    const REDIRECT_URI = `${window.location.origin}${AppPageRoutes.ROOT}`;

    const navigate = useNavigate();

    const onRedirectCallback = (appState?: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENT_ID}
            redirectUri={REDIRECT_URI}
            onRedirectCallback={onRedirectCallback}
            audience={AUTH0_AUDIENCE}
            cacheLocation="localstorage"
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
