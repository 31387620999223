import { faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Grid, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ProductImage from 'src/components/ProductImage';
import Button from 'src/components/button/Button';
import { ButtonCustomVariant } from 'src/components/button/type';
import { TCartReturnItemModel } from 'src/stores/types/ReturnCartItemModel';

interface CartItemProps {
    item: TCartReturnItemModel;
    onRemove?: () => void;
    onEdit?: () => void;
}

export const CartItem = ({ item, onRemove, onEdit }: CartItemProps) => {
    const theme = useTheme();

    const showActions = onEdit || onRemove;

    return (
        <>
            <Card
                data-testid={`cart-returns-item-${item.productCode}`}
                sx={{
                    maxWidth: '100%',
                    margin: '0 0 1rem',
                    boxShadow: 'none',
                }}
            >
                <Grid container spacing={2} xs={12} sx={{ marginLeft: 0, width: '100%' }}>
                    <Grid item xs={showActions ? 3 : 4}>
                        <ProductImage id={item.productCode} type={item.productType} />
                    </Grid>
                    <Grid item xs={showActions ? 7 : 8}>
                        <Typography title={item.productName}>{item.productName} </Typography>
                        <Typography>{`# ${item.productCode}`}</Typography>
                    </Grid>
                    {showActions && (
                        <Grid item xs={2}>
                            {onEdit && (
                                <FormattedMessage id="labels.edit">
                                    {text => (
                                        <Button
                                            customVariant={ButtonCustomVariant.ICON_BUTTON}
                                            tooltipTitle={text}
                                            size="small"
                                            onClick={onEdit}
                                            data-testid={`edit-cart-returns-item-${item.productCode}`}
                                            sx={{ padding: '8px' }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPencil}
                                                size="xs"
                                                color={theme.palette.secondary.light}
                                            />
                                        </Button>
                                    )}
                                </FormattedMessage>
                            )}
                            {onRemove && (
                                <FormattedMessage id="labels.remove">
                                    {text => (
                                        <Button
                                            customVariant={ButtonCustomVariant.ICON_BUTTON}
                                            tooltipTitle={text}
                                            size="small"
                                            onClick={onRemove}
                                            data-testid={`remove-cart-returns-item-${item.productCode}`}
                                            sx={{ padding: '8px' }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                                size="xs"
                                                color={theme.palette.secondary.light}
                                            />
                                        </Button>
                                    )}
                                </FormattedMessage>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Card>
        </>
    );
};
