import AddIcon from '@mui/icons-material/Add';
import { Card, Grid, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/button/Button';
import CurrencyFormat from 'src/components/currency/Currency';
import InputReference from 'src/components/InputReference';
import ProductAvailabilityLabel from 'src/components/productAvailabilityLabel';
import ProductImage from 'src/components/ProductImage';
import QuantitySelector from 'src/components/QuantitySelector';
import { AppModules } from 'src/services/api/models';
import RoleBaseAccess from 'src/services/RoleBaseAccess';
import Store from 'src/stores/RootStore';
import { TCartItem } from 'src/stores/types/CartItemModel';
import { TProductModel } from 'src/stores/types/ProductTypes';
import UserStore from 'src/stores/UserStore';

interface ProductDetailsContentProps extends WithStyles<typeof styles> {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
    product: TProductModel;
    onCancelButton: () => void;
    reference: string;
    updateReference: (val: string) => void;
    quantity: number;
    updateQuantity: (val: number) => void;
    setCartTypeToSparePart: () => void;
}

const ProductDetailsContent = inject(
    'store',
    'user',
)(
    observer(
        ({
            store,
            user,
            classes,
            product,
            onCancelButton,
            reference,
            updateReference,
            quantity,
            updateQuantity,
            setCartTypeToSparePart,
        }: ProductDetailsContentProps) => {
            const handleOnAddToCart = (item: TProductModel) => {
                const newProduct = {
                    id: `${item.id}`,
                    type: item.type,
                    product: item,
                    battery: null,
                    quantity,
                    reference,
                } as TCartItem;
                store!.cart.addCartItem(newProduct, quantity);
                setCartTypeToSparePart();
                onCancelButton();
            };

            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Card elevation={0} data-testid="product-img">
                            <ProductImage id={product.id} type={product.type} showThumbnail={false} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} className={classes.info}>
                        <div className={classes.infoHeader}>
                            <Typography className={classes.title} gutterBottom variant="h6" data-testid="product-title">
                                {product.translatedDescription(user!.preferredLang)}
                            </Typography>
                            {product.price !== null && (
                                <div className={classes.price}>
                                    <Typography variant="h4" color="primary" data-testid="product-price">
                                        <CurrencyFormat value={product.price} />
                                    </Typography>
                                </div>
                            )}
                            {product.productInformation.inStock && <ProductAvailabilityLabel
                                inStock={product.productInformation.inStock}
                                availableFrom={product.productInformation.availableFrom}
                            />}
                        </div>
                        {RoleBaseAccess.isModuleAllowed(AppModules.ORDER_PRODUCTS) && product.price !== null && (
                            <>
                                {!store!.marketingMaterials.isMarketingMaterialDetailDialogFromCart ? (
                                    <div className={classes.actions}>
                                        <div className={classes.itemAction}>
                                            <Grid container spacing={3}>
                                                <Grid item lg={12} sm={12}>
                                                    <InputReference
                                                        reference={reference}
                                                        updateReference={updateReference}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className={classes.itemAction}>
                                            <div className={classes.quantity}>
                                                <QuantitySelector
                                                    quantity={quantity}
                                                    maxQuantity={product.productInformation.stockQuantity}
                                                    updateQuantity={updateQuantity}
                                                />
                                            </div>
                                        </div>

                                        <Grid container spacing={3} className={classes.buttons}>
                                            <Grid item xs={12} sm={6}>
                                                <Button
                                                    onClick={onCancelButton}
                                                    variant="outlined"
                                                    fullWidth
                                                    className={classes.button}
                                                    data-testid="product-cancel-button"
                                                >
                                                    <FormattedMessage id="returns.form_label_cancel" />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Button
                                                    data-testid="add-product-button"
                                                    disabled={!product.productInformation.stockQuantity}
                                                    className={classes.button}
                                                    color="primary"
                                                    variant="contained"
                                                    fullWidth
                                                    onClick={() => handleOnAddToCart(product)}
                                                >
                                                    <FormattedMessage id="dialog.addToCart" />
                                                    <AddIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                ) : (
                                    <div className={classes.actions}>
                                        <div className={classes.buttons}>
                                            <Button
                                                data-testid="sparePart-detail-close"
                                                onClick={onCancelButton}
                                                variant="contained"
                                                color="primary"
                                            >
                                                <FormattedMessage id="dialog.backToCart" />
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            );
        },
    ),
);

function styles({ spacing }: Theme) {
    return createStyles({
        info: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginTop: '1rem',
        },
        infoHeader: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',

            '& > svg': {
                marginRight: spacing(1),
            },
        },
        title: {
            width: '100%',
        },
        price: {
            flex: 1,
        },
        actions: {
            display: 'flex',
            flexDirection: 'column',
            margin: '1rem 0',
        },
        itemAction: {
            display: 'flex',
            justifyContent: 'space-around',
            marginBottom: '2rem',
        },
        quantity: {
            display: 'flex',
            flex: 1,
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        button: {
            padding: '1em 2em',
            justifyContent: 'space-between',
        },
    });
}

export default withStyles(styles)(ProductDetailsContent);
